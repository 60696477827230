const SET_PLAYER_DATA = './playerData/SET-PLAYER-DATA';

const initialState = {
  playerData: {
    id: null,
    username: null,
    childEmail: null,
    screenname: null,
    firstname: null,
    password: null,
    last_login: null,
    status: null,
    createdAt: null,
    userId: null,
    accessAllVideos: false,
  },
};

const playerDataReducer = (state = initialState, action) => {
  if (action.type === SET_PLAYER_DATA) {
    return {
      ...state,
      playerData: {
        ...action.playerData,
      },
    };
  } else {
    return state;
  }
};

export const setOnlyChildData = playerData => ({
  type: SET_PLAYER_DATA,
  playerData,
});

export default playerDataReducer;
