import React, { useEffect, useMemo, useState } from 'react'
import styles from './Contest.module.sass'
import { ContestButtons } from './ContestButtons'
import { getContest } from '../../../../api/api'
import ViewContest from './View'

const Contest = ({ currentTeam, isManager, isPlayerManager }) => {
  const [contest, setContest] = useState(null)

  useEffect(() => {
    let isActive = true

    if (isActive) {
      if (currentTeam?.id) {
        getContest(currentTeam.id).then((res) => setContest(res))
      }
    }
  
    return () => {
      isActive = true
    }
  }, [currentTeam])

  const renderContest = useMemo(() => <ViewContest contest={contest} isManager={isManager} isPlayerManager={isPlayerManager} />, [contest])

  return (
    <div className={styles.contest} style={{
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '2rem'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        paddingBottom: '2rem',
      }}>
        <h2 className={styles.contest__title} style={{ textTransform: 'uppercase'}}>{currentTeam.name}</h2>
        <div className={styles.header}>
          <h2 className={styles.contest__info}>Team Training Contest</h2>
          <ContestButtons isManager={isManager} isPlayerManager={isPlayerManager} contest={contest?.contest} />
        </div>
      </div>
      {renderContest}
    </div>
  )
}

export default Contest