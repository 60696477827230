import React from 'react';
import styles from './UpgradePopUp.module.sass';
import path from '../../../config/routes.json';
import { Link } from 'react-router-dom';
import { Price } from '../../../constants/const.js';

const UpgradePopUp = ({ setShowUpgradePopup }) => {
  return (
    <>
      <div className={styles.upgrade_popupWrapper}></div>
      <div className={styles.upgrade_popup}>
        <div className={styles.closeImg} onClick={() => setShowUpgradePopup(false)}>
          &times;
        </div>
        <div className={styles.container}>
          <ul className={styles.suggestions_list}>
            <li className={styles.suggestions_list__item}>
              <h3 className={styles.suggestions_list__item__title}>All Access Pass</h3>
              <p className={styles.suggestions_list__item__text}>Starting at</p>
              <p className={styles.suggestions_list__item__price}>
                ${Price.FULL_PRICE} <span className={styles.year}>/ year</span>
              </p>
            </li>
            <li className={styles.suggestions_list__item}>
              <h3 className={styles.suggestions_list__item__title}>Teams & Clubs</h3>
              <p className={styles.suggestions_list__item__text}>Starting at</p>
              <p className={styles.suggestions_list__item__price}>
                ${Price.REQUEST_DEMO} <span className={styles.year}>/ year (per player)</span>
              </p>
              <a
                className={styles.button}
                href={path.teamDiscounts}
                target="_blank"
                rel="noreferrer"
              >
                Request Demo
                <img className={styles.arrow} src="/img/right-arrow.webp" alt="right arrow" />
              </a>
            </li>
            <li className={styles.suggestions_list__item__link}>
              <Link to={path.teamDiscounts} target="_blank">
                Team Discount ${Price.REQUEST_DEMO} per player - Request Demo
              </Link>
            </li>
          </ul>
          <section className={styles.container__details}>
            <h3 className={styles.header}>Membership Details</h3>
            <ul>
              <li className={styles.item}>
                <img className={styles.item__image} src="/img/check.webp" alt="check" />
                <span className={styles.check}>Over 5,000 Training Videos </span>
              </li>
              <li className={styles.item}>
                <img className={styles.item__image} src="/img/check.webp" alt="check" />
                <span className={styles.check}>Over 101 Fun Soccer Games</span>
              </li>
              <li className={styles.item}>
                <img className={styles.item__image} src="/img/check.webp" alt="check" />
                <span className={styles.check}>Free Lifetime Membership of Select Videos</span>
              </li>
              <li className={styles.item}>
                <img className={styles.item__image} src="/img/check.webp" alt="check" />
                <span className={styles.check}>Free 7-Day All Access Pass to Start</span>
              </li>
            </ul>
            <div className={styles.buttonWrapper}>
              <Link className={styles.buttonDefault} to={path.upgradePage}>
                Upgrade
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default UpgradePopUp;
