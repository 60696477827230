import { useQuery, useMutation } from '@tanstack/react-query';
import { getChallenge, duplicateChallenge, notifyChallenge } from '../api/api';
import { message } from 'antd';

const useCoachChallenge = (id) => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['coach-challenge', 'user', id],
    queryFn: async () => await getChallenge(id),
    enabled: !!id,
  });

  const { mutate: handleDuplicateChallenge } = useMutation({
    mutationFn: async ({ id, status }) => await duplicateChallenge(id, { status }),
    onSuccess: (_data, variables) => {
      console.log({ variables });
      message.success(
        `Successfully ${variables.status === 'ongoing' ? 'Repeat' : 'Stop'} Coach's Challenge`,
      );
    },
    onError: (err) => {
      console.error(err);
    },
    onSettled: () => {
      refetch();
    },
  });

  const { mutate: handleNotifyChallenge } = useMutation({
    mutationFn: async (challengeId) => await notifyChallenge(challengeId),
    onSuccess: () => {
      message.success("Successfully notified Coach's Challenge");
    },
    onError: (err) => {
      console.error(err);
      message.error("Failed to notified Coach's Challenge");
    },
    onSettled: () => {
      refetch();
    },
  });

  return {
    isLoading,
    data: data ?? null,
    error,
    refetch,
    handleDuplicateChallenge,
    handleNotifyChallenge,
  };
};

export default useCoachChallenge;
