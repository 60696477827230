import React from 'react';
import styles from './ReportRow.module.sass';

export const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function ReportRow(props) {
    return (
        <ul className={styles.report_row}>
            {props.months.map((element, index) => {
                return <li key={Months[index]} className={styles.report_row__item}>
                    <p className={styles.report_row__data}>{Months[index]}</p>
                    <p className={styles.report_row__data}>{element.allViewedVideoForMonth}</p>
                    <p className={styles.report_row__data}>{element.allTimeForMonth ? element.allTimeForMonth : '-'}</p>
                </li>
            })}
        </ul>
    );
};