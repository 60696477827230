const SET_USER_DATA = './userData/SET_USER_DATA';

const initialState = {
  userData: {
    userInfo: {
      id: null,
      email: '',
      firstname: '',
      lastname: '',
      username: '',
      status: '',
      address: '',
      country: '',
      createdAt: '',
      stripeSub: '',
      subPeriodEnd: '',
    },
    childInfo: {
      forChildId: '',
      childrenCount: 0,
    },
  },
};

const userDataReducer = (state = initialState, action) => {
  if (action.type === SET_USER_DATA) {
    return {
      ...state,
      userData: { ...action.userData },
    };
  } else {
    return state;
  }
};

export const setUserData = userData => ({ type: SET_USER_DATA, userData });

export default userDataReducer;
