import { SubfolderPageUrl } from '../constants/const';

const initialState = {
  data: [
    {
      title: 'Master the Ball',
      subTitle: '',
      actionType: 'SET_MASTER_THE_BALL_DATA',
      children: [
        {
          title: 'Quick 1,000 Touch Sessions',
          subTitle: '',
          actionType: 'SET_QUICK_1000_TOUCH_SESSIONS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Two Cone Essentials',
          subTitle: '',
          actionType: 'SET_TWO_CONE_ESSENTIALS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Four Cone & Lateral Essentials',
          subTitle: '',
          actionType: 'SET_FOUR_CONE_&_LATERAL_ESSENTIALS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Break Juggling Record',
      subTitle: '',
      actionType: 'SET_BREAK_JUGGLING_RECORD_DATA',
      children: [
        {
          title: 'Intermediate',
          subTitle: '',
          actionType: 'SET_INTERMEDIATE_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Advanced',
          subTitle: '',
          actionType: 'SET_ADVANCED_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Partner Tossing',
          subTitle: '',
          actionType: 'SET_PARTNER_TOSSING_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Dribble with More Speed & Control',
      subTitle: '',
      actionType: 'SET_DRIBBLE_WITH_MORE_SPEED_&_CONTROL_DATA',
      children: [
        {
          title: 'Dribbling In Space',
          subTitle: '',
          actionType: 'SET_DRIBBLING_IN_SPACE_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Tight Space Dribbling',
          subTitle: '',
          actionType: 'SET_TIGHT_SPACE_DRIBBLING_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Line Cone & Speed Dribbling',
          subTitle: '',
          actionType: 'SET_LINE_CONE_&_SPEED_DRIBBLING_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Freestyle',
          subTitle: '',
          actionType: 'SET_FREESTYLE_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Improve First Touch & Passing',
      subTitle: '',
      actionType: 'SET_IMPROVE_FIRST_TOUCH_&_PASSING_DATA',
      children: [
        {
          title: 'Rebounder Drills',
          subTitle: '',
          actionType: 'SET_REBOUNDER_DRILLS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Essential Passing',
          subTitle: '',
          actionType: 'SET_ESSENTIAL_PASSING_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Dominate 1v1 & Finishing',
      subTitle: '',
      actionType: 'SET_DOMINATE_1V1_&_FINISHING_DATA',
      children: [
        {
          title: '1v1 Moves',
          subTitle: '',
          actionType: 'SET_1V1_MOVES_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: '1v1 & Finishing',
          subTitle: '',
          actionType: 'SET_1V1_&_FINISHING_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: '1v1 Games',
          subTitle: '',
          actionType: 'SET_1V1_GAMES_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Make Quicker Turns & Change Direction',
      subTitle: '',
      actionType: 'SET_MAKE_QUICKER_TURNS_&_CHANGE_DIRECTION_DATA',
      children: [
        {
          title: 'Inside & Outside Foot',
          subTitle: '',
          actionType: 'SET_INSIDE_&_OUTSICE_FOOT_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Other Essential Turns',
          subTitle: '',
          actionType: 'SET_OTHER_ESSENTIAL_TURNS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
    {
      title: 'Build Soccer Fitness',
      subTitle: '',
      actionType: 'SET_BUILD_SOCCER_FITNESS_DATA',
      children: [
        {
          title: 'Speed & Agility',
          subTitle: '',
          actionType: 'SET_SPEED_&_AGILITY_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
        {
          title: 'Jump Rope & Circuit Fitness',
          subTitle: '',
          actionType: 'SET_JUMP_ROPE_&_CIRCUIT_FITNESS_DATA',
          subFolder: SubfolderPageUrl.videoSeries,
        },
      ],
    },
  ],
};

const selectVideosReducer = (state = initialState) => {
  return state;
};

export default selectVideosReducer;
