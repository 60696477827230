import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import styles from './Forgot.module.sass';
import { forgotPassRequest } from '../../../api/api';
import { useHistory } from 'react-router-dom';

function Forgot(props) {
  const history = useHistory();
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleFocus = () => {
    setIsError(false);
  };
  
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    }),
    onSubmit: async values => {
      try {
        const response = await forgotPassRequest(values.email)
        if (response.ok) {
          setIsSend(true);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.log(error)
      }
    },
  });

  return (
    <main className={styles.forgot}>
      <h2 className={styles.forgot__title}>Forgot Password</h2>
      <form className={styles.forgot__form} onSubmit={formik.handleSubmit}>
        <label className={styles.forgot__label} htmlFor="email">Email</label>
        <input
          className={formik.touched.email && formik.errors.email ? styles['errorsMessages'] : styles.forgot__input}
          id="email"
          type="email"
          name="email"
          placeholder="example@gmail.com"
          onFocus={handleFocus}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {
          isError && <p className={styles.forgot__error}>There are no accounts associated with this
            email address.</p>
        }
        <button type="submit" className={styles.forgot__button}>Send Password</button>
        <p className={styles.forgot__sign_in}>
          I remember my password!
          <Link to={props.path.sign.in} className={styles.forgot__link}>Sign In</Link>
        </p>
      </form>
      <br />
      {isSend && (history.push('/auth/resetPassword'))}
    </main>
  );
}

export default Forgot;
