import React from 'react';
import s from './NotFound.module.sass';

function NotFound() {
  return (
    <>
      <div className={s.container}>That page doesn&apos;t exist! <br /> 404 ERROR</div>
    </>
  );
}

export default NotFound;
