import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/** TODO: for React Router v6 - Redirect Component must be replaced by Navigate one */ 
function PrivateRouteToSearch ({render, path, exact, accessAllVideos }) {

  return (
    <Route
      exact={exact}
      path={path}
      render={(routeProps) => (
        accessAllVideos 
          ? render(routeProps)
          : <Redirect to={'/error404'} />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  accessAllVideos: state.playerDataReducer.playerData.accessAllVideos,
});

export default connect(mapStateToProps, null)(PrivateRouteToSearch);
