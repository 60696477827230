import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ThankYou.module.sass';
import phone from '../../../asset/home/18-layers.webp';
import FacebookShareButton from 'react-share/es/FacebookShareButton';
import TwitterShareButton from 'react-share/es/TwitterShareButton';
import EmailShareButton from 'react-share/es/EmailShareButton';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

function ThankYou() {
  useScrollToTop();
  const urlLink = 'http://anytime-soccer.com';

  return (
    <main className={styles.main}>
      <img src={phone} alt="phone" className={styles.img_phone} />
      <section>
        <h1>
          <strong>Thank You</strong>
          <br /> for Your Purchase
        </h1>
        <p>Have a friend who will enjoy Anytime Soccer Training?</p>
        <div>
          <span>Invite them to join us!</span>
          <img src="/img/home/arrow.webp" alt="arrow" className={styles.arrow} />
        </div>

        <div className={styles.social}>
          <div>
            <FacebookShareButton url={urlLink} style={{ outline: 'none' }}>
              <div className={styles.facebook}>
                <i className={`fab fa-facebook-f ${styles.icon}`} />
                Share on Facebook
              </div>
            </FacebookShareButton>
          </div>

          <div className={styles.socialContainer}>
            <TwitterShareButton
              url={urlLink}
              style={{ outline: 'none' }}
              title={'Anytime Soccer Training:'}
              via={'_anytimesoccer'}
              hashtags={['soccer', 'youthsoccer', 'soccertraining']}
              related={['S0RQuYirY5erEb8']}
            >
              <div className={styles.twitter}>
                <i className={`fab fa-twitter ${styles.icon}`} />
                Share on Twitter
              </div>
            </TwitterShareButton>
            <EmailShareButton
              style={{ outline: 'none' }}
              url={urlLink}
              subject={'We just purchased Anytime Soccer Training!'}
              body={
                'Hello,\n' +
                '\n' +
                'Have you tried Anytime Soccer training? It’s a training website with over 200 hours of' +
                ' individual soccer skills training videos. We purchased it because the videos are 100%' +
                ' follow-along and cover all the major areas of soccer.\n' +
                '\n' +
                'Check out Anytime Soccer Training and receive seven free training videos. Sign-up today' +
                ' and get 20% off your first year’s subscription using promo code FriendsSave20\n'
              }
            >
              <div className={styles.mail}>
                <i className={`far fa-envelope ${styles.icon}`} />
                Email
              </div>
            </EmailShareButton>
          </div>
        </div>
        <Link to={'/dashboard'} className={styles.other}>
          To dashboard
        </Link>
      </section>
    </main>
  );
}

export default ThankYou;
