const SWITCH_TO_LIST_VIEW = 'SWITCH_TO_LIST_VIEW';
const SWITCH_TO_GRID_VIEW = 'SWITCH_TO_GRID_VIEW';
const LIST_VIEW = 'LIST_VIEW';
const GRID_VIEW = 'GRID_VIEW';

const listGridViewReducer = (state = LIST_VIEW, action) => {
  switch (action.type) {
    case SWITCH_TO_LIST_VIEW:
      return LIST_VIEW;
    case SWITCH_TO_GRID_VIEW:
      return GRID_VIEW;
    default:
      return state;
  }
};

export const switchToListView = () => ({ type: SWITCH_TO_LIST_VIEW });
export const switchToGridView = () => ({ type: SWITCH_TO_GRID_VIEW });

export default listGridViewReducer;
