import React, { useEffect, useState } from 'react';
import styles from './TableButtons.module.sass';
import { ACCEPT, DECLINE, REMOVE, SEND_REMINDER } from '../../../../../../../constants/teams';
import Accept from '../../../../../../../asset/teams/roster/invitations/Accept_check.webp';
import Decline from '../../../../../../../asset/teams/roster/invitations/Decline_x.webp';
import Bell from '../../../../../../../asset/teams/roster/invitations/Bell_reminder.webp';
import {
  acceptPendingMember,
  // removeRequest,
  removeSentRequest,
  sendReminder,
} from '../../../../../../../api/teams-api';

const Update = {
  DECLINE: 'DECLINE',
  ACCEPT: 'ACCEPT',
  SEND_REMINDER: 'SEND_REMINDER',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
};

export function TableButtons({ table, childId, teamId, isUpdated, setIsUpdated }) {
  const [update, setUpdate] = useState(null);
  useEffect(() => {
    if (update === Update.DECLINE) {
      removeSentRequest({ childId, teamId }).then((response) => {
        responseHandler(response);
      });
    }
    if (update === Update.ACCEPT) {
      acceptPendingMember({ childId, teamId }).then((response) => {
        responseHandler(response);
      });
    }
    if (update === Update.SEND_REMINDER) {
      sendReminder({ childId, teamId }).then((response) => {
        responseHandler(response);
      });
    }
    if (update === Update.REMOVE_REQUEST) {
      removeSentRequest({ childId, teamId }).then((response) => {
        responseHandler(response);
      });
    }
  }, [update]);

  const handleRedButton = (table) => {
    table === 'Pending Invitations' ? setUpdate(Update.ACCEPT) : setUpdate(Update.SEND_REMINDER);
  };

  const handleGrayButton = (table) => {
    table === 'Pending Invitations' ? setUpdate(Update.DECLINE) : setUpdate(Update.REMOVE_REQUEST);
  };

  const responseHandler = (response) => {
    response.json().then(() => {
      if (response.ok) {
        setIsUpdated(!isUpdated);
      }
    });
  };

  const redButtonTitle = table === 'Pending Invitations' ? ACCEPT : SEND_REMINDER;
  const grayButtonTitle = table === 'Pending Invitations' ? DECLINE : REMOVE;

  return (
    <div className={styles.table_buttons}>
      <button className={styles.red_button} onClick={() => handleRedButton(table)}>
        <img
          className={styles.red_button__image}
          src={table === 'Pending Invitations' ? Accept : Bell}
          alt=""
        />
        {redButtonTitle}
      </button>
      <button className={styles.gray_button} onClick={() => handleGrayButton(table)}>
        <img className={styles.gray_button__image} src={Decline} alt="" />
        {grayButtonTitle}
      </button>
    </div>
  );
}
