import React from 'react';
import styles from './RedButton.module.sass';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../constants/const.js';

export function RedButton(props) {
  const handleButtonClick = () => {
    if (typeof props.handleButtonClick === 'function') props.handleButtonClick();
  };
  const { width } = useWindowDimensions();

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={props.className + ' ' + styles.red_button}
    >
      {props.children}
      {width <= MOBILE_DIMENSION && props.width ? (
        <p style={{ width: props.width }}>{props.title}</p>
      ) : (
        <p>{props.title}</p>
      )}
    </button>
  );
}
