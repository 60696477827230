import { SubfolderPageUrl } from './const';
import {
  setQuickTurnsData,
  setDribbleMasterData,
  setDribblingGamesData,
  set1v1MovesData,
  setClinicalFinishingData,
  setFinishingData,
  setWarmUpData,
  setBeginnerData,
  setFigure8Data,
  setLineConeData,
  set1000Touch,
  set100TouchPart1,
  set100TouchPart2,
  setLateralDynamicOther,
  setConeAndLateral,
  setLongDistanceAndOther,
  setRebounderAndWall,
  setCorePassing,
  setBattle1v1Finishing,
  setMoves1V1Pro,
  setFirstTouchPro,
  setTwoConePro,
  setTightSpaceDribbling,
  setLineConeAndConeSlalomDribbling,
  setShapePattern,
  setSpeedDribblingTurnsFitness,
  setObstacleLaneLateralDribbling,
  setCoreJugglingBeginnerToAdvanced,
  setSizeOneTennisBallJuggling,
  setPartnerTossAerialWallJuggling,
  setBodyAerialDribblingAerialFinishing,
  setPartnerDrills,
  setAdvancedJuggleMaster,
  setMiddleJuggleMaster,
  setLateralMovement,
  setBallMasteryData,
  setChangeDirection,
  setFastSpeedDribbleMaster,
  setPartnerDrillsPro,
  setPassingReceivingData,
  setDribbling1v1ShieldingData,
  setBoxDribbling,
  setDirectionalControlRapidFire,
  setDribblingFinishing,
  setDribblePassShoot,
  setAerialControlOther,
  setFreeKicksPenalties,
  setOneTwoFourCone,
  setOtherProgramsBallMastery,
  setOtherDribblingProgramsData,
  setJugglingAndArealProgramsData,
  setOtherJugglingAndArealProgramsData,
  setOtherTightDribblingProgramsData,
  setOtherFigure8Programs,
} from '../Redux/dashboardChildReducer';

/** This object uses in GridCards component
 * This solution isn't optimal
 */
export const Routing = {
  [SubfolderPageUrl.quickTurns]: setQuickTurnsData,
  [SubfolderPageUrl.dribbleMaster]: setDribbleMasterData,
  [SubfolderPageUrl.dribblingGames]: setDribblingGamesData,
  [SubfolderPageUrl.finishing1v1]: setFinishingData,
  [SubfolderPageUrl.dribbling1v1Shielding]: setDribbling1v1ShieldingData,
  [SubfolderPageUrl.moves]: set1v1MovesData,
  [SubfolderPageUrl.clinicalFinishing]: setClinicalFinishingData,
  [SubfolderPageUrl.warmUp]: setWarmUpData,
  [SubfolderPageUrl.beginner]: setBeginnerData,
  [SubfolderPageUrl.figure8]: setFigure8Data,
  [SubfolderPageUrl.lineCone]: setLineConeData,
  [SubfolderPageUrl.boxDribbling]: setBoxDribbling,
  [SubfolderPageUrl.touch1000]: set1000Touch,
  [SubfolderPageUrl.touch1000Part1]: set100TouchPart1,
  [SubfolderPageUrl.touch1000Part2]: set100TouchPart2,
  [SubfolderPageUrl.lateralDynamicOther]: setLateralDynamicOther,
  [SubfolderPageUrl.coneAndLateral]: setConeAndLateral,
  [SubfolderPageUrl.longDistanceAndOther]: setLongDistanceAndOther,
  [SubfolderPageUrl.rebounderAndWall]: setRebounderAndWall,
  [SubfolderPageUrl.corePassing]: setCorePassing,
  [SubfolderPageUrl.battle1v1Finishing]: setBattle1v1Finishing,
  [SubfolderPageUrl.moves1V1Pro]: setMoves1V1Pro,
  [SubfolderPageUrl.firstTouchPro]: setFirstTouchPro,
  [SubfolderPageUrl.twoConePro]: setTwoConePro,
  [SubfolderPageUrl.tightSpaceDribbling]: setTightSpaceDribbling,
  [SubfolderPageUrl.lineConeAndConeSlalomDribbling]: setLineConeAndConeSlalomDribbling,
  [SubfolderPageUrl.shapePattern]: setShapePattern,
  [SubfolderPageUrl.speedDribblingTurnsFitness]: setSpeedDribblingTurnsFitness,
  [SubfolderPageUrl.obstacleLaneLateralDribbling]: setObstacleLaneLateralDribbling,
  [SubfolderPageUrl.coreJugglingBeginnerToAdvanced]: setCoreJugglingBeginnerToAdvanced,
  [SubfolderPageUrl.sizeOneTennisBallJuggling]: setSizeOneTennisBallJuggling,
  [SubfolderPageUrl.partnerTossAerialWallJuggling]: setPartnerTossAerialWallJuggling,
  [SubfolderPageUrl.bodyAerialDribblingAerialFinishing]: setBodyAerialDribblingAerialFinishing,
  [SubfolderPageUrl.partnerDrills]: setPartnerDrills,
  [SubfolderPageUrl.advancedJuggleMaster]: setAdvancedJuggleMaster,
  [SubfolderPageUrl.middleJuggleMaster]: setMiddleJuggleMaster,
  [SubfolderPageUrl.lateralMovement]: setLateralMovement,
  [SubfolderPageUrl.ballMastery]: setBallMasteryData,
  [SubfolderPageUrl.changeDirection]: setChangeDirection,
  [SubfolderPageUrl.fastSpeedDribbleMaster]: setFastSpeedDribbleMaster,
  [SubfolderPageUrl.partnerDrillsPro]: setPartnerDrillsPro,
  [SubfolderPageUrl.passingReceivingData]: setPassingReceivingData,
  [SubfolderPageUrl.directionalControlRapidFire]: setDirectionalControlRapidFire,
  [SubfolderPageUrl.dribblingFinishing]: setDribblingFinishing,
  [SubfolderPageUrl.dribblePassShoot]: setDribblePassShoot,
  [SubfolderPageUrl.aerialControlOther]: setAerialControlOther,
  [SubfolderPageUrl.freeKicksPenalties]: setFreeKicksPenalties,
  [SubfolderPageUrl.oneTwoFourCone]: setOneTwoFourCone,
  [SubfolderPageUrl.otherProgramsBallMasetery]: setOtherProgramsBallMastery,
  [SubfolderPageUrl.otherDribblingPrograms]: setOtherDribblingProgramsData,
  [SubfolderPageUrl.jugglingAndArealPrograms]: setJugglingAndArealProgramsData,
  [SubfolderPageUrl.otherJugglingAndArealPrograms]: setOtherJugglingAndArealProgramsData,
  [SubfolderPageUrl.otherTightDribblingPrograms]: setOtherTightDribblingProgramsData,
  [SubfolderPageUrl.otherFigure8Programs]: setOtherFigure8Programs,
};
