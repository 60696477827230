const SET_SUBSCRIPTION_PRICE_DATA = './subscriptionPriceData/SET-SUBSCRIPTION-PRICE-DATA';

const initialState = {
  subscriptionPriceData: {},
};

const subscriptionPriceDataReducer = (state = initialState, action) => {
  if (action.type === SET_SUBSCRIPTION_PRICE_DATA) {
    return {
      ...state,
      subscriptionPriceData: {
        ...action.subscriptionPriceData,
      },
    };
  } else {
    return state;
  }
};

export const getSubscriptionPriceDataAction = subscriptionPriceData => ({
  type: SET_SUBSCRIPTION_PRICE_DATA,
  subscriptionPriceData,
});

export default subscriptionPriceDataReducer;
