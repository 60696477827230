import React, { useState, useRef, useEffect } from 'react';
import styles from './CreateChallengeForm.module.sass';
import { message } from 'antd';
import { GOAL_MAX_VIDEO_COUNT, MAX_DURATION_GOAL } from '../../constants/const.js';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createChallenge } from '../../api/api.js';
import { useQueryClient } from '@tanstack/react-query';

function CreateChallengeFormComponent() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentTeam } = useSelector((state) => state.teamReducer);
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    let isActive = true;

    if (isActive && !currentTeam?.name) {
      history.push('/dashboardChild');
    }

    return () => {
      isActive = false;
    };
  }, [currentTeam]);

  const [formData, setFormData] = useState({
    name: '',
    videoGoal: 10,
    targetDate: '',
    teamId: currentTeam.id,
  });
  const formRef = useRef();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (checkDate(formData.targetDate)) {
      setIsSubmitting(true);
      await createChallenge(formData).then((res) => {
        console.log({ res });
        setIsSubmitting(false);
        queryClient.invalidateQueries({
          queryKey: ['coach-challenge', 'user', currentTeam.id],
        });
        history.push('/teams/dashboard?nav=challenge');
        message.success("Successfully Created Coach's Challenge");
      });
    } else {
      message.error('You can set the end date no later than next three months');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const checkDate = (date) => {
    const currentDate = new Date();
    const targetDate = moment(date);
    const duration = targetDate.diff(currentDate, 'months', true);

    return duration > 0 && duration <= MAX_DURATION_GOAL;
  };

  const handleInputDateBlur = (event) => {
    if (!checkDate(event.target.value)) {
      message.error('You can set the end date no later than next three months');
    }
  };

  const handleEnterKeyDown = (event) => {
    if (event.keyCode === 13 && event.target.type !== 'submit') {
      event.preventDefault();
      handleFormSubmit(event);
    }
  };

  return (
    <section className={styles.section}>
      <img src="/img/photo.webp" className={styles.section_img} alt="Decorative picture" />
      <form
        aria-label="Training Plan Form"
        ref={formRef}
        onSubmit={handleFormSubmit}
        onKeyDown={handleEnterKeyDown}
        className={styles.form}
      >
        <h1 className={styles.form__title}>Create Coach&apos; Challenges</h1>
        <p className={styles.form__description}>Set coach challenges.</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <label className={styles.form__item_label}>
            Challenge Name
            <input
              className={styles.form__item}
              type="text"
              name="name"
              placeholder="Challenge Name"
              required
              onChange={handleInputChange}
              value={formData.name}
            />
          </label>
          <label className={styles.form__item_label}>
            Video Goal
            <input
              className={styles.form__item}
              type="number"
              min="1"
              max={GOAL_MAX_VIDEO_COUNT}
              name="videoGoal"
              placeholder={`Min. 50 / Max. ${GOAL_MAX_VIDEO_COUNT}`}
              required
              value={formData.videoGoal}
              onChange={handleInputChange}
              style={{ marginBottom: '.9rem', marginTop: '.5rem' }}
            />
          </label>
          <label className={styles.form__item_label}>
            Target Date
            <input
              className={styles.form__item}
              type="date"
              name="targetDate"
              required
              value={formData.targetDate}
              onChange={handleInputChange}
              onBlur={handleInputDateBlur}
            />
          </label>
          {/* <label className={styles.form__item_label}>
            Reward
            <input
              className={styles.form__item}
              type="text"
              name="reward"
              maxLength="40"
              placeholder="Reward"
              value={formData.reward}
              onChange={handleInputChange}
            />
          </label>
          <label className={styles.form__item_label}>
            <span>
              <img
                src="/img/teams/top-reward.png"
                alt="Top Reward"
                style={{
                  width: '1.4rem',
                  height: '1.4rem',
                }}
              />{' '}
              Top Reward
            </span>
            <input
              className={styles.form__item}
              type="text"
              name="topReward"
              maxLength="40"
              placeholder="Top Reward"
              value={formData.topReward}
              onChange={handleInputChange}
            />
          </label> */}
          <button type="submit" className={styles.button} disabled={isSubmitting}>
            <span className={styles.button__title}>
              {isSubmitting ? 'Submitting' : 'Get started'}
            </span>
          </button>
        </div>
      </form>
    </section>
  );
}

export default CreateChallengeFormComponent;
