import React from 'react';
import styles from './mainDashboardCard.module.sass';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SubfolderPageUrl } from '../../../constants/const';

const MainDashboardCard = (props) => {
  const {
    item,
    isAdditionalCardType,
    accessAllVideos,
    setShowUpgradePopup,
    isSpecialPrograms,
    isSkillAreas,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const updateDashboardChildInfo = () => {
    if (item.actionType !== undefined) {
      dispatch({ type: item.actionType });
    }
  };

  const checkUrl = (url) => {
    switch (url) {
      case SubfolderPageUrl.videoCategories:
        return `${SubfolderPageUrl.videoCategories}/${item.vimeoName}subtitle=${item.subTitle}`;
      default:
        return url;
    }
  };
  const replaceToVideoCategory = () => {
    if (accessAllVideos !== true) {
      return setShowUpgradePopup(true);
    } else if (isSkillAreas) {
      updateDashboardChildInfo();
      history.push(checkUrl('/dashboardChild/skillAreas'));
    } else {
      updateDashboardChildInfo();
      history.push(checkUrl(item.link));
    }
  };

  return isAdditionalCardType ? (
    <article className={styles.container}>
      <section tabIndex="0" onClick={replaceToVideoCategory} className={styles.additional_card}>
        <img
          src={item.img}
          className={styles.img}
          alt={item.title}
          style={isSpecialPrograms ? { border: '0' } : {}} // because in 'Special Programs' there are images with borders used. But in 'Skill Areas' are not.
        />
      </section>
      <h3 className={styles.title}>{item.title}</h3>
      <h3 className={styles.subTitle}>{item.subTitle}</h3>
    </article>
  ) : (
    <article tabIndex="0" onClick={replaceToVideoCategory} className={styles.main_card}>
      <img src={item.img} className={styles.img} alt={item.title} />
      <h3 className={styles.title}>{item.title}</h3>
      <h3 className={styles.subTitle}>{item.subTitle}</h3>
    </article>
  );
};

export default MainDashboardCard;
