const listViewCategoryReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_CATEGORY_LIST_VIEW_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const setCategory = (categoryActionType) => {
  return { type: 'SET_CATEGORY_LIST_VIEW_DATA', payload: categoryActionType };
};

export default listViewCategoryReducer;
