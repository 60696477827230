import React, { useState } from 'react';
import styles from './Invitations.module.sass';
import { pendingInvitationsHeaders, requestsSentHeaders } from '../../../../../constants/teams.js';
import { useScrollToTop } from '../../../../../hooks/useScrollToTop';
import { InvitationRow } from './InvitationRow/InvitationRow';
import { Switch, message } from 'antd'
import { updateTeamGoals } from '../../../../../api/teams-api.js';
import { getFormData } from '../../../../../utils/utils.js';
import { setCurrentTeam } from '../../../../../Redux/teamReducer.js';
import { useDispatch } from 'react-redux';

export function Invitations({ teamId, invitations, requests, isUpdated, setIsUpdated, isAutoAccept }) {
  useScrollToTop();
  const [autoAccept, setAutoAccept] = useState(isAutoAccept)
  const dispatch = useDispatch()
  

  const onChange = (checked) => {
    setAutoAccept(checked)
    const formData = getFormData({
      teamId,
      autoAcceptPending: checked
    });

    updateTeamGoals(formData).then(async (response) => {
      const data = await response.json((data) => data)

      dispatch(setCurrentTeam(data))
      
      message.success('Successfully updated Auto-Accept!');
    });
  };

  return (
    <section className={styles.invitations}>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {pendingInvitationsHeaders.map(header => (
              <th scope="col" className={styles.player_data__header__item} key={header}>
                <div>{header}</div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: '#78a3be'
                }}>Auto-Accept: <Switch onChange={onChange} checked={autoAccept} />
                  <span style={{
                    color: autoAccept ? '#e1343f' : 'inherit'
                  }}>{autoAccept ? 'ON' : 'OFF'}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          <InvitationRow
            invitations={invitations}
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
            teamId={teamId}
          />
        </tbody>
      </table>
      <h2 className={styles.requests__title}>Request Sent</h2>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {requestsSentHeaders.map(header => (
              <th scope="col" className={styles.player_data__header__item} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          <InvitationRow
            requests={requests}
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
            teamId={teamId}
          />
        </tbody>
      </table>
    </section>
  );
}
