import React from 'react';
import styles from './resetPasswordSuccess.module.sass';
import editPlayerThankPic from '../../../asset/editPlayerThank/editPlayerThankPic.webp';
import { useHistory } from 'react-router-dom';
import { withRoleRedirectComp } from '../../../hoc/redirectComp';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

const ResetPasswordSuccess = () => {
  useScrollToTop();

  const history = useHistory();
  const redirectToForgotPage = () => {
    history.replace('/auth/forgot');
  };

  return (
    <main>
      <div className={styles.mainDiv}>
        <div className={styles.subDiv}>
          <img src={editPlayerThankPic} alt="Thank pic" />
          <h4>Thank You!</h4>
          <h5>Check your inbox for a password reset email</h5>
          <button
            onClick={redirectToForgotPage}
            className={styles.buttonDefault}
            style={{ outline: 'none' }}
          >
            Got It
          </button>
        </div>
      </div>
    </main>
  );
};

export default withRoleRedirectComp(ResetPasswordSuccess);
