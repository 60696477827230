import React, { useState, useRef, useEffect } from 'react';
import styles from './CreateContestForm.module.sass';
import { message } from 'antd';
import { GOAL_MAX_VIDEO_COUNT, MAX_DURATION_GOAL } from '../../constants/const.js';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { createContest } from '../../api/api.js';

function CreateContestFormComponent() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentTeam } =  useSelector(state => state.teamReducer);
  const history = useHistory();

  useEffect(() => {
    let isActive = true

    if (isActive && !currentTeam?.name) {
      history.push('/dashboardChild')
    }

    return () => {
      isActive = false
    }
  }, [currentTeam])

  const [formData, setFormData] = useState({
    name: currentTeam.name,
    videoGoal: 50,
    targetDate: '',
    reward: '',
    topReward: '',
    teamId: currentTeam.id
  });
  const formRef = useRef();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (checkDate(formData.targetDate)) {
      setIsSubmitting(true);
      await createContest(formData).then(res => {
        console.log({ res })
        setIsSubmitting(false)
        history.push('/teams/contest')
      })
    } else {
      message.error('You can set the end date no later than next three months');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'reward' || name === 'topReward') {
      const fieldNames = {
        reward: "Reward",
        topReward: "Top Reward"
      };

      if (value.length > 16) {
        message.error(`${fieldNames[name]} field maximum is 16 characters`);
        event.preventDefault();
        return;
      }
    }

    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const checkDate = (date) => {
    const currentDate = new Date();
    const targetDate = moment(date);
    const duration = targetDate.diff(currentDate, 'months', true);

    return duration > 0 && duration <= MAX_DURATION_GOAL;
  };

  const handleInputDateBlur = (event) => {
    if (!checkDate(event.target.value)) {
      message.error('You can set the end date no later than next three months');
    }
  };


  const handleEnterKeyDown = (event) => {
    if (event.keyCode === 13 && event.target.type !== 'submit') {
      event.preventDefault();
      handleFormSubmit(event);
    }
  };

  return (
    <section className={styles.section}>
      <img src="/img/photo.webp" className={styles.section_img} alt="Decorative picture" />
      <form
        aria-label="Training Plan Form"
        ref={formRef}
        onSubmit={handleFormSubmit}
        onKeyDown={handleEnterKeyDown}
        className={styles.form}
      >
        <h1 className={styles.form__title}>Create Team Training Contest</h1>
        <p className={styles.form__description}>
          Set a team training contest and reward your players.
        </p>
        <label className={styles.form__item_label}>
          Full Name
          <input
            className={styles.form__item}
            type="text"
            name="teamName"
            placeholder="Team Name"
            required
            readOnly
            value={formData.name}
          />
        </label>
        <label className={styles.form__item_label}>
          Video Goal
          <input
            className={styles.form__item}
            type="number"
            min="50"
            max={GOAL_MAX_VIDEO_COUNT}
            name="videoGoal"
            placeholder={`Min. 50 / Max. ${GOAL_MAX_VIDEO_COUNT}`}
            required
            value={formData.videoGoal}
            onChange={handleInputChange}
          />
        </label>
        <label className={styles.form__item_label}>
          Target Date
          <input
            className={styles.form__item}
            type="date"
            name="targetDate"
            required
            value={formData.targetDate}
            onChange={handleInputChange}
            onBlur={handleInputDateBlur}
          />
        </label>
        <label className={styles.form__item_label}>
          Reward
          <input
            className={styles.form__item}
            type="text"
            name="reward"
            maxLength="40"
            placeholder="Reward"
            value={formData.reward}
            onChange={handleInputChange}
          />
        </label>
        <label className={styles.form__item_label}>
          <span><img src="/img/teams/top-reward.png" alt="Top Reward" style={{
            width: '1.4rem',
            height: '1.4rem'
          }} /> Top Reward</span>
          <input
            className={styles.form__item}
            type="text"
            name="topReward"
            maxLength="40"
            placeholder="Top Reward"
            value={formData.topReward}
            onChange={handleInputChange}
          />
        </label>
        <button type="submit" className={styles.button} disabled={isSubmitting}>
          <span className={styles.button__title}>{isSubmitting ? 'Submitting' : 'Get started'}</span>
        </button>
      </form>
    </section>
  );
}

export default CreateContestFormComponent;
