import { useSelector } from 'react-redux';

export const useVideoCategoriesByAction = (actionType) => {
  const array = useSelector(state => state.listViewReducer.data);
  for (let i = 0; i < array.length; i++) {
    if (array[i].actionType !== actionType) {
      const lowerArray = array[i].children;
      for (let j = 0; j < lowerArray.length; j++) {
        if (lowerArray[j].actionType === actionType) {
          return lowerArray[j].children;
        }
      }
    }
  }
  return [];
};

