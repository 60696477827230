const SET_SIGNUP1_DATA = './signUpDataReducer/SET_SIGNUP1_DATA';
const SET_SIGNUP2_DATA = './signUpDataReducer/SET_SIGNUP2_DATA';
const SET_SIGNUP3_DATA = './signUpDataReducer/SET_SIGNUP3_DATA';
const SET_SIGNUP_DATA_DELETE = './signUpDataReducer/SET_SIGNUP_DATA_DELETE';

const initialState = {
  userData: {
    email: '',
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    hear: '',
    address: '',
    country: '',
    city: '',
    region: '',
    postalCode: '',
    stripeCus: '',
  },
  playersData: {},
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNUP1_DATA:
      return {
        ...state,
        userData: { ...state.userData, ...action.userData },
      };
    case SET_SIGNUP2_DATA:
      return {
        ...state,
        playersData: action.playersData,
      };
    case SET_SIGNUP3_DATA:
      return {
        ...state,
        userData: { ...state.userData, ...action.locationData },
      };
    case SET_SIGNUP_DATA_DELETE:
      return {
        state: '',
      };
    default:
      return state;
  }
};

export const setUserDataStep1 = userData => ({ type: SET_SIGNUP1_DATA, userData });
export const setUserDataStep2 = playersData => ({ type: SET_SIGNUP2_DATA, playersData });
export const setUserDataStep3 = locationData => ({ type: SET_SIGNUP3_DATA, locationData });
export const delUserDataSteps = () => ({ type: SET_SIGNUP3_DATA });

export default userDataReducer;
