import React from 'react';
import styles from './ActionMenu.module.sass';

export function ActionMenu(props) {
    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                {props.content.map((button) => {
                    return (
                        <button
                            type="button"
                            key={button}
                            data-button={button}
                            onClick={props.handleActionMenuButtonClick}
                            className={styles.buttons__item}
                        >
                            <span className={styles.button__title}>{button}</span>
                        </button>
                    )
                })}
            </div>
        </div>
    );
};