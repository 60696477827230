import React from 'react';
import styles from './trainingPlanPopup.module.sass';
import path from '../../config/routes.json';
import { useHistory } from 'react-router-dom';
import { TrainingGoalStatus } from '../../constants/const';

const TrainingPlanPopup = ({ setShowNewPlanPopup, setTrainingGoal }) => {
  const history = useHistory();

  const createNewTrainingPlan = () => {
    setTrainingGoal(TrainingGoalStatus.CREATING);
    setShowNewPlanPopup(false);
    history.push(path.trainingGoal);
  };

  return (
    <>
      <div className={styles.new_plan_popup__wrapper}></div>
      <div className={styles.new_plan_popup}>
        <div className={styles.closeImg} onClick={() => setShowNewPlanPopup(false)}>
          &times;
        </div>
        <div className={styles.container}>
          <h3 className={styles.header}>New plan</h3>
          <hr className={styles.hr} />
          <img src="/img/image.webp" className={styles.img} aria-hidden="true" alt="" />
          <p className={styles.popup_description}>
            Are you sure? The current plan will be deleted.
          </p>
        </div>
        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.buttons__cancel_button}
            onClick={() => setShowNewPlanPopup(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className={styles.buttons__create_button}
            onClick={createNewTrainingPlan}
          >
            Create new plan
          </button>
        </div>
      </div>
    </>
  );
};

export default TrainingPlanPopup;
