import React from 'react';
import RewardCenter from '../RewardCenter/RewardCenter';
import styles from './CertificatePage.module.sass';

export function CertificatePage(props) {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1 className={styles.title}>Awards</h1>
            </header>
            <RewardCenter setUserAwards={props.setUserAwards} userAwards={props.userAwards} />
        </div>
    )
};

