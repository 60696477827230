import React from 'react';
import styles from './CancelSubscription.module.sass';

const StepOneBody = ({ getUserName, setUserName, childStatus }) => {
  return (
    <>
      <label className={styles.radioBtnContainer}> All
        <input type="radio" name="radio" onClick={() => setUserName([0, "ALL"])} />
        <span className={styles.checkmark} />
      </label>
      {getUserName().map((a, index) => (
        <div key={a}>
          {childStatus[`child${index + 1}`].status === 'active'
            &&
            <label className={styles.radioBtnContainer}>
              {a}
              {' '}
              ({childStatus[`child${index + 1}`].status})
              <input type="radio" name="radio" onClick={() => setUserName([index + 1, a])} />
              <span className={styles.checkmark} />
            </label>
          }
        </div>
      ))}
    </>
  )
};

export default StepOneBody;


