import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import path from '../../../config/routes.json';
import styles from './../GridCards/gridCards.module.sass';
import UpgradePopUp from '../UpgradePopUp/UpgradePopUp';
import MainDashboardCard from '../MainDashboardCard/MainDashboardCard';
import { SectionName } from '../../../constants/const';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../constants/const';
import SelectVideos from '../SelectVideos/SelectVideos';

const MainFoldersGridCards = (props) => {
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();

  const forwardToSearch = (e) => {
    e.preventDefault();
    if (props.accessAllVideos !== true) {
      setShowUpgradePopup(true);
    } else {
      history.push(path.search);
    }
  };
  return (
    <section className={styles.grid_cards}>
      {showUpgradePopup && <UpgradePopUp setShowUpgradePopup={setShowUpgradePopup} />}
      <article className={styles.search} onClick={forwardToSearch}>
        <div className={styles.container}>
          <input
            type="text"
            className={styles.search__input}
            aria-required="true"
            autoComplete="on"
            placeholder="Keyword"
          />
        </div>
        <RedButton title={width >= MOBILE_DIMENSION && 'Search'} className={styles.red_btn}>
          <img
            src="/img/Shape 2.webp"
            className={styles.button_img}
            style={{ paddingRight: '1rem' }}
            alt="Magnifying glass icon"
          />
        </RedButton>
      </article>
      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <h3 className={styles.additional_section__title}>Free Content</h3>
          <div className={styles.flex_container}>
            <section
              className={
                props.dashboardInfo.mainCategories.length
                  ? styles.card_container_main_categories
                  : styles.cardContainer
              }
            >
              {props.dashboardInfo.mainCategories.map((item) => {
                switch (item.title) {
                  case SectionName.FREE_SECTION:
                    return <MainDashboardCard key={item.title} item={item} accessAllVideos />;
                  case SectionName.TEAMS:
                    return <MainDashboardCard key={item.title} item={item} accessAllVideos />;
                  default:
                    return (
                      <MainDashboardCard
                        key={item.title}
                        item={item}
                        accessAllVideos={props.accessAllVideos}
                        setShowUpgradePopup={setShowUpgradePopup}
                      />
                    );
                }
              })}
            </section>
          </div>
          {!!props.dashboardInfo.premiumAreas.length && (
            <section className={styles.additional_section}>
              <h3 className={styles.additional_section__title}>Training Resources</h3>
              <div className={styles.flex_container}>
                {props.dashboardInfo.premiumAreas.map((item) => {
                  return (
                    <MainDashboardCard
                      key={item.title}
                      item={item}
                      isSkillAreas={item.isSkillAreas}
                      accessAllVideos={props.accessAllVideos}
                      setShowUpgradePopup={setShowUpgradePopup}
                    />
                  );
                })}
              </div>
            </section>
          )}
          {/* {!!props.dashboardInfo.specialPrograms.length && (
        <section className={styles.additional_section}>
          <h3 className={styles.additional_section__title}>Special programs</h3>
          <div className={styles.flex_container}>
            {props.dashboardInfo.specialPrograms.map((item) => {
              return (
                <MainDashboardCard
                  key={item.title}
                  item={item}
                  isAdditionalCardType
                  isSpecialPrograms
                  accessAllVideos={props.accessAllVideos}
                  setShowUpgradePopup={setShowUpgradePopup}
                />
              );
            })}
          </div>
        </section>
      )} */}
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.additional_section__title}>
            Select Videos to Improve Specific Areas of Your Game
          </h3>
          <div className={styles.box}>
            <div className={styles.row}>
              <div className={styles.column30}>
                <SelectVideos
                  accessAllVideos={props.accessAllVideos}
                  setShowUpgradePopup={setShowUpgradePopup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFoldersGridCards;
