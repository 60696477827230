import mainRoute from '../mainRoute';
import path from '../config';

export const getPrices = async () => {
  return await fetch(mainRoute.route + path.getPrices);
};

/** duplicated request - checkUniqueValues */
export const getDataForPayment = async data => {
  return await fetch(mainRoute.route + path.unitsearch, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
