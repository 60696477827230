import React from 'react';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import styles from './TeamDiscounts.module.sass';
import TeamDiscount from './TeamDiscountsForm/TeamDiscountsForm';

const TeamDiscounts = () => {
  useScrollToTop();

  return (
    <div className={styles.main_container}>
      <div className={styles.main_title}>
        <img
          className={styles.img_back}
          src="/img/teamDiscounts/timdisk.webp"
          alt="backgroundimage"
        />
        <div className={styles.main_container__block}>
          <h2>Let’s Help Your Team Get Better Together</h2>
          <p className={styles.header_block}>Team discount Inquiries</p>
          <div className={styles.info_block}>
            <p>
              <a href="mailto:neil@anytime-soccer.com" className={styles.span_block_email__a}>
                neil@anytime-soccer.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <TeamDiscount />
    </div>
  );
};

export default TeamDiscounts;
