import React, { useEffect, useState } from 'react';
import styles from './SearchScreen.module.sass';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { JoinToRequestCard } from '../JoinToRequestCard/JoinToRequestCard';
import { MIN_SEARCH_REQUEST_LENGTH } from '../../../../constants/const.js';
import PaginationLogic from '../../VideoSeries/Pagination';
import { searchTeams } from '../../../../api/teams-api';
import { showErrorMessage, showDefaultErrorMessage } from '../../../../utils/throwError';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';

const MAX_SEARCH_REQUEST_LENGTH = 40;

export function SearchScreen() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [requestResult, setRequestResult] = useState([]);
  const [requestPayload, setRequestPayload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (isSubmitting) {
      searchTeams(1, searchRequest).then((response) => {
        requestHandler(response);
      });
    }
  }, [isSubmitting]);

  const handleSearchInputChange = (event) => {
    setSearchRequest(event.target.value);
  };

  const handleSearchRequestSubmit = () => {
    setIsSubmitting(true);
  };

  const requestHandler = (response) => {
    response.json().then((data) => {
      if (data.existingTeams.length) {
        setRequestResult(data.existingTeams);
        setPages(data.totalPage);
        setIsSubmitting(false);
      } else {
        showErrorMessage('Nothing found for your request');
        setRequestResult([]);
        setIsSubmitting(false);
      }
    });
  };

  const changePage = (direction) => {
    if (direction === 'prev' && 1 <= currentPage - 1) {
      setCurrentPage(currentPage - 1);
      lessonsRequest(currentPage - 1);
    } else if (direction === 'next' && pages >= currentPage + 1) {
      setCurrentPage(currentPage + 1);
      lessonsRequest(currentPage + 1);
    }
  };

  const lessonsRequest = (e) => {
    if (!requestPayload) {
      setRequestPayload(true);
      setCurrentPage(e);
      onChange(e);
    }
  };

  const onChange = (event) => {
    try {
      searchTeams(event, searchRequest).then((response) => requestHandler(response));
    } catch (e) {
      showDefaultErrorMessage();
    }
  };

  return (
    <>
      <article className={styles.search}>
        <h2 className={styles.visuallyHidden}>Find a Team to join</h2>
        <div className={styles.container}>
          <input
            type="text"
            className={styles.search__input}
            defaultValue={searchRequest}
            minLength={MIN_SEARCH_REQUEST_LENGTH}
            maxLength={MAX_SEARCH_REQUEST_LENGTH}
            aria-required="true"
            autoFocus={width >= MOBILE_DIMENSION ? true : false}
            autoComplete="on"
            onChange={handleSearchInputChange}
          />
          <p className={styles.search__info}>
            Enter any part of the team&apos;s name in the search bar.
          </p>
        </div>
        <RedButton handleButtonClick={handleSearchRequestSubmit} title={'Search'}>
          {width >= MOBILE_DIMENSION && (
            <img
              src="/img/Shape 2.webp"
              className={styles.button_img}
              style={{ paddingRight: '1rem' }}
              alt="Magnifying glass icon"
            />
          )}
        </RedButton>
      </article>
      {!!requestResult.length && (
        <article className={styles.search_result}>
          <h2 className={styles.search_result__title}>Search Result</h2>
          {requestResult.map((teamCard) => {
            return <JoinToRequestCard key={teamCard.teamId} teamCard={teamCard} />;
          })}
          {pages > 1 && (
            <PaginationLogic
              currentPage={currentPage}
              request={lessonsRequest}
              changePage={changePage}
              pages={pages}
            />
          )}
        </article>
      )}
    </>
  );
}
