import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './TeamItem.module.sass';
import path from '../../../../config/routes.json';
import { SendInvitePopup } from '../SendInvitePopup/SendInvitePopup';
import { InviteStatus, Congratulation } from '../../../../constants/teams';
import { CongratulationPopup } from '../CongratulationPopup/CongratulationPopup';
import { setCurrentTeamId } from '../../../../Redux/teamReducer';
import { useDispatch } from 'react-redux';

/** { Входные данные с бека:
 * "teamId" : "1", "name" : "name", "managerName" : "firstname lastname", “imgUrl":"link"}
 * manager name - two fields
 */

export function ActiveCard(props) {
  const {
    teamCard: { teamId, name: title, childFirstName, childLastName, imgUrl },
  } = props;

  const [inviteStatus, setInviteStatus] = useState(InviteStatus.HIDE);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch();
  const handleInviteClick = () => {
    setInviteStatus(InviteStatus.SEND_INVITE);
    dispatch(setCurrentTeamId(teamId));
  };

  const handleTeamButtonsClick = () => {
    dispatch(setCurrentTeamId(teamId));
  };

  const fullName = childFirstName.concat(' ', childLastName);

  return (
    <>
      {inviteStatus === InviteStatus.SEND_INVITE && (
        <SendInvitePopup
          setInviteStatus={setInviteStatus}
          popupTitle={'Send Team Invite'}
          teamId={teamId}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      )}
      {inviteStatus === InviteStatus.INVITE_SUCCESS && (
        <CongratulationPopup
          changeChosenScreen={setInviteStatus}
          defaultScreen={InviteStatus.HIDE_INVITE}
          popupText={Congratulation.INVITE}
        >
          <img src="/img/teams/letters.webp" alt="" />
        </CongratulationPopup>
      )}
      <article className={styles.team_card}>
        <div className={styles.container}>
          <h3 className={styles.team_card__title}>{title}</h3>
          <p className={styles.team_card__manager}>{fullName}</p>
          <div className={styles.buttons}>
            <Link
              to={path.teams.dashboard}
              className={`${styles.button} ${styles.button_dashboard}`}
              onClick={handleTeamButtonsClick}
            >
              Dashboard
            </Link>
            <Link
              to={path.teams.roster}
              className={`${styles.button} ${styles.button_roster}`}
              onClick={handleTeamButtonsClick}
            >
              Roster
            </Link>
            <button
              className={`${styles.button} ${styles.button_invite}`}
              onClick={handleInviteClick}
            >
              Invite
            </button>
            <Link
              to={path.teams.homework}
              className={`${styles.button} ${styles.button_homework}`}
              onClick={handleTeamButtonsClick}
            >
              Homework
            </Link>
            <Link
              to={path.teams.contest}
              className={`${styles.button} ${styles.button_contest}`}
              onClick={handleTeamButtonsClick}
            >
              Team Contest
            </Link>
            <Link
              to={path.teams.challenge}
              className={`${styles.button} ${styles.button_contest}`}
              onClick={handleTeamButtonsClick}
            >
              Coach&apos;s Challenges
            </Link>
          </div>
        </div>
        <div className={styles.round_container}>
          <img src={imgUrl} className={styles.team_card__logo} alt="" />
        </div>
      </article>
    </>
  );
}
