import React, { useState } from 'react';
import styles from './ContestButtons.module.sass';
import Plus from '../../../../../asset/teams/roster/invitations/Rectangle_plus.webp';
import { useHistory } from 'react-router-dom'
import { notifyContest } from '../../../../../api/api';
import { message } from 'antd';

export function ContestButtons({ isManager, isPlayerManager, contest }) {
  const history = useHistory()
  const [isNotifying, setIsNotifying] = useState(false)
  const [isNotified, setIsNotified] = useState(false)

  const handleNotification = async () => {
    setIsNotifying(true)
    await notifyContest(contest.id).then((res) => {
      setIsNotifying(false)
      setIsNotified(true)
      message.success('Notification sent successfully')

      console.log({ res })
      setTimeout(() => {
        setIsNotified(false)
      }, 5000);
    })
  }

  if (!(isManager || isPlayerManager)) {
    return null
  }

  return (
    <div className={styles.contest_buttons}>
      <button
        className={styles.contest_button}
        onClick={() => {
          history.push('contest/create')
        }}
      >
        <img className={styles.contest_button__image} src={Plus} alt="" />
        New Contest
      </button>
      {contest?.id && <button
        className={styles.contest_button}
        onClick={handleNotification}
        disabled={isNotifying}
      >
        {isNotifying ? 'Sending Notification' : `${isNotified ? 'Notified' : 'Notify'} Players`}
      </button>}
    </div>
  );
}
