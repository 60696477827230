import React, { useEffect, useState } from 'react';
import styles from './SignUp.module.sass';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { connect } from 'react-redux';
import { setUserData } from '../../../Redux/userDataReducer';
import { Price } from '../../../constants/const';

function SignUp(props) {
  const [step, changeStep] = useState(1);
  const [finalPrice, setfinalPrice] = useState(0);
  const [emailAndUsername, setEmailAndUsername] = useState();
  const [signUpFieldValues, changeSignUpFieldValues] = useState({
    step1: {
      parentFirstName: '',
      parentLastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      players: '1',
      hear: '',
    },
    step2: null,
    step3: null,
  });

  useEffect(() => {
    return () => {
      props.delUserDataSteps();
    };
  }, []);

  return (
    <main>
      <div className={`${styles.title} ${styles.title_mobile}`}>
        <h2 className={styles.h2}>All-in-One: Anytime Soccer Training</h2>
        <p className={styles.p}>
          With over 200 hours of follow-along
          <br /> videos and 1,000 individual skill moves, Anytime Soccer Training will seamlessly
          take your child’s game to the next level with
          <br /> <strong>no guesswork</strong>.{' '}
          <span className={styles.p_background}>
            Improved first touch, better juggling &amp; aerial control, supreme ball mastery,
            clinical dribbling, and greater fitness are only a few of the benefits your child will
            receive.
          </span>
        </p>
        <img
          className={styles.img_back}
          src="/img/sign/main-section-bg.webp"
          alt="backgroundimage"
        />
        <img className={styles.img} src="/img/sign/main-section-badge.webp" alt="7day" />
      </div>
      <section className={styles.section}>
        {step === 1 && (
          <Step1
            step={step}
            changeStep={changeStep}
            changeSignUpFieldValues={changeSignUpFieldValues}
            signUpFieldValues={signUpFieldValues}
            setUserDataStep1={props.setUserDataStep1}
          />
        )}
        {step === 2 && (
          <Step2
            step={step}
            changeStep={changeStep}
            changeSignUpFieldValues={changeSignUpFieldValues}
            signUpFieldValues={signUpFieldValues}
            setfinalPrice={setfinalPrice}
            email={signUpFieldValues.step1.email}
            setUserDataStep2={props.setUserDataStep2}
            setUserDataStep3={props.setUserDataStep3}
            signUpData={props.signUpData}
            setEmailAndUsername={setEmailAndUsername}
          />
        )}
        {step === 3 && (
          <Step3
            changeSignUpFieldValues={changeSignUpFieldValues}
            signUpFieldValues={signUpFieldValues}
            finalPrice={finalPrice}
            setfinalPrice={setfinalPrice}
            setUserData={props.setUserData}
            setLogin={props.setLogin}
            setRole={props.setRole}
            setUserDataStep3={props.setUserDataStep3}
            signUpData={props.signUpData}
            emailAndUsername={emailAndUsername}
          />
        )}
        <div className={styles.card}>
          <div>
            <h4 className={styles.card__title}>Early Bird Special</h4>
            <h4 className={styles.card__subtitle}>Pricing</h4>
            <h5>Annual Subscription</h5>
            <table>
              <tbody>
                <tr>
                  <td>First Player</td>
                  <td>${Price.FULL_PRICE}</td>
                </tr>
                <tr>
                  <td>Additional Player</td>
                  <td>${Price.ADDITIONAL_PLAYER_PRICE}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <span>Prices are in U.S. dollars.</span>
          </div>
        </div>
      </section>
    </main>
  );
}

const mapStateToProp = () => ({ playerData: '' });

export default connect(mapStateToProp, { setUserData })(SignUp);
