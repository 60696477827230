import { useQuery, useMutation } from '@tanstack/react-query';
import { getChallengeScore, resetChallengeScore } from '../api/api';
import { message } from 'antd';

const useChallengeScore = (id) => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['challenge-score', 'user', id],
    queryFn: async () => await getChallengeScore(id),
    enabled: !!id,
  });

  const { mutate: handleResetChallengeScore } = useMutation({
    mutationFn: async (teamId) => await resetChallengeScore(teamId),
    onSuccess: () => {
      message.success('Successfully Reset Score');
    },
    onError: (err) => {
      console.error(err);
      message.error('Failed to Reset Score');
    },
    onSettled: () => {
      refetch();
    },
  });

  return {
    isLoading,
    data: data ?? null,
    error,
    refetch,
    handleResetChallengeScore,
  };
};

export default useChallengeScore;
