import React from "react";
import styles from './FreeTrialButton.module.sass'

function FreeTrialButton({setShowTrialPeriodPopup}) {
    const handleTrialButtonClick = () => setShowTrialPeriodPopup(true);

    return (
        <button onClick={handleTrialButtonClick} className={styles.button}>
            <span className={styles.button__text}>Start 15-Day Trial</span>
        </button>
    )
}

export default FreeTrialButton;