import React from 'react';
import styles from './ManagerRow.module.sass';
import { Avatars } from '../../../../../../../constants/const';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';

export function ManagerRow(props) {
  const {
    managerData,
    teamId,
    thisItemOpen,
    setThisItemOpen,
    handleActionMenuButtonClick,
  } = props;

  return (
    <>
      {managerData.map((data, index) => {
        const isMenuOpen = thisItemOpen === String(data.childId);
        return (
          <tr className={styles.row} key={data?.childId}>
            <th scope="row" className={styles.cell_first}>
              {index + 1}
            </th>
            <td className={styles.cell_player_name}>
              <img
                src={data?.image || Avatars[parseInt(data?.picture) - 1]}
                className={styles.cell__logo}
                alt=""
              />
              {data?.childFirstName} {data?.childLastName}{data.role === 'homework_manager' && '*'}
            </td>
            <td className={styles.cell}>
              <ProfileMenu
                isMenuOpen={isMenuOpen}
                thisItemOpen={thisItemOpen}
                setThisItemOpen={setThisItemOpen}
                picture={data?.picture}
                handleActionMenuButtonClick={handleActionMenuButtonClick}
                managerData={data}
                teamId={teamId}
              />
            </td>
          </tr>
        );
      })}
    </>
  );
}
