import React, { useMemo, useState } from 'react';
import styles from './Table.module.sass';
import { timeConvert } from '../../../../../utils/utils.js';
import { Avatars } from '../../../../../constants/const';

export const playersHeaders = [
  { label: 'No.', accessor: 'index', sortable: false },
  { label: 'Name', accessor: 'childFirstName', sortable: false },
  { label: 'Videos', accessor: 'countVideo', sortable: true },
  { label: 'Time', accessor: 'timeVideo', sortable: true },
  { label: 'Goal', accessor: 'playerGoal', sortable: true },
  { label: 'Time Goal', accessor: 'timeGoal', sortable: true },
  { label: 'Status', accessor: 'complete', sortable: true },
  { label: '', accessor: 'actions', sortable: false },
];

function ChallengeTable({ playerData }) {
  const [sortConfig, setSortConfig] = useState({ key: 'timeVideo', direction: 'descending' });

  const sortAscDesc = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPlayers = useMemo(() => {
    const sortablePlayers = [...playerData]; // Create a copy of the player data
    if (sortConfig !== null) {
      sortablePlayers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlayers;
  }, [playerData, sortConfig]);

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <h2 className={styles.title}>Players</h2>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {playersHeaders.map((header, i) => (
              <th
                scope="col"
                className={styles.player_data__header__item}
                key={i}
                onClick={() => header.sortable && sortAscDesc(header.accessor)}
                style={header.accessor === 'playerGoal' ? { borderLeft: '1px solid #ccdfe9' } : {}}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: header.sortable ? 'pointer' : 'default',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header.label}
                  </span>
                  {sortConfig.key === header.accessor ? (
                    <span
                      style={{
                        fontSize: '.75rem',
                        marginLeft: '.3rem',
                        marginTop: '5px',
                      }}
                    >
                      {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                    </span>
                  ) : (
                    <>
                      {header.sortable && (
                        <span
                          style={{
                            fontSize: '.75rem',
                            marginLeft: '.3rem',
                            marginTop: '5px',
                          }}
                        >
                          ▼
                        </span>
                      )}
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          {sortedPlayers.map((data, index) => {
            const classSelector = data.isManager
              ? styles.cell_player_manager
              : styles.cell_player_name;
            const {
              childFirstName,
              childId,
              childLastName,
              countVideo,
              image,
              picture,
              playerStatusGoal,
              playerGoal,
              timeVideo,
              timeGoal,
            } = data;
            const fullName = childFirstName.concat(childLastName ? `${' ' + childLastName}` : ' ');
            return (
              <tr className={styles.row} key={childId}>
                <th scope="row" className={styles.cell_first}>
                  {index + 1}
                </th>
                <td className={classSelector}>
                  <img
                    src={image || Avatars[parseInt(picture) - 1]}
                    className={styles.cell_logo}
                    alt=""
                  />
                  <span>{fullName}</span>
                </td>
                <td className={styles.cell}>{countVideo}</td>
                <td className={styles.cell}>{timeVideo ? timeConvert(timeVideo) : '0m'}</td>
                <td
                  className={styles.cell}
                  style={{
                    borderLeft: '1px solid #ccdfe9',
                  }}
                >
                  {Math.round(playerGoal)}
                </td>
                <td className={styles.cell}>{timeConvert(timeGoal)}</td>
                <td className={styles.cell}>
                  <img
                    src={'/img/teams/medal.png'}
                    style={{
                      filter: playerStatusGoal ? '' : 'grayscale(100%)',
                      height: '25px',
                    }}
                    alt=""
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

ChallengeTable.defaultProps = {
  playerData: [],
};

export default ChallengeTable;
