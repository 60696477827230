const listViewSubcategoryReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_SUBCATEGORY_LIST_VIEW_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const setSubcategory = (subCategoryActionType) => {
  return { type: 'SET_SUBCATEGORY_LIST_VIEW_DATA', payload: subCategoryActionType };
};

export default listViewSubcategoryReducer;
