import React from 'react';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { PopupStatusProfilePage } from '../constants';
import { removeProfileRequest } from '../../../api/teams-api';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

export function ModalWindowPopupLeaveTeam({setPopupStatusProfilePage, teamId, childId}) {
  const history = useHistory();
  const handleClosePopupButtonClick = () => {
    setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
  };

  const handleActivationPopupButtonClick = () => {
    removeProfileRequest({ teamId: teamId, childId: childId} ).then((response) => {
      if (response.ok) {
        setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
        history.push('/teams/roster')
      } else {
        message.error('Something went wrong. Please try again later.')
      }
    })
  };


  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 className={styles.invite_popup__title}>Leave Team</h3>
        <div className={styles.container}>
          <p className={styles.invite_popup__description}>Are you sure want to leave this team?</p>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopupButtonClick}
              width={'35rem'}
            />
            <RedButton
              width={'35rem'}
              handleButtonClick={handleActivationPopupButtonClick}
              title={'Leave'} />
          </div>
        </div>
      </section>
    </>
  );
}