import React, { useEffect, useState } from 'react';
import styles from './Dropdown.module.sass'
import { Avatars } from '../../../constants/const';
import { Spinner } from 'reactstrap';
import s from '../../../app.module.sass';

const CloseIcon = () => {
  return (
    <svg  viewBox="0 0 20 20" >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};


const Dropdown = ({isMulti, placeHolder, allMembers, selectedValue, setSelectedValue}) => {

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handler = () => setShowMenu(false);

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }

    if(!allMembers){
      return (
        <div style={{ background: '#f4fbff', minHeight: '80vh' }}>
          <Spinner color="primary" className={s.spinner} />
        </div>
      )
    }

    if (isMulti) {
      return (
        <div className={styles.dropdown_tags}>
          {selectedValue.map((option) => (
            <div key={option.childId} className={styles.dropdown_tag_item}>
                <img
                  src={option.image || Avatars[parseInt(option.picture) - 1]}
                  className={styles.cell_logo}
                  alt=""
                />
              <span>{option.firstName} {option.lastName}</span>
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className={styles.dropdown_tag_close}
              >
                <CloseIcon style={'fill: red; margin-bottom: 0.2rem;'}/>
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue.label;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.childId !== option.childId);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.childId === option.childId) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.childId === option.childId).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.childId === option.childId;
  };


  return (
    <div className={styles.dropdown_container}>
      <div onClick={handleInputClick} className={styles.dropdown_input}>
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools">
        </div>
      </div>
      {showMenu && (
        <div className={styles.dropdown_menu}>
          {allMembers.map((option) => (
            <div
              onClick={() => onItemClick(option)}
              key={option.childId}
              className={ isSelected(option) ? styles.dropdown_selected : styles.dropdown_item}
            >
              <img
                src={option.image || Avatars[parseInt(option.picture) - 1]}
                className={styles.cell_logo}
                alt=""
              />
              <span>{option.firstName} {option.lastName}</span>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown;