/** директория, содержит данные для секций внутри free section
 *  структура объекта: заголовок (отвечает проверке item.name.includes(title),
 * где item.name - это название папки на vimeo),
 * подзаголовок,
 * isSeries: true - флаг - у объектов без вложенных папок
 * img, link
 * часть папок имеет совпадающие с другой секцией названия,
 * поэтому при создании папок на Vimeo и здесь добавляем название секции (Pro, Free Section)
 * */

import { SubfolderPageUrl } from '../constants/const';
import { ActionType } from './dashboardChildReducer';

const initialState = {
  data: [
    {
      title: 'Free Section (Free) 7-Day',
      subTitle: '',
      actionType: ActionType.SET_SEVEN_DAY_CHALLENGE_DATA,
      freeAccess: true,
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_100_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Warm-Up and Juggling',
      subTitle: '',
      actionType: ActionType.SET_WARM_UP_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_30_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Freestyle Challenge Free Section',
      subTitle: '',
      actionType: ActionType.SET_FREESTYLE_CHALLENGE_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_36_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Ball Mastery',
      subTitle: '',
      actionType: ActionType.SET_BALL_MASTERY_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_31_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: '1V1 Games',
      subTitle: '',
      actionType: ActionType.SET_1V1_GAMES_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_35_grey_blue_Games.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Dribbling Games',
      subTitle: '',
      actionType: ActionType.SET_DRIBBLING_TURNS_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_32_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Passing Receiving',
      subTitle: '',
      actionType: ActionType.SET_PASSING_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_33_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Finishing',
      subTitle: '',
      actionType: ActionType.SET_FINISHING_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_36_grey_blue_Finishing.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
    {
      title: 'Fitness Agility',
      subTitle: '',
      actionType: ActionType.SET_FITNESS_AGILITY_DATA,
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_34_grey_blue.webp',
      link: SubfolderPageUrl.freeSectionSubfolders,
    },
  ],
  subfoldersData: [],
};
// папки должны содержать 'Free Section'
const subfoldersData = {
  sevenDayChallenge: [
    {
      title: 'Free Section (Free) 7-Day',
      subTitle: '',
      freeAccess: true,
      isSeries: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_Design_blue_Bottom_29.png',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  warmUpSection: [
    {
      title: 'Free Section Dynamic Warmup',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Challenge_Cards_Design_Dynamic_WarmUp_Blue_Bottom.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section 04 Juggle Master',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Challenge_Cards_Design_Two_Footed_Juggle_Master_Blue_Bottom.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Partner Aerial Toss',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Challenge_Cards_Design_Partner_Aerial_Toss_Blue_Bottom.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  freestyleChallengeSection: [
    // anytimeSkills Challenge
    {
      title: 'Free Section 02 Anytime Soccer',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_2.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Anytime Freestyle',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_1.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  ballMasterySection: [
    {
      title: 'Free Section 03 Ball Mastery',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_3.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section One Cone',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_4.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Two Cone',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_5.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Body Feints',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_6.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Five Minute',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_7.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  games1V1Section: [
    {
      title: 'Free Section Games',
      subTitle: '',
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_83_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section 1V1 Moves',
      subTitle: '',
      freeAccess: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_82_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribblingTurnsSection: [
    {
      title: 'Free Section Figure 8 Dribbling',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_12.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Dribble Master',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_13.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Line Cone',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_14.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Line Cone & Slalom Free Section',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_15.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Cone Slalom',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_16.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Five Star',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_17.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Three Cone',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_18.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Quick Turns',
      subTitle: 'Change Direction',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_19.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  passingSection: [
    {
      title: 'Free Section First Touch',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_20.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Partner Drills',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_21.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  finishingSection: [
    {
      title: 'Free Section Clinical',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_22.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  agilitySection: [
    {
      title: 'Free Section Fitness & Soccer',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_23.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Section Agility',
      subTitle: '',
      isSeries: true,
      freeAccess: true,
      img: '/img/home/new-design/Free_Area_Video_Cards_blue_Bottom_24.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const freeSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_SEVEN_DAY_CHALLENGE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.sevenDayChallenge,
      };
    case ActionType.SET_WARM_UP_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.warmUpSection,
      };
    case ActionType.SET_FREESTYLE_CHALLENGE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.freestyleChallengeSection,
      };
    case ActionType.SET_BALL_MASTERY_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.ballMasterySection,
      };
    case ActionType.SET_1V1_GAMES_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.games1V1Section,
      };
    case ActionType.SET_DRIBBLING_TURNS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribblingTurnsSection,
      };
    case ActionType.SET_PASSING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.passingSection,
      };
    case ActionType.SET_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.finishingSection,
      };
    case ActionType.SET_FITNESS_AGILITY_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.agilitySection,
      };
    default:
      return state;
  }
};

export default freeSectionReducer;
