import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input } from 'reactstrap';
import styles from './ReactivationPlayer.module.sass';
import exclamationMark from '../../../asset/dashboard/exclamationMark.webp';
import { useHistory, withRouter } from 'react-router-dom';
import { reactivationPlayerSendInfoToBack } from '../../../api/api';
import { withRoleRedirectComp, haveNoLoginRedirect } from '../../../hoc/redirectComp';
import { compose } from 'redux';
import mainRoute from '../../../mainRoute';
import path from '../../../config';
import Cookies from 'universal-cookie';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function ReactivationPlayer(props) {
  const [toggleButton, setToggleButton] = useState(false);
  const [checkToggle, setCheckToggle] = useState('black');
  const [toggSubmitBtnSpinner, setToggSubmitBtnSpinner] = useState(false);
  const cookies = new Cookies();
  const history = useHistory();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 30, color: 'red', marginLeft: '5rem' }} spin />
  );
  const currentChildUserName =
    props.props.userData.childInfo[`child` + props.match.params.childNumber].username; // userName для отображения имени выбранного ребенка на странице

  const date = props.props.userData.userInfo.nextChargeDate.slice(0, 19).replace('T', ' ');
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const finaleDate = `${month + '.' + day + '.' + year}`;

  useEffect(() => {
    try {
      // const response =
      fetch(`${mainRoute.route + path.reactivateplayer}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get('finaleToken'),
        },
        body: JSON.stringify({ username: currentChildUserName }),
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  //For status in dashboard update.
  const setUpdateDashboard = props?.setUpdateDashboard;
  const updateDashboardFunc = () => {
    setUpdateDashboard(!props?.updateDashboard);
  };

  // 2. Change text to green/red color.
  const checkBoxHandler = () => {
    setToggleButton(!toggleButton);
    if (toggleButton) {
      setCheckToggle('red');
    } else {
      setCheckToggle('green');
    }
  };

  // 3. Check if toggle green show spinner and make response.
  const serverPostRequest = () => {
    if (checkToggle === 'green') {
      setToggSubmitBtnSpinner(true);
      try {
        reactivationPlayerSendInfoToBack(currentChildUserName).then(() => {
          history.replace('/dashboard');
          updateDashboardFunc();
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setCheckToggle('red');
    }
  };

  return (
    <main>
      <div className={styles.upContainer}>
        <div className={styles.upLeftContainer}>
          <h4 className={styles.title}>Confirm Your Subscription</h4>
          <h5 className={styles.subTitle}>Activation Player</h5>
          <p className={styles.text}>{currentChildUserName}</p>
        </div>

        <div className={styles.upRightContainer}>
          <div className={styles.card}>
            <div>
              <h4>Pricing</h4>
              <h5>Annual Subscription</h5>
              <span className={styles.spanUpRightContainer}>
                <span className={styles.upRightContainer}>
                  <span>First Player</span>
                  <span>
                    $49.98
                    <br />
                  </span>
                </span>
                <span className={styles.upRightContainer}>
                  <span>Additional Player</span>
                  <span>$14.98</span>
                </span>
              </span>
              <hr className={styles.upRightContainerHr} />
              <span className={styles.upRightContainerLast}>Prices are in U.S. dollars.</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.downLeftContainer}>
        <h5 className={styles.subTitle}>Players Activated</h5>
        <p className={styles.namesInfo1}>
          Your subscription plan will reflect the additional purchase
          <br />
          and will renew automatically until you cancel it.
          <br />
          All amounts in US dollars.
        </p>

        <div className={styles.checkBoxContainer}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                className={styles.checkBox}
                value={toggleButton}
                onClick={checkBoxHandler}
              />
              <p
                style={{
                  color: checkToggle,
                }}
                className={styles.checkBox__label}
              >
                I agree to the new subscription plan.
              </p>
            </Label>
          </FormGroup>
        </div>
        <div className={styles.displayFlex}>
          <span>
            <img src={exclamationMark} alt="exclamation mark" className={styles.img} />
          </span>
          <p className={styles.namesInfo}>
            <span className={styles.edit}>Important:</span>
            You will not be charged until subscription ends at {finaleDate}
          </p>
        </div>

        <div className={styles.submitBtnContainer}>
          <p className={styles.redText}>No, thanks.</p>
          <div className={styles.payBtn}>
            {toggSubmitBtnSpinner ? (
              <Spin indicator={antIcon} className={styles.spinnerSubmitBtn} />
            ) : (
              <button
                onClick={serverPostRequest}
                className={styles.payBtn__submite}
                disabled={toggSubmitBtnSpinner}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default compose(withRouter, withRoleRedirectComp, haveNoLoginRedirect)(ReactivationPlayer);
