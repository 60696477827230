import React from 'react';
import styles from './TeamStatsRow.module.sass';

export const TeamStatsRow = ({ stats }) => (
  <tr className={styles.row}>
    {Object.entries(stats).map((cell) => {
      const isGoalCompleted = cell[0] === 'isGoalCompleted';
      return (
        <td className={isGoalCompleted ? styles.cell_goal : styles.cell} key={cell[0]}>
          {isGoalCompleted ? (
            <>
              {cell[1] ? (
                <img
                  src="/img/teams/trophies.webp"
                  style={{ width: '5.5rem', height: 'auto', paddingBottom: '0.45rem' }}
                  alt=""
                />
              ) : (
                <img
                  src="/img/teams/goalFail.webp"
                  style={{ width: '3.6rem', height: 'auto', paddingBottom: '0.5rem' }}
                  alt=""
                />
              )}
              <p className={styles.goal_status}>
                {cell[1] ? (
                  <>
                    Congrats! <br /> Goal Completed
                  </>
                ) : (
                  <>
                    Goal <br /> Incomplete
                  </>
                )}
              </p>
            </>
          ) : (
            <>
              <p className={styles.cell__title}>{cell[0]}</p>
              <p className={styles.cell__info}>{cell[1]}</p>
            </>
          )}
        </td>
      );
    })}
  </tr>
);
