import React from 'react';
import { ActionMenu } from '../../../../../../commonComponents/ActionMenu/ActionMenu';
import { TeamActionMenu } from '../../../../../../../constants/teams.js';
import styles from './ProfileMenu.module.sass';
import { useHistory } from 'react-router-dom';

const openMenu =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGBQTFRFAAAAAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VAC9VEDxgj6S07/L0kKS1////gJeq8PP13+Xq4ObqAC9VAC9VAC9V513puQAAACB0Uk5TAE9/v8//n0BQ3yBg74+QsKAQgMD////////////Q4PCKIvX5AAABFklEQVR4nJWV65LCIAyFozTVXlQWVne7Fvv+bynoUluatOH8OjN8w+QCCUCi3V4V6FWo/S49S8gSZyoPLHqscKHqSKK1WqJBql6yTUuziG2TsicODTrN2XKN9YlO2fM6i3iexLvFIo5FqdncPmpjTZiazaX+eyFhYyBE3yhVGRcjhndykcIa4EvKImZEEeIwctiAlcMWimi/r7fblXVBBYz259er49xLH/gvHNw5R8E95xJYEoYbbdf3He+8XF7pspqS1W7YhqL8E9VSVuc+fnjIWJf/YWWltnHIDNvsMA7enPElGIxmOkY3iq3nA3r1bgOJGjbLgdhBNVNBSyyg0B2il45ebUGHJFHNL803b+zrpzlrFuQTMQcpXMKHMscAAAAASUVORK5CYII=';
const closedMenu =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJlQTFRFAAAAd6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9nbvPnbvPnbvPnbvPnbvPnbvPnbvPnbvPnbvPd6O9d6O9d6O9d6O9d6O9d6O9d6O9d6O9mJajagAAADN0Uk5TAE9Yv8//f26wOJ9AUJlD3yCnrzdglVnRjyxwkKR4EO+AwBCP75D/gPDf4NCg4KjSpZaa6VStFgAAAVJJREFUeJyVlX9fgyAQxllIOeacU3I/bE3bylLbqvf/4oJIUeQmPP94HN8PPgLeIaRpdoc9wuXh+5k+p5EPxJ+3g7lP6AJEg6UipUK8CoxotI6TcTaJcTTOssfU/L50w/TUlu4gdzu6HSboHkKF9rQ/ym6ynM5UzCjMScXdpkQb0G+r5KndEwzsQ1/pWj6DeJrtjKwMZ2EwsrRfmJsV98S3WpjfkwNCz0c7FiFi7+LPR27pgvtgqLBlEUe9Nnw5nc8nMBLyhG2p1zeuEoqEiILfxcQHFJngCoo02MZG3fkvq6qEI67abeucDsXpuNUXTkmAjcMVdbv86NPut5JlyemHRYVNKbj8B9F1ush8dYXXpXxZFMa8P2pul9xmOM7gYp7EuZ5i31CbuBp6UHQxN6DC0IC4gh8cDjMhrs2tTWhxIIoPMWngpil5VtSiHddFPiJ/AWrjHZZfX+rYAAAAAElFTkSuQmCC';

export function ProfileMenu(props) {
    const {
        isMenuOpen,
        thisItemOpen,
        setThisItemOpen,
        picture,
        handleActionMenuButtonClick,
        teamId,
        managerData} = props;
    const history = useHistory();
    const handleMenuClick = event => {
        const targetEvent = event.target.dataset.value;
        if (targetEvent !== thisItemOpen) {
            setThisItemOpen(targetEvent);
            history.push(`/profilePage/${managerData.childId}/${teamId}`);
        } else {
            setThisItemOpen('');
        }
    };

    return (<>
        <div className={styles.menuButton}>
            <img
                src={isMenuOpen ? openMenu : closedMenu}
                className={styles.action_menu}
                data-value={picture}
                onClick={handleMenuClick}
                alt="menu button"
            />
        </div>
        {isMenuOpen && (
            <ActionMenu
                content={TeamActionMenu}
                handleActionMenuButtonClick={handleActionMenuButtonClick}
            />
        )}
    </>
    )
}