import React, { useState, useRef, useEffect } from 'react';
import styles from './SearchPageComponent.module.sass';
import VideoSeriesRow from '../VideoSeries/VideoseriesRow';
import { message } from 'antd';
import { searchListOfVideosVimeo } from '../../../api/api';
import PaginationLogic from '../VideoSeries/Pagination';
import { MIN_SEARCH_REQUEST_LENGTH } from '../../../constants/const';

const defaultSelectorValue = 5;
const MAX_SEARCH_REQUEST_LENGTH = 100;

const SearchPageComponent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [requestPayload, setRequestPayload] = useState(false);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const [selectorValue, setSelectorValue] = useState(defaultSelectorValue);
  const [isHidden, setIsHidden] = useState(true);
  // search request strung
  const [searchRequest, setSearchRequestValue] = useState('');
  // This flag is used for submitting the form
  const [isSubmitting, setSubmitting] = useState(false);
  const inputRef = useRef();

  // useEffect for loading folders data
  useEffect(() => {
    setUpLoadedData(false);
    if (isSubmitting) {
      searchListOfVideosVimeo(currentPage, selectorValue, searchRequest)
        .then((response) => {
          requestHandler(response);
          setSubmitting(false);
          setIsHidden(false);
        })
        .catch((response) => {
          setSubmitting(false);
          setIsHidden(false);
          message.error('status: ' + response.status + ` ${response.statusText}`);
        });
    }
  }, [isSubmitting]);

  const handleSearchInputChange = (evt) => {
    setSearchRequestValue(evt.target.value);
    setCurrentPage(1);
    if (evt.target.value.length) {
      setIsHidden(false);
    } else if (!evt.target.value.length) {
      setIsHidden(true);
    }
  };

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    if (!searchRequest.length) {
      message.error('You should write in a video title in the search form');
    } else if (!searchRequest.trim().length) {
      message.error('Search title cannot contain only spaces');
    } else {
      setSubmitting(true);
    }
  };

  const handleEnterDownFormSubmit = (evt) => {
    if (evt.keyCode === 13) {
      handleFormSubmit(evt);
    }
  };

  const handleEnterDownFormReset = (evt) => {
    if (evt.keyCode === 13) {
      handleFormReset(evt);
    }
  };

  const handleFormReset = (evt) => {
    evt.preventDefault();
    inputRef.current.value = '';
    setSearchRequestValue('');
    setIsHidden(true);
  };

  const handleSelectorChange = (evt) => {
    setSelectorValue(evt.target.value);
    setCurrentPage(1);
    if (searchRequest.length) {
      setSubmitting(true);
    } else {
      message.error('You should write in a video title in the search form');
    }
  };

  const numOfPages = props.vimeoVideoData.total;
  const pages = Math.ceil(numOfPages / selectorValue);

  const changePage = (direction) => {
    if (direction === 'prev' && 1 <= currentPage - 1) {
      setCurrentPage(currentPage - 1);
      lessonsRequest(currentPage - 1);
    } else if (direction === 'next' && pages >= currentPage + 1) {
      setCurrentPage(currentPage + 1);
      lessonsRequest(currentPage + 1);
    }
  };

  const lessonsRequest = (e) => {
    if (!requestPayload) {
      setRequestPayload(true);
      setCurrentPage(e);
      onChange(e);
    }
  };

  const onChange = (event) => {
    try {
      setCurrentPage(event);
      searchListOfVideosVimeo(event, selectorValue, searchRequest).then((response) =>
        requestHandler(response),
      );
    } catch (e) {
      console.log(e);
    }
  };
  const requestHandler = (response) => {
    setRequestPayload(false);
    if (response.status === 200) {
      response.json().then((result) => {
        const videoArray = result.data.map((a) => ({
          name: a.name,
          link: a.link,
          viewed: a.viewed,
        }));
        props.setVideo({ total: result.total, videoArray });
        setUpLoadedData(true);
      });
    } else {
      message.error('status: ' + response.status + ` ${response.statusText}`);
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.section__flexContainer}>
          <div className={styles.title}>Search</div>
        </div>
      </section>
      <form
        className={styles.searchForm}
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
        action="#"
        method="post"
        disabled={isSubmitting}
        aria-label="Search Form"
      >
        <label htmlFor="search-form-input" className={styles.searchForm__label}>
          Enter the title of the video you want to search
        </label>
        <input
          onChange={handleSearchInputChange}
          onKeyDown={handleEnterDownFormSubmit}
          minLength={MIN_SEARCH_REQUEST_LENGTH}
          maxLength={MAX_SEARCH_REQUEST_LENGTH}
          ref={inputRef}
          type="text"
          className={styles.searchForm__input}
          name="search-form"
          id="search-form-input"
          placeholder="Enter the title of the video you want to search"
          aria-required="true"
          autoFocus
          autoComplete="on"
        />
        <button
          type="reset"
          onKeyDown={handleEnterDownFormReset}
          className={
            isHidden ? styles.searchForm__button_reset__hidden : styles.searchForm__button_reset
          }
        >
          <img
            className={styles.button__img_reset}
            src="img/Rounded Rectangle 4 copy 3.svg"
            alt="Button to clear form"
          />
        </button>
        <button type="submit" className={styles.searchForm__button_submit}>
          <img
            className={styles.button__img}
            src="./img/Shape 2.webp"
            alt="Magnifying glass icon"
          />
          <span className={styles.button__title}>Search</span>
        </button>
      </form>
      <label htmlFor="select" className={styles.searchForm__label}>
        You may choose how many videos will be per page
      </label>
      <select
        id="select"
        onChange={handleSelectorChange}
        className={styles.select}
        aria-label="Select"
      >
        <option value="0" disabled>
          Choose video count per page
        </option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="25">25</option>
      </select>
      {upLoadedData ? (
        <section className={styles.section}>
          {props.vimeoVideoData.videoArray.map((a, index) => (
            <VideoSeriesRow
              count={index}
              text={a.name}
              link={a.link}
              key={a.name + index}
              viewed={a.viewed}
              setViewed={props.setViewed}
              categoryName={'vimeoVideoData'}
            />
          ))}
          {props.vimeoVideoData.videoArray.length ? (
            <PaginationLogic
              currentPage={currentPage}
              request={lessonsRequest}
              changePage={changePage}
              pages={pages}
            />
          ) : (
            <div className={styles.section_result}>
              <div className={styles.result_empty}>Nothing found for your request</div>
            </div>
          )}
        </section>
      ) : (
        <div
          style={{
            background: '#f4fbff',
            minHeight: '10vh',
          }}
        ></div>
      )}
    </div>
  );
};

export default SearchPageComponent;
