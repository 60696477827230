import React, { useEffect } from 'react';
import styles from './ModalWindowButton.module.sass';
import ToDoList from '../../../asset/profilePageIcons/toDoList.webp';
import Edit from '../../../asset/profilePageIcons/Edit.webp';
import Update from '../../../asset/profilePageIcons/Update.webp';
import Leave from '../../../asset/profilePageIcons/Leave.webp';
import { useHistory } from 'react-router-dom';
import { PopupStatusProfilePage } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountManagerData } from '../asyncActions/countManager';

const ModalWindowButton = ({
  profileData,
  checkYourProfile,
  setPopupStatusProfilePage,
  teamId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateEditProfile = checkYourProfile || profileData.currentChildRole.includes('manager');
  const stateUpdateRole = profileData.currentChildRole.includes('manager');

  useEffect(() => {
    dispatch(fetchCountManagerData({ teamId }));
  }, [teamId]);

  const countManager = useSelector((state) => state.countManagerDataReducer.countManager);

  const handleClosePopupButtonClick = () => {
    if (profileData.role === 'manager') {
      if (countManager.countManager < 2) {
        setPopupStatusProfilePage(PopupStatusProfilePage.WARNING_ONE_MANAGER);
      } else {
        setPopupStatusProfilePage(PopupStatusProfilePage.WARNING_LEAVE_MANAGER);
      }
    } else {
      setPopupStatusProfilePage(PopupStatusProfilePage.LEAVE_TEAM);
    }
  };

  return (
    <section className={styles.modalWindowButtonWrapper}>
      <div className={styles.buttonWrapper}>
        <div className={styles.buttonWrapper_button} onClick={() => history.push('/teams/roster')}>
          <div>
            <img
              className={styles.buttonWrapper_button_toDoList}
              src={ToDoList}
              alt={'Back to Roster Icon'}
            />
          </div>
          <p>Back to Roster</p>
        </div>
        {checkYourProfile ? (
          <div
            className={styles.buttonWrapper_button}
            onClick={() => setPopupStatusProfilePage(PopupStatusProfilePage.EDIT_PROFILE)}
          >
            <div>
              <img
                className={styles.buttonWrapper_button_edit}
                src={Edit}
                alt={'Edit Profile Icon'}
              />
            </div>
            <p>Edit Profile</p>
          </div>
        ) : null}
        {stateUpdateRole ? (
          <div
            className={styles.buttonWrapper_button}
            onClick={() => setPopupStatusProfilePage(PopupStatusProfilePage.UPDATE_ROLE)}
          >
            <div>
              <img
                className={styles.buttonWrapper_button_update}
                src={Update}
                alt={'Update Role Icon'}
              />
            </div>
            <p>Update Role</p>
          </div>
        ) : null}
        {stateEditProfile ? (
          <div
            className={styles.buttonWrapper_button}
            onClick={() => handleClosePopupButtonClick()}
          >
            <div>
              <img
                className={styles.buttonWrapper_button_leave}
                src={Leave}
                alt={'Leave Team Icon'}
              />
            </div>
            <p>Leave Team</p>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default ModalWindowButton;
