import { useSelector } from "react-redux";

const useUserTeams = () => {
  const { userActiveTeams, currentTeamStats } = useSelector(state => state.teamReducer)

  const allTeams = userActiveTeams.map((item) => item.team)
  const manageTeams = userActiveTeams.filter((item) => item.role.includes('manager')).map((item) => item.team);

  return { teams: allTeams, manageTeams, currentTeamStats }
}

export default useUserTeams 