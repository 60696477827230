import React, {  useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { joinMailingRequest } from '../../../api/api';
import styles from './JoinMailingList.module.sass';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { birthArray } from '../../../constants/const';

const JoinMailingList = () => {
  useScrollToTop();
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      role: '',
      firstname: '',
      lastname: '',
      email: '',
      child: '',
      childbirth: ''
    },
    validationSchema: Yup.object({
      role: Yup.string().required('required'),
      firstname: Yup.string().required('required'),
      lastname: Yup.string().required('required'),
      email: Yup.string().required('required'),
      child: Yup.string().required('required'),
      childbirth: Yup.string().required('required')
    }),
    onSubmit: (values) => {
      const requestData = {
        category: 'JoinMailingList',
        role: values.role,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        child: values.child,
        childbirth: values.childbirth
      };
      try {
        joinMailingRequest(requestData).then(response => requestHandler(response));
      } catch (e) {
        console.log(e)
      }
    }
  });
  const requestHandler = (response) => {
    if (response.status === 200) {
      toggle();
    }
  };
  const toggle = () => {
    setModal(!modal)
  };
  
  const dataRoles = [
    { value: '', field: 'Choose role' },
    { value: 'Parent/Trainer', field: 'Parent/Trainer' },
    { value: 'Individual Trainer', field: 'Individual Trainer' },
    { value: 'Club Director', field: 'Club Director' },
    { value: 'Player', field: 'Player' }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container_main}>
        <div>
          <h2 className={styles.container_main__desc}>Join Our Mailing List</h2>
          <p className={styles.container_main__subtitle}>Get first access to new videos, soccer news,
            exclusive tips and much more!</p>
          <form onSubmit={formik.handleSubmit} className={styles.container_main__form}>
            {/*role(coach/parent)*/}
            <div className={styles.container_main__roleinput}>
              <label htmlFor="role" className={styles.form_labels}>
                Role
              </label>
              <select
                name="role"
                id="role"
                placeholder="Choose Role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}>
                {dataRoles.map((item) => {
                  return <option key={item.value + item.field} value={item.value}>{item.field}</option>
                })}
              </select>
            </div>
            {/*firstName*/}
            <div className={styles.container_main__forminputs}>
              <label htmlFor="firstname" className={styles.form_labels}>First Name</label>
              <input
                className={formik.touched.firstname && formik.errors.firstname
                  ? styles[formik.errors.firstname]
                  : ''}
                type="text"
                name="firstname"
                id="firstname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname.replace(/[^A-z\s]/g, '')}
              />
            </div>
            {/*lastname*/}
            <div className={styles.form_inputs}>
              <label htmlFor="lastname" className={styles.form_labels}>Last Name</label>
              <input
                className={formik.touched.lastname && formik.errors.lastname
                  ? styles[formik.errors.lastname]
                  : ''}
                type="text"
                name="lastname"
                id="lastname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname.replace(/[^A-z@._0-9-]/g, '')}
              />
            </div>
            {/*Email*/}
            <div className={styles.email_form}>
              <label htmlFor="email" className={styles.form_labels}>Email</label>
              <input type="email"
                className={formik.touched.email && formik.errors.email
                  ? styles[formik.errors.email]
                  : ''}
                name="email"
                id="email"
                placeholder="example@gmail.com"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {/*ChildName*/}
            <div className={styles.form_inputs}>
              <label htmlFor="child" className={styles.form_labels}>Child&apos;s Name</label>
              <input
                className={formik.touched.child && formik.errors.child
                  ? styles[formik.errors.child]
                  : ''}
                type="text"
                name="child"
                id="child"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.child.replace(/[^A-z\s]/g, '')}
              />
            </div>
            {/*ChildBirthYear*/}
            <div className={styles.form_inputs}>
              <label htmlFor="childbirth" className={styles.form_labels}>Child’s Birth Year</label>
              <select
                className={styles.select_col}
                type="text"
                name="childbirth"
                id="childbirth"
                placeholder="Choose Year"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.childbirth}
              >
                <option value="">Choose Year</option>
                {birthArray.map((item) => {
                  return (
                    <option key={item.toString()}>{item}</option>
                  )
                })}
              </select>
            </div>
            <div className={styles.form_button}>
              <button type="submit" className={styles.send_button} style={{ outline: 'none' }}>
                Subscribe
              </button>
            </div>
          </form>


          <Modal isOpen={modal} toggle={toggle} className={styles.modal}>
            <ModalHeader toggle={toggle}>
              Thank you
            </ModalHeader>

            <ModalBody>
              Your message has been sent!
            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={toggle} className={styles.buttonStrap}>Ok</Button>
            </ModalFooter>
          </Modal>

        </div>

      </div>

    </div>
  )
};

export default JoinMailingList;