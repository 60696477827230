import React, { useState, useEffect } from 'react';
import styles from '../TeamItem/TeamItem.module.sass';
import { RequestToJoinStatus } from '../../../../constants/teams';
import { joinTeam } from '../../../../api/teams-api';
import { message } from 'antd';

/** { "teamId" : "1", "name" : "name", "childFirstName" : "firstname lastname", “imgUrl":"link"}
 * manager name - two fields
 * с бека не возвращаются команды, в которые юзер уже вступил или отправил запрос
 */

export function JoinToRequestCard(props) {
    const { teamCard: { teamId, name, childFirstName, childLastName, imgUrl, applicationStatus } } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [
        requestToJoinStatus,
        setRequestToJoinStatus
    ] = useState(RequestToJoinStatus.REQUEST_AVAILABLE);

    useEffect(() => {
        if (isSubmitting) {
            joinTeam(teamId).then((response) => {
                if (response.ok) {
                    setRequestToJoinStatus(RequestToJoinStatus.REQUEST_PENDING);
                } else {
                    message.error('Something went wrong. Please try again later.')
                }
            })
            /** here we handle request to join to the team and change button state */
        }
    }, [isSubmitting]);

    const handleButtonClick = () => {
        setIsSubmitting(true);
    };

    const checkRequestStatus = () => {
        switch (requestToJoinStatus) {
            case RequestToJoinStatus.REQUEST_PENDING:
                return (<div className={styles.button_request}>
                    <div className={`${styles.sprite} ${styles.sent}`}></div>
                    Request Sent
                </div>);
            default:
                return (<button
                    onClick={handleButtonClick}
                    disabled={isSubmitting}
                    className={styles.button_join}
                >
                    <div className={`${styles.sprite} ${styles.active}`}></div>
                    {applicationStatus === 'pending' ? 'Send Reminder' : 'Request to Join Team'}
                </button>);
        }
    };

    return (
        <article className={styles.team_card}>
            <div className={styles.container}>
                <h3 className={styles.team_card__title}>{name}</h3>
                <p className={styles.team_card__manager}>{childFirstName} {childLastName}</p>
                {checkRequestStatus()}
            </div>
            <div className={styles.round_container}>
                <img src={imgUrl} className={styles.team_card__logo} alt="" />
            </div>
        </article>
    );
}
