import React from 'react';
import styles from './KebabMenu.module.sass';
import menuBtn from '../../../asset/dashboard/dashboardBtn.svg';
import activeMenuBtn from '../../../asset/active_menu.webp';

export function KebabMenu(props) {
  const { isKebabMenuOpen, setIsKebabMenuOpen } = props;

  const handleMobileMenuClick = () => setIsKebabMenuOpen(!isKebabMenuOpen);
  return (
    <div className={styles.container}>
      <div className={styles.menuButton}>
        <img
          src={isKebabMenuOpen ? activeMenuBtn : menuBtn}
          className={styles.menu_pic}
          onClick={handleMobileMenuClick}
          alt="menu button"
        />
      </div>
      {isKebabMenuOpen && <ul className={styles.buttons}>{props.children}</ul>}
    </div>
  );
}
