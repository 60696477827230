import React from 'react';
import styles from './trialPeriodSuggestionPopup.module.sass';
import { activateTrialPeriod } from '../../../api/api.js';
import { message } from 'antd';

const TrialPeriodSuggestionPopup = (props) => {
  const { setShowTrialPeriodPopup, closeMobileMenu, setIsStartTrial, setContentLoaded } = props;
  const handleClick = () => {
    setShowTrialPeriodPopup(false);
    if (closeMobileMenu) closeMobileMenu();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    activateTrialPeriod().then((response) => responseHandler(response));
  };

  const responseHandler = (response) => {
    if (response.ok) {
      handleClick();
      setContentLoaded(false);
      setIsStartTrial(true);
    } else {
      message.error('Something went wrong. Please try again.');
      handleClick();
    }
  };

  return (
    <>
      <div className={styles.popup__wrapper}></div>
      <section className={styles.popup}>
        <div className={styles.closeImg} onClick={handleClick}>
          &times;
        </div>
        <div aria-hidden="true" className={styles.popup__decorative_image}></div>
        <article className={styles.popup__description}>
          <h3 className={styles.popup__title}>Start your 15-day trial today!</h3>
          <p className={styles.popup__text}>
            Your 15-day trial starts today. Get access to +1,000 training videos hundreds of fun
            games, challenges and more.
          </p>
          <div className={styles.horizontal_line}></div>
          <div className={styles.buttons}>
            <button className={styles.popup__button_cancel} type="button" onClick={handleClick}>
              No, thanks
            </button>
            <button className={styles.popup__button_submit} type="submit" onClick={handleSubmit}>
              Start Trial
              <img
                aria-hidden="true"
                className={styles.arrow}
                src="/img/right-arrow.webp"
                alt="right arrow"
              />
            </button>
          </div>
        </article>
      </section>
    </>
  );
};

export default TrialPeriodSuggestionPopup;
