import React from 'react';
import RewardCertificate from '../RewardCertificate/RewardCertificate';
import styles from './rewardPreview.module.sass';
import { downloadRewardCertificate } from '../../../api/api.js';
import { message } from 'antd';

function RewardPreview(props) {
  const { trainingPlan, setRewardShown } = props;

  const handleDownloadButtonClick = () => {
    downloadRewardCertificate(trainingPlan.id)
      .then((response) => {
        requestHandler(response);
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.');
        setRewardShown(false);
      });
  };

  const requestHandler = (response) => {
    response.blob({ type: 'application/pdf' }).then((response) => {
      const fileURL = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileURL
      link.download = 'cerficate-of-completion.pdf'

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    });
  };

  return (
    <section className={styles.preview_popup_wrapper}>
      <article className={styles.reward_certificate_container}>
        <RewardCertificate trainingPlan={trainingPlan} />
        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.buttons__cancel_button}
            onClick={() => setRewardShown(false)}
          >
            Cancel
          </button>
          <button onClick={handleDownloadButtonClick} className={styles.buttons__download_button}>
            <span className={styles.download_button__title}>Download</span>
          </button>
        </div>
      </article>
    </section>
  );
}

export default RewardPreview;
