import React from 'react';
import styles from './editPlayerThank.module.sass';
import editPlayerThankPic from '../../../asset/editPlayerThank/editPlayerThankPic.webp';
import { useHistory, useLocation } from 'react-router-dom';
import { withRoleRedirectComp } from '../../../hoc/redirectComp';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { SubfolderPageUrl } from '../../../constants/const';

const EditPlayerThank = () => {
  useScrollToTop();

  const history = useHistory();
  const location = useLocation();
  const redirection = () => {
    history.replace(SubfolderPageUrl.parentDashboard);
  };

  return (
    <main>
      <div className={styles.mainDiv}>
        <div className={styles.subDiv}>
          <img src={editPlayerThankPic} alt="Thank pic" />
          <h4>Thank You!</h4>
          <h5>
            {location.pathname === '/registerFreeThunk'
              ? 'Check your email to complete registration'
              : 'Your account has been updated.'}
          </h5>
          <button
            onClick={redirection}
            className={styles.buttonDefault}
            style={{ outline: 'none' }}
          >
            Got It
          </button>
        </div>
      </div>
    </main>
  );
};

export default withRoleRedirectComp(EditPlayerThank);
