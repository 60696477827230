import React, { useState, useEffect } from 'react';
import styles from './trainingGoal.module.sass';
import { Link, useParams } from 'react-router-dom';
import TrainingGoalPopup from '../TrainingPlanPopup/TrainingPlanPopup';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GradientSVG from './GradientSVG';
import { message } from 'antd';
import RewardCenter from '../Reward/RewardCenter/RewardCenter';
import path from '../../config/routes.json';
import { getTrainingPlan, getChildAwardList } from '../../api/api';
import { compareDates } from '../../utils/utils.js';
import TrainingGoalMobileHeader from './TrainingGoalMobileHeader/TrainingGoalMobileHeader';
import menuBtn from '../../asset/dashboard/dashboardBtn.svg';
import activeMenuBtn from '../../asset/active_menu.webp';
import VideoAdjustmentPopup from '../VideoAdjustmentPopup/VideoAdjustmentPopup';
import { Avatars, GOAL_MAX_VIDEO_COUNT } from '../../constants/const.js';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { Loading } from '../commonComponents/Loading/Loading';

const Gradients = {
  RED_GRADIENT: 'redGradient',
  BLUE_GRADIENT: 'blueGradient',
  RED_GRADIENT_MOBILE: 'redGradientMobile',
  BLUE_GRADIENT_MOBILE: 'blueGradientMobile',
};

export const ComponentType = {
  TRAINING_PLAN: 'TRAINING_PLAN',
  AWARDS: 'AWARDS',
  BADGE: 'BADGE',
  PROGRESS_TRACKER: 'PROGRESS_TRACKER',
};

function TrainingGoal({
  userAwards,
  trainingPlan,
  setTrainingPlan,
  setTrainingGoal,
  setUserAwards,
  playerData,
}) {
  const [componentType, setComponentType] = useState(ComponentType.TRAINING_PLAN);
  const [mobileComponentType, setMobileComponentType] = useState(ComponentType.BADGE);
  const [isShowNewPlanPopup, setShowNewPlanPopup] = useState(false);
  const [isShowVideoAdjustmentPopup, setShowVideoAdjustmentPopup] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isVideoCountUpdateToday, setVideoCountUpdateToday] = useState(false);
  const [isVideoCountUpdateSuccessfully, setVideoCountUpdateSuccessfully] = useState(false);
  const { isTrainingPlanLoaded, name, videoGoal, videoCompleted, targetDate, startDate } =
    trainingPlan;

  useScrollToTop();
  useEffect(() => {
    if (!trainingPlan.isTrainingPlanLoaded || isVideoCountUpdateSuccessfully) {
      getTrainingPlan().then((response) => {
        requestTrainingPlanHandler(response);
      });
    }
    if (!userAwards.isUserAwardsLoaded) {
      getChildAwardList(undefined).then((response) => {
        requestUserAwardsHandler(response);
      });
    }
  }, [isVideoCountUpdateSuccessfully]);

  const totalDuration = compareDates(targetDate, startDate);
  const timeLeft = compareDates(targetDate, new Date());

  const requestTrainingPlanHandler = (response) => {
    response
      .json()
      .then((result) => {
        setTrainingPlan(result);
        setTrainingGoal(true);
      })
      .catch((e) => {
        console.error(e?.message || e);
      });
  };

  const requestUserAwardsHandler = (response) => {
    response
      .json()
      .then((result) => {
        setUserAwards(result);
      })
      .catch((response) => {
        console.log(response);
      });
  };

  const percentageTrainingPlanComplete = ((videoCompleted / videoGoal) * 100).toFixed(1);
  const rewardStatus = videoGoal <= videoCompleted;

  const handleNewPlanButtonClick = () => {
    setShowNewPlanPopup(true);
    setMobileMenu(!isMobileMenu);
  };

  const handleVideoAdjustButtonClick = () => {
    if (isVideoCountUpdateToday) {
      message.error('You can update video count only one time per day.');
    } else {
      setShowVideoAdjustmentPopup(true);
      setMobileMenu(!isMobileMenu);
    }
  };

  const handleAwardButtonClick = () => {
    if (userAwards.certificate.length) {
      componentType === ComponentType.TRAINING_PLAN
        ? setComponentType(ComponentType.AWARDS)
        : setComponentType(ComponentType.TRAINING_PLAN);
      setMobileMenu(!isMobileMenu);
    } else {
      message.error('Unfortunately you don`t have any awards.');
    }
  };

  const handleRadioButtonClick = (event) => {
    setMobileComponentType(event.target.value);
  };

  const handleMobileMenuClick = () => {
    setMobileMenu(!isMobileMenu);
  };

  const checkShieldPhoto = () => {
    let avatar = null;
    if (playerData.image) {
      avatar = 'image';
    } else if (playerData.picture) {
      avatar = 'picture';
    }
    switch (avatar) {
      case 'picture':
        return (
          <img
            className={styles.shield__avatar}
            src={Avatars[parseInt(playerData.picture) - 1]}
            alt=""
          />
        );
      case 'image':
        return <img className={styles.shield__photo} src={playerData.image} alt="user avatar" />;
      default:
        return <img className={styles.shield__ball} src="img/trainingPlan/ball.webp" alt="" />;
    }
  };
  const checkCompletedVideo = () =>
    videoCompleted <= GOAL_MAX_VIDEO_COUNT ? videoCompleted : `>${GOAL_MAX_VIDEO_COUNT}`;
  return (
    <>
      {isShowNewPlanPopup && (
        <TrainingGoalPopup
          setShowNewPlanPopup={setShowNewPlanPopup}
          setTrainingGoal={setTrainingGoal}
        />
      )}
      {isShowVideoAdjustmentPopup && (
        <VideoAdjustmentPopup
          setShowVideoAdjustmentPopup={setShowVideoAdjustmentPopup}
          setVideoCountUpdateSuccessfully={setVideoCountUpdateSuccessfully}
          setVideoCountUpdateToday={setVideoCountUpdateToday}
          videoCompleted={videoCompleted}
        />
      )}
      {isMobileMenu && (
        <TrainingGoalMobileHeader
          isMobileMenu={isMobileMenu}
          handleNewPlanButtonClick={handleNewPlanButtonClick}
          handleAwardButtonClick={handleAwardButtonClick}
          handleVideoAdjustButtonClick={handleVideoAdjustButtonClick}
          componentType={componentType}
        />
      )}
      {isTrainingPlanLoaded ? (
        <div className={styles.container}>
          <header className={styles.header}>
            {componentType === ComponentType.TRAINING_PLAN && (
              <h1 className={styles.title}>My training goal</h1>
            )}
            {componentType === ComponentType.AWARDS && <h1 className={styles.title}>Awards</h1>}
            <div className={styles.menuButton}>
              <img
                src={isMobileMenu ? activeMenuBtn : menuBtn}
                width="28px"
                onClick={handleMobileMenuClick}
                alt="menu button"
              />
            </div>
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={handleNewPlanButtonClick}
                className={styles.buttons__new_plan}
              >
                <img
                  aria-hidden="true"
                  className={styles.button__img}
                  src="/img/rounded-rectangle.webp"
                  alt=""
                />
                <span className={styles.button__title}>New Plan</span>
              </button>
              <button
                type="button"
                onClick={handleVideoAdjustButtonClick}
                className={styles.buttons__video_adjustment}
              >
                <img
                  aria-hidden="true"
                  className={styles.button__img}
                  src="/img/equalizer.webp"
                  alt=""
                />
                <span className={styles.button__title}>Video Adjustment</span>
              </button>
              <button
                type="button"
                onClick={handleAwardButtonClick}
                className={styles.buttons__awards}
              >
                <div aria-hidden="true" className={styles.bg__awards}></div>
                <span className={styles.button__title}>
                  {componentType === ComponentType.TRAINING_PLAN ? 'Awards' : 'Training Plan'}
                </span>
              </button>
            </div>
          </header>
          <div className={styles.mobile_button}>
            <input
              onClick={handleRadioButtonClick}
              type="radio"
              id="badge"
              name="component"
              value={ComponentType.BADGE}
              className={styles.mobile_button__badge}
              defaultChecked
            />
            <label
              htmlFor="badge"
              value={ComponentType.BADGE}
              className={styles.mobile_button__name}
            >
              Badge
            </label>
            <input
              onClick={handleRadioButtonClick}
              type="radio"
              name="component"
              id="tracker"
              value={ComponentType.PROGRESS_TRACKER}
              className={styles.mobile_button__tracker}
            />
            <label
              htmlFor="tracker"
              value={ComponentType.PROGRESS_TRACKER}
              className={styles.mobile_button__name}
            >
              Progress Tracker
            </label>
          </div>
          {componentType === ComponentType.TRAINING_PLAN && (
            <div className={styles.main}>
              <article className={styles.shield_desktop}>
                <div className={styles.shield__goal_videos}>
                  <span className={styles.goal}>Goal</span>
                  <span className={styles.number_video}>{videoGoal}</span>videos
                </div>
                {checkShieldPhoto()}
                <div className={styles.shield__detalization}>
                  <p className={styles.player_name}>{name}</p>
                  <p className={styles.text}>Videos Completed</p>
                  <p className={styles.watched_number_video}>{checkCompletedVideo()}</p>
                </div>
              </article>
              <article className={styles.tracker_desktop}>
                <h3 className={styles.tracker__title}>Progress Tracker</h3>
                <div className={styles.trackers_container}>
                  <div className={styles.progress_tracker}>
                    <GradientSVG
                      startColor={'#ba343a'}
                      endColor={'#dd373d'}
                      idCSS={Gradients.RED_GRADIENT}
                      rotation={350}
                    />
                    <CircularProgressbarWithChildren
                      value={percentageTrainingPlanComplete}
                      background
                      backgroundPadding={2}
                      styles={{
                        root: {},
                        path: {
                          strokeLinecap: 'butt',
                          stroke: `url(#${Gradients.RED_GRADIENT})`,
                          height: '100%',
                        },
                        trail: {
                          strokeLinecap: 'butt',
                          stroke: '#dfe8ef',
                        },
                        background: {
                          fill: '#c4cdd2',
                        },
                      }}
                    >
                      <div className={styles.video_tracker}>
                        <p className={styles.tracker_title}>{percentageTrainingPlanComplete}%</p>
                        <p className={styles.tracker_description}>Progress</p>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className={styles.progress_tracker}>
                    <GradientSVG
                      startColor={'#003056'}
                      endColor={'#1d639a'}
                      idCSS={Gradients.BLUE_GRADIENT}
                      rotation={90}
                    />
                    <CircularProgressbarWithChildren
                      value={((totalDuration - timeLeft + 1) / totalDuration) * 100}
                      background
                      backgroundPadding={2}
                      styles={{
                        root: {},
                        path: {
                          strokeLinecap: 'butt',
                          stroke: `url(#${Gradients.BLUE_GRADIENT})`,
                          height: '100%',
                        },
                        trail: {
                          strokeLinecap: 'butt',
                          stroke: '#dfe8ef',
                        },
                        background: {
                          fill: '#c4cdd2',
                        },
                      }}
                    >
                      <div className={styles.day_tracker}>
                        <p className={styles.tracker_title}>{timeLeft >= 0 ? timeLeft : 0}</p>
                        <p className={styles.tracker_description}>Days Remaining</p>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </article>
              {mobileComponentType === ComponentType.BADGE && (
                <article className={styles.shield}>
                  <div className={styles.shield__goal_videos}>
                    <span className={styles.goal}>Goal</span>
                    <span className={styles.number_video}>{videoGoal}</span>videos
                  </div>
                  {checkShieldPhoto()}
                  <div className={styles.shield__detalization}>
                    <p className={styles.player_name}>{name}</p>
                    <p className={styles.text}>Videos Completed</p>
                    <p className={styles.watched_number_video}>{checkCompletedVideo()}</p>
                  </div>
                </article>
              )}
              {mobileComponentType === ComponentType.PROGRESS_TRACKER && (
                <article className={styles.tracker}>
                  <h3 className={styles.tracker__title}>Progress Tracker</h3>
                  <div className={styles.trackers_container}>
                    <div className={styles.progress_tracker}>
                      <GradientSVG
                        startColor={'#ba343a'}
                        endColor={'#dd373d'}
                        idCSS={Gradients.RED_GRADIENT_MOBILE}
                        rotation={350}
                      />
                      <CircularProgressbarWithChildren
                        value={percentageTrainingPlanComplete}
                        background
                        backgroundPadding={2}
                        styles={{
                          root: {},
                          path: {
                            strokeLinecap: 'butt',
                            stroke: `url(#${Gradients.RED_GRADIENT_MOBILE})`,
                            height: '100%',
                          },
                          trail: {
                            strokeLinecap: 'butt',
                            stroke: '#dfe8ef',
                          },
                          background: {
                            fill: '#c4cdd2',
                          },
                        }}
                      >
                        <div className={styles.video_tracker}>
                          <p className={styles.tracker_title}>{percentageTrainingPlanComplete}%</p>
                          <p className={styles.tracker_description}>Progress</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className={styles.progress_tracker}>
                      <GradientSVG
                        startColor={'#003056'}
                        endColor={'#1d639a'}
                        idCSS={Gradients.BLUE_GRADIENT_MOBILE}
                        rotation={90}
                      />
                      <CircularProgressbarWithChildren
                        value={((totalDuration - timeLeft + 1) / totalDuration) * 100}
                        background
                        backgroundPadding={2}
                        styles={{
                          root: {},
                          path: {
                            strokeLinecap: 'butt',
                            stroke: `url(#${Gradients.BLUE_GRADIENT_MOBILE})`,
                            height: '100%',
                          },
                          trail: {
                            strokeLinecap: 'butt',
                            stroke: '#dfe8ef',
                          },
                          background: {
                            fill: '#c4cdd2',
                          },
                        }}
                      >
                        <div className={styles.day_tracker}>
                          <p className={styles.tracker_title}>{timeLeft >= 0 ? timeLeft : 0}</p>
                          <p className={styles.tracker_description}>Days Remaining</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </article>
              )}
              <hr className={styles.hr} />
              <article className={styles.footer}>
                <div className={styles.logo}>
                  <img
                    src="/img/logo.webp"
                    alt="Anytime Soccer logo"
                    className={styles.logo__img}
                  />
                </div>
                <div className={styles.get_reward}>
                  {rewardStatus ? (
                    <>
                      <p className={styles.first_p}>Congratulations! </p>
                      <p className={styles.second_p}>Claim Your Reward!</p>
                      <Link to={path.rewardCertificate} className={styles.button__reward}>
                        <div aria-hidden="true" className={styles.bg__button_active}></div>
                        <span className={styles.button__title}>Get reward</span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <p className={styles.first_p}>Keep Practicing to</p>
                      <p className={styles.second_p}>Claim Your Reward!</p>
                      {percentageTrainingPlanComplete >= 50 && (
                        <Link to={'/'} disabled className={styles.button__reward_disabled}>
                          <div aria-hidden="true" className={styles.bg__button_disabled}></div>
                          <span className={styles.button__title}>Get reward</span>
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </article>
            </div>
          )}
          {componentType === ComponentType.AWARDS && (
            <RewardCenter setUserAwards={setUserAwards} userAwards={userAwards} />
          )}
        </div>
      ) : (
        <Loading height={'50vh'} />
      )}
    </>
  );
}

export default TrainingGoal;
