import React from 'react'
import { Link } from 'react-router-dom';
import styles from '../Contest.module.sass'


export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const StatCard = ({ name, value, color }) => {

  const truncatedValue = truncateText(value, 16);

  return (
    <div className={styles.contest_metadata} style={{
      flex: '33.33%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '.75rem',
      padding: '.8rem .5rem'
    }}>
      <span className={styles.statLabel}>{name}</span>
      {name === 'Official Page' ? (
        <Link className={styles.button__reward} to={`/contest/${value}`}>
          <div aria-hidden="true" className={styles.bg__button_active}></div> 
          <span className={styles.button__title}>View Page</span>
        </Link>
      ) : (
        <span className={styles.statValue} style={{
          color,
          textTransform: 'capitalize',
          width: '100%',
          textAlign: 'center'
        }} title={value}>{truncatedValue}</span>
      )}
    </div>
  )
}

StatCard.defaultProps = {
  color: '#e1343f'
}

export default StatCard