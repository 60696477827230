import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NavPanelMobileMenu.module.sass';
import StepOneBody from '../Page';
import Step3 from '../Pay';
import { useState } from 'react';
import { Modal, message } from 'antd';
import path from '../../../../config/routes.json';
import FreeTrialButton from '../FreeTrialButton/FreeTrialButton';
import { ButtonStatus } from '../NavPanel';

const NavPanelMobileMenu = (props) => {
  const [total, setTotal] = useState(0);
  const [step, setStep] = useState(0);
  const [subscriptionPlayers, setSubscriptionPlayers] = useState({});

  const getUserName = () => {
    const numOfAllChild = props.playerData.childInfo.childrenCount;
    const resultArr = [];
    for (let i = 1; i <= numOfAllChild; i++) {
      resultArr.push(props.playerData.childInfo[`child${i}`].username);
    }
    return resultArr;
  };

  const checkChildStatus = () => {
    let flag = false;
    if (props.playerData.childInfo.childrenCount === 0) {
      return flag;
    }
    for (const key in props.playerData.childInfo) {
      if (
        props.playerData.childInfo[key].status !== 'active' &&
        key !== 'childrenCount' &&
        key !== 'forChildId'
      ) {
        flag = true;
      }
    }
    return flag;
  };

  const checkShouldWeShownTrialButton = () => {
    switch (props.trialStatus) {
      case ButtonStatus.ACTIVATE_TRIAL:
        return <FreeTrialButton setShowTrialPeriodPopup={props.setShowTrialPeriodPopup} />;
      case ButtonStatus.TRIAL_ACTIVATED:
        return null;
      case ButtonStatus.TRIAL_IS_OVER:
        return null;
      default:
        return null;
    }
  };

  const showModal = () => {
    props.setShowUpgradePopup(true);
  };

  const handleOk = () => {
    props.setIsModalVisible(false);
    if (step === 1) {
      window.location.reload();
    }
    setStep(0);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
    if (step === 1) {
      window.location.reload();
    }
    setStep(0);
  };

  const handleClick = () => message.error('The number of players cannot be more than four!');
  const isNewChildCanBeAdded =
    props.playerData.childInfo.childrenCount < 4 ||
    props.playerData.userInfo.email === 'neil@anytime-soccer.com';

  return (
    <>
      <div className={styles.modal}>
        <ul className={styles.modal_content}>
          <li className={styles.list_item}>
            {checkChildStatus() && (
              <button
                type="submit"
                id="toggleForBtnNext"
                className={styles.button}
                onClick={showModal}
              >
                Upgrade
              </button>
            )}
            <Modal
              title={<span style={{ color: 'black', fontSize: '25px' }}>Upgrade</span>}
              visible={props.isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              width="90%"
              destroyOnClose
            >
              {step === 0 ? (
                <div style={{ marginTop: '-19px' }}>
                  <div style={{ paddingBottom: '10px', fontSize: '20px', fontWeight: '700' }}>
                    Player Accounts
                  </div>
                  <StepOneBody
                    getUserName={getUserName}
                    childStatus={props.playerData.childInfo}
                    setStep={setStep}
                    setTotal={setTotal}
                    userInfo={props.playerData.userInfo}
                    setUserDataStep1={props.setUserDataStep1}
                    setSubscriptionPlayers={setSubscriptionPlayers}
                  />
                </div>
              ) : (
                <Step3
                  total={total}
                  setUserDataStep3={props.setUserDataStep3}
                  signUpData={props.signUpData}
                  playerData={props.playerData}
                  subscriptionPlayers={subscriptionPlayers}
                  handleCancel={handleCancel}
                />
              )}
            </Modal>
          </li>
          <li className={styles.list_item}>
            <Link to={path.dashboard.manage.account} className={styles.button}>
              Account Management
            </Link>
          </li>
          <li className={styles.list_item}>
            {isNewChildCanBeAdded ? (
              <Link to={path.dashboard.createPlayerFree} className={styles.button}>
                <span className={styles.plus}>+ &nbsp;</span>
                Add Player/Profile
              </Link>
            ) : (
              <button className={styles.button} onClick={handleClick}>
                <span className={styles.plus}>+ &nbsp;</span>
                Add Player/Profile
              </button>
            )}
          </li>
          <li className={styles.list_item}>{checkShouldWeShownTrialButton()}</li>
        </ul>
      </div>
    </>
  );
};

export default NavPanelMobileMenu;
