import { useQuery } from '@tanstack/react-query';
import { getHomeworkReportsByTeamId } from '../api/api';

const useHomeworkReports = (teamId) => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['homework-reports', teamId],
    queryFn: async () => await getHomeworkReportsByTeamId(teamId).then((res) => res.data),
    enabled: !!teamId,
  });

  return {
    isLoading,
    data: data ?? [],
    error,
    refetch,
  };
};

export default useHomeworkReports;
