import React from 'react';
import styles from './ExtraInfo.module.sass';

export function ExtraInfo() {
    return (
        <>
            <p className={styles.placingTextTitle}>
                By placing an order you are stating that you agree to the
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="../termsNconditions"
                    className={styles.linkTerms}
                >
                    Terms & Conditions
                </a>
            </p>
            <p className={styles.secure_info}>
                Secure Server - Your information is encrypted and protected.
            </p>
        </>
    )
}