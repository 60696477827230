import React, { useEffect, useState } from 'react';
import { BorderedButton } from '../../../commonComponents/BorderedButton/BorderedButton';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './CreateTeamForm.module.sass';
import { ClosePopupButton } from '../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { Screen } from '../../../../constants/teams';
import { checkIsTeamNameUnique, createTeam } from '../../../../api/teams-api';
import { showDefaultErrorMessage } from '../../../../utils/throwError';
import { useSelector } from 'react-redux';

// TODO: team - передавать через пропсы
// компоненты кнопок? - могут принимать функцию которую будут вызывать по клику
// опциональные пропсы
// ошибки вложенности в форме?

export function CreateTeamForm(props) {
  const [isReadyToCheckName, setIsReadyToCheckName] = useState(false);
  const [hasNameChecked, setHasNameChecked] = useState(false);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const childScreenName = useSelector((state) => state?.playerDataReducer?.playerData?.screenname);
  const [firstName, ...lastName] = childScreenName.split(' ');

  const [teamData, setTeamData] = useState({
    teamName: '',
    childFirstName: firstName,
    childLastName: lastName.join(' '),
  });

  async function checkUniqueness(teamName) {
    const response = await checkIsTeamNameUnique(teamName);
    setIsNameUnique(response.ok);
    setHasNameChecked(true);
  }

  useEffect(() => {
    checkUniqueness(teamData.teamName);
  }, [isReadyToCheckName]);

  const handleClosePopupButtonClick = () => {
    props.changeChosenScreen(props.defaultScreen);
  };

  const submitForm = () => {
    if (!hasNameChecked) {
      checkUniqueness(teamData.teamName);
    }
    if (isNameUnique) {
      createTeam(teamData).then((response) => {
        if (response.ok) {
          props.changeChosenScreen(Screen.CREATE_TEAM_SUCCESS);
        } else {
          showDefaultErrorMessage();
        }
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTeamData({
      ...teamData,
      [name]: value,
    });
  };

  const handleTeamNameChange = (event) => {
    setTeamData({
      ...teamData,
      teamName: event.target.value,
    });
    if (!isNameUnique) {
      setIsNameUnique(true);
    }
  };

  const handleTeamNameBlur = () => {
    setIsReadyToCheckName(!isReadyToCheckName);
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.create_form}>
        <ClosePopupButton handleClosePopupButtonClick={handleClosePopupButtonClick} />
        <h2 className={styles.create_form__title}>Create a New Team</h2>
        <div className={styles.flex_container}>
          <article className={styles.description}>
            <p className={styles.description__title}>Let’s get started</p>
            <p className={styles.description__promo}>
              Teams are a great way to assign homework, stay connected and compete.
            </p>
            <ul className={styles.pros}>
              <li className={styles.pros__item}>Set team and individual goals</li>
              <li className={styles.pros__item}>Track your members’ progress</li>
              <li className={styles.pros__item}>Send rewards and shoutouts</li>
            </ul>
          </article>
          <form className={styles.form}>
            <label className={styles.form__label}>
              Team Name
              <input
                type="text"
                value={teamData.teamName}
                className={styles.form__input}
                onChange={handleTeamNameChange}
                onBlur={handleTeamNameBlur}
              />
              {!isNameUnique && (
                <p className={styles.form__error}>This name is already being used.</p>
              )}
            </label>
            <label className={styles.form__label}>
              Team Manager First Name
              <input
                value={teamData.childFirstName}
                name="childFirstName"
                onChange={handleInputChange}
                type="text"
                required
                className={styles.form__input}
              />
            </label>
            <label className={styles.form__label}>
              Team Manager Last Name
              <input
                value={teamData.childLastName}
                name="childLastName"
                onChange={handleInputChange}
                type="text"
                required
                className={styles.form__input}
              />
            </label>
            <p className={styles.form__importance}>This information can be changed later</p>
            <div className={styles.buttons}>
              <BorderedButton
                handleButtonClick={handleClosePopupButtonClick}
                width={'20rem'}
              ></BorderedButton>
              <RedButton
                handleButtonClick={submitForm}
                title={'Create Team'}
                width={'20rem'}
              ></RedButton>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
