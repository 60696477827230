const SET_COUNT_MANAGER = './countManager/SET-COUNT-MANAGER';

const initialState = {
  countManager: 0,
};

const countManagerDataReducer = (state = initialState, action) => {
  if (action.type === SET_COUNT_MANAGER) {
    return {
      ...state,
      countManager: action.countManager,
    };
  } else {
    return state;
  }
};

export const getCountManagerAction = countManager => ({
  type: SET_COUNT_MANAGER,
  countManager,
});

export default countManagerDataReducer;
