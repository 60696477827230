import React from 'react';
import s from '../../../app.module.sass';
import { Spinner } from 'reactstrap';

export function Loading(props) {
    return (
        <div style={{ background: '#f4fbff', minHeight: props.height ?? '80vh' }}>
            <Spinner color="primary" className={s.spinner} />
        </div>
    );
}
