import React, { useState } from 'react';
import styles from './TeamSummary.module.sass';
import { playersHeaders, managerHeaders, managerRoles } from '../../../../../../constants/teams.js';
import { PlayerRow } from './PlayerRow/PlayerRow';
import { ManagerRow } from './ManagerRow/ManagerRow';
import { useScrollToTop } from '../../../../../../hooks/useScrollToTop';
import PlayersGoal from '../../../../../../asset/teams/roster/invitations/Players_goal.webp';
import RedCross from '../../../../../../asset/videoSeries/RedCross.webp';
import { showErrorMessage } from '../../../../../../utils/throwError.js';
import { timeConvert, convertMinutesInHours } from '../../../../../../utils/utils';
import { useDispatch } from 'react-redux';
import { setPlayers } from '../../../../../../Redux/teamReducer';

export function TeamSummary(props) {
  const { team, managers, countVideosSum, timeVideosSum, activeSelector, teamSummary, teamId } =
    props;
  const dispatch = useDispatch();
  useScrollToTop();
  const [videosOrder, setVideosOrder] = useState(false);
  const [timeOrder, setTimeOrder] = useState(false);
  const [thisItemOpen, setThisItemOpen] = useState('');

  const checkIsManager = (player) => {
    return managerRoles.some((element) => element === player);
  };

  const handleActionMenuButtonClick = () => {
    showErrorMessage('Coming Soon... :)');
  };

  const sortAscDesc = (header, arr) => {
    switch (header) {
      case 'Videos':
        if (!videosOrder) {
          dispatch(setPlayers(arr.sort((a, b) => a.countVideo - b.countVideo)));
          setVideosOrder(true);
          setTimeOrder(false);
        } else {
          dispatch(setPlayers(arr.sort((a, b) => b.countVideo - a.countVideo)));
          setVideosOrder(false);
        }
        break;
      case 'Time':
        if (!timeOrder) {
          dispatch(setPlayers(arr.sort((a, b) => a.timeVideo - b.timeVideo)));
          setTimeOrder(true);
          setVideosOrder(false);
        } else {
          dispatch(setPlayers(arr.sort((a, b) => b.timeVideo - a.timeVideo)));
          setTimeOrder(false);
        }
        break;
    }
  };

  return (
    <>
      {activeSelector !== 'All Time' && (
        <div className={styles.team_summary}>
          <p>
            Videos:
            <span
              className={
                countVideosSum >= teamSummary?.teamVideoGoal
                  ? styles.team_summary__goal_reached
                  : styles.team_summary__goal_not_reached
              }
            >
              {countVideosSum}
            </span>
          </p>
          <p>
            Time:
            <span
              className={
                Math.round(timeVideosSum / 60) >= teamSummary?.teamTimeGoal // summary time from backend comes in seconds, so we multiply goal hours by 3600
                  ? styles.team_summary__goal_reached
                  : styles.team_summary__goal_not_reached
              }
            >
              {' '}
              {timeConvert(timeVideosSum)}
            </span>
          </p>
          <p>
            Goal:
            <span
              className={
                countVideosSum >= teamSummary?.teamVideoGoal // summary time from backend comes in seconds, so we multiply goal hours by 3600
                  ? styles.team_summary__goal_reached
                  : styles.team_summary__goal_not_reached
              }
            >
              {teamSummary?.teamVideoGoal}
            </span>
            |
            <span
              className={
                Math.round(timeVideosSum / 60) >= teamSummary?.teamTimeGoal // summary time from backend comes in seconds, so we multiply goal hours by 3600
                  ? styles.team_summary__goal_reached
                  : styles.team_summary__goal_not_reached
              }
            >
              {convertMinutesInHours(teamSummary?.teamTimeGoal)}
              <img
                className={styles.reached_icon}
                src={
                  countVideosSum >= teamSummary?.teamVideoGoal &&
                  Math.round(timeVideosSum / 60) >= teamSummary?.teamStatusGoal
                    ? PlayersGoal
                    : RedCross
                }
                alt=""
              />
            </span>
          </p>
        </div>
      )}
      <h2 className={styles.title}>Players</h2>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {playersHeaders.map((header) => (
              <th
                scope="col"
                className={styles.player_data__header__item}
                key={header}
                onClick={() => sortAscDesc(header, team)}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          <PlayerRow
            playerData={team}
            teamId={teamId}
            thisItemOpen={thisItemOpen}
            setThisItemOpen={setThisItemOpen}
            handleActionMenuButtonClick={handleActionMenuButtonClick}
          />
        </tbody>
      </table>
      {team.some(checkIsManager) && <div className={styles.manager_player}>Player/Manager</div>}
      <h2 className={styles.title}>Managers</h2>
      <table className={styles.player_data}>
        <thead className={styles.player_data__thead}>
          <tr className={styles.player_data__header}>
            {managerHeaders.map((header) => (
              <th scope="col" className={styles.player_data__header__manager_item} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          <ManagerRow
            managerData={managers}
            teamId={teamId}
            thisItemOpen={thisItemOpen}
            setThisItemOpen={setThisItemOpen}
            handleActionMenuButtonClick={handleActionMenuButtonClick}
          />
        </tbody>
      </table>
    </>
  );
}
