import { SubfolderPageUrl } from '../constants/const';

const ActionType = {
  SET_WARM_UP_DATA: 'set-warm-up-data',
  SET_BALL_MASTERY_DATA: 'set-ball-mastery-data',
  SET_DRIBBLING_TURNS_DATA: 'set-dribbling-games-data',
  SET_PASSING_RECEIVING_DATA: 'set-passing-receiving-data',
  SET_FITNESS_AGILITY_DATA: 'set-fitness-agility-data',
};

const initialState = {
  data: [
    {
      title: 'Warm-Up & Juggling',
      subTitle: '',
      actionType: ActionType.SET_WARM_UP_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_30_grey_blue.webp',
      link: SubfolderPageUrl.playerJourneysSubfolder,
    },
    {
      title: 'Ball Mastery',
      subTitle: '',
      actionType: ActionType.SET_BALL_MASTERY_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_31_grey_blue.webp',
      link: SubfolderPageUrl.playerJourneysSubfolder,
    },
    {
      title: 'Dribbling',
      subTitle: '',
      actionType: ActionType.SET_DRIBBLING_TURNS_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_32_grey_blue.webp',
      link: SubfolderPageUrl.playerJourneysSubfolder,
    },
    {
      title: 'Passing Receiving',
      subTitle: '',
      actionType: ActionType.SET_PASSING_RECEIVING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_33_grey_blue.webp',
      link: SubfolderPageUrl.playerJourneysSubfolder,
    },
    {
      title: 'Fitness Agility',
      subTitle: '',
      actionType: ActionType.SET_FITNESS_AGILITY_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_34_grey_blue.webp',
      link: SubfolderPageUrl.playerJourneysSubfolder,
    },
  ],
  subfoldersData: [],
};

const newData = {
  warmUp: [
    {
      title: 'Juggling Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_19_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  passing: [
    {
      title: 'Wall Pass Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_20_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribbling: [
    {
      title: 'Dribble Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_21_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  fitness: [
    {
      title: '30 Day Fitness',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_26_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  ballMastery: [
    {
      title: '30 Day Player',
      subTitle: 'Getting Started Essentials',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_18_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '30 Day Ball Mastery',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_23_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '30 Day No Weak Foot ',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_24_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '30 Day Tight Space',
      subTitle: 'Sessions',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_25_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const playerJourneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_WARM_UP_DATA:
      return {
        ...state,
        subfoldersData: newData.warmUp,
      };
    case ActionType.SET_BALL_MASTERY_DATA:
      return {
        ...state,
        subfoldersData: newData.ballMastery,
      };
    case ActionType.SET_DRIBBLING_TURNS_DATA:
      return {
        ...state,
        subfoldersData: newData.dribbling,
      };
    case ActionType.SET_PASSING_RECEIVING_DATA:
      return {
        ...state,
        subfoldersData: newData.passing,
      };
    case ActionType.SET_FITNESS_AGILITY_DATA:
      return {
        ...state,
        subfoldersData: newData.fitness,
      };
    default:
      return state;
  }
};

export const setWarmUpData = () => ({
  type: ActionType.SET_WARM_UP_DATA,
});

export const setBallMasteryData = () => ({
  type: ActionType.SET_BALL_MASTERY_DATA,
});

export const setDribblingTurnsData = () => ({
  type: ActionType.SET_DRIBBLING_TURNS_DATA,
});

export const setPassingReceivingData = () => ({
  type: ActionType.SET_PASSING_RECEIVING_DATA,
});

export const setFitnessAgilityData = () => ({
  type: ActionType.SET_FITNESS_AGILITY_DATA,
});

export default playerJourneyReducer;
