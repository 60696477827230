import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './CreatePlayer.module.sass';
import FirstBallImg from '../../../asset/dashboard/createPlayer/FirstBall.webp';
import SecondBallImg from '../../../asset/dashboard/createPlayer/SecondBall.webp';
import ThirdBallImg from '../../../asset/dashboard/createPlayer/ThirdBall.webp';
import FourthBallImg from '../../../asset/dashboard/createPlayer/FourthBall.webp';
import FifthBallImg from '../../../asset/dashboard/createPlayer/FifthBall.webp';
import { useHistory } from 'react-router-dom';
import { checkUniqueValues } from '../../../api/api';
import {
  fourChildRedirect,
  withRoleRedirectComp,
  haveNoLoginRedirect,
} from '../../../hoc/redirectComp';
import { compose } from 'redux';
import { Input } from 'reactstrap';
import { message } from 'antd';

const CreatePlayer = (props) => {
  const [userNameErr, setUserNameErr] = useState(false);
  const [ball, setBall] = useState(1);

  const history = useHistory();
  const currentChild = `child` + (props.childCount + 1);
  const formik = useFormik({
    initialValues: {
      screenName: props.playerData.screenName,
      birthyear: props.playerData.birthyear || new Date().getFullYear(),
      childEmail: props.playerData.childEmail,
      username: props.playerData.username,
      password: props.playerData.password,
    },
    validationSchema: Yup.object({
      screenName: Yup.string().trim().required('Required'),
      birthyear: Yup.string().required('Required'),
      childEmail: Yup.string().trim().email('Required').required('Required'),
      username: Yup.string().trim().required('Required'),
      password: Yup.string().trim().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        checkUniqueValues({ [currentChild]: values.username, addPlayer: true }).then((response) =>
          requestHandler(response),
        );
      } catch (e) {
        console.log(e);
      }
    },
  });

  const requestHandler = (response) => {
    response.json().then((result) => {
      props.setPlayerTotalPrice({ playerTotalPrice: result.totalTarifs.total });
      if (result.result === true) {
        props.setPlayerData({ ...formik.values, picture: ball });
        history.replace('/dashboard/confirmPlayer');
        setUserNameErr(false);
      } else {
        setUserNameErr(true);
      }
    });
  };
  const getYears = (min, max) => {
    const years = [];
    for (let i = max; i >= min; i--) years.push(i);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  useEffect(() => {
    if (userNameErr) {
      message.error('This username is already taken.');
    }
  }, [userNameErr]);

  return (
    <div className={styles.container}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <h3>Add a Player</h3>
        <p>Fields marked with * are mandatory</p>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_rightP}>
                <label htmlFor="screenName">Screen Name *</label>
                <input
                  className={
                    formik.touched.screenName && formik.errors.screenName
                      ? styles[formik.errors.screenName]
                      : ''
                  }
                  name="screenName"
                  id="screenName"
                  type="text"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.screenName.replace(/[^A-z0-9\s]/g, '')} //
                />
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_leftP}>
                <label htmlFor="birthYear">Birth Year *</label>
                <Input
                  name="birthyear"
                  className={styles.birthForm}
                  type="select"
                  bsSize="lg"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birthyear}
                >
                  {getYears(1990, new Date().getFullYear())}
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <label htmlFor="childEmail">Contact Email *</label>
          <input
            className={
              formik.touched.childEmail && formik.errors.childEmail
                ? styles[formik.errors.childEmail]
                : ''
            }
            name="childEmail"
            id="childEmail"
            type="email"
            placeholder="example@gmail.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.childEmail.replace(/[^A-z@._0-9-]/g, '')}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_rightP}>
                <div className={styles.flex}>
                  <label htmlFor="username">Username *</label>
                </div>
                <input
                  className={
                    formik.touched.username && formik.errors.username
                      ? styles[formik.errors.username]
                      : ''
                  }
                  name="username"
                  id="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username.replace(/[\s]/g, '')}
                />
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_leftP}>
                <div className={styles.flex}>
                  <label htmlFor="password">Password *</label>
                </div>
                <input
                  className={
                    formik.touched.password && formik.errors.password
                      ? styles[formik.errors.password]
                      : ''
                  }
                  name="password"
                  id="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password.replace(/[\s]/g, '')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.iconContainer}>
          <img
            onClick={() => setBall(1)}
            className={ball === 1 ? styles.iconChoiseEffect : styles.iconEffects}
            src={FirstBallImg}
            alt="FirstBallImg"
          />
          <img
            onClick={() => setBall(2)}
            className={ball === 2 ? styles.iconChoiseEffect : styles.iconEffects}
            src={SecondBallImg}
            alt="SecondBallImg"
          />
          <img
            onClick={() => setBall(3)}
            className={ball === 3 ? styles.iconChoiseEffect : styles.iconEffects}
            src={ThirdBallImg}
            alt="ThirdBallImg"
          />
          <img
            onClick={() => setBall(4)}
            className={ball === 4 ? styles.iconChoiseEffect : styles.iconEffects}
            src={FourthBallImg}
            alt="FourthBallImg"
          />
          <img
            onClick={() => setBall(5)}
            className={ball === 5 ? styles.iconChoiseEffect : styles.iconEffects}
            src={FifthBallImg}
            alt="FifthBallImg"
          />
        </div>

        <button type="submit" className={styles.submitButton}>
          Next
        </button>
      </form>
    </div>
  );
};

export default compose(fourChildRedirect, withRoleRedirectComp, haveNoLoginRedirect)(CreatePlayer);
