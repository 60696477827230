import React from 'react';
import styles from './Payment.module.sass';
import Pay from '../../Dashbord/NavPanel/Pay';

export function Payment(props) {
    return (
        <section className={styles.payment}>
            <Pay
                total={props.total}
                signUpData={props.signUpData}
                userData={props.userData}
                subscriptionPlayers={props.subscriptionPlayers}
                setIsPaymentStep={props.setIsPaymentStep}
            />
        </section>
    )
};