import React from 'react';
import styles from './TeamStats.module.sass';
import { TeamStatsRow } from '../TeamStatsRow/TeamStatsRow';
import { headers, mobileHeaders } from '../../../../constants/teams.js';
import { PlayerStatsRow } from './PlayerStatsRow/PlayerStatsRow';
import { Selector } from '../../../commonComponents/Selector/Selector';
import { SelectorForTeam } from '../../../../constants/teams.js';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { timeConvert } from '../../../../utils/utils';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';
import { changeFormatData } from '../../../../utils/utils';

export function TeamStats({ activeSelector, setActiveSelector, currentTeamStats, goalStats }) {
  useScrollToTop();
  const { width } = useWindowDimensions();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const teamStats = {
    'Team Size': goalStats.teamSize || 0,
    'Total Videos Completed':  numberWithCommas(goalStats.totalVideo),
    'Total Training Time': goalStats.totalTime ? timeConvert(goalStats.totalTime) : 0,
  };

  const teamGoalStats = {
    isGoalCompleted: currentTeamStats.teamSummary.teamStatusGoal,
    'Total Videos Completed': numberWithCommas(changeFormatData(currentTeamStats.teamSummary.teamVideoGoal || "0")),
    'Minimum Training Time': `${changeFormatData(currentTeamStats.teamSummary.teamTimeGoal || "0")}m`,
  };

  /** We need to get current value of selector and change second title in dependence of it */
  return (
    <section className={styles.team_stats}>
      <ul className={styles.selector_container}>
        <Selector
          selectorList={SelectorForTeam}
          activeSelector={activeSelector}
          setActiveSelector={setActiveSelector}
        />
      </ul>
      <table>
        <tbody className={styles.table_body}>
          <TeamStatsRow stats={teamStats} />
        </tbody>
      </table>
      {activeSelector !== 'All Time' && (
        <>
          <h3 className={styles.team_stats__title}>{activeSelector} Team Goals</h3>
          <table>
            <tbody>
              <TeamStatsRow stats={teamGoalStats} />
            </tbody>
          </table>
        </>
      )}
      {!!currentTeamStats.players.length && (
        <>
          {activeSelector !== 'All Time' &&
            <>
              <h3 className={styles.team_stats__title}>{activeSelector} Player Stats</h3>
              <table className={styles.player_stats}>
                <thead>
                  <tr className={styles.player_stats__header}>
                    {width >= MOBILE_DIMENSION ?
                      headers.map(header => (
                        <th scope="col" className={styles.player_stats__header__item} key={header}>
                          {header}
                        </th>
                      ))
                      :
                      mobileHeaders.map(header => (
                        <th scope="col" className={styles.player_stats__header__item} key={header}>
                          {header}
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody className={styles.player_stats__main}>
                  <PlayerStatsRow
                    playerStats={goalStats.playerStats}
                    numberWithCommas={numberWithCommas}
                  />
                </tbody>
              </table>
            </>}
        </>
      )}
    </section>
  );
}
