import React, { useMemo, useState } from 'react';
import styles from './Table.module.sass';
import RedCross from '../../../../../asset/videoSeries/RedCross.webp';
import PlayersGoal from '../../../../../asset/teams/roster/invitations/Players_goal.webp';
import { timeConvert } from '../../../../../utils/utils.js';
import { Avatars } from '../../../../../constants/const';
import { ProfileMenu } from '../../Roster/RosterTeamSummary/TeamSummary/ProfileMenu/ProfileMenu';
import { showErrorMessage } from '../../../../../utils/throwError.js';

export const playersHeaders = [
  { label: 'No.', accessor: 'index', sortable: false },
  { label: 'Name', accessor: 'childFirstName', sortable: false },
  { label: 'Videos', accessor: 'countVideo', sortable: true },
  { label: 'Time', accessor: 'timeVideo', sortable: true },
  { label: 'Goal', accessor: 'playerGoal', sortable: true },
  { label: 'Status', accessor: 'complete', sortable: true },
  { label: '', accessor: 'actions', sortable: false }
];

function ContestTable({ playerData, teamId }) {
  const [sortConfig, setSortConfig] = useState({ key: 'timeVideo', direction: 'descending' });
  const [thisItemOpen, setThisItemOpen] = useState('');

  const sortAscDesc = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  
  const handleActionMenuButtonClick = () => {
    showErrorMessage('Coming Soon... :)');
  };

  const sortedPlayers = useMemo(() => {
    const sortablePlayers = [...playerData]; // Create a copy of the player data
    if (sortConfig !== null) {
      sortablePlayers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlayers;
  }, [playerData, sortConfig]);
  

  return (
    <div style={{
      padding: '1rem'
    }}>
      <h2 className={styles.title}>Leaderboard</h2>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {playersHeaders.map((header) => (
              <th
                scope="col"
                className={styles.player_data__header__item}
                key={header.accessor}
                onClick={() => header.sortable && sortAscDesc(header.accessor)}
              >
                <div style={{
                  display: 'flex', alignItems: 'center', cursor: header.sortable ? 'pointer' : 'default'
                }}>
                  <span>{header.label}</span>
                  {sortConfig.key === header.accessor ? (
                    <span style={{
                      fontSize: '.75rem',
                      marginLeft: '.3rem',
                      marginTop: '5px'
                    }}>
                      {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                    </span>
                  ) : (
                    <>{header.sortable && <span style={{
                        fontSize: '.75rem',
                        marginLeft: '.3rem',
                        marginTop: '5px'
                      }}>
                        ▼
                      </span>}
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          {sortedPlayers.map((data, index) => {
            const isMenuOpen = thisItemOpen === String(data.childId);
            const classSelector = data.isManager ? styles.cell_player_manager : styles.cell_player_name;
            const {
              childFirstName,
              childId,
              childLastName,
              countVideo,
              image,
              picture,
              playerStatusGoal,
              playerGoal,
              timeVideo,
            } = data;
            const fullName = childFirstName.concat(childLastName ? `${' ' + childLastName}` : ' ');
            return (
              <tr className={styles.row} key={childId}>
                <th scope="row" className={styles.cell_first}>
                  {index + 1}
                </th>
                <td className={classSelector}>
                  <img
                    src={image || Avatars[parseInt(picture) - 1]}
                    className={styles.cell_logo}
                    alt=""
                  />
                  <span>{fullName}</span>
                </td>
                <td className={styles.cell}>{countVideo}</td>
                <td className={styles.cell}>{timeVideo ? timeConvert(timeVideo) : '0m'}</td>
                <td className={styles.cell}>{Math.round(playerGoal)}</td>
                <td className={styles.cell}>
                  <img className={styles.icon} src={playerStatusGoal ? PlayersGoal : RedCross} alt="" />
                </td>
                <td className={styles.cell}>
                  <ProfileMenu
                    managerData={data}
                    teamId={teamId}
                    isMenuOpen={isMenuOpen}
                    thisItemOpen={thisItemOpen}
                    setThisItemOpen={setThisItemOpen}
                    picture={picture}
                    handleActionMenuButtonClick={handleActionMenuButtonClick}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

ContestTable.defaultProps = {
  playerData: []
}

export default ContestTable