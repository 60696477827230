import React from 'react';
import styles from './InvitationButtons.module.sass';
import {
  INVITE_MEMBERS,
  PENDING_INVITATIONS,
  BACK_TO_SUMMARY,
  InviteStatus,
} from '../../../../../constants/teams.js';
import Plus from '../../../../../asset/teams/roster/invitations/Rectangle_plus.webp';

export function InvitationButtons({
  pendingInvitations,
  handlePendingInvitationButtonClick,
  setInviteStatus,
}) {
  return (
    <div className={styles.invitation_buttons}>
      <button
        onClick={handlePendingInvitationButtonClick}
        className={styles.summary_invitations_toggler}
      >
        {pendingInvitations ? BACK_TO_SUMMARY : PENDING_INVITATIONS}
      </button>
      <button
        className={styles.invite_members_button}
        onClick={() => setInviteStatus(InviteStatus.SEND_INVITE)}
      >
        <img className={styles.invite_members_button__image} src={Plus} alt="" />
        {INVITE_MEMBERS}
      </button>
    </div>
  );
}
