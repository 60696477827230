const SET_VIMEO_FOLDER_DATA = './childDashInfoReducer/SET-VIMEO-FOLDER-DATA';
const SET_VIDEO_DATA = './childDashInfoReducer/SET-VIDEO-DATA';
const SET_URI = './childDashInfoReducer/SET-URI';
const SET_VIEWED = './childDashInfoReducer/SET-VIEWED';
const SET_TRAINING_GOAL = './childDashInfoReducer/SET_TRAINING_GOAL';
const SET_TRAINING_PLAN = './childDashInfoReducer/SET_TRAINING_PLAN';
const SET_USER_AWARDS = './childDashInfoReducer/SET_USER_AWARDS';

const initialState = {
  vimeoFolderData: [],
  vimeoVideoData: {},
  currentUri: '',
  hasTrainingGoal: false,
  trainingPlan: {
    isTrainingPlanLoaded: false,
    name: '',
    videoGoal: 0,
    videoCompleted: 0,
    startDate: '',
    targetDate: '',
    reward: '',
  },
  userAwards: {
    isUserAwardsLoaded: false,
    data: {},
  },
};

const childDashVimeoInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIMEO_FOLDER_DATA:
      return {
        ...state,
        vimeoFolderData: action.vimeoFolderData,
      };
    case SET_VIDEO_DATA:
      return {
        ...state,
        vimeoVideoData: action.vimeoVideoData,
      };
    case SET_URI:
      return {
        ...state,
        currentUri: action.uri,
      };
    case SET_TRAINING_GOAL:
      return {
        ...state,
        hasTrainingGoal: action.hasTrainingGoal,
      };
    case SET_TRAINING_PLAN:
      return {
        ...state,
        trainingPlan: { isTrainingPlanLoaded: true, ...action.trainingPlan },
      };
    case SET_USER_AWARDS:
      return {
        ...state,
        userAwards: { isUserAwardsLoaded: true, ...action.data },
      };
    case SET_VIEWED:
      return {
        ...state,
        [action.whereViewed]: {
          ...state[action.whereViewed],
          videoArray: state[action.whereViewed].videoArray.map((a, index) => {
            if (index === action.index) {
              return { ...a, viewed: true };
            } else {
              return a;
            }
          }),
        },
      };
    default:
      return state;
  }
};

export const setChildDashVimeoInfo = vimeoFolderData => ({
  type: SET_VIMEO_FOLDER_DATA,
  vimeoFolderData,
});
export const setVideo = vimeoVideoData => ({ type: SET_VIDEO_DATA, vimeoVideoData });
export const setUri = uri => ({ type: SET_URI, uri });
export const setViewed = (whereViewed, index) => ({ type: SET_VIEWED, whereViewed, index });
export const setTrainingGoal = hasTrainingGoal => ({ type: SET_TRAINING_GOAL, hasTrainingGoal });
export const setTrainingPlan = trainingPlan => ({ type: SET_TRAINING_PLAN, trainingPlan });
export const setUserAwards = data => ({ type: SET_USER_AWARDS, data });

export default childDashVimeoInfoReducer;
