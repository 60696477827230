import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from '../AccountManage.module.sass';
import { changeEmail } from '../../../../api/api';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

// Here is one Modal window where changes bodies and footers in dependence of toggle from parent component (AccountManage.jsx) (toggle is toggChangeEmailPopUpWindow)
export const ModalWindowChangePass = ({
  formik,
  // changeEmailToggle,
  // setChangeEmailToggle,
  toggChangeEmailPopUpWindow,
  setToggChangeEmailPopUpWindow,
  // update,
}) => {
  const history = useHistory();
  const cookies = new Cookies();

  const changeToggAndRequest = () => {
    setToggChangeEmailPopUpWindow(2);
    try {
      changeEmail(formik.values.email);
    } catch (e) {
      console.log(e);
    }
  };

  const delCookieAndRedirect = () => {
    setToggChangeEmailPopUpWindow(0);
    cookies.set('finaleToken', ``, { path: '/' });
    history.push('/');
    window.location.reload(true);
  };

  const redirect = () => {
    setToggChangeEmailPopUpWindow(0);
    window.location.reload(true);
    history.push('/dashboard/account');
  };

  return (
    <Modal
      isOpen={!!toggChangeEmailPopUpWindow}
      modalClassName={styles.modal}
      toggle={() => false} // закрытие черного фона toggle как onClick нажимаешь на него , переключаешь toggle в false и отправляешь его в isOpen
      style={{ width: '30rem', margin: '0 auto', marginTop: '10rem', maxWidth: '35%' }}
    >
      {toggChangeEmailPopUpWindow === 1 ? (
        <>
          <ModalHeader style={{ display: 'block' }}>
            <div
              style={{
                marginLeft: '28%',
                display: 'block',
                fontSize: '1.4583rem',
                fontWeight: '400',
                color: '#003056',
              }}
            >
              Change Your Email
            </div>
          </ModalHeader>

          <form className={styles.modal}>
            <ModalBody>
              <div className={styles.row}>
                <div className={styles.col}>
                  <label htmlFor="email" style={{ color: '#003056' }}>
                    Enter your new email
                  </label>
                  <input
                    style={{ color: '#78a3be' }}
                    className={
                      formik.touched.email && formik.errors.email
                        ? styles[formik.errors.email]
                        : styles.emailInput
                    }
                    type="text"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email.replace(/[^A-z@._0-9-]/g, '')}
                  />
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                style={{
                  width: '9rem',
                  height: '3rem',
                  borderRadius: '2rem',
                  color: '#fff',
                  backgroundColor: '#007bff',
                  borderColor: '#007bff',
                }}
                onClick={() => changeToggAndRequest()}
              >
                <p>Submit</p>
              </Button>
              <Button
                style={{
                  width: '9rem',
                  height: '3rem',
                  borderRadius: '2rem',
                  color: '#fff',
                  backgroundColor: '#6c757d',
                  borderColor: '#6c757d',
                }}
                onClick={redirect}
              >
                <p>Cancel</p>
              </Button>
            </ModalFooter>
          </form>
        </>
      ) : toggChangeEmailPopUpWindow === 2 ? (
        <>
          <ModalHeader style={{ display: 'block' }}>
            <div
              style={{
                marginLeft: '28%',
                display: 'block',
                fontSize: '1.4583rem',
                fontWeight: '400',
                color: '#003056',
              }}
            >
              Change Your Email
            </div>
          </ModalHeader>

          <form className={styles.modal}>
            <ModalBody style={{ width: '24rem', margin: '0 auto' }}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <label htmlFor="email" style={{ color: '#003056' }}>
                    Confirmation has been sent to your new mail.
                    <br />
                    Please check your email and follow the link for confirmation.
                  </label>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  width: '9rem',
                  height: '3rem',
                  borderRadius: '2rem',
                  color: '#fff',
                  backgroundColor: '#007bff',
                  borderColor: '#007bff',
                }}
                onClick={() => delCookieAndRedirect()}
              >
                <p>Ok</p>
              </Button>
            </ModalFooter>
          </form>
        </>
      ) : (
        ''
      )}
    </Modal>
  );
};
