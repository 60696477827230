/** Directory is for all server requests for teams */
import mainRoute from '../mainRoute';
import Cookies from 'universal-cookie';
import path from '../config.json';
import { CHILD_TOKEN } from '../constants/const.js';

const cookies = new Cookies();

/** response: { "teamId" : "1",
 * "name" : "name",
 * "managerName" : "firstname lastname",
 * “imgUrl":"link"} */
export const getActiveTeamList = async (requestedPage = 1) => {
  return await fetch(`${mainRoute.route}${path.activeTeams}?page=${requestedPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getUserActiveTeamList = async () => {
  return await fetch(`${mainRoute.route}${path.userActiveTeams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

/** response: { "teamId" : "1",
 * "name" : "name",
 * "managerName" : "firstname lastname",
 * “imgUrl":"link"} */
export const getPendingTeamList = async (requestedPage = 1) => {
  return await fetch(`${mainRoute.route}${path.pendingTeams}?page=${requestedPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const acceptTeamInvite = async (teamId) => {
  return await fetch(`${mainRoute.route}${path.acceptTeamInvite}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ teamId }),
  });
};

export const declineTeamInvite = async (teamId) => {
  return await fetch(`${mainRoute.route}${path.declineTeamInvite}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ teamId }),
  });
};

export const joinTeam = async (teamId) => {
  return await fetch(`${mainRoute.route}${path.joinTeam}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ teamId }),
  });
};
/** in case of Error joinTeam request - 500 message "Request doesn't created"
 */

export const checkIsTeamNameUnique = async (teamName) => {
  return await fetch(`${mainRoute.route}${path.checkTeamName}?name=${teamName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const searchTeams = async (requestedPage = 1, searchRequestString = '') => {
  return await fetch(
    `${mainRoute.route}${path.teamSearch}?page=${requestedPage}&searchName=${searchRequestString}
    `,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: cookies.get(CHILD_TOKEN),
      },
    },
  );
};

export const createTeam = async (data) => {
  return await fetch(`${mainRoute.route}${path.team}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const deleteTeam = async (teamId) => {
  return await fetch(`${mainRoute.route}${path.team}/${teamId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getPendingMembers = async (teamID) => {
  return await fetch(`${mainRoute.route}${path.pendingMembers}/${teamID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getSentRequests = async (teamID) => {
  return await fetch(`${mainRoute.route}${path.sentRequests}/${teamID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const removeRequest = async (data) => {
  return await fetch(`${mainRoute.route}${path.inviteMembers}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const inviteMembers = async (data) => {
  return await fetch(`${mainRoute.route}${path.inviteMembers}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const acceptPendingMember = async (data) => {
  return await fetch(`${mainRoute.route}${path.acceptPendingMember}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const sendReminder = async (data) => {
  return await fetch(`${mainRoute.route}${path.sendReminder}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const removeSentRequest = async (data) => {
  return await fetch(`${mainRoute.route}${path.removeSentRequest}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

// {
//     "id": 7,
//     "name": "test comand",
//     "imgUrl": "https://anytime-soccer-logo-team.s3.amazonaws.com/photo_2022-07-07_13-42-46.jpg"
// }

export const getLogoTeam = async (teamId) => {
  return await fetch(`${mainRoute.route}${path.team}/${teamId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getTeamStats = async (teamID, period = 'weekly') => {
  const periodToLowCase = period.toLowerCase();
  return await fetch(`${mainRoute.route}${path.teamStats}${teamID}?period=${periodToLowCase}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getTeamGoalStats = async (teamID, period = 'weekly') => {
  const periodToLowCase = period.toLowerCase();
  return await fetch(`${mainRoute.route}${path.teamDashboard}${teamID}?period=${periodToLowCase}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getTeamGoals = async (teamID) => {
  return await fetch(`${mainRoute.route}${path.team}/${teamID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const updateTeamGoals = async (data) => {
  return await fetch(`${mainRoute.route}${path.team}`, {
    method: 'PUT',
    headers: {
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: data,
  });
};

// GET team/subscription/price
// token
// res {
//     "team15playersPrice": "84.98",
//     "team20playersPrice": "110.98",
//     "team25playersPrice": "124.98",
//     "team35playersPrice": "159.98"
// }
export const getTeamPrices = async () => {
  return await fetch(`${mainRoute.route}${path.teamPrices}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

// token + {teamId, maxTeamSize}
// res {"isUpgrading": true }
// maxTeamSize это кол-во человек в тарифе, который выбрали
export const addTeamSubscription = async (data) => {
  return await fetch(`${mainRoute.route}${path.teamSubscription}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const getProfileData = async (data) => {
  return await fetch(`${mainRoute.route}${path.getProfileData}/${data.childId}/${data.teamId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getAllMembers = async (data) => {
  return await fetch(`${mainRoute.route}${path.getAllMembers}/${data.teamId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getCountManager = async (data) => {
  return await fetch(`${mainRoute.route}${path.getCountManager}/${data.teamId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const updateProfileName = async (data) => {
  return await fetch(`${mainRoute.route}${path.getProfileData}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const removeProfileRequest = async (data) => {
  return await fetch(`${mainRoute.route}${path.getProfileData}/${data.childId}/${data.teamId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const sendMessageProfile = async (data) => {
  return await fetch(`${mainRoute.route}${path.sendMessage}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const updateRoleProfile = async (data) => {
  return await fetch(`${mainRoute.route}${path.updateRole}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const getSubscriptionPrice = async () => {
  return await fetch(`${mainRoute.route}${path.subscriptionPrice}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};
