import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Dashboard.module.sass';
import path from '../../../../config/routes.json';
import { Loading } from '../../../commonComponents/Loading/Loading';
import { TeamStats } from '../TeamStats/TeamStats';
import { Roster } from '../Roster/Roster';
import {
  COACH_CHALLENGES,
  Navigation,
  NavigationForPlayers,
  NavigationUrl,
  SelectorForTeam,
  UPGRADE_PLAYERS,
  backendAllTime,
} from '../../../../constants/teams';
import { ManagementTeam } from '../ManagementTeam/ManagementTeam';
import { UpgradeTeam } from '../UpgradeTeam/UpgradeTeam';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getLogoTeam, getTeamStats, getTeamGoalStats } from '../../../../api/teams-api.js';
import {
  setCurrentTeamStats,
  setCurrentTeam,
  setGoalStats,
} from '../../../../Redux/teamReducer.js';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';
import { grayArrowUp, grayArrowDown } from '../../../../constants/assets';
import { KebabMenu } from '../../../commonComponents/KebabMenu/KebabMenu';
import { showErrorMessage } from '../../../../utils/throwError';
import Homework from '../Homework';
import Contest from '../Contest';
import CoachChallenge from '../CoachChallenge/index.jsx';

export function Dashboard() {
  const [upLoadedData, setUpLoadedData] = useState(true);
  const [screen, setScreen] = useState(Navigation.DASHBOARD);
  const [activeSelector, setActiveSelector] = useState(SelectorForTeam.WEEKLY);
  const dispatch = useDispatch();
  const currentTeam = useSelector((state) => state.teamReducer.currentTeam);
  const currentTeamId = useSelector((state) => state.teamReducer.currentTeamId);
  const currentTeamStats = useSelector((state) => state.teamReducer.currentTeamStats);
  const goalStats = useSelector((state) => state.teamReducer.goalStats);
  const playerID = useSelector((state) => state.playerDataReducer.playerData.id);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);
  const { width } = useWindowDimensions();
  const [isGoalsUpdated, setIsGoalUpdated] = useState(false);

  useScrollToTop();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nav = searchParams.get('nav');

  useEffect(() => {
    if (location.pathname === path.teams.roster) {
      setScreen(Navigation.ROSTER);
    }

    if (location.pathname === path.teams.homework) {
      setScreen(Navigation.HOMEWORK);
    }

    if (location.pathname === path.teams.contest) {
      setScreen(Navigation.CONTEST);
    }
    getLogoTeam(currentTeamId).then((response) => {
      responseLogoHandler(response);
    });

    if (nav === 'challenge' || location.pathname === path.teams.challenge) {
      setScreen(COACH_CHALLENGES);
    }
  }, [isGoalsUpdated, nav]);

  useEffect(() => {
    let selector = activeSelector;
    if (activeSelector === SelectorForTeam.ALL_TIME) selector = backendAllTime;
    if (selector) {
      getTeamStats(currentTeamId, selector).then((response) => {
        responseStatsHandler(response);
      });
      getTeamGoalStats(currentTeamId, selector).then((response) => {
        responseGoalStatsHandler(response);
      });
    }
  }, [isGoalsUpdated, activeSelector]);

  const responseLogoHandler = (response) => {
    response.json().then((data) => {
      dispatch(setCurrentTeam(data));
    });
  };
  const responseStatsHandler = (response) => {
    response.json().then((stats) => {
      dispatch(setCurrentTeamStats(stats));
    });
  };

  const responseGoalStatsHandler = (response) => {
    response.json().then((stats) => {
      dispatch(setGoalStats(stats));
      setUpLoadedData(true);
    });
  };

  const setDefaultScreen = () => {
    setScreen(Navigation.DASHBOARD);
  };

  const handleChange = (event) => {
    if (event.target.value === Navigation.GOALS && !isManager) {
      showErrorMessage('This is only for managers.');
    } else {
      setScreen(event.target.value);
    }
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
    if (isKebabMenuOpen) {
      setIsKebabMenuOpen(false);
    }
  };

  const handleMobileSelectorClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const checkAccess = () =>
    currentTeamStats.managers.some((element) => element.childId === playerID);
  const isPlayerManager = currentTeamStats.players.some(
    (element) => element.childId === playerID && element.role === 'player_manager',
  );
  const isManager = checkAccess();

  return (
    <>
      {upLoadedData ? (
        <section className={styles.dashboard}>
          <h2 className={styles.dashboard__title}>{currentTeam?.name}</h2>
          <ul className={styles.links}>
            <li className={styles.links__item}>
              <Link to={path.dashboardChild.teams}>BACK TO HOME</Link>
            </li>
            {width >= MOBILE_DIMENSION ? (
              <>
                <li className={styles.links__item}>
                  <input
                    id={UPGRADE_PLAYERS}
                    type="radio"
                    onChange={handleChange}
                    name="navigation"
                    value={UPGRADE_PLAYERS}
                    className={styles.nav__item__input}
                    defaultChecked={screen === UPGRADE_PLAYERS}
                  />
                  <label
                    htmlFor={UPGRADE_PLAYERS}
                    tabIndex="0"
                    className={styles.links__item_button}
                  >
                    {UPGRADE_PLAYERS}
                  </label>
                </li>
                <li className={styles.links__item}>
                  <input
                    id={COACH_CHALLENGES}
                    type="radio"
                    onChange={handleChange}
                    name="navigation"
                    value={COACH_CHALLENGES}
                    className={styles.nav__item__input}
                    defaultChecked={screen === COACH_CHALLENGES}
                  />
                  <label
                    htmlFor={COACH_CHALLENGES}
                    tabIndex="0"
                    className={styles.links__item_button}
                  >
                    {COACH_CHALLENGES}
                  </label>
                </li>
              </>
            ) : (
              <KebabMenu isKebabMenuOpen={isKebabMenuOpen} setIsKebabMenuOpen={setIsKebabMenuOpen}>
                <li className={styles.upgrade_mobile_menu}>
                  <input
                    id={UPGRADE_PLAYERS}
                    type="radio"
                    onChange={handleChange}
                    name="navigation"
                    value={UPGRADE_PLAYERS}
                    className={styles.nav__item__input}
                    defaultChecked={screen === UPGRADE_PLAYERS}
                  />
                  <label
                    htmlFor={UPGRADE_PLAYERS}
                    tabIndex="0"
                    className={styles.upgrade_mobile_menu__label}
                  >
                    {UPGRADE_PLAYERS}
                  </label>
                </li>
              </KebabMenu>
            )}
          </ul>
          {width >= MOBILE_DIMENSION ? (
            <ul className={styles.nav}>
              {Object.values(isManager ? Navigation : NavigationForPlayers)
                .filter((item) => item !== "Coach's Challenges")
                .map((navItem) => {
                  const isChecked = screen === navItem;
                  return (
                    <li key={navItem} className={styles.nav__item}>
                      <input
                        id={navItem}
                        type="radio"
                        name="navigation"
                        value={navItem}
                        onChange={handleChange}
                        className={styles.nav__item__input}
                        checked={isChecked}
                      />
                      <label htmlFor={navItem} tabIndex="0" className={styles.nav__item__label}>
                        <img
                          src={isChecked ? NavigationUrl[navItem][1] : NavigationUrl[navItem][0]}
                          alt=""
                          className={styles.nav__item__image}
                        />
                        {navItem}
                      </label>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <button
              className={styles.mobile_selector}
              type="button"
              onClick={handleMobileSelectorClick}
            >
              <span>
                {NavigationUrl?.[screen]?.[1] && (
                  <img
                    src={NavigationUrl?.[screen]?.[1]}
                    className={styles.nav__item__image}
                    alt=""
                  />
                )}
                {screen}
              </span>
              <img
                src={isMobileMenuOpen ? grayArrowUp : grayArrowDown}
                alt=""
                aria-hidden="true"
                className={styles.mobile_selector__image}
              />
            </button>
          )}
          {isMobileMenuOpen && (
            <div className={styles.mobile_menu_container}>
              <ul className={styles.mobile_menu}>
                {Object.values(Navigation).map((navItem) => {
                  const isChecked = screen === navItem;
                  return (
                    <li key={navItem} className={styles.nav__item}>
                      <input
                        id={navItem}
                        type="radio"
                        name="navigation"
                        value={navItem}
                        onChange={handleChange}
                        className={styles.nav__item__input}
                        checked={isChecked}
                      />
                      <label htmlFor={navItem} tabIndex="0" className={styles.nav__item__label}>
                        <img
                          src={isChecked ? NavigationUrl[navItem][1] : NavigationUrl[navItem][0]}
                          className={styles.nav__item__image}
                          alt=""
                        />
                        {navItem}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={styles.flex_container}>
            <div className={styles.container}>
              {screen === Navigation.DASHBOARD && (
                <TeamStats
                  goalStats={goalStats}
                  currentTeamStats={currentTeamStats}
                  activeSelector={activeSelector}
                  setActiveSelector={setActiveSelector}
                />
              )}
              {screen === Navigation.ROSTER && (
                <Roster
                  activeSelector={activeSelector}
                  setActiveSelector={setActiveSelector}
                  currentTeamId={currentTeamId}
                  currentTeamStats={currentTeamStats}
                  isManager={isManager}
                  isPlayerManager={isPlayerManager}
                  currentTeam={currentTeam}
                />
              )}
              {screen === Navigation.GOALS && (
                <ManagementTeam
                  setIsGoalUpdated={setIsGoalUpdated}
                  setDefaultScreen={setDefaultScreen}
                  currentTeamId={currentTeamId}
                />
              )}
              {screen === Navigation.HOMEWORK && <Homework currentTeam={currentTeam} />}
              {screen === Navigation.CONTEST && (
                <Contest
                  currentTeam={currentTeam}
                  isManager={isManager}
                  isPlayerManager={isPlayerManager}
                />
              )}
              {screen === UPGRADE_PLAYERS && <UpgradeTeam currentTeamId={currentTeamId} />}
              {screen === COACH_CHALLENGES && (
                <CoachChallenge
                  currentTeam={currentTeam}
                  isManager={isManager}
                  isPlayerManager={isPlayerManager}
                />
              )}
            </div>
            {width >= MOBILE_DIMENSION && (
              <article className={styles.logo}>
                <img className={styles.logo__picture} src="/img/teams/Ribbon.webp" alt="" />
                <img className={styles.logo__shield} src="/img/teams/top_shield.webp" alt="" />
                <div className={styles.logo__container}>
                  <img className={styles.logo__team} src={currentTeam?.imgUrl} alt="" />
                </div>
                <p className={styles.logo__name}>{currentTeam?.name}</p>
              </article>
            )}
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
}
