import React, { useState } from 'react';
import styles from './ConfirmPlayer.module.sass';
import exclamationMark from '../../../asset/dashboard/exclamationMark.webp';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { checkUniqueValues, profileInfoRequest, submitPlayerData } from '../../../api/api';
import {
  fourChildRedirect,
  haveNoDataRedirect,
  withRoleRedirectComp,
  haveNoLoginRedirect,
} from '../../../hoc/redirectComp';
import { compose } from 'redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function ConfirmPlayer(props) {
  const [toggleButton, setToggleButton] = useState(0);
  const [checkToggle, setCheckToggle] = useState('black');
  const [stopSubmitBtn, setStopSubmitBtn] = useState(false);
  const [toggSubmitBtnSpinner, setToggSubmitBtnSpinner] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 30, color: 'red', marginLeft: '5rem' }} spin />
  );

  const history = useHistory();
  const currentChild = `child` + (props.userData.childInfo.childrenCount + 1);

  // add 1 to toggle when put tick
  const checkBoxHandler = () => {
    if (toggleButton) {
      setToggleButton(0);
    } else {
      setToggleButton(1);
      setCheckToggle('green');
    }
  };

  // 1) green / red box tick
  const checkUniqueUserName = () => {
    if (toggleButton) {
      setStopSubmitBtn(true);
      setCheckToggle('green');
      setToggSubmitBtnSpinner(true);
      try {
        checkUniqueValues({ [currentChild]: props.playerData.username }).then(
          (
            response, // проверка username на уникальность
          ) => uniqueUserRequestHandler(response),
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      setCheckToggle('red');
    }
  };

  //2 step если имя уникально добавляет +1 в toggleButton
  const uniqueUserRequestHandler = (response) => {
    if (response.status === 200) {
      setToggleButton(toggleButton + 1);
      serverPostRequest();
    }
  };

  //4 step
  const serverPostRequest = () => {
    try {
      submitPlayerData(currentChild, props.playerData).then((response) =>
        addPlayerRequestHandler(response),
      );
    } catch (e) {
      console.log(e);
    }
  };
  // 5 step
  const addPlayerRequestHandler = (response) => {
    response.json().then((result) => {
      if (result.statusAdd === 'true') {
        try {
          profileInfoRequest().then((response) => requestHandler(response));
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  // 6 step
  const requestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        props.setUserData(result);
        props.setDeletePlayer();
        history.replace('/dashboard');
      } else {
        setToggSubmitBtnSpinner(false);
        setStopSubmitBtn(false);
      }
    });
  };

  // ===============================================================================================
  const displChildrenUserScreenNames = () => {
    const res = [];
    for (let i = 1; i <= props.userData.childInfo.childrenCount; i++) {
      res.push(
        <div className={styles.namesCont1} key={i}>
          <p className={styles.names}> {props.userData.childInfo['child' + i].username} </p>
          <p className={styles.namesInfo}>( {props.userData.childInfo['child' + i].screenname} )</p>
        </div>,
      );
    }
    return res;
  };

  return (
    <main>
      <div className={styles.upContainer}>
        <div className={styles.upLeftContainer}>
          <h4 className={styles.title}>Confirm Your Subscription</h4>
          <h5 className={styles.subTitle}>Players Activated</h5>

          {displChildrenUserScreenNames()}

          <NavLink className={styles.edit} to="/dashboard/createPlayer">
            Edit or add more players
          </NavLink>
        </div>

        <div className={styles.upRightContainer}>
          <div className={styles.card}>
            <div>
              <h4>Pricing</h4>
              <h5>Annual Subscription</h5>
              <span className={styles.spanUpRightContainer}>
                <span className={styles.upRightContainer}>
                  <span>First Player</span>
                  <span>
                    $49.98
                    <br />
                  </span>
                </span>
                <span className={styles.upRightContainer}>
                  <span>Additional Player</span>
                  <span>$14.98</span>
                </span>
              </span>
              <hr className={styles.upRightContainerHr} />
              <span className={styles.upRightContainerLast}>Prices are in U.S. dollars.</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.downLeftContainer}>
        <h5 className={styles.subTitle}>Players Activated</h5>
        <p className={styles.namesInfo1}>
          Your subscription plan will reflect the additional purchase
          <br />
          and will renew automatically until you cancel it.
          <br />
          All amounts in US dollars.
        </p>

        <div className={styles.checkBoxContainer}>
          <input
            type="checkbox"
            className={styles.checkBox}
            value={toggleButton}
            onClick={checkBoxHandler}
          />
          <span style={{ color: checkToggle }} className={styles.checkBox__label}>
            I agree to the new subscription plan.
          </span>
        </div>
        <div className={styles.displayFlex}>
          <span>
            <img src={exclamationMark} alt="exclamation mark" className={styles.img} />
          </span>
          <p className={styles.namesInfo}>
            <span className={styles.edit}>Important:</span>
            only click submit once to avoid duplicate charges.
          </p>
        </div>

        <div className={styles.submitBtnContainer}>
          <NavLink to="/dashboard" style={{ textDecoration: 'none' }} className={styles.redText}>
            No, thanks.
          </NavLink>
          <div className={styles.payBtn}>
            {toggSubmitBtnSpinner ? (
              <Spin indicator={antIcon} className={styles.spinnerSubmitBtn} />
            ) : (
              <button
                onClick={checkUniqueUserName}
                className={styles.payBtn__submite}
                disabled={stopSubmitBtn}
                style={{ outline: 'none' }}
              >
                Submit
              </button>
            )}
          </div>
        </div>

        <div>
          <div className={styles.divTotal}>
            <span>Total:</span>
            <span className={styles.spanTotal}>
              {'$' + props.addPlayerReducer.playerTotalPrice / 100}
            </span>
          </div>
        </div>
      </div>
    </main>
  );
}

// haveNoDataRedirect (if user update app we lose data from createPlayer, so we redirect him  back to this step)
// if user have more than 4 child we redirect him to dashboard
export default compose(
  haveNoDataRedirect,
  fourChildRedirect,
  withRoleRedirectComp,
  haveNoLoginRedirect,
)(ConfirmPlayer);
