import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProfileInformation.module.sass';
import ModalWindowButton from '../ModalWindowButton/ModalWindowButton';
import { Avatars } from '../../../constants/const';
import { PopupStatusProfilePage } from '../constants';
import path from '../../../config/routes.json';

const ProfileInformation = ({
  profileData,
  checkYourProfile,
  playerStatus,
  setPopupStatusProfilePage,
  teamId,
  userId,
}) => {
  return (
    <section className={styles.profileInformationWrapper}>
      <div className={styles.profileInformationWrapper_header}>
        <hr className={styles.profileInformationWrapper_header_lineMobile} />
        <div>
          <p
            className={styles.profileInformationWrapper_header_title}
          >{`${profileData.firstName} ${profileData.lastName}`}</p>
        </div>
        <hr className={styles.profileInformationWrapper_header_line} />
      </div>
      <div className={styles.profileInformationMainSection}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center',
        }}>
          <div className={styles.profileInformationMainSection_personPhoto}>
            <img
              src={profileData.image || Avatars[parseInt(profileData.picture) - 1]}
              className={styles.profileInformationMainSection_personPhoto_logo}
              alt=""
            />
          </div>
          <div
            className={styles.profileInformationMainSection_sendMessageButton}
            onClick={() => setPopupStatusProfilePage(PopupStatusProfilePage.SEND_MESSAGE)}
          >
            Send Message
          </div>
          <a href={`mailto:${profileData.email}`} target="_blank" rel="noopener noreferrer" className={styles.profileInformationWrapper_header_email}>
            {profileData.email}
          </a>
        </div>
        <div className={styles.profileInformationMainSection_playerStatsInfo}>
          <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_stats}>
              <p>Stats</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <div className={styles.profileInformationMainSection_playerReportButton}>
                <Link
                  to={path.dashboardChild.teammateReports + `/${userId}/${profileData.firstName}`}
                >
                  Player Report
                </Link>
              </div>
            </div>
          </div>
          {/* <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_goalTitle}>
              <p>Set Individual Training Goal</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <div className={styles.profileInformationMainSection_playerGoalButton}>
                <Link to={path.teammateTrainingGoal + '/' + userId} params={{ userId: userId }}>
                  Goal
                </Link>
              </div>
            </div>
          </div> */}
          <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_name}>
              <p>Annual Videos</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <p>{profileData.annualVideo}</p>
            </div>
          </div>
          <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_name}>
              <p>Annual Training Time</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <p>{profileData.annualTime}</p>
            </div>
          </div>
          <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_name}>
              <p>All Time Videos</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <p>{profileData.alltimeVideo}</p>
            </div>
          </div>
          <div className={styles.profileInformationMainSection_playerStatsInfo_info}>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_name}>
              <p>All Time Training Time</p>
            </div>
            <div className={styles.profileInformationMainSection_playerStatsInfo_info_amount}>
              <p>{profileData.alltimeTime}</p>
            </div>
          </div>
        </div>
      </div>
      <ModalWindowButton
        profileData={profileData}
        checkYourProfile={checkYourProfile}
        playerStatus={playerStatus}
        setPopupStatusProfilePage={setPopupStatusProfilePage}
        teamId={teamId}
      />
    </section>
  );
};

export default ProfileInformation;
