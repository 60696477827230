import React from 'react';
import styles from '../../Steps.module.sass';

export default function PlayerForm({ formik, playerNumber, errorArray, confirmPass, touched }) {
  const firstname = `firstname${playerNumber}`;
  const screenname = `screenname${playerNumber}`;
  const username = `username${playerNumber}`;
  const password = `password${playerNumber}`;
  const passwordConfirm = `passwordConfirm${playerNumber}`;

  return (
    <div className={styles.section}>
      <h4>Player#{playerNumber + 1}</h4>
      <div className={styles.col}>
        <label htmlFor={firstname}>First Name *</label>
        <input
          className={
            formik.touched[firstname] && formik.errors[firstname]
              ? styles[formik.errors[firstname]]
              : ''
          }
          name={firstname}
          id={firstname}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[firstname].replace(/[^A-z\s]/g, '')}
        />
      </div>
      <div className={styles.col}>
        <label htmlFor={screenname}>Screen Name *</label>
        <input
          className={
            formik.touched[screenname] && formik.errors[screenname]
              ? styles[formik.errors[screenname]]
              : ''
          }
          name={screenname}
          id={screenname}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[screenname].replace(/[^A-z\s]/g, '')}
        />
      </div>

      <div className={styles.col}>
        <div>
          <label htmlFor={username}>Login User Name *</label>
          {errorArray.includes(playerNumber) ? (
            <span className={styles.invalidEmailAdressMessage}>User name must be unique</span>
          ) : (
            ''
          )}
        </div>

        <input
          className={
            formik.touched[username] && formik.errors[username]
              ? styles[formik.errors[username]]
              : ''
          }
          name={username}
          id={username}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[username].replace(/[^A-z@._0-9-]/g, '')}
        />
      </div>
      <div className={styles.col}>
        <label htmlFor={password}>Password *</label>
        <input
          className={
            formik.touched[password] && formik.errors[password]
              ? styles[formik.errors[password]]
              : ''
          }
          name={password}
          id={password}
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[password].replace(/[\s]/g, '')}
        />
      </div>
      <div className={styles.col}>
        <div>
          <label htmlFor={passwordConfirm}>Confirm Password *</label>
          {touched ? <span>{confirmPass ? 'Password must match' : ''}</span> : ''}
        </div>
        <input
          className={
            formik.touched[passwordConfirm] && formik.errors[passwordConfirm]
              ? styles[formik.errors[passwordConfirm]]
              : ''
          }
          name={passwordConfirm}
          id={passwordConfirm}
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[passwordConfirm].replace(/[\s]/g, '')}
        />
      </div>
    </div>
  );
}
