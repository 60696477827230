import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderEighthMobile.module.sass';
import menuClose from '../../../../asset/home/menuClose.webp';
import { HELP_CENTER_URL } from '../../../../constants/const.js';
import path from '../../../../config/routes.json';
import { Role } from '../../../../constants/const.js';
import { message } from 'antd';

function HeaderEightMobile(props) {
  const closeMenu = () => {
    if (props.setMobileMenu) {
      props.setMobileMenu(!props.mobileMenu);
    }
  };

  const handleDropdownClick = () => {
    if (props.role !== Role.CHILD) {
      message.warning('Only for players :)');
    } else {
      props.setIsOpenDropdown(!props.isOpenDropdown);
    }
  };

  const handleDashboardClick = (event) => {
    if (props.role !== Role.CHILD) {
      event.preventDefault();
      message.warning('Only for players :)');
    } else {
      closeMenu();
    }
  };

  return (
    <>
      <div className={styles.mobile_container}>
        <div className={styles.mobile_links}>
          <img src={menuClose} alt="menuClose" onClick={closeMenu} className={styles.close_image} />
          <ul className={styles.mobile_links_list}>
            <li className={styles.li_action} onClick={handleDropdownClick}>
              Actions
              <img
                alt=" "
                src={
                  props.isOpenDropdown
                    ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAeGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAAqACAAQAAAABAAAAEaADAAQAAAABAAAACgAAAAAlUdAjAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNi4wLjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyIKICAgICAgICAgICAgeG1sbnM6ZXhpZj0iaHR0cDovL25zLmFkb2JlLmNvbS9leGlmLzEuMC8iPgogICAgICAgICA8dGlmZjpZUmVzb2x1dGlvbj43MjwvdGlmZjpZUmVzb2x1dGlvbj4KICAgICAgICAgPHRpZmY6UmVzb2x1dGlvblVuaXQ+MjwvdGlmZjpSZXNvbHV0aW9uVW5pdD4KICAgICAgICAgPHRpZmY6WFJlc29sdXRpb24+NzI8L3RpZmY6WFJlc29sdXRpb24+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xNzwvZXhpZjpQaXhlbFhEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOkNvbG9yU3BhY2U+MTwvZXhpZjpDb2xvclNwYWNlPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+MTA8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KCToxHgAAALxJREFUKBVjYCAA7pjZBtw3cBDAp4wRlyRI4z/2v0eA8tpAfJHpJ7OD4oUDH7CpZ8ImCDXgAFAOZMAXINYHGngAl4swDEEyQJ+BgfECI/NfHRCNzyAUQ9ANYPrJ5Kh07NhDEI3PIHiYYDMAOQwg8v/2MzD8NwC6CiWMwIYQMgCoCQxwGcRIrAH4DGL6y/Z3A1ABOBBBfkf2AkwjMg2SRwkjjn8FDPdNrQzumdvuB7kIWTEhNkj9XQu7BpA6ADx2fbimQwHIAAAAAElFTkSuQmCC'
                    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEaADAAQAAAABAAAACgAAAABqmViRAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNi4wLjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoZXuEHAAAAy0lEQVQoFWNkAIK7FnYNTD+YJiheOPABxCcG3DdwEPjP/nc947+/hYwgAxj+/69nYGC8wPSTyZEYg0AG/GP/t5+B4b/B//8MB5lALgDafBEkAJIAKcDnEmQDQPqYfzEHMII0QCT+HgAy9fG5CN0App/MDiCXgw0hxiBcBoD0wg3BZxA+AzAMwWYQI/OfgP9/WTaAwgwofxHmBZBaGEBxCUwQNYwYvgDFeYAYqwEgPVgNAUmgGXQV6AIbYqIfpBcFgAy6Y2YbgCKIhQMA5tiKtKeR0bwAAAAASUVORK5CYII='
                }
                className={styles.action_button}
              />
              {props.isOpenDropdown && (
                <ul className={styles.nested_list}>
                  <li className={styles.nested_list__item} onClick={closeMenu}>
                    <Link to={path.dashboardChild.reports} className={styles.link}>
                      Reports
                    </Link>
                  </li>
                  {props.accessAllVideos && (
                    <li className={styles.nested_list__item}>
                      <Link to={path.trainingGoal} className={styles.link} onClick={closeMenu}>
                        My Training Goal
                      </Link>
                    </li>
                  )}
                  <li className={styles.nested_list__item} onClick={closeMenu}>
                    <Link to={HELP_CENTER_URL} className={styles.link}>
                      Help
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className={styles.li}>
              <Link
                to={path.dashboardChild.main}
                className={styles.link}
                onClick={handleDashboardClick}
              >
                Dashboard
              </Link>
            </li>
            <li className={styles.li} onClick={props.logout}>
              <a href={props.redirect} className={styles.link}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default HeaderEightMobile;
