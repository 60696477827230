import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import appReducer from './appReducer';
import thunkMiddleware from 'redux-thunk';
import authReducer from './authReducer';
import userDataReducer from './userDataReducer';
import signUpDataReducer from './signUpDataReducer';
import playerDataReducer from './playerDataReducer';
import dashboardChildReducer from './dashboardChildReducer';
import childDashVimeoInfoReducer from './childDashVimeoInfoReducer';
import addPlayerReducer from './addPlayerReducer';
import billingDetailsReducer from './billingDetailsReducer';
import mainFoldersDashboardChildReducer from './mainFoldersDashboardChildReducer';
import playerJourneyReducer from './playerJourneyReducer';
import freeSectionReducer from './freeSectionReducer';
import getStartedReducer from './getStartedReducer';
import anytimeReducer from './anytimeReducer';
import teamReducer from './teamReducer';
import profileDataReducer from './profileDataReducer';
import allMembersDataReducer from './allMembersDataReducer';
import countManagerDataReducer from './countManagerReducer';
import subscriptionPriceDataReducer from './subscriptionPriceReducer';
import quickSessionsReducer from './quickSessionsReducer';
import listGridViewReducer from './listGridViewReducer';
import listViewReducer from './listViewReducer';
import listViewSubcategoryReducer from './listViewSubcategoryReducer';
import listViewCategoryReducer from './listViewCategoryReducer';
import videoCategoryBookmarkReducer from './videoCategoryBookmarkReducer';
import selectVideosReducer from './selectVideosReducer';

const reducers = combineReducers({
  appReducer,
  authReducer,
  userDataReducer,
  signUpDataReducer,
  playerDataReducer,
  dashboardChildReducer,
  childDashVimeoInfoReducer,
  addPlayerReducer,
  billingDetailsReducer,
  mainFoldersDashboardChildReducer,
  getStartedReducer,
  playerJourneyReducer,
  freeSectionReducer,
  anytimeReducer,
  teamReducer,
  profileDataReducer,
  allMembersDataReducer,
  countManagerDataReducer,
  subscriptionPriceDataReducer,
  quickSessionsReducer,
  listGridViewReducer,
  listViewReducer,
  listViewSubcategoryReducer,
  listViewCategoryReducer,
  videoCategoryBookmarkReducer,
  selectVideosReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

window.__store__ = store;
export default store;
