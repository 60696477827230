import React from 'react';
import styles from './gettingStartedPopup.module.sass';

const TrainingPlanPopup = ({ setShowFirstSignInPopup }) => {
    const handleClick = () => setShowFirstSignInPopup(false);

    return (
        <>
            <div className={styles.popup__wrapper}></div>
            <div className={styles.popup}>
                <div className={styles.closeImg} onClick={handleClick}>&times;</div>
                    <h3 className={styles.popup__title}>Getting Started with Anytime</h3>
                    <iframe className={styles.popup__video} src="https://www.youtube.com/embed/47uqMn7oHnk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <button className={styles.popup__button} type="button" onClick={handleClick}>Skip this video</button>
            </div>
        </>
    );
};

export default TrainingPlanPopup;