import React, { useMemo, useState } from 'react';
import styles from '../Table/Table.module.sass';
import { Avatars } from '../../../../../constants/const';
import useChallengeScore from '../../../../../hooks/useChallengeScore.js';
import { Loading } from '../../../../commonComponents/Loading/Loading.jsx';

export const playersHeaders = [
  { label: 'No.', accessor: 'index', sortable: false },
  { label: 'Name', accessor: 'childFirstName', sortable: false },
  { label: 'Won', accessor: 'wins', sortable: true },
  { label: 'Loss', accessor: 'losses', sortable: true },
];

function ScoreTable({ teamId, isManager, isPlayerManager }) {
  const { data, isLoading, handleResetChallengeScore } = useChallengeScore(teamId);
  const [isResetting, setIsResetting] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'wins', direction: 'descending' });

  const sortAscDesc = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleReset = () => {
    setIsResetting(true);
    handleResetChallengeScore(teamId, {
      onSettled: () => setIsResetting(false),
    });
  };

  const sortedPlayers = useMemo(() => {
    if (data) {
      const sortablePlayers = [...data]; // Create a copy of the player data
      if (sortConfig !== null) {
        sortablePlayers.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortablePlayers;
    } else {
      return [];
    }
  }, [data, sortConfig]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <span>No Data</span>;
  }

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <h2 className={styles.title}>Players</h2>
        {(isManager || isPlayerManager) && (
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: '#e1343f',
              borderBottom: '1px solid #e1343f',
              padding: '0px',
              fontSize: '15px',
            }}
            onClick={handleReset}
          >
            {isResetting ? 'Resetting' : 'Reset Scores'}
          </button>
        )}
      </div>
      <table className={styles.player_data}>
        <thead>
          <tr className={styles.player_data__header}>
            {playersHeaders.map((header, i) => (
              <th
                scope="col"
                className={styles.player_data__header__item}
                key={i}
                onClick={() => header.sortable && sortAscDesc(header.accessor)}
                style={header.accessor === 'childFirstName' ? { width: '80%' } : {}}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: header.sortable ? 'pointer' : 'default',
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header.label}
                  </span>
                  {sortConfig.key === header.accessor ? (
                    <span
                      style={{
                        fontSize: '.75rem',
                        marginLeft: '.3rem',
                        marginTop: '5px',
                      }}
                    >
                      {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                    </span>
                  ) : (
                    <>
                      {header.sortable && (
                        <span
                          style={{
                            fontSize: '.75rem',
                            marginLeft: '.3rem',
                            marginTop: '5px',
                          }}
                        >
                          ▼
                        </span>
                      )}
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.player_data__main}>
          {sortedPlayers.map((data, index) => {
            const classSelector = data.isManager
              ? styles.cell_player_manager
              : styles.cell_player_name;
            const {
              childFirstName,
              childId,
              childLastName,
              wins,
              child: { image, picture },
              losses,
            } = data;
            const fullName = childFirstName.concat(childLastName ? `${' ' + childLastName}` : ' ');
            return (
              <tr className={styles.row} key={childId}>
                <th scope="row" className={styles.cell_first}>
                  {index + 1}
                </th>
                <td className={classSelector}>
                  <img
                    src={image || Avatars[parseInt(picture) - 1]}
                    className={styles.cell_logo}
                    alt=""
                  />
                  <span>{fullName}</span>
                </td>
                <td className={styles.cell} style={{ color: '#2cdebe', textAlign: 'center' }}>
                  {wins}
                </td>
                <td className={styles.cell} style={{ textAlign: 'center' }}>
                  {losses}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ScoreTable;
