import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { message } from 'antd';

function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : '');
  const [promoDuration, setPromoDuration] = useState(30); // In days
  const [promoExpires, setPromoExpires] = useState(moment().subtract(-30, 'days').format('YYYY-MM-DD'));

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = e => {
    setInput(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (input === ''){
      return message.error("Please, enter a name for the new team code");
    }

    props.onSubmit({
      promoName: input,
      durationTime: promoDuration * 24 * 60 * 60, // Should be in seconds
      expiresAt: promoExpires
    });
    setInput('');
  };

  return (
    <form onSubmit={handleSubmit} className='todo-form'>
      {props.edit ? (
        <>
          <input
            placeholder='Update your item'
            value={input}
            onChange={handleChange}
            name='text'
            ref={inputRef}
            className='todo-input edit'
          />
          <button onClick={handleSubmit} className='todo-button edit'>
            Update
          </button>
        </>
      ) : (
        <>
          <input
            placeholder='Add a Team code'
            value={input}
            onChange={handleChange}
            name='text'
            className='todo-input'
            ref={inputRef}
          />
          <label className="todo-time-label" htmlFor="promo-duration">Duration time of Team code (in days)</label>
          <input 
            type="number"
            name="promo-duration"
            value={promoDuration}
            min="1"
            max="360"
            onChange={e => setPromoDuration(e.target.value)}
            >
          </input>
          <label className="todo-time-label" htmlFor="promo-expires">Team code expiration time</label>
          <input 
            type="date"
            name="promo-expires"
            value={promoExpires}
            min={moment().format('YYYY-MM-DD')}
            onChange={e => setPromoExpires(moment(e.target.value).format('YYYY-MM-DD'))}
            >
          </input>
          <button onClick={handleSubmit} className='todo-button'>
            Add Team code
          </button>
        </>
      )}
    </form>
  );
}

export default TodoForm;
