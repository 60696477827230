import React, { useState, useEffect } from 'react';
import styles from './NavPanel.module.sass';
import { Link } from 'react-router-dom';
import NavPanelMobile from './NavPanelMobile/NavPanelMobile';
import UpgradePopUp from '../../DashboardChild/UpgradePopUp/UpgradePopUp';
import { message } from 'antd';
import path from '../../../config/routes.json';
import TrialPeriodSuggestionPopup from '../TrialPeriodSuggestionPopup/TrialPeriodSuggestionPopup';
import { compareDates } from '../../../utils/utils.js';
import FreeTrialButton from './FreeTrialButton/FreeTrialButton';
import { getFreeTrialDaysLeft } from '../../../api/api.js';
import s from '../../../app.module.sass';
import { Spinner } from 'reactstrap';
import { devEmail } from '../../../utils/consts';

export const ButtonStatus = {
  ACTIVATE_TRIAL: 'ACTIVATE_TRIAL',
  TRIAL_ACTIVATED: 'TRIAL_ACTIVATED',
  TRIAL_IS_OVER: 'TRIAL_IS_OVER',
};

const ParentAccountStatus = {
  FREE: 'freeAccount',
};

let trialStatus = null;
let freeTrialDaysLeft = null;

const responseHandler = (response) => {
  switch (response.status) {
    case 200:
      trialStatus = ButtonStatus.TRIAL_ACTIVATED;
      break;
    case 400:
      trialStatus = ButtonStatus.TRIAL_IS_OVER;
      break;
    case 404:
      trialStatus = ButtonStatus.ACTIVATE_TRIAL;
      break;
  }
  return response;
};

export default function NavPanel(props) {
  const [isContentLoaded, setContentLoaded] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [isStartTrial, setIsStartTrial] = useState(false);
  const [showTrialPeriodPopup, setShowTrialPeriodPopup] = useState(false);
  const daysOfFreeUse = compareDates(new Date(), props.playerData.userInfo.createdAt);
  const isFreeDaysFinished = daysOfFreeUse >= 7;
  const checkConditions =
    props.playerData.userInfo.status === ParentAccountStatus.FREE && isFreeDaysFinished;

  useEffect(() => {
    if (checkConditions && (!isContentLoaded || isStartTrial)) {
      getFreeTrialDaysLeft()
        .then((response) => responseHandler(response))
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              freeTrialDaysLeft = data;
              if (freeTrialDaysLeft < 0) {
                if (props.playerData.username !== devEmail)
                  trialStatus = ButtonStatus.TRIAL_IS_OVER;
              }
              setContentLoaded(true);
            });
          } else {
            setContentLoaded(true);
          }
        });
    } else {
      setContentLoaded(true);
    }
  }, [isStartTrial]);

  const checkShouldWeShownTrialButton = () => {
    switch (trialStatus) {
      case ButtonStatus.ACTIVATE_TRIAL:
        return <FreeTrialButton setShowTrialPeriodPopup={setShowTrialPeriodPopup} />;
      case ButtonStatus.TRIAL_ACTIVATED:
        return <p className={styles.trial_activated}>Trial: {freeTrialDaysLeft} Days Left</p>;
      case ButtonStatus.TRIAL_IS_OVER:
        return null;
      default:
        return null;
    }
  };

  const checkChildStatus = () => {
    let flag = false;

    if (props.playerData.childInfo.childrenCount === 0) {
      return flag;
    }

    for (const key in props.playerData.childInfo) {
      if (
        props.playerData.childInfo[key].status !== 'active' &&
        key !== 'childrenCount' &&
        key !== 'forChildId'
      ) {
        flag = true;
      }
    }
    return flag;
  };

  const showModal = () => setShowUpgradePopup(true);

  return (
    <>
      {isContentLoaded ? (
        <>
          <div className={styles.mobile_navpanel}>
            <NavPanelMobile
              playerData={props.playerData}
              signUpData={props.signUpData}
              setUserDataStep1={props.setUserDataStep1}
              setUserDataStep3={props.setUserDataStep3}
              freeTrialDaysLeft={freeTrialDaysLeft}
              setIsStartTrial={setIsStartTrial}
              setContentLoaded={setContentLoaded}
              trialStatus={trialStatus}
            />
          </div>
          <section className={styles.section}>
            {showUpgradePopup && <UpgradePopUp setShowUpgradePopup={setShowUpgradePopup} />}
            <h2 className={styles.header}>Dashboard</h2>
            <ul className={styles.menu}>
              <li className={styles.menu__item}>
                {checkShouldWeShownTrialButton()}
                {showTrialPeriodPopup && (
                  <TrialPeriodSuggestionPopup
                    setShowTrialPeriodPopup={setShowTrialPeriodPopup}
                    setIsStartTrial={setIsStartTrial}
                    setContentLoaded={setContentLoaded}
                  />
                )}
              </li>
              <li className={styles.menu__item}>
                {checkChildStatus() && (
                  <button
                    type="submit"
                    id="toggleForBtnNext"
                    className={styles.buttonDev}
                    onClick={showModal}
                  >
                    Upgrade
                  </button>
                )}
              </li>
              <li className={styles.menu__item}>
                <Link to={path.dashboard.manage.account} className={styles.bordered}>
                  Account Management{' '}
                </Link>
              </li>
              {props.amountPlayer === 4 &&
              props.playerData.userInfo.email !== 'neil@anytime-soccer.com'
                ? message.error('Max Player Accounts Created')
                : null}
              {props.playerData.childInfo.childrenCount < 4 ||
              props.playerData.userInfo.email === 'neil@anytime-soccer.com' ? (
                props.playerData.childInfo.childrenCount !== 0 ? (
                  <Link to={path.dashboard.createPlayerFree} className={styles.createPlayer}>
                    Add Player/Profile
                  </Link>
                ) : null
              ) : (
                <Link
                  to={'#'}
                  className={styles.createPlayer}
                  onClick={() => message.error('The number of players cannot be more than four!')}
                >
                  Add Player/Profile
                </Link>
              )}
            </ul>
          </section>
        </>
      ) : (
        <div style={{ background: '#f4fbff', minHeight: '10vh' }}>
          <Spinner color="primary" className={s.spinner} />
        </div>
      )}
    </>
  );
}
