import React, { useState } from 'react';
import styles from './ContactForm.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { contactRequest } from '../../../../api/api'


const ContactForm = () => {
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      task: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('required'),
      email: Yup.string().required('required'),
      task: Yup.string().required('required')
    }),
    onSubmit: (values) => {
      const requestData = {
        category: 'ContactUs',
        name: values.name,
        email: values.email,
        task: values.task
      };
      try {
        contactRequest(requestData).then(response => requestHandler(response))
      } catch (e) {
        console.log(e)
      }
    }
  });
  const requestHandler = (response) => {
    if (response.status === 200) {
      toggle();

    }
  };
  const toggle = () => {
    setModal(!modal)
  };
  // Leonid must add two fields to the request

  return (
    <div className={styles.main_container}>
      <div className={styles.main_form}>
        <div>
          <h2 className={styles.text_desc}>Have any questions for us? Let&apos;s talk.</h2>
          <form onSubmit={formik.handleSubmit} className={styles.main_form_form}>
            <div className={styles.form_inputs}>
              <label htmlFor="name" className={styles.form_inputs__label}>Your Name</label>
              <input
                className={formik.touched.name && formik.errors.name
                  ? styles[formik.errors.name]
                  : ''}
                type="text"
                name="name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name.replace(/[^A-z\s]/g, '')}
              />
            </div>
            <div className={styles.form_inputs}>
              <label htmlFor="email" className={styles.form_inputs__label}>Email</label>
              <input
                className={formik.touched.email && formik.errors.email
                  ? styles[formik.errors.email]
                  : ''}
                type="email"
                name="email"
                id="email"
                placeholder="example@gmail.com"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email.replace(/[^A-z@._0-9-]/g, '')}
              />
            </div>
            <div className={styles.message_form}>
              <label htmlFor="message" className={styles.form_inputs__label}>Message</label>
              <textarea type="text"
                className={formik.touched.task && formik.errors.task
                  ? styles[formik.errors.task]
                  : ''}
                name="task"
                id="task"
                rows="10"
                maxLength="4000"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
              />
            </div>
            <div className={styles.form_button}>
              <button type="submit" className={styles.send_button} style={{ outline: 'none' }}>
                Send Your Message
              </button>
            </div>
          </form>
          <Modal isOpen={modal} toggle={toggle} className={styles.modal}>
            <ModalHeader toggle={toggle}>
              <h4>Thank you</h4>
            </ModalHeader>
            <ModalBody>
              Your message has been sent!
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle} className={styles.buttonStrap}>Ok</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
};

export default ContactForm;