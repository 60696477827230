import React, { useState, useEffect } from 'react';
import styles from './UpgradePage.module.sass';
import { PlayersSubscription } from './PlayersSubscription/PlayersSubscription';
import { Payment } from './Payment/Payment';
import { Spinner } from 'reactstrap';
import s from '../../app.module.sass';
import { parentInfo } from '../../api/api.js';
import path from '../../config/routes.json';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../constants/const';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from '../../config/routes.json';

export function UpgradePage(props) {
  const {
    userData: {
      childInfo: { childrenCount },
    },
  } = props;
  const [total, setTotal] = useState(0);
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [subscriptionPlayers, setSubscriptionPlayers] = useState({});
  const { width } = useWindowDimensions();
  const [data, setData] = useState(null);
  const [isContentLoaded, setContentLoaded] = useState(!!childrenCount);
  const history = useHistory();

  useEffect(() => {
    /** TODO: What return this request */
    if (!isContentLoaded) {
      parentInfo(props.playerData.childEmail).then((response) => {
        responseParentInfoHandler(response);
      });
    } else {
      setContentLoaded(true);
    }
  }, []);

  const getChildStatus = (data) => {
    if (!data || !data.childInfo) {
      return null;
    }
    const res = {
      childInfo: {},
      userInfo: {},
    };
    data.childInfo.forEach((child, idx) => {
      res.childInfo[`child${idx + 1}`] = child;
    });
    if (!Object.keys(res.childInfo).length) {
      message.error("We didn't find user accounts need to pay");
      history.push(routes.dashboardChild.main);
    }
    res.userInfo = data.userInfo;
    return res;
  };

  const responseParentInfoHandler = (response) => {
    response.json().then((response) => {
      const result = getChildStatus(response);
      setData(result);
      setContentLoaded(true);
    });
  };

  if (!isContentLoaded)
    return (
      <div style={{ background: '#f4fbff', minHeight: '10vh' }}>
        <Spinner color="primary" className={s.spinner} />
      </div>
    );

  return (
    <>
      {isPaymentStep && (
        <>
          <h2 className={styles.container__title}>Upgrade Your Plan</h2>
          <p className={styles.container__description}>
            Give your players everything they need to gain more skill and confidence.
          </p>
        </>
      )}
      <div className={styles.container}>
        {isPaymentStep ? (
          <Payment
            userData={data || props.userData}
            total={total}
            signUpData={props.signUpData}
            subscriptionPlayers={subscriptionPlayers}
            setIsPaymentStep={setIsPaymentStep}
          />
        ) : (
          <PlayersSubscription
            userData={data || props.userData}
            setStep={setIsPaymentStep}
            setTotal={setTotal}
            setSubscriptionPlayers={setSubscriptionPlayers}
          />
        )}
        <section className={styles.banner}>
          <h2 className={styles.banner__title}>Membership Details</h2>
          <ul className={styles.promo_list}>
            <li className={styles.promo_list__item}>Unlimited access to +5,000 training videos</li>
            <li className={styles.promo_list__item}>+100 soccer games and skills challenges</li>
            <li className={styles.promo_list__item}>Performance reports & rewards</li>
            <li className={styles.promo_list__item}>$49.98/year $14.98 per player</li>
          </ul>
          <div className={styles.last_promo}>
            <h3 className={styles.last_promo__title}>
              {width >= MOBILE_DIMENSION ? 'Anytime Soccer Training for Teams' : 'Team Discounts'}
            </h3>
            <p className={styles.last_promo__text}>
              Are you a coach, team manager or club director that would like to give your entire
              team training homework?
            </p>
            <a className={styles.button} href={path.teamDiscounts} target="_blank" rel="noreferrer">
              Request Demo
            </a>
          </div>
        </section>
      </div>
    </>
  );
}
