import React, { useEffect, useState } from 'react';
import styles from './TeamsMainScreen.module.sass';
import {
  DefaultRadioButton,
  TEAMS,
  TEAMS_DESCRIPTION,
  MOBILE_TEAM_DESCRIPTION,
  Button,
  Screen,
  Congratulation,
} from '../../../../constants/teams';
import { ProxyTeamsComponent } from '../ProxyTeamsComponent/ProxyTeamsComponent';
import { RadioButton } from '../../../commonComponents/RadioButton/RadioButton';
import { ButtonList } from '../ButtonList/ButtonList';
import { CreateTeamForm } from '../CreateTeamForm/CreateTeamForm';
import { SearchScreen } from '../SearchScreen/SearchScreen';
import { CongratulationPopup } from '../CongratulationPopup/CongratulationPopup';
import { Loading } from '../../../commonComponents/Loading/Loading';
import { getActiveTeamList, getPendingTeamList } from '../../../../api/teams-api';
import { showDefaultErrorMessage } from '../../../../utils/throwError';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';
import { useScrollToTop } from '../../../../hooks/useScrollToTop.js';
import { grayArrowUp, grayArrowDown } from '../../../../constants/assets';
import { MobileMenuItem } from '../../../commonComponents/MobileMenuItem/MobileMenuItem';

const radioButtons = Object.values(DefaultRadioButton);

export function TeamsMainPage() {
  const [upLoadedData, setUpLoadedData] = useState(false);
  const defaultScreen = Object.keys(Button)[0];
  const [checkedRadioButton, setCheckedRadioButton] = useState(DefaultRadioButton.ACTIVE);
  const [chosenScreen, changeChosenScreen] = useState(defaultScreen);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const { width } = useWindowDimensions();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTeamCards, setActiveTeamCards] = useState({
    activeTeams: [],
    currentPage: 1,
    totalPage: 0,
  });

  const [pendingTeamCards, setPendingTeamCards] = useState({
    inviteTeams: [],
    currentPage: 1,
    totalPage: 0,
  });

  useScrollToTop();

  useEffect(() => {
    if (!upLoadedData || isNeedUpdate) {
      getActiveTeamList().then((response) => {
        responseActiveTeamsHandler(response);
      });
      getPendingTeamList().then((response) => {
        responsePendingTeamsHandler(response);
      });
    }
  }, [isNeedUpdate]);

  const responseActiveTeamsHandler = (response) => {
    response.json().then((data) => {
      if (response.ok) {
        if (data.activeTeams) {
          setActiveTeamCards(data);
        }
        setUpLoadedData(true);
      }
    });
  };

  const responsePendingTeamsHandler = (response) => {
    response.json().then((data) => {
      if (response.ok) {
        if (data.inviteTeams) {
          setPendingTeamCards(data);
        }
        setUpLoadedData(true);
      } else {
        showDefaultErrorMessage();
      }
    });
  };

  const handleMobileSelectorClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const handleMobileRadioButtonChange = (event) => {
    setCheckedRadioButton(event.target.value);
    if (isMobileMenuOpen) setIsMobileMenuOpen(false);
  };
  if (!upLoadedData) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <section className={styles.main_screen}>
        <header className={styles.main_screen__header}>
          <h2 className={styles.main_screen__title}>{TEAMS}</h2>
          <p className={styles.main_screen__promo}>
            {width >= MOBILE_DIMENSION ? TEAMS_DESCRIPTION : MOBILE_TEAM_DESCRIPTION}
          </p>
          <ul className={styles.buttons}>
            <ButtonList chosenScreen={chosenScreen} changeChosenScreen={changeChosenScreen}>
              {Button}
            </ButtonList>
          </ul>
        </header>
        {chosenScreen === Screen.MY_TEAMS && (
          <>
            {width >= MOBILE_DIMENSION ? (
              <div className={styles.selectors}>
                {radioButtons.map((radioButton) => {
                  return (
                    <RadioButton
                      key={radioButton}
                      radioButton={radioButton}
                      checkedRadioButton={checkedRadioButton}
                      setCheckedRadioButton={setCheckedRadioButton}
                    />
                  );
                })}
              </div>
            ) : (
              <button
                className={styles.mobile_selector}
                type="button"
                onClick={handleMobileSelectorClick}
              >
                <span>{checkedRadioButton}</span>
                <img
                  src={isMobileMenuOpen ? grayArrowUp : grayArrowDown}
                  alt=""
                  aria-hidden="true"
                  className={styles.mobile_selector__image}
                />
              </button>
            )}
            {isMobileMenuOpen && (
              <div className={styles.mobile_menu_container}>
                <ul className={styles.mobile_menu}>
                  {radioButtons.map((radioButton) => {
                    const isChecked = checkedRadioButton === radioButton;
                    return (
                      <MobileMenuItem
                        key={radioButton}
                        radioButton={radioButton}
                        isChecked={isChecked}
                        handleMobileRadioButtonChange={handleMobileRadioButtonChange}
                      />
                    );
                  })}
                </ul>
              </div>
            )}
            <ProxyTeamsComponent
              checkedRadioButton={checkedRadioButton}
              activeTeamCards={activeTeamCards}
              setActiveTeamCards={setActiveTeamCards}
              pendingTeamCards={pendingTeamCards}
              isNeedUpdate={isNeedUpdate}
              setIsNeedUpdate={setIsNeedUpdate}
            />
          </>
        )}
        {chosenScreen === Screen.JOIN_TEAM && (
          <SearchScreen changeChosenScreen={changeChosenScreen} defaultScreen={defaultScreen} />
        )}
        {chosenScreen === Screen.CREATE_TEAM && (
          <CreateTeamForm changeChosenScreen={changeChosenScreen} defaultScreen={defaultScreen} />
        )}
        {chosenScreen === Screen.CREATE_TEAM_SUCCESS && (
          <CongratulationPopup
            changeChosenScreen={changeChosenScreen}
            defaultScreen={defaultScreen}
            popupText={Congratulation.CREATING}
            setIsNeedUpdate={setIsNeedUpdate}
          >
            <img
              src="/img/teams/no_active_team.webp"
              style={{ width: '250px', height: 'auto' }}
              alt=""
            />
          </CongratulationPopup>
        )}
      </section>
    </div>
  );
}
