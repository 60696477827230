import React from 'react';
import styles from './VideoCategories.module.sass';
import { listOfVideosVimeo } from '../../../api/api';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { SubfolderPageUrl } from '../../../constants/const';
import { useDispatch } from 'react-redux';
import { setUri, setVideo } from '../../../Redux/childDashVimeoInfoReducer';

const checkTitle = (title) => {
  switch (title) {
    case 'Free Section Games':
      return false;
    case 'Soccer Chasing':
      return false;
    case 'Soccer Tagging':
      return false;
    case 'Anytime Skills':
      return false;
    default:
      return true;
  }
};

const VideoCart = (props) => {
  let { title, name, uri, urlImage } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const serverVideoRequest = () => {
    dispatch(setUri(uri));
    try {
      listOfVideosVimeo(uri, 1).then((response) => requestHandler(response));
    } catch (e) {
      console.log(e);
    }
  };

  const requestHandler = (response) => {
    if (response.status === 200) {
      response.json().then((result) => {
        // ===========    ========================================      ==================
        const videoArray = result.data.map((a) => {
          return { name: a.name, link: a.link, viewed: a.viewed };
        });
        dispatch(setVideo({ total: result.total, videoArray }));
        if (result.total) {
          history.push(`${SubfolderPageUrl.videoSeries}/${name}`);
        } else {
          message.error('This category will appear soon');
        }
      });
    } else {
      console.error('Please, reload the page');
    }
  };
  // TODO temporary walkaround for category title problem. In the project everything is relied on titles. It is a very bad practice.
  if (title === 'Midfield Ball Mastery') {
    title = 'Midfielder Ball Mastery';
  }

  return (
    <article>
      <div
        onClick={serverVideoRequest}
        className={styles.card}
        style={{
          backgroundImage: `url('${urlImage}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></div>
      {checkTitle(title) && (
        <div className={styles.desc}>
          {/* по просьбе заказчика необходимо убрать из имен папок Fast Speed */}
          {name.includes('Fast Speed') ? (
            <p className={styles.desc_text}>{name.slice('Fast Speed'.length)}</p>
          ) : (
            <p className={styles.desc_text}>{name.slice(title.length)}</p>
          )}
        </div>
      )}
    </article>
  );
};

export default VideoCart;
