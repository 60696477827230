import React, { useState, useEffect } from 'react';
import TodoForm from './TodoForm';
import Todo from './Todo';
import config from '../../../config.json';
import mainRoute from '../../../mainRoute';
import { RiBarChartBoxFill, RiLineChartFill } from 'react-icons/ri';
import { Modal, message } from 'antd';

function TodoList() {
  const [todos, setTodos] = useState([]);
  const [activeUsers, setActiveUsers] = useState(null);
  const [allFreeUsers, setAllFreeUsers] = useState(null);
  const [freeWithPromoUsers, setFreeWithPromoUsers] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //GET
  useEffect(() => {
    async function fetchPromos() {
      try {
        const res = await fetch(mainRoute.route + config.getAllPromoCodes);
        if (res.status === 200) {
          const data = await res.json();
          setTodos(data.promoCodes, data.expiresAt, data.durationTime);
        }
      } catch (err) {
        console.log(`fetchPromos error status ${err}`);
      }
    }
    fetchPromos();

    async function fetchAmountActiveUsers() {
      try {
        const res = await fetch(mainRoute.route + config.getAmountUsersStatistics);
        if (res.status === 200) {
          const data = await res.json();
          setActiveUsers(data.activeUsers);
          setAllFreeUsers(data.allFreeUsers);
          setFreeWithPromoUsers(data.freeWithPromoUsers);
        }
      } catch (err) {
        console.log(`fetchAmountActiveUsers error status ${err}`);
      }
    }
    fetchAmountActiveUsers();
  }, []);

  //POST
  const addTodo = async (todo) => {
    if (!todo.promoName || /^\s*$/.test(todo.promoName)) {
      return;
    }

    try {
      const data = {
        promoName: todo.promoName,
        durationTime: todo.durationTime,
        expiresAt: todo.expiresAt,
      };
      const res = await fetch(mainRoute.route + config.createPromoCode, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        const data = await res.json();
        const newData = [data.promoCode, ...todos];
        setTodos(newData);
      } else {
        const error = await res.json();
        message.error(error.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // пока под вопросом надо ли оно
  const updateTodo = (todoId, newValue) => {
    if (!newValue.text || /^\s*$/.test(newValue.text)) {
      return;
    }

    setTodos((prev) => prev.map((item) => (item.id === todoId ? newValue : item)));
  };

  //DELETE
  const removeTodo = async (id) => {
    const removedArr = [...todos].filter((todo) => todo.id !== id);
    try {
      const res = await fetch(`${mainRoute.route}${config.deletePromoCode}/${id}`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        setTodos(removedArr);
      } else {
        console.log(`removeTodo error status ${res.status}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const completeTodo = (id) => {
    const updatedTodos = todos.map((todo) => {
      if (todo.id === id) {
        todo.isComplete = !todo.isComplete;
      }
      return todo;
    });
    setTodos(updatedTodos);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className="icons">
          <RiBarChartBoxFill
            style={{
              width: '100px',
              height: '100px',
              color: 'white',
            }}
            onClick={showModal}
          />
        </div>
        <a
          href={`${mainRoute.route}${config.downloadUserStatistics}`}
          target="__blank"
          className="icons"
        >
          <RiLineChartFill
            style={{
              width: '100px',
              height: '100px',
              color: 'white',
            }}
          />
        </a>
      </div>
      <Modal
        title={<div style={{ fontSize: '30px' }}>Statistics</div>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        headStyle={{ backgroundColor: 'red' }}
      >
        <h6>Amount of all active users: {activeUsers}</h6>
        <hr />
        <h6>Amount of all free users: {allFreeUsers}</h6>
        <hr />
        <h6>Amount of all free users with activated Team Code: {freeWithPromoUsers}</h6>
      </Modal>
      <TodoForm onSubmit={addTodo} />
      <Todo
        todos={todos}
        completeTodo={completeTodo}
        removeTodo={removeTodo}
        updateTodo={updateTodo}
      />
    </>
  );
}

export default TodoList;
