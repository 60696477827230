import React from 'react';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './CongratulationPopup.module.sass';
import { ClosePopupButton } from '../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BUTTON_TITLE } from '../../../../constants/teams.js';

/** This component needs function handleClosePopupButtonClick for closing popup,
 * props.children (it can be img tag)
 * and props.popupText */

export function CongratulationPopup(props) {
  const handleClosePopupButtonClick = () => {
    props.changeChosenScreen(props.defaultScreen);
    if (typeof props.setIsNeedUpdate === 'function') props.setIsNeedUpdate(true);
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.success_popup}>
        <ClosePopupButton 
        handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        {props.children}
        <h3 className={styles.success_popup__title}>Congratulations!</h3>
        <p className={styles.success_popup__text}>{props.popupText}</p>
        <RedButton 
        handleButtonClick={handleClosePopupButtonClick} 
        title={BUTTON_TITLE} 
        />
      </section>
    </>
  );
}
