import React from 'react';
import styles from './HeaderProfilePage.module.sass';
import Profile from '../../../asset/profilePageIcons/Profile.webp';

const HeaderProfilePage = () => (
  <section className={styles.headerWrapper}>
    <img src={Profile} alt="Profile Icon" className={styles.headerWrapper_image} />
    <p className={styles.headerWrapper_title}>
      <span> Profile</span>
    </p>
  </section>
);

export default HeaderProfilePage;
