import React from 'react';
import styles from './PlayersSubscription.module.sass';
import SelectPlayerAccountForPayment from '../../Dashbord/NavPanel/Page';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../constants/const';

export function PlayersSubscription(props) {
    const { width } = useWindowDimensions();

    return (
        <section className={styles.players_subscription}>
            {width >= MOBILE_DIMENSION ?
                <h1 className={styles.players_subscription__title}>
                    Upgrade to <span className={styles.red}>All Access Pass!</span>
                </h1>
                :
                <h1 className={styles.players_subscription__title}>
                    Upgrade Your Plan
                </h1>}
            <article className={styles.heading}>
                <h2 className={styles.heading__players}>
                    Player Accounts
                </h2>
                <p className={styles.heading__info}>Annual Subscription</p>
            </article>
            <SelectPlayerAccountForPayment
                userData={props.userData}
                setStep={props.setStep}
                setTotal={props.setTotal}
                setSubscriptionPlayers={props.setSubscriptionPlayers}
            />
        </section>
    )
};