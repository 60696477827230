import React, { useMemo } from 'react'
import StatCard from '../StatCard'

const ContestMetadata = ({ contest, totalTime, totalVideo }) => {

  // Calculate days remaining
  const daysRemaining = useMemo(() => {
    const today = new Date();
    const targetDate = new Date(contest.targetDate);
    return Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
  }, [contest.targetDate]);

  console.log({ contest })

  // Define the items to display in StatCards
  const items = useMemo(() => [
    {
      name: 'Days Remaining',
      value: daysRemaining,
      color: '#e1343f'
    },
    {
      name: 'Team Reward',
      value: contest?.reward ?? 'Coach Surprise',
      color: '#e1343f'
    },
    {
      name: 'Top Player Reward',
      value: contest?.topReward ?? 'Coach Surprise',
      color: '#e1343f'
    },
    {
      name: 'Goal',
      value: `${contest.videoGoal} | ${((contest.videoGoal * 3) / 60).toFixed(2)}h`,
      color: '#2cdebe'
    },
    {
      name: 'Completed',
      value: `${totalVideo} | ${((totalTime / 60) / 60).toFixed(2)}h`,
      color: contest.completed ? '#2cdebe' : '#e1343f'
    },{
      name: 'Official Page',
      value: contest.referenceCode,
      color: contest.completed ? '#2cdebe' : '#e1343f'
    },
  ], [daysRemaining, contest, totalTime, totalVideo]);

  return (
    <div style={{
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      padding: '1rem'
    }}>
       {items.map((item, i) => (
        <StatCard key={i} name={item.name} value={item.value} color={item.color} />
      ))}
    </div>
  )
}

export default ContestMetadata