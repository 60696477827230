import React from 'react'
import WelcomeMenu from '../WelcomeMenu/WelcomeMenu';
import HomeMenu from '../HomeMenu/HomeMenu';

const MobileHeader = (props) => {
  return (
    <>
      {props.welcomePage ? <WelcomeMenu path={props.path}
                                        welcomePage={props.welcomePage}
                                        setWelcomePage={props.setWelcomePage}
                                        setMobileMenu={props.setMobileMenu}
                                        mobileMenu={props.mobileMenu}/> :
                            <HomeMenu path={props.path}
                                      welcomePage={props.welcomePage}
                                      setWelcomePage={props.setWelcomePage}
                                      setMobileMenu={props.setMobileMenu}
                                      mobileMenu={props.mobileMenu}/>}
    </>
  );
};

export default MobileHeader;