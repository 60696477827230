import React, { useState } from 'react';
import styles from './SignUpFree.module.sass';
import Form from './Form/Form';
import { PromoForCreateAccount } from '../../../constants/PromoList';
import { MOBILE_DIMENSION } from '../../../constants/const';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import path from '../../../config/routes.json';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { PromoItem } from '../../commonComponents/PromoItem/PromoItem';

export const SignUpFree = () => {
  const { width } = useWindowDimensions();
  useScrollToTop();

  const [signUpFieldValues, changeSignUpFieldValues] = useState({
    step1: {
      parentFirstName: '',
      parentLastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      players: '1',
    },
  });

  return (
    <section className={styles.create_account}>
      <h2 className={styles.create_account__title}>Create An Account</h2>
      <p className={styles.create_account__description}>
        Give your players everything they need to gain more skill and confidence.
      </p>
      <div className={styles.container}>
        <Form
          changeSignUpFieldValues={changeSignUpFieldValues}
          signUpFieldValues={signUpFieldValues}
        />
        <article className={styles.banner}>
          <h3 className={styles.banner__title}>Membership Details</h3>
          <ul className={styles.promo}>
            {PromoForCreateAccount.map((promoTitle) => {
              return (
                <PromoItem key={promoTitle} listItem={promoTitle}>
                  <img src="/img/check.webp" alt="check" className={styles.checkImg} />
                </PromoItem>
              );
            })}
          </ul>
          <div className={styles.last_promo}>
            <h3 className={styles.last_promo__title}>
              {width >= MOBILE_DIMENSION ? 'Anytime Soccer Training for Teams' : 'Team Discounts'}
            </h3>
            <p className={styles.last_promo__text}>
              Are you a coach, team manager or club director that would like to give your entire
              team training homework?
            </p>
            <a className={styles.button} href={path.teamDiscounts} target="_blank" rel="noreferrer">
              Request Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};
