import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHomework, deleteFavorite, addFavorite, removeHomework, addHiddenFolder, removeHiddenFolder  } from '../../../Redux/listViewReducer';
import styles from './ListViewPage.module.sass';
import PinRed from './pin_red.png';
import PinGrey from './pin_grey.png'
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons'
import BookmarkGrey from './bookmark_grey.png';
import BookmarkGreen from './bookmark_green.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { message } from 'antd';
import {
  createVideoCategoryBookmark,
  deleteVideoCategoryBookmarkBySubTitle,
  getAllVideoCategoryBookmarks,
  createFavoriteJourney,
  deleteFavoriteBySubTitle,
  createHomework,
  deleteHomework,
  createHiddenFolder,
  deleteHiddenBySubTitle
} from '../../../api/api';
import {
  addBookmarkData,
  deleteBookmarkData,
  setBookmarksData,
} from '../../../Redux/videoCategoryBookmarkReducer';
import { useVideoCategoriesByAction } from '../../../hooks/useVideoCategoriesByAction';
import useUserTeams from '../../../hooks/useUserTeam';
import { ModalAssignment } from '../../ProfilePage/ModalWindowPopap/ModalAssignment';

const ListView = () => {
  const fromListViewPage = true;
  const selectedSubCategory = useSelector((state) => state.listViewSubcategoryReducer);
  const selectedData = useVideoCategoriesByAction(selectedSubCategory);

  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find((item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA').children
  const [selectedItem, setSelectedItem] = useState(null)
  const hiddenData = useVideoCategoriesByAction('SET_MY_HIDDEN_CATEGORY_DATA').find((item) => item.actionType === 'SET_MY_HIDDEN_SUBCATEGORY_DATA').children
  
  const { data: bookmarks } = useSelector((state) => state.videoCategoryBookmarkReducer);
  const { manageTeams } = useUserTeams()
  
  const { data } = useSelector(state => state.listViewReducer)

  const homeworkData = useMemo(() => {
    const allHomework = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.map((item) => item.children[0].children).flat();
    
    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(item => item?.title === current?.title && item?.subTitle === current?.subTitle);
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);
  
    return uniqueHomework;
  }, [data]);

  const dispatch = useDispatch();

  const switchBookmark = (e) => {
    const dataSubTitle = e.target.getAttribute('data-subtitle');
    const isExists = checkBookmark(dataSubTitle);
    if (isExists) {
      // delete bookmark
      const result = deleteVideoCategoryBookmarkBySubTitle({ actionTypeCategory: dataSubTitle });
      result.then(() => dispatch(deleteBookmarkData(dataSubTitle)));
    } else {
      //add bookmark
      const result = createVideoCategoryBookmark({ actionTypeCategory: dataSubTitle });
      result.then((result) => dispatch(addBookmarkData(result)));
    }
  };

  const checkBookmark = (dataSubTitle) => {
    const arrayFromBookmarks = Array.from(bookmarks);
    return arrayFromBookmarks.some((el) => el.actionTypeCategory === dataSubTitle);
  };

  const checkFavorite = useCallback(
    (_item) => {
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return favoriteCheck
    },
    [favoriteData],
  )

  const checkHiddenFolder = useCallback(
    (_item) => {
      const hiddenCheck = hiddenData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return hiddenCheck
    },
    [hiddenData],
  )

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {

      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })
  
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return manageTeams.length > 0 ? (homeworkCheck || favoriteCheck) : favoriteCheck
    },
    [favoriteData, homeworkData, manageTeams],
  )

  

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item)
        return
      } 
  
      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg)
            dispatch(deleteFavorite(item))
          })
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite))
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  )

  const handleHideFolderClick = useCallback(
    async (item) => {
      if (checkHiddenFolder(item)) {
        await deleteHiddenBySubTitle(item).then((res) => {
          console.log(res.msg)
          dispatch(removeHiddenFolder(item))
          message.success('Successfully remove folder from Hidden.')
        })
      } else {
        await createHiddenFolder(item).then((res) => {
          dispatch(addHiddenFolder(res.folder))
          message.success('Successfully added folder to Hidden.')
        });
      }
    },
    [checkHiddenFolder, dispatch],
  )
  

  useEffect(() => {
    //load all bookmarks
    getAllVideoCategoryBookmarks().then((response) => {
      dispatch(setBookmarksData(response));
    });
  }, []);

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find(item => item.actionType === `cat-${team.id}`)
        ?.children[0].children;
  
      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });
  
      return homeworkCheck;
    },
    [data],
  );

  const onSubmit = useCallback(async (teams, addToFavorite) => {
    // Remove homework from teams that have been unchecked
    const teamsToRemoveHomework = manageTeams.filter(team => 
      checkHomeworkWithTeam(team, selectedItem) && !teams.some(selectedTeam => selectedTeam.id === team.id)
    );

    for (const team of teamsToRemoveHomework) {
      await deleteHomework({...selectedItem, team }).then((res) => {
        console.info(res.msg);
        dispatch(removeHomework(selectedItem, team));
      });
    }

    // Add homework to newly selected teams
    for (const team of teams) {
      if (!checkHomeworkWithTeam(team, selectedItem)) {
        await createHomework({ ...selectedItem, teamId: team.id }).then((res) => {
          console.info(res.success);
          dispatch(addHomework(selectedItem, team));
        });
      }
    }
  
    // Handle favorites
    if (checkFavorite(selectedItem) === false && addToFavorite === true) {
      await createFavoriteJourney(selectedItem).then((res) => {
        dispatch(addFavorite(res.favorite));
      });
    }
  
    if (checkFavorite(selectedItem) === true && addToFavorite === false) {
      await deleteFavoriteBySubTitle(selectedItem).then((res) => {
        console.log(res.msg);
        dispatch(deleteFavorite(selectedItem));
      });
    }
  
    setSelectedItem(null);
  }, [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams]);

  return (
    <>
      {selectedData?.map((category) => (
        <div key={category.title} className={styles.videoCategory}>
          <p className={styles.videoCatogoryHeader}>{category.title}</p>
          <div className={styles.videoCluster}>
            <ul>
              {category?.children?.map((child) => {
                let link = ''

                if (child?.subFolder) {
                  link = child?.subFolder + '/' + child.title + '/' + fromListViewPage
                } else {
                  link = child.actionType + '/' + child.subTitle + '/' + fromListViewPage
                }

                const categoriesRegex = /^cat-\d+$/;

                if (selectedSubCategory !== 'SET_MY_HIDDEN_CATEGORY_DATA' && !categoriesRegex.test(selectedSubCategory) && checkHiddenFolder(child)) {
                  return null
                }

                return (
                  <li key={child.title} className={styles.listRow}>
                    <div className={styles.listRow}>
                      <div className={styles.listItem}>
                        <img className={styles.pin} src={(checkHomeworkWithFavorite(child) || checkFavorite(child)) ? PinRed : PinGrey} style={{ cursor: 'pointer' }} onClick={() => handleFavoriteClick(child)} />
                        <Link
                          to={link}
                          className={styles.textListItem}
                        >
                          {child.title}
                        </Link>
                      </div>
                      <div className={styles.bookmarkColumn}>
                        {!categoriesRegex.test(selectedSubCategory) && (
                          <div onClick={() => handleHideFolderClick(child)} style={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            {checkHiddenFolder(child) ? (
                              <EyeFilled style={{
                                fontSize: '20px',
                                color: '#e1343f'
                              }} />
                            ) : (
                              <EyeInvisibleFilled style={{
                                fontSize: '20px',
                                color: '#86adc5'
                              }} />
                            )}
                          </div>
                      )}
                        <img
                          className={styles.bookmark}
                          src={checkBookmark(child.subTitle) ? BookmarkGreen : BookmarkGrey}
                          data-subtitle={child.subTitle}
                          onClick={(e) => switchBookmark(e)}
                        />
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      ))}
      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default ListView;
