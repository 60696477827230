import React, { useEffect, useState } from 'react';
import styles from '../TeamItem/TeamItem.module.sass';
import { acceptTeamInvite, declineTeamInvite } from '../../../../api/teams-api';
import { showDefaultErrorMessage } from '../../../../utils/throwError';
import { setCurrentTeamId } from '../../../../Redux/teamReducer';
import { useDispatch } from 'react-redux';

const InviteStatus = {
  ACCEPT: 'Accept',
  DECLINE: 'Decline',
};
/** { Входные данные: "teamId" : "1",
 * "name" : "name", "managerName" : "firstname lastname", “imgUrl":"link"}
 * manager name - two fields
 */

export function PendingCard(props) {
  const {
    teamCard: { teamId, name: title, childFirstName, childLastName, imgUrl },
    setIsNeedUpdate,
    isNeedUpdate,
  } = props;
  const [inviteStatus, setInviteStatus] = useState(true);
  const dispatch = useDispatch();
  const fullName = childFirstName.concat(' ', childLastName);

  useEffect(() => {
    if (inviteStatus === InviteStatus.ACCEPT) {
      acceptTeamInvite(teamId).then(response => {
        responseHandler(response);
      });
    }
    if (inviteStatus === InviteStatus.DECLINE) {
      declineTeamInvite(teamId).then(response => {
        responseHandler(response);
      });
    }
  }, [inviteStatus]);

  const handleButtonClick = event => {
    setInviteStatus(event.target.value);
    dispatch(setCurrentTeamId(teamId));
  };

  const responseHandler = response => {
    if (response.ok) {
      // тригерим запрос на сервер за обновленным списком pending cards
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      showDefaultErrorMessage();
    }
  };

  return (
    <article className={styles.team_card}>
      <div className={styles.container}>
        <h3 className={styles.team_card__title}>{title}</h3>
        <p className={styles.team_card__manager}>{fullName}</p>
        <div className={styles.buttons}>
          <button
            onClick={e => handleButtonClick(e)}
            value={InviteStatus.ACCEPT}
            disabled={!inviteStatus}
            className={`${styles.button} ${styles.button_accept_invite}`}
          >
            Accept Invite
          </button>
          <button
            onClick={e => handleButtonClick(e)}
            value={InviteStatus.DECLINE}
            disabled={!inviteStatus}
            className={`${styles.button} ${styles.button_decline_invite}`}
          >
            Decline Invite
          </button>
        </div>
      </div>
      <div className={styles.round_container}>
        <img src={imgUrl} className={styles.team_card__logo} alt="" />
      </div>
    </article>
  );
}
