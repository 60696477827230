import React, { useState, useEffect } from 'react';
import styles from './gridCards.module.sass';
import Card from './Card';
import PaginationLogic from '../VideoSeries/Pagination';
import CreateSeries from '../MainFoldersGridCards/CreateSeries';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SubfolderPageUrl } from '../../../constants/const.js';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Routing } from '../../../constants/routing';
import { Loading } from '../../commonComponents/Loading/Loading';
import { isInternetExplorer } from '../../../utils/utils';

const GridCards = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const url = location.pathname;

  useScrollToTop();
  /** Check if current route is unique for the app and in this case we dispatch the action
   * and set needed data to the redux */
  useEffect(() => {
    if (Object.keys(Routing).includes(url)) {
      dispatch(Routing[url]());
    }
    if (!props.dashboardInfo.length) {
      if (Object.keys(Routing).includes(url)) {
        dispatch(Routing[url]());
      } else {
        history.push(SubfolderPageUrl.dashboard);
      }
    } else {
      setUpLoadedData(true);
    }
  }, [props.dashboardInfo, location]);

  const allVideos = props.dashboardInfo.length;
  const pages = Math.ceil(allVideos / 6);

  const changePage = (direction) => {
    if (direction === 'prev' && 1 <= currentPage - 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && pages >= currentPage + 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      {upLoadedData ? (
        <section className={styles.grid_cards}>
          <div
            className={isInternetExplorer() ? styles.cardContainerIExplorer : styles.cardContainer}
          >
            {props.dashboardInfo.map((item, id) => {
              const checkCategories = Boolean(id >= currentPage * 6 - 6 && id < currentPage * 6);
              if (checkCategories) {
                // у объектов без вложенных папок есть флаг isSeries: true (пример см freeSectionReducer),
                // у остальных объектов флага нет = false
                // TODO: Add an error handler for the case, when 'isSeries' property is forfotten and the folder is for videos. Right now the app crashes in such a case

                return item.isSeries ? (
                  <CreateSeries
                    key={item?.title + id}
                    array={props.vimeoFolderData.filter((element) =>
                      element.name.includes(item.title),
                    )}
                    itemOfDashboard={item}
                    accessAllVideos={item.freeAccess ?? props.accessAllVideos}
                  />
                ) : (
                  <Card
                    key={item + id}
                    item={item}
                    accessAllVideos={item.freeAccess ?? props.accessAllVideos}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                );
              }
            })}
          </div>
          <div className={styles.grid_cards__pagination}>
            <PaginationLogic
              currentPage={currentPage}
              request={(e) => setCurrentPage(e)}
              changePage={changePage}
              pages={pages}
            />
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default GridCards;
