import React, { useState } from 'react';
import styles from './ChallengeButtons.module.sass';
import Plus from '../../../../../asset/teams/roster/invitations/Rectangle_plus.webp';
import { useHistory } from 'react-router-dom';

export function ChallengeButtons({ isManager, isPlayerManager, challenge }) {
  const history = useHistory();
  const [isNotifying, setIsNotifying] = useState(false);
  const [isNotified, setIsNotified] = useState(false);

  // const handleNotification = async () => {
  //   setIsNotifying(true)
  //   await notifyContest(contest.id).then((res) => {
  //     setIsNotifying(false)
  //     setIsNotified(true)
  //     message.success('Notification sent successfully')

  //     console.log({ res })
  //     setTimeout(() => {
  //       setIsNotified(false)
  //     }, 5000);
  //   })
  // }

  if (!(isManager || isPlayerManager)) {
    return null;
  }

  return (
    <div className={styles.challenge_buttons}>
      <button
        className={styles.challenge_button}
        onClick={() => {
          history.push('challenge/create');
        }}
      >
        <img className={styles.challenge_button__image} src={Plus} alt="" />
        New Challenge
      </button>
      {challenge?.id && (
        <button
          className={styles.challenge_button}
          // onClick={handleNotification}
          disabled={isNotifying}
        >
          {isNotifying ? 'Sending Notification' : `${isNotified ? 'Notified' : 'Notify'} Players`}
        </button>
      )}
    </div>
  );
}
