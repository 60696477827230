import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './Footer.module.sass';

export const fullYear = new Date().getFullYear();

function Footer(props) {
  const history = useHistory();
  const currentUri = useLocation();

  const location = Boolean(history.location.pathname === '/');

  const changeFooter = () => (
    <>
      {currentUri.pathname !== '/admin' && currentUri.pathname !== '/adminDashboard' ? (
        <footer className={styles.footer}>
          <section className={styles.section}>
            <article>
              <div className={styles.logo}>
                <img src="/img/logo.webp" alt="Anytime Soccer logo" className={styles.img} />
                <div className={styles.h2}>Anytime Soccer Training</div>
              </div>
              <p className={styles.p}>
                Our mission is to help children reach their full potential in soccer by providing
                world-class online training content that is affordable, easily accessible and fun.
                Our expertly designed t raining modules cover every major area of individual skill
                development. The program has proven to create players who have greater fitness,
                supreme confidence on the ball, and better field vision.
              </p>
              <ul className={styles.ul_links}>
                <li className={styles.social}>
                  <a
                    href={`https://www.facebook.com/groups/anytimesoccer/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li className={styles.social}>
                  <a
                    href={`https://twitter.com/_anytimesoccer/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className={styles.social}>
                  <a
                    href={`https://www.instagram.com/anytimesoccertraining/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className={styles.social}>
                  <a
                    href={`https://www.vimeo.com/anytimesoccer/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <i className="fab fa-vimeo-v" />
                  </a>
                </li>
                <li className={styles.social}>
                  <a
                    href={`https://www.youtube.com/channel/UC0jwsQlqzOIZLl5QHGIoveg?view_as=subscriber/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </article>
            <nav className={styles.nav}>
              <ul>
                <div className={styles.h4}>Quick links</div>
                <li className={styles.li}>
                  <Link to={props.path.joinMailingList}>Join mailing list</Link>
                </li>
                <li className={styles.li}>
                  <a target="_blank" rel="noreferrer" href={props.path.teamDiscounts}>Team discounts</a>
                </li>
              </ul>
              <ul>
                <div className={styles.h4}>Resource</div>
                <li className={styles.li}>
                  <Link to={props.path.privacyPolicy}>Privacy policy</Link>
                </li>
                <li className={styles.li}>
                  <Link to={props.path.termsNconditions}>Terms &amp; conditions</Link>
                </li>
                <li className={styles.li}>
                  <Link to={props.path.contactUs}>Contact us</Link>
                </li>
                <li className={styles.li}>
                  <Link to={props.path.employment}>Employment</Link>
                </li>
              </ul>
            </nav>
          </section>
          <div className={styles.copyright}>
            <div className={styles.h5}>
              &copy; Anytime Soccer Training, {fullYear}. All Rights Reserved
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );

  return <>{!props.welcomePage || !location ? changeFooter() : null}</>;
}

export default Footer;
