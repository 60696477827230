import React from 'react';
import styles from './CancelSubscription.module.sass';


const InputRadio = ({ text, clearRadio, isChecked, setChecked, setUnsubscribeReason, setValueTextAria }) => {

return (
    <label className={styles.radioBtnContainer}>{text}
        <input type="radio" name="radio" checked={isChecked} onChange={() => {
            clearRadio();
            setChecked(true)
            setValueTextAria("")
            setUnsubscribeReason(text);
        }
        } />
        <span className={styles.checkmark} />
    </label>
)
};

export default InputRadio;


