const SET_BOOKMARKS = 'SET_BOOKMARKS';
const ADD_BOOKMARK = 'ADD_BOOKMARK';
const DELETE_BOOKMARK = 'DELETE_BOOKMARK';

const initialState = {
  data: [],
};

const videoCategoryBookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKMARK:
      return { ...state, data: [...state.data, action.bookmarksData.data] };
    case SET_BOOKMARKS:
      return {
        data: action.bookmarksData.data,
      };
    case DELETE_BOOKMARK:
      return {
        ...state,
        data: state.data.filter((bookmark) => bookmark.actionTypeCategory !== action.bookmarksData),
      };
    default:
      return state;
  }
};

export const setBookmarksData = (bookmarksData) => ({ type: SET_BOOKMARKS, bookmarksData });
export const addBookmarkData = (bookmarksData) => ({ type: ADD_BOOKMARK, bookmarksData });
export const deleteBookmarkData = (bookmarksData) => ({ type: DELETE_BOOKMARK, bookmarksData });

export default videoCategoryBookmarkReducer;
