import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../config/routes.json';

/** TODO: for React Router v6 - Redirect Component must be replaced by Navigate one */ 
function PrivateRouteToTeamDashboard({ render, path, exact, currentTeamId }) {
    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps) => (
                currentTeamId
                    ? render(routeProps)
                    : <Redirect to={routes.dashboardChild.teams} />
            )}
        />
    );
}

const mapStateToProps = (state) => ({
    currentTeamId: state.teamReducer.currentTeamId,
});

export default connect(mapStateToProps, null)(PrivateRouteToTeamDashboard);