import React, { useState } from 'react';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { PopupStatusProfilePage } from '../constants';
import { updateProfileName } from '../../../api/teams-api';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { updateProfileNameDataAction } from '../../../Redux/profileDataReducer';


export function ModalWindowPopupEditMember({setPopupStatusProfilePage, profileData, teamId}) {
  const [newFirstName, setNewFirstName ] = useState(profileData.firstName)
  const [newLastName, setNewLastName ] = useState(profileData.lastName)
  const dispatch = useDispatch();
  const handleClosePopupButtonClick = () => {
    setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
  };

  const handleActivationPopupButtonClick = () => {
    updateProfileName({ teamId: parseInt(teamId), newFirstName: newFirstName,  newLastName: newLastName} ).then((response) => {
      if (response.ok) {
        response.json().then(stats => {
          dispatch(updateProfileNameDataAction(stats));
        })
        setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
      } else {
        message.error('Something went wrong. Please try again later.')
      }
    })
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 className={styles.invite_popup__title}>Edit Member</h3>
        <div className={styles.container}>
          <form className={styles.form}>
          <label className={styles.form__label}>
            First Name
            <input
              type="text"
              defaultValue={profileData.firstName}
              onChange={(e) => setNewFirstName(e.target.value)}
              className={styles.form__input}
            />
          </label>
            <label className={styles.form__label}>
              Last Name
              <input
                type="text"
                defaultValue={profileData.lastName}
                onChange={(e) => setNewLastName(e.target.value)}
                className={styles.form__input}
              />
              </label>
            <div className={styles.form__label__checkbox} >
              <input
                type="checkbox"
                id="cb1"
              />
              <label htmlFor="cb1">Receive team emails & training start</label>
            </div>
          </form>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopupButtonClick}
              width={'35rem'}
            />
            <RedButton
              width={'35rem'}
              handleButtonClick={handleActivationPopupButtonClick}
              title={'Save'} />
          </div>
        </div>
      </section>
    </>
  );
}