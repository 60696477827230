import React from 'react';
import styles from './SubscriptionDetailsButton.module.sass';
import { message } from 'antd';

/** If Subscription Details is popup */
export function SubscriptionDetailsButton(props) {
    const { handleButtonClick } = props;

    const handleClick = () => {
        if (typeof handleButtonClick === 'function') {
            handleButtonClick();
        } else {
            message.info('Coming Soon...');
        }
    };

    return (
        <button
            className={styles.button_subscription}
            onClick={handleClick}
        >
            Subscription Details
        </button>
    );
}