import React, { useState } from 'react';
import styles from './NavPanelMobile.module.sass';
import dashboardBtn from '../../../../asset/dashboard/dashboardBtn.svg';
import NavPanelMobileMenu from '../NavPanelMobileMenu/NavPanelMobileMenu';
import UpgradePopUp from '../../../DashboardChild/UpgradePopUp/UpgradePopUp';
import TrialPeriodSuggestionPopup from '../../TrialPeriodSuggestionPopup/TrialPeriodSuggestionPopup';
import activeMenuBtn from '../../../../asset/active_menu.webp';
let freeTrialDaysLeft = null;

const NavPanelMobile = (props) => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showTrialPeriodPopup, setShowTrialPeriodPopup] = useState(false);
  freeTrialDaysLeft = props.freeTrialDaysLeft;

  const closeMobileMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  return (
    <>
      {showUpgradePopup && (
        <UpgradePopUp
          setShowUpgradePopup={setShowUpgradePopup}
          setIsModalVisible={setIsModalVisible}
        />
      )}
      {showTrialPeriodPopup && (
        <TrialPeriodSuggestionPopup
          setShowTrialPeriodPopup={setShowTrialPeriodPopup}
          setIsStartTrial={props.setIsStartTrial}
          closeMobileMenu={closeMobileMenu}
          setContentLoaded={props.setContentLoaded}
        />
      )}
      <section className={styles.section}>
        <h2 className={styles.section__title}>Dashboard</h2>
        {freeTrialDaysLeft > 0 && (
          <p className={styles.trial_activated}>Trial: {freeTrialDaysLeft} Days Left</p>
        )}
        <div className={styles.menuButton}>
          <img
            src={isMobileMenu ? activeMenuBtn : dashboardBtn}
            alt="menu button"
            onClick={closeMobileMenu}
          />
        </div>
        {isMobileMenu && (
          <NavPanelMobileMenu
            setUserDataStep1={props.setUserDataStep1}
            setUserDataStep3={props.setUserDataStep3}
            signUpData={props.signUpData}
            playerData={props.playerData}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            setShowUpgradePopup={setShowUpgradePopup}
            setShowTrialPeriodPopup={setShowTrialPeriodPopup}
            trialStatus={props.trialStatus}
          />
        )}
      </section>
    </>
  );
};

export default NavPanelMobile;
