import React from 'react';
import styles from './NoCards.module.sass';

export function NoCards(props) {
  return (
    <article className={styles.empty_section}>
      <img
        src="/img/teams/no_active_team.webp"
        className={styles.empty_section__picture}
        alt="Member of the team"
      />
      <p className={styles.empty_section__description}>{props.children}</p>
    </article>
  );
}
