import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export function ModalAssignment({ manageTeams, onClose, onSubmit, initFav, selectedItem }) {
  const { data } = useSelector(state => state.listViewReducer);
  const homeworkData = useMemo(() => {
    return data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.map((item) => ({
      name: item.actionType,
      data: item.children[0].children
    }));
  }, [data]);
  
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [addToFavorites, setAddToFavorites] = useState(initFav);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(manageTeams.length / 6);

  const handleChangePage = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setCurrentPage(newPage);
    }
  };

  const currentData = () => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
    return manageTeams.slice(startIndex, endIndex);
  };

  const checkHomework = useCallback(
    (teamId) => {
      const data = homeworkData.find(item => item.name === `cat-${teamId}`);
      return data?.data.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === selectedItem.subTitle;
        } else {
          return item.title === selectedItem.title;
        }
      });
    },
    [homeworkData, selectedItem],
  );
  
  const handleTeamCheckboxChange = (team) => {
    setSelectedTeams((prevSelectedTeams) => {
      if (prevSelectedTeams.some(t => t.id === team.id)) {
        return prevSelectedTeams.filter((t) => t.id !== team.id);
      } else {
        return [...prevSelectedTeams, team];
      }
    });
  };
  
  const handleMarkAllTeams = () => {
    setSelectedTeams(manageTeams);
  };

  const handleFavoritesCheckboxChange = () => {
    setAddToFavorites((prevValue) => !prevValue);
  };

  const handleClearAll = () => {
    setSelectedTeams([]);
    setAddToFavorites(initFav);
  };

  const opacityArrow = extreme => {
    if (currentPage === extreme) {
      return { opacity: '0.4' };
    } else return { opacity: '1' };
  };

  useEffect(() => {
    let isActive = true

    if (isActive) {
      const teamsToAdd = manageTeams.filter(team => checkHomework(team.id));
      setSelectedTeams(teamsToAdd);
    }
  
    return () => {
      isActive = false
    }
  }, [manageTeams, checkHomework])


  const paginationItems = (maxPage) => {
    const pages = [];
    if (maxPage <= 6) {
      for (let i = 1; i <= maxPage; i++) {
        pages.push(
          <PaginationItem key={i} active={currentPage === i}>
            <PaginationLink onClick={() => handleChangePage(i)} style={{
              fontSize: '13px',
              padding: '5px 10px'
            }}>
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      pages.push(
        <PaginationItem key={1} active={currentPage === 1}>
          <PaginationLink onClick={() => handleChangePage(1)} style={{
            fontSize: '13px',
            padding: '5px 10px'
          }}>
            1
          </PaginationLink>
        </PaginationItem>
      );
  
      let startPage = 2;
      let endPage = 5;
  
      if (currentPage > 3) {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
  
      if (currentPage > maxPage - 3) {
        startPage = maxPage - 4;
        endPage = maxPage - 1;
      }
  
      if (startPage > 2) {
        pages.push(<PaginationItem key="startEllipsis" disabled><PaginationLink style={{
          fontSize: '13px',
          padding: '5px 10px'
        }}>...</PaginationLink></PaginationItem>);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <PaginationItem key={i} active={currentPage === i}>
            <PaginationLink onClick={() => handleChangePage(i)} style={{
              fontSize: '13px',
              padding: '5px 10px'
            }}>
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
  
      if (endPage < maxPage - 1) {
        pages.push(<PaginationItem key="endEllipsis" disabled><PaginationLink style={{
          fontSize: '13px',
          padding: '5px 10px'
        }}>...</PaginationLink></PaginationItem>);
      }
  
      pages.push(
        <PaginationItem key={maxPage} active={currentPage === maxPage}>
          <PaginationLink onClick={() => handleChangePage(maxPage)} style={{
            fontSize: '13px',
            padding: '5px 10px'
          }}>
            {maxPage}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton handleClosePopupButtonClick={onClose} />
        <h3 style={{ padding: '0 7rem' }} className={styles.invite_popup__title}>Assignment</h3>
        <div className={styles.container}>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h5 style={{ fontWeight: 600, 
              fontSize: '16px', }}>Add to Teams ({selectedTeams.length} added)</h5>
            <button style={{
              border: 'none',
              outline: 'none',
              background: 'none',
              fontWeight: 600,
              fontSize: '15px',
              color: '#e1343f',
              textDecoration: 'underline',
            }} onClick={handleMarkAllTeams}>MARK ALL TEAMS</button>
          </div>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '1rem',
            marginTop: '1rem'
          }}>
            {currentData().map((item) => (
              <div className={styles.form__label__checkbox} key={item.id} style={{
                display: 'flex'
              }}>
                <input
                  type="checkbox"
                  id={item.id}
                  checked={selectedTeams.some(team => team.id === item.id)}
                  onChange={() => handleTeamCheckboxChange(item)}
                />
                <label htmlFor={item.id}>{item.name}</label>
              </div>
            ))}
          </div>
          {manageTeams.length > 6 && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
              <Pagination>
                {maxPage > 6 && (
                  <PaginationItem disabled={currentPage === 1} >
                    <PaginationLink first onClick={() => handleChangePage(1)} style={{...opacityArrow(1),
                      fontSize: '13px', 
                      padding: '5px 10px'}} />
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink previous onClick={() => handleChangePage(currentPage - 1)} style={{...opacityArrow(1),
                    fontSize: '13px', 
                    padding: '5px 10px'}} />
                </PaginationItem>
                {paginationItems(maxPage)}
                <PaginationItem>
                  <PaginationLink next onClick={() => handleChangePage(currentPage + 1)} style={{...opacityArrow(maxPage),
                    fontSize: '13px', 
                    padding: '5px 10px'}} />
                </PaginationItem>
                {maxPage > 6 && (
                  <PaginationItem disabled={currentPage === maxPage} >
                    <PaginationLink last onClick={() => handleChangePage(maxPage)} style={{...opacityArrow(1),
                      fontSize: '13px', 
                      padding: '5px 10px'}} />
                  </PaginationItem>
                )}
              </Pagination>
            </div>
          )}
        </div>
        <div className={styles.container} style={{ paddingTop: '0px' }}>
          <div style={{ borderBottom: '0.1rem solid #bfcdd9', marginBottom: '2rem' }} />
          <div className={styles.form__label__checkbox} style={{
            display: 'flex'
          }}>
            <input
              type="checkbox"
              id="favorite"
              checked={addToFavorites}
              onChange={handleFavoritesCheckboxChange}
            />
            <label htmlFor="favorite">Add to Favorites</label>
          </div>
          <div className={styles.buttons}>
            <button onClick={handleClearAll} style={{
              border: 'none',
              outline: 'none',
              background: 'none',
              fontWeight: 600,
              fontSize: '15px',
              color: '#78a3be',
              textDecoration: 'underline',
              flexShrink: 0
            }}>Clear All</button>
            <BorderedButton
              handleButtonClick={onClose}
              width={'35rem'}
            />
            <RedButton
              width={'35rem'}
              handleButtonClick={() => onSubmit(selectedTeams, addToFavorites)}
              title={'Save'}
            />
          </div>
        </div>
      </section>
    </>
  );
}
