import React from 'react';
import styles from './Parent.module.sass';
import NavPanel from '../NavPanel/NavPanel';
import Table from '../Table/Table';
import { withRoleRedirectComp } from '../../../hoc/redirectComp';
import { haveNoLoginRedirect } from '../../../hoc/redirectComp';
import { compose } from 'redux';

function Parent(props) {
  return (
    <>
      <main className={styles.main}>
        <NavPanel
          playerData={props.playerData}
          setUserDataStep1={props.setUserDataStep1}
          setUserDataStep3={props.setUserDataStep3}
          signUpData={props.signUpData}
          amountPlayer={props.amountPlayer}
        />
        <Table
          playerData={props.playerData}
          setRole={props.setRole}
          setOnlyChildData={props.setOnlyChildData}
        />
      </main>
    </>
  );
}

export default compose(
  withRoleRedirectComp,
  haveNoLoginRedirect,
)(Parent);
