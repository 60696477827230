import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './CancelSubscription.module.sass';
import CancelSubBody from './CancelSubBody';
import { message } from 'antd';
import { cancelSubscriptionForChild } from '../../../api/api';
import StepOneBody from './StepOneBody';

const CancelSubscription = (props) => {
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState(0);
  const [unsubscribeReason, setUnsubscribeReason] = useState('');
  const [valueTextAria, setValueTextAria] = useState('');
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState([]);

  const checkChildStatus = () => {
    for (const key in props.childData) {
      if (props.childData[key].status === 'active') {
        return true;
      }
    }
    return false;
  };

  const isCanceledPlayers = checkChildStatus();

  const createRequestObject = (reason) => {
    if (userName[1] !== 'ALL') {
      return {
        [`child${userName[0]}`]: {
          username: userName[1],
          reason: reason,
        },
      };
    } else {
      const result = {};
      getUserName().forEach(
        (a, index) =>
          (result[`child${index + 1}`] = {
            username: a,
            reason: reason,
          }),
      );
      return result;
    }
  };

  const getUserName = () => {
    const numOfAllChild = props.childData.childrenCount;
    const resultArr = [];
    for (let i = 1; i <= numOfAllChild; i++) {
      resultArr.push(props.childData[`child${i}`].username);
    }
    return resultArr;
  };

  const toggle = () => {
    if (!isCanceledPlayers) {
      return message.error('You do not have any active subscriptions');
    }

    setError(false);
    setValueTextAria('');
    setUnsubscribeReason('');
    setUserName([]);
    setStep(0);
    setModal((modal) => !modal);
  };

  const changeTextAria = (e) => {
    setError(false);
    setUnsubscribeReason('');
    setValueTextAria(e.currentTarget.value);
  };

  const submit = () => {
    let reason;
    if (unsubscribeReason && unsubscribeReason !== 'Other') {
      reason = unsubscribeReason;
    } else if (valueTextAria) {
      reason = valueTextAria;
    } else {
      setError(true);
    }
    if (reason) {
      const result = createRequestObject(reason);
      try {
        cancelSubscriptionForChild(result).then((response) => requestHandler(response));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const requestHandler = (response) => {
    if (response.status === 200) {
      setModal((modal) => !modal);
      props.update();
      message.success('Your application is accepted');
    }
  };

  const changeStep = () => {
    if (step === 1 && userName) {
      setStep(step + 1);
    } else if (step === 0) {
      setStep(step + 1);
    } else {
      message.error('Please choice some user name');
    }
  };

  return (
    <div>
      <Button onClick={toggle}>{props.title}</Button>
      <Modal isOpen={modal} toggle={toggle} className={styles.modal}>
        <ModalHeader toggle={toggle}>
          <span>{props.title}</span>
        </ModalHeader>
        <form>
          <ModalBody className={styles.formStyle}>
            {step === 0 ? (
              <h5>Do you really want to cancel your subscription</h5>
            ) : step === 1 ? (
              <StepOneBody
                getUserName={getUserName}
                setUserName={setUserName}
                childStatus={props.childData}
              />
            ) : (
              <CancelSubBody
                changeTextAria={changeTextAria}
                valueTextAria={valueTextAria}
                setUnsubscribeReason={setUnsubscribeReason}
                setValueTextAria={setValueTextAria}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {error ? (
              <div className={styles.errStep2}>Please Select Some Reason Of Unsubscribe</div>
            ) : (
              ''
            )}
            <Button
              color="secondary"
              className={styles.acceptBtn}
              onClick={step <= 1 ? changeStep : submit}
            >
              {step === 0 ? 'Yes' : 'Submit'}
            </Button>
            <Button color="primary" className={styles.cancelBtn} onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default CancelSubscription;
