import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './ImageCropper.module.sass';
import { message } from 'antd';

export function ImageCropper(props) {
  const {
    isClearImage,
    setIsClearImage,
    requestData,
    setRequestData,
    title = 'Upload File',
  } = props;

  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [output, setOutput] = useState(null);
  const imgRef = useRef(null);
  const uploadInputRef = useRef();
  let fileType = 'image/jpg';

  useEffect(() => {
    if (isClearImage) {
      setImgSrc(null);
      setOutput(null);
      setIsClearImage(false);
      uploadInputRef.current.value = '';
    }
  }, [isClearImage]);

  const handleInputFileChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      message.error('Your photo can not be more than 5Mb');
      return;
    }
    if (e.target.files && e.target.files.length > 0) {
      setCrop(null);
      setOutput(null);
      // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result);
        if (requestData.picture !== undefined) {
          delete requestData.picture;
        }
      });
      setRequestData({ ...requestData, picture: '', image: e.target.files[0] });
      fileType = e.target.files[0].name;
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cropImageNow = () => {
    const image = imgRef.current;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width;
    canvas.height = crop.height;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    if (crop.width / crop.height > 1.5 || crop.width / crop.height < 0.5) {
      message.warning({
        content:
          'We advise you to choose a photo that is close to a square or avatar picture shape',
        duration: 5,
      });
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    setOutput(base64Image);

    canvas.toBlob((blob) => {
      setRequestData({ ...requestData, picture: '', image: blob });
    }, fileType);
    ctx.restore();
  };

  return (
    <div className={styles.imageCropper}>
      <label className={`${styles.button} ${styles.upload_button}`}>
        {title}
        <input
          id="input-file"
          className={styles.input_file}
          ref={uploadInputRef}
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/gif"
          onChange={handleInputFileChange}
        />
      </label>
      {imgSrc && (
        <section className={styles.crop}>
          {Boolean(imgSrc) && (
            <ReactCrop crop={crop} onChange={setCrop}>
              <img className={styles.image_preview} ref={imgRef} alt="Crop me" src={imgSrc} />
            </ReactCrop>
          )}
          <br />
          <button
            type="button"
            className={`${styles.button} ${styles.crop_button}`}
            onClick={cropImageNow}
          >
            Crop
          </button>
        </section>
      )}
      <section className={styles.preview}>
        {output && <img className={styles.image_preview} src={output} alt="" />}
      </section>
    </div>
  );
}
