import React, { useState }  from 'react';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopapUpdateRole.module.sass';
import { ClosePopupButton } from '../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../../commonComponents/BorderedButton/BorderedButton';
import { PopupStatusProfilePage } from '../../constants';
import { updateRoleProfile } from '../../../../api/teams-api';
import { message } from 'antd';
import { updateProfileRoleDataAction } from '../../../../Redux/profileDataReducer';
import { useDispatch } from 'react-redux';


export function ModalWindowPopupUpdateRole({setPopupStatusProfilePage, profileData, teamId, childId}) {
  const [radioValue, setRadioValue] = useState(profileData.role);
  const dispatch = useDispatch();

  const handleClosePopupButtonClick = () => {
    setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
  };

  function changeRadioValue(event) {
    setRadioValue(event.target.value);
  }

  const handleActivationPopupButtonClick = () => {
    updateRoleProfile({ childId: parseInt(childId) , newRole: radioValue, teamId: parseInt(teamId) } ).then((response) => {
      if (response.ok) {
        response.json().then(stats => {
          dispatch(updateProfileRoleDataAction(stats));
        })
        setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
      } else {
        message.error('Something went wrong. Please try again later.')
      }
    })
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 className={styles.invite_popup__title}>Update Role</h3>
        <div className={styles.container}>
          <form className={styles.form}>
            <span className={styles.invite_popup__label}>
              Update Role:
            </span>
            <div className={styles.form_radio}>
              <label>
              <input checked={radioValue === 'player'} id="radio-1" type="radio" name="radio"
                     value="player"
                     onChange={(event) => changeRadioValue(event)}/>
                <div className={styles.form_radio_label}>
                  <span>Player Only</span>
                   <br/>
                  <i className={styles.form_radio_redText} >Included in team stats but does not have admin rights</i>
                </div>
              </label>
            </div>

            <div className={styles.form_radio}>
              <label>
                <input checked={radioValue === 'player_manager'} id="radio-2" type="radio"
                       name="radio"
                       value="player_manager"
                       onChange={(event) => changeRadioValue(event)}/>
                <div className={styles.form_radio_label}>
                  <span>Player/Manager</span>
                  <br/>
                  <i className={styles.form_radio_redText} >Included in team stats and has admin rights</i>
                </div>
              </label>
            </div>

            <div className={styles.form_radio}>
              <label>
                <input checked={radioValue === 'homework_manager'}  id="radio-3" type="radio" name="radio"
                       value="homework_manager"
                       onChange={(event) => changeRadioValue(event)}/>
                <div className={styles.form_radio_label}>
                  <span>Homework Manager</span>
                  <br/>
                  <i className={styles.form_radio_redText} >Able to add homework and has admin right</i>
                </div>
              </label>
            </div>

            <div className={styles.form_radio}>
              <label>
                <input checked={radioValue === 'manager'}  id="radio-3" type="radio" name="radio"
                       value="manager"
                       onChange={(event) => changeRadioValue(event)}/>
                <div className={styles.form_radio_label}>
                  <span>Manager Only</span>
                  <br/>
                  <i className={styles.form_radio_redText} >Has admin right but is not included in team stats</i>
                </div>
              </label>
            </div>

          </form>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopupButtonClick}
              width={'40rem'}
            />
            <RedButton
              width={'40rem'}
              handleButtonClick={handleActivationPopupButtonClick}
              title={'Save'} />
          </div>
        </div>
      </section>
    </>
  );
}