const SET_PLAYER_TOTAL_PRICE = 'SET_PLAYER_TOTAL_PRICE';

const initialState = {
  playerTotalPrice: 0,
};

const addPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER_TOTAL_PRICE:
      return {
        ...state,
        playerTotalPrice: action.playerTotalPrice,
      };
    default:
      return state;
  }
};

export const setPlayerTotalPrice = playerTotalPrice => ({
  type: SET_PLAYER_TOTAL_PRICE,
  playerTotalPrice,
});

export default addPlayerReducer;
