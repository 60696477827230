const ActionType = {
  SET_ACTIVE_TEAMS: 'SET_ACTIVE_TEAMS',
  SET_PENDING_TEAMS: 'SET_PENDING_TEAMS',
  SET_CURRENT_TEAM: 'SET_CURRENT_TEAM',
  SET_CURRENT_TEAM_ID: 'SET_CURRENT_TEAM_ID',
  SET_CURRENT_TEAM_STATS: 'SET_CURRENT_TEAM_STATS',
  SET_GOAL_STATS: 'SET_GOAL_STATS',
  SET_PLAYERS: 'SET_PLAYERS',
  SET_USER_ACTIVE_TEAMS: 'SET_USER_ACTIVE_TEAMS'
};

const initialState = {
  currentTeamId: null,
  activeTeams: {
    activeTeams: [],
    currentPage: 1,
    totalPage: 0,
  },
  userActiveTeams: [],
  pendingTeams: {
    inviteTeams: [],
    currentPage: 1,
    totalPage: 0,
  },
  currentTeam: {
    name: '',
    imgUrl: '',
  },
  currentTeamStats: {
    players: [],
    managers: [],
    homeworkManagers: [],
    teamSummary: {
      totalVideo: 0,
      totalTime: 0,
      goalCompleted: false,
    },
  },
  goalStats: {
    teamSize: 0,
    teamGoalStatus: {
      teamGoalStatus: false,
      totalVideo: 0,
      totalTime: 0,
    },
    totalVideo: 0,
    totalTime: 0,
    playerStats: {
      highest: {},
      lowest: {},
      average: {
        name: 'Team',
        countVideo: 4,
        timeVideo: 1200,
      },
    },
  },
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_TEAMS:
      return {
        ...state,
        activeTeams: action.payload,
      };

    case ActionType.SET_USER_ACTIVE_TEAMS:
      return {
        ...state,
        userActiveTeams: action.payload,
      };
    case ActionType.SET_PENDING_TEAMS:
      return {
        ...state,
        pendingTeams: action.payload,
      };
    case ActionType.SET_CURRENT_TEAM_ID:
      return {
        ...state,
        currentTeamId: action.payload,
      };
    case ActionType.SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.payload,
      };
    case ActionType.SET_CURRENT_TEAM_STATS:
      return {
        ...state,
        currentTeamStats: action.payload,
      };
    case ActionType.SET_GOAL_STATS:
      return {
        ...state,
        goalStats: action.payload,
      };
    case ActionType.SET_PLAYERS:
      return {
        ...state,
        currentTeamStats: {
          ...state.currentTeamStats,
          players: action.payload,
        },
      };
    default:
      return state;
  }
};

export const setActiveTeams = activeTeams => ({
  type: ActionType.SET_ACTIVE_TEAMS,
  payload: activeTeams,
});

export const setUserActiveTeams = activeTeams => ({
  type: ActionType.SET_USER_ACTIVE_TEAMS,
  payload: activeTeams,
});

export const setPendingTeams = pendingTeams => ({
  type: ActionType.SET_PENDING_TEAMS,
  payload: pendingTeams,
});
export const setCurrentTeamId = currentTeamId => ({
  type: ActionType.SET_CURRENT_TEAM_ID,
  payload: currentTeamId,
});

export const setCurrentTeam = currentTeam => ({
  type: ActionType.SET_CURRENT_TEAM,
  payload: currentTeam,
});

export const setCurrentTeamStats = currentTeamStats => ({
  type: ActionType.SET_CURRENT_TEAM_STATS,
  payload: currentTeamStats,
});

export const setGoalStats = goalStats => ({
  type: ActionType.SET_GOAL_STATS,
  payload: goalStats,
});

export const setPlayers = players => ({
  type: ActionType.SET_PLAYERS,
  payload: players,
});

export default teamReducer;
