import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import path from '../../../../config';
import mainRoute from '../../../../mainRoute';
import * as Yup from 'yup';
import styles from './Steps.module.sass';
import Cookies from 'universal-cookie';
import countries from './CountryList/ArrayCountry';
import { message } from 'antd';
import { checkUnicValuesInBase, profileInfoRequest, stripeTokenHandler } from '../../../../api/api';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import paymentSystems from '../../../../asset/step3img/paymentSystems.webp';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { card3, card2, CARD_ELEMENT_OPTIONS } from '../../../../constants/paymentTemplate';

function Step3({
  signUpFieldValues,
  finalPrice,
  setUserData,
  setLogin,
  setRole,
  emailAndUsername,
  setUserDataStep3,
  signUpData,
}) {
  const { step3 } = signUpFieldValues;
  const cookies = new Cookies();
  const history = useHistory();

  const [toggPassConfirmCheckState, setToggPassConfirmCheckState] = useState(false);
  const [toggShowConfirmOrStripe, setToggShowConfirmOrStripe] = useState(false);
  const [totalPrice, setTotalPrice] = useState(finalPrice);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [stopSubmitBtn, setStopSubmitBtn] = useState(false);
  const [toggSubmitBtnSpinner, setToggSubmitBtnSpinner] = useState(false);
  const [stopApplyBtn, setStopApplyBtn] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 30, color: 'red', marginTop: '1.5rem', marginLeft: '9rem' }}
      spin
    />
  );

  useScrollToTop();

  const clearCardElements = (elementsToClear) => {
    const elementsArray = elementsToClear._elements;
    elementsArray.forEach((element) => {
      element.clear();
    });
  };

  const paymentErrorHandler = (errorMessage) => {
    setToggSubmitBtnSpinner(false);
    clearCardElements(elements);
    setToggPassConfirmCheckState(false);
    if (errorMessage) {
      message.error({
        content: errorMessage,
        duration: 6,
      });
    }
  };

  const stripeCheckoutHandler = (response) => {
    response.json().then((result) => {
      if (result.raw && result.raw.type.localeCompare('card_error') === 0) {
        paymentErrorHandler(result.raw.message);
      }
      if (response.status === 200) {
        cookies.set('finaleToken', `${result.resFirstSave.token}`, {
          path: '/',
          maxAge: 3600 * 30,
        });
        try {
          profileInfoRequest().then((response) => requestHandler(response));
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        setUserData(result);
        setRole('parent');
        setLogin(true);
        history.replace('/auth/thank');
      }
    });
  };

  const unitSearchHandler = async (response) => {
    const responseJson = await response.json();
    if (responseJson.result === true) {
      setStopSubmitBtn(false);
      setToggShowConfirmOrStripe(!toggShowConfirmOrStripe);
      setUserDataStep3({
        address: formik.values.address,
        country: formik.values.country,
        city: formik.values.city,
        region: formik.values.region,
        postalCode: formik.values.postalCode,
      });
      setToggPassConfirmCheckState(!toggPassConfirmCheckState);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          address: {
            country: formik.values.country,
            city: formik.values.city,
            state: formik.values.region,
            line1: formik.values.address,
            postal_code: formik.values.postalCode,
          },
          email: signUpData.userData.email,
          name: signUpData.userData.firstname,
        },
        card: elements.getElement(CardNumberElement),
      });

      if (!error) {
        const { id } = paymentMethod;
        try {
          stripeTokenHandler({ id, signUpData }).then((response) =>
            stripeCheckoutHandler(response),
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        paymentErrorHandler(error.message);
      }
    } else {
      setStopSubmitBtn(stopSubmitBtn);
      setToggSubmitBtnSpinner(false);
      message.error('Payment is not success');
    }
  };

  const formik = useFormik({
    initialValues: step3
      ? step3
      : {
          address: '',
          country: 'US',
          city: '',
          region: '',
          postalCode: '',
          discountCoupon: '',
        },
    validationSchema: Yup.object({
      address: Yup.string().trim().required('Required'),
      country: Yup.string().trim().required('Required'),
      city: Yup.string().trim().required('Required'),
      region: Yup.string().trim().required('Required'),
      postalCode: Yup.string().trim().required('Required'),
    }),

    onSubmit: async () => {
      setToggSubmitBtnSpinner(true);
      if (!stopSubmitBtn) {
        try {
          checkUnicValuesInBase(emailAndUsername).then((response) => unitSearchHandler(response));
        } catch (e) {
          throw new Error(e);
        }
      }
    },
  });

  const submitCoupon = async (e) => {
    e.preventDefault();
    const stripeCus = signUpData.userData.stripeCus;
    const count = signUpData.userData.count;

    if (formik.values.discountCoupon) {
      setStopApplyBtn(!stopApplyBtn);
      try {
        const response = await fetch(`${mainRoute.route + path.signUpStep3Coupon}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ promocode: formik.values.discountCoupon, stripeCus, count }),
        });
        const responseJson = await response.json();
        try {
          if (responseJson.totalTarifs.subtotal === 0) {
            setSubTotalPrice('0000');
          } else {
            setSubTotalPrice(responseJson.totalTarifs.subtotal);
          }
        } catch (e) {
          throw new Error(e);
        }
        if (responseJson.totalTarifs) {
          setUserDataStep3({ promocode: formik.values.discountCoupon });
          setStopApplyBtn(!stopApplyBtn);
        } else {
          setStopApplyBtn(stopApplyBtn);
        }
      } catch (e) {
        throw new Error(e);
      }
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
        <h5>Step 3 of 3</h5>
        <h3>Billing Information</h3>
        <h6>Fields marked with * are mandatory</h6>
        <div className={styles.section}>
          <div className={styles.col}>
            <label htmlFor="address">Address *</label>
            <input
              className={
                formik.touched.address && formik.errors.address ? styles[formik.errors.address] : ''
              }
              name="address"
              id="address"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
          </div>

          <div className={styles.col}>
            <label htmlFor="country">Country *</label>
            <select
              name="country"
              id="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
            >
              {countries.map((item) => {
                return (
                  <option key={item.name} value={item.code}>
                    {' '}
                    {item.name}{' '}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={styles.col}>
            <label htmlFor="city">City *</label>
            <input
              className={
                formik.touched.city && formik.errors.city ? styles[formik.errors.city] : ''
              }
              name="city"
              id="city"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="region">Region/State *</label>
            <input
              className={
                formik.touched.region && formik.errors.region ? styles[formik.errors.region] : ''
              }
              name="region"
              id="region"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.region}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="postalCode">Zip/Postal Code *</label>
            <input
              className={
                formik.touched.postalCode && formik.errors.postalCode
                  ? styles[formik.errors.postalCode]
                  : ''
              }
              name="postalCode"
              id="postalCode"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
            />
          </div>
        </div>

        <h3 className={styles.PaymentInfoTitle}>Payment Information</h3>
        <p className={styles.PaymentInfoSubtitle}>Fields marked with * are mandatory</p>
        <div className={styles.parent_form}>
          <label htmlFor="card-element" className={styles.cardNumText}>
            Credit card number <span className={styles.redStar}>*</span>
          </label>
          <CardNumberElement
            id="card-element"
            options={CARD_ELEMENT_OPTIONS}
            className={styles.card}
          />

          <div className={styles.cardContainer}>
            <div className={styles.cardContainer1}>
              <label htmlFor="card-element-2" className={styles.cardNumText}>
                Expiration <span className={styles.redStar}>*</span>
              </label>
              <CardExpiryElement id="card-element-2" options={card2} className={styles.card1} />
            </div>

            <div className={styles.cardContainer1}>
              <label htmlFor="card-element-3" className={styles.cardNumText2}>
                CVC <span className={styles.redStar}>*</span>
              </label>
              <CardCvcElement id="card-element-3" options={card3} className={styles.card2} />
            </div>
          </div>
        </div>

        <img src={paymentSystems} alt="Visa MsterCard Pictures" className={styles.picCardsStyle} />
        <h3>Order Summary</h3>
        <label htmlFor="discountCoupon" className={styles.cardNumTextDiscCoupon}>
          Discount Coupon
        </label>
        <div className={styles.container_coupon}>
          <input
            className={
              formik.touched.discountCoupon && formik.errors.discountCoupon
                ? styles[formik.errors.discountCoupon]
                : ''
            }
            name="discountCoupon"
            id="discountCoupon"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.discountCoupon.replace(/[\s]/g, '')}
          />

          <button className={styles.buttonDefault} onClick={submitCoupon}>
            Apply
          </button>
        </div>

        <div className={styles.totalAndBtnPay}>
          <div className={styles.divTotal}>
            <hr className={subTotalPrice ? styles.totalPrice_hr : 'display:none'} />
            <div className={styles.divTotalAndPrice}>
              <div className={styles.totalPrice}>
                <span>Total:</span>
                <span>${totalPrice / 100}</span>
              </div>
              <div className={styles.subPrice}>
                <span className={styles.subPrice_firstspan}>
                  {subTotalPrice ? 'Total with sale:' : ''}
                </span>
                <span className={styles.spanTotal}>
                  {subTotalPrice ? `$${subTotalPrice / 100}` : ''}
                </span>
              </div>
            </div>
          </div>

          {toggSubmitBtnSpinner ? (
            <Spin indicator={antIcon} />
          ) : (
            <button
              type="submit"
              disabled={toggPassConfirmCheckState}
              className={styles.pay_button}
              style={{ outline: 'none' }}
            >
              Order Now
            </button>
          )}
        </div>
        <div className={styles.placingText}>
          <h5 className={styles.placingTextTitle}>
            By placing an order you are stating that you agree to the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="../termsNconditions"
              className={styles.linkTerms}
            >
              Terms & Conditions
            </a>
          </h5>
        </div>
      </form>
    </>
  );
}

export default Step3;

////stripetokenhandler
