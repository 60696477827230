import React from 'react';
import styles from './RadioButton.module.sass';

export function RadioButton(props) {
    const { radioButton, setCheckedRadioButton, checkedRadioButton ,value, upgradeTeam } = props;

    const handleRadioButtonChange = (event) => {
      upgradeTeam ?
        setCheckedRadioButton({ number: event.target.value, price: value?.amount, priceId: value?.id})
        :
        setCheckedRadioButton(event.target.value);
    };

    return (
        <label key={radioButton} className={styles.radio_button_label}>
            <div className={styles.round}>
                {radioButton === checkedRadioButton &&
                    <div className={styles.round_checked}></div>}
              {radioButton === checkedRadioButton?.number &&
                <div className={styles.round_checked}></div>}
            </div>
            <input
                type="radio"
                onChange={handleRadioButtonChange}
                className={styles.radio_button}
                name="custom-radio-buttons"
                value={radioButton}
                checked={radioButton === checkedRadioButton}
            />
            {radioButton}
            {props.children}
        </label>
    );
};