import React, { useEffect, useState, useRef } from 'react';
import styles from './createTrainingGoalForm.module.sass';
import { createNewTrainingPlan, getTrainingPlan } from '../../api/api';
import { message } from 'antd';
import s from '../../app.module.sass';
import { Spinner } from 'reactstrap';
import {
  TrainingGoalStatus,
  GOAL_MAX_VIDEO_COUNT,
  MAX_DURATION_GOAL,
} from '../../constants/const.js';
import moment from 'moment';

function CreateTrainingGoalFormComponent({ hasTrainingGoal, setTrainingPlan, setTrainingGoal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNewGoalCreated, setNewGoalCreated] = useState(false);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    videoGoal: 5,
    targetDate: '',
    reward: '',
  });
  const formRef = useRef();

  useEffect(() => {
    if (isNewGoalCreated || hasTrainingGoal !== TrainingGoalStatus.CREATING) {
      getTrainingPlan().then((response) => {
        requestTrainingPlanHandler(response);
      });
    } else {
      setUpLoadedData(true);
    }
  }, [isNewGoalCreated]);

  useEffect(() => {
    if (isSubmitting) {
      createNewTrainingPlan(formData)
        .then(() => {
          formRef.current.reset();
          setIsSubmitting(false);
          setNewGoalCreated(true);
        })
        .catch(() => {
          message.error('Something went wrong. Please try to submit form again.');
          setIsSubmitting(false);
        });
    }
  }, [isSubmitting]);

  const requestTrainingPlanHandler = (response) => {
    response
      .json()
      .then((result) => {
        setTrainingPlan(result);
        setTrainingGoal(true);
      })
      .catch((e) => {
        setTrainingGoal(false);
        setUpLoadedData(true);
        console.error(e?.message || e);
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (checkDate(formData.targetDate)) {
      setIsSubmitting(true);
    } else {
      message.error('You can set the end date no later than next three months');
    }
  };
  const handleInputChange = (event) => {
    event.target.name === 'firstName'
      ? setFormData(Object.assign(formData, { name: event.target.value }))
      : setFormData(Object.assign(formData, { [event.target.name]: event.target.value }));
  };

  const handleEnterDownFormSubmit = (evt) => {
    if (evt.keyCode === 13) {
      handleFormSubmit(evt);
    }
  };

  const checkDate = (date) => {
    const dateArray = date.split('-');
    // result [YYYY, MM, DD];
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const duration = moment(date).diff(currentDate, 'months');

    return true
    // if (!duration) {
    //   return Number(dateArray[2]) >= currentDay;
    // } else {
    //   return duration < MAX_DURATION_GOAL && duration > 0;
    // }
  };

  const handleInputDateBlur = (event) => {
    if (!checkDate(event.currentTarget.value)) {
      message.error('You can set the end date no later than next twelve months.');
    }
  };

  return (
    <>
      {upLoadedData ? (
        <section className={styles.section}>
          <img
            src="/img/photo.webp"
            className={styles.section_img}
            aria-hidden="true"
            alt="Decorative picture"
          />
          <form
            action="#"
            aria-label="Training Plan Form"
            ref={formRef}
            onChange={handleInputChange}
            method="post"
            onSubmit={handleFormSubmit}
            onKeyDown={handleEnterDownFormSubmit}
            name="training-goal"
            className={styles.form}
          >
            <h1 className={styles.form__title}>Create My Training Goal</h1>
            <p className={styles.form__description}>
              Set your goal and agree on the reward with your parent or coach.
            </p>
            <label className={styles.form__item_label}>
              Full Name
              <input
                className={styles.form__item}
                type="text"
                name="firstName"
                placeholder="Your Name"
                required
              />
            </label>
            <label className={styles.form__item_label}>
              Video Goal
              <input
                className={styles.form__item}
                type="number"
                min="5"
                max={GOAL_MAX_VIDEO_COUNT}
                step="1"
                name="videoGoal"
                placeholder={`Min. 5 / Max. ${GOAL_MAX_VIDEO_COUNT}`}
                required
              />
            </label>
            <label className={styles.form__item_label}>
              Target Date
              <input
                className={styles.form__item}
                type="date"
                name="targetDate"
                onBlur={handleInputDateBlur}
                required
              />
            </label>
            <label className={styles.form__item_label}>
              Reward
              <input
                className={styles.form__item}
                type="text"
                name="reward"
                maxLength="40"
                placeholder="Reward?"
              />
              <span className={styles.form__item_description}>Maximum 40 characters</span>
            </label>
            <button type="submit" className={styles.button}>
              <span className={styles.button__title}>Get started</span>
            </button>
          </form>
        </section>
      ) : (
        <div style={{ background: '#f4fbff', minHeight: '50vh' }}>
          <Spinner color="primary" className={s.spinner} />
        </div>
      )}
    </>
  );
}

export default CreateTrainingGoalFormComponent;
