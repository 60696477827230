import React from 'react';
import { Link } from 'react-router-dom';
import styles from './FirstPlayerAddBlock.module.sass';

function FirstPlayerAddBlock() {
  const PATH = '/dashboard/createPlayerFree';

  return (
    <article className={styles.createPlayer}>
      <div className={styles.container}>
        <div className={styles.createPlayer__logo}>
          <img
            src="/img/logo1.webp"
            alt="Anytime Soccer logo"
            className={styles.createPlayer__logoImg}
          />
        </div>
        <div className={styles.textWrapper}>
          <h3 className={styles.createPlayer__title}>Create first player</h3>
          <p className={styles.createPlayer__logoText}>Anytime Soccer Training</p>
          <p className={styles.createPlayer__info}>
            No more guesswork with 100% follow-along videos
          </p>
          <p className={styles.createPlayer__description}>
            Tired of searching YouTube for random training videos that are impossible to follow?{' '}
            <br />
            Instead follow a program with over 101 soccer games and over 1,000 training videos.{' '}
            <br />
            Step-by-step from beginner to advanced, every video is short and 100% follow-along.
          </p>
          <p className={styles.createPlayer__description}>
            Get started by adding your first player!
          </p>
          <div className={styles.createPlayer__button}>
            <img
              src="/img/rounded-rectangle.webp"
              alt="Plus symbol"
              className={styles.createPlayer__img}
            />
            <Link to={PATH} className={styles.createPlayer__link}>
              Add Player
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}

export default FirstPlayerAddBlock;
