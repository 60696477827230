import React from 'react';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { PopupStatusProfilePage } from '../constants';

export function WarningPopup({setPopupStatusProfilePage, titleWarning, descriptionWarning, titleButton}) {

  const handleClosePopupButtonClick = () => {
    setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 style={{padding: '0 7rem'}} className={styles.invite_popup__title}>{titleWarning}</h3>
        <div className={styles.container}>
          <p className={styles.invite_popup__description} style={{textAlign: 'center'}}>{descriptionWarning}</p>
          <div className={styles.buttons}>
            <RedButton
              width={'35rem'}
              handleButtonClick={handleClosePopupButtonClick}
              title={titleButton} />
          </div>
        </div>
      </section>
    </>
  );
}