import { useEffect } from 'react';

export function useScrollToTop() {
  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    } catch (errorEvent) {
      console.log(errorEvent);
    }
  }, []);
}
