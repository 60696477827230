import React, { useState } from 'react';
import styles from './CancelSubscription.module.sass';
import InputRadio from './InputRadio';

const CancelSubBody = ({
  changeTextAria,
  valueTextAria,
  setUnsubscribeReason,
  setValueTextAria,
}) => {
  const [isOther, setIsOther] = useState(false);
  const [familyRadio, setFamilyRadio] = useState(false);
  const [tempRadio, setTempRadio] = useState(false);
  const [familyEnoughRadio, setFamilyEnoughRadio] = useState(false);
  const [contentRadio, setContentRadio] = useState(false);

  const clearRadio = () => {
    setFamilyRadio(false);
    setTempRadio(false);
    setIsOther(false);
    setFamilyEnoughRadio(false);
    setContentRadio(false);
  };
  return (
    <>
      <h5>Thank you for your membership. We are sorry to see you go.</h5>
      <InputRadio
        text={'My family isn’t sure how to use Anytime Soccer Training'}
        setValueTextAria={setValueTextAria}
        setUnsubscribeReason={setUnsubscribeReason}
        clearRadio={clearRadio}
        isChecked={familyRadio}
        setChecked={setFamilyRadio}
      />
      <InputRadio
        text={'This is temporary. We’ll be back'}
        setValueTextAria={setValueTextAria}
        setUnsubscribeReason={setUnsubscribeReason}
        clearRadio={clearRadio}
        isChecked={tempRadio}
        setChecked={setTempRadio}
      />
      <InputRadio
        text={'My family isn’t using Anytime Soccer Training enough'}
        setValueTextAria={setValueTextAria}
        setUnsubscribeReason={setUnsubscribeReason}
        clearRadio={clearRadio}
        isChecked={familyEnoughRadio}
        setChecked={setFamilyEnoughRadio}
      />
      <InputRadio
        text={'The training content wasn’t appropriate for my child.'}
        setValueTextAria={setValueTextAria}
        setUnsubscribeReason={setUnsubscribeReason}
        clearRadio={clearRadio}
        isChecked={contentRadio}
        setChecked={setContentRadio}
      />
      <InputRadio
        text={'Other'}
        setValueTextAria={setValueTextAria}
        setUnsubscribeReason={setUnsubscribeReason}
        clearRadio={clearRadio}
        isChecked={isOther}
        setChecked={setIsOther}
      />
      <div
        onClick={() => {
          clearRadio();
          setIsOther(true);
          setUnsubscribeReason('');
        }}
      >
        <textarea
          name="comment"
          className={styles.otherTextField}
          disabled={!isOther}
          onChange={changeTextAria}
          value={valueTextAria}
        />
      </div>
    </>
  );
};

export default CancelSubBody;
