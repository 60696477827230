import { getSubscriptionPrice } from '../../../../api/teams-api';
import { getSubscriptionPriceDataAction } from '../../../../Redux/subscriptionPriceReducer';

export const fetchSubscriptionPriceData = () => {
  return dispatch => {
    try {
      getSubscriptionPrice().then(response => {
        response.json().then(stats => {
          dispatch(getSubscriptionPriceDataAction(stats));
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};
