import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './CancelSubscription.module.sass';
import CancelSubBody from './CancelSubBody';
import { message } from 'antd';
import { sevenDaysMoneyBack } from '../../../api/api';

const CancelSubscription = (props) => {
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState(0);
  const [unsubscribeReason, setUnsubscribeReason] = useState('');
  const [valueTextAria, setValueTextAria] = useState('');
  const [otherField, setOtherField] = useState(true);
  const [error, setError] = useState(false);

  const toggle = () => {
    setError(false);
    setValueTextAria('');
    setUnsubscribeReason('');
    setOtherField(true);
    setStep(0);
    setModal(!modal);
  };

  const changeTextAria = (e) => {
    setError(false);
    setUnsubscribeReason('');
    setValueTextAria(e.currentTarget.value);
  };

  const toggleCheckBox = (e) => {
    setError(false);
    setOtherField(true);
    setUnsubscribeReason(e.currentTarget.parentElement.outerText);
  };

  const submit = () => {
    let reason;
    if (unsubscribeReason) {
      reason = unsubscribeReason;
    } else if (valueTextAria) {
      reason = valueTextAria;
    } else {
      setError(true);
    }
    if (reason) {
      sevenDaysMoneyBack(props.cancelType, reason).then((response) => requestHandler(response));
    }
  };

  const requestHandler = (response) => {
    if (response.status === 200) {
      setModal(!modal);
      message.success('Your application is accepted');
    }
  };

  const changeStep = () => {
    if (props.cancelType === 'Cancel your subscription') {
      setStep(1);
    } else {
      const regTime = Date.parse(props.createdAt);
      const currentTime = Date.now();
      if ((currentTime - regTime) / 1000 / 60 / 60 / 24 >= 7) {
        setModal(false);
        message.error('More than 7 days have passed since registration');
      } else {
        setStep(1);
      }
    }
  };

  return (
    <div>
      <Button onClick={toggle}>{props.title}</Button>

      <Modal isOpen={modal} toggle={toggle} className={styles.modal}>
        <ModalHeader toggle={toggle}>
          <span className={styles.header}>{props.title}</span>
        </ModalHeader>
        <form>
          <ModalBody className={styles.formStyle}>
            {step === 0 ? (
              <h5>Do you really want to cancel your subscription ?</h5>
            ) : (
              <CancelSubBody
                toggleCheckBox={toggleCheckBox}
                changeTextAria={changeTextAria}
                valueTextAria={valueTextAria}
                otherField={otherField}
                setOtherField={setOtherField}
                setUnsubscribeReason={setUnsubscribeReason}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {error ? (
              <div className={styles.errStep2}>Please Select Some Reason Of Unsubscribe</div>
            ) : (
              ''
            )}
            <Button
              color="secondary"
              className={styles.acceptBtn}
              onClick={step === 0 ? changeStep : submit}
            >
              {step === 0 ? 'Yes' : 'Submit'}
            </Button>
            <Button color="primary" className={styles.cancelBtn} onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default CancelSubscription;
