const SET_AUTH = './auth/SET_AUTH';
const SET_LOGIN = './auth/SET-LOGIN';
const SET_NEED_UPDATE = './auth/SET-NEED-UPDATE';
const SET_ROLE = './auth/SET-ROLE';

const initialState = {
  isAuth: false,
  isLogin: false,
  needUpdate: 0,
  role: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case SET_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      };
    case SET_NEED_UPDATE:
      return {
        ...state,
        needUpdate: state.needUpdate + 1,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.role,
      };
    default:
      return state;
  }
};

export const setAuth = isAuth => ({ type: SET_AUTH, isAuth });
export const setLogin = isLogin => ({ type: SET_LOGIN, isLogin });
export const setNeedUpdate = () => ({ type: SET_NEED_UPDATE });
export const setRole = role => ({ type: SET_ROLE, role });

export default authReducer;
