import React, { useState } from 'react';
import styles from './SignInMobile.module.sass';
import { Link } from 'react-router-dom';

const SignInMobile = ({ toggActiveInactSubParentBtn, formik, formik2, toggActiveInactSubPlayerBtn, path }) => {
  const [changeTab, setChangeTab] = useState(true);
  return (
    <>
      <section className={styles.container}>
        <h2>Sign In</h2>
        <div className={styles.signInTabs}>
          <div className={styles.signInTabs_parent} style={changeTab ? { background: '#fff' } : { opacity: '.5' }}
            onClick={() => setChangeTab(true)}>Single</div>
          <div className={styles.signInTabs_player} style={!changeTab ? { background: '#fff' } : { opacity: '.5' }}
            onClick={() => setChangeTab(false)}>Player</div>
        </div>
        {/*CONTAINER PARENT*/}
        <section className={styles.container_main}>
          {changeTab ?
            <div className={styles.container_main__parent}>
              <form className={styles.form} name={'Parent'} onSubmit={formik.handleSubmit}>
                <div className={styles.div}>
                  <label htmlFor="email" className={styles.label}>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@gmail.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email.replace(/[^A-z@.0-9-]/g, '')}
                    className={
                      formik.touched.email && formik.errors.email
                        ? `${styles.input} ${styles.error}`
                        : styles.input}
                  />
                </div>
                <div className={styles.div}>
                  <label htmlFor="password" className={styles.label}>
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formik.values.password.replace(/[\s]/g, '')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.password && formik.errors.password
                        ? `${styles.input} ${styles.error}`
                        : styles.input}
                  />
                </div>
                <div className={styles.linkCont}>
                  <Link className={styles.fpLink} to={path.sign.forgot}>Forgot password?</Link>
                  <button className={styles.btn} type="submit" disabled={toggActiveInactSubParentBtn}>Sign In</button>
                </div>
                <p className={styles.p}>
                  Don&apos;t have an account? <Link to={path.sign.upFree}>Sign Up</Link>
                </p>
              </form>
            </div>
            :
            <div className={styles.container_main__player}>
              <form className={styles.form} onSubmit={formik2.handleSubmit}>
                <div className={styles.div}>
                  <label htmlFor="email2" className={styles.label_user}>
                    Username
                  </label>
                  <input
                    type="text"
                    name="email2"
                    id="email2"
                    placeholder="exampleUserName"
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    value={formik2.values.email2.replace(/[^A-z@._0-9-]/g, '')}
                    className={
                      formik2.touched.email2 && formik2.errors.email2
                        ? `${styles.input} ${styles.error}`
                        : styles.input}
                  />
                </div>
                <div className={styles.div}>
                  <label htmlFor="pass2" className={styles.label_password}>Password</label>
                  <input
                    type="password"
                    name="password2"
                    id="password2"
                    value={formik2.values.password2.replace(/[\s]/g, '')}
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    className={
                      formik2.touched.password2 && formik2.errors.password2
                        ? `${styles.input} ${styles.error}`
                        : styles.input}
                  />
                </div>
                <div className={styles.btnCont}>
                  <button className={styles.btn} type="submit" disabled={toggActiveInactSubPlayerBtn}>
                    Sign In
                  </button>
                </div>
              </form>
            </div>}
          {/*CONTAINER PLAYER*/}
        </section>
      </section>
    </>
  );
};

export default SignInMobile;