import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './Header.module.sass';
import MobileHeader from '../Home/WelcomePage/MobileHeader/mobileHeader';
import menuBtn from '../../asset/home/menuRed.webp';

function Header(props) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const history = useHistory();
  const currentUri = useLocation();

  const changeHeader = () => {
    if (history.location.pathname === '/') {
      const changeWhite = `${styles.white}`;
      return changeWhite;
    }
  };

  const changeToWelcome = () => {
    if (history.location.pathname === '/' && props.welcomePage) {
      return `${styles.welcomePage}`;
    }
  };

  return (
    <>
      {mobileMenu && (
        <MobileHeader path={props.path} mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      )}
      {currentUri.pathname !== '/admin' && currentUri.pathname !== '/adminDashboard' ? (
        <header className={`${styles.header} ${changeHeader()} ${changeToWelcome()}`}>
          <div className={styles.menu_btn}>
            <img src={menuBtn} alt="menuButton" onClick={() => setMobileMenu(!mobileMenu)} />
          </div>
          <Link to={props.path.home} className={styles.logo}>
            <img src="/img/logo.webp" alt="logo" className={styles.img} />
            <h2 className={`${styles.h2} ${styles.white_text}`}>Anytime Soccer Training</h2>
          </Link>
          <nav className={styles.nav}>
            <ul>
              <Link to={changeToWelcome() ? props.path.home : props.path.sign.upFree}>
                {currentUri.pathname === '/auth/registerFree' ? null : (
                  <li
                    className={`${styles.li} ${styles.btn} ${
                      styles.getstarted
                    } ${changeToWelcome()}`}
                    onClick={() => props.setWelcomePage(false)}
                  >
                    {changeToWelcome() ? 'Visit Us' : 'Get Started'}
                  </li>
                )}
              </Link>
              <Link to={props.path.sign.in}>
                <li
                  className={`${styles.li} ${styles.btn}`}
                  onClick={() => props.setWelcomePage(false)}
                >
                  <i className="fas fa-user"></i>&nbsp;&nbsp;&nbsp;Sign In
                </li>
              </Link>
            </ul>
          </nav>
        </header>
      ) : null}
    </>
  );
}

export default Header;
