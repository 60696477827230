import React from 'react';
import styles from './../GridCards/gridCards.module.sass';
import MainDashboardCard from './MainDashboardCard';

export default function GetStartedAndThirtyDays(props) {
  return (
    <section className={styles.grid_cards}>
      <div>
        <h2 className={styles.grid_cards}>
          Welcome to Your Member Dashboard,
          {' ' + props.playerData.screenname}!
        </h2>
      </div>
      <section
        className={
          props.dashboardInfo.mainCategories.length
            ? styles.card_container_main_categories
            : styles.cardContainer
        }
      >
        {props.dashboardInfo.getStartedAnd30DaysPlan.map((item) => {
          return (
            <MainDashboardCard
              key={item.title}
              item={item}
              accessAllVideos={props.playerData.accessAllVideos}
            />
          );
        })}
      </section>
    </section>
  );
}
