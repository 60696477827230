import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationLogic = ({ currentPage, request, changePage, pages }) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const arrayOfPages = [];
  let pageLimit = 11;
  let startPosition = 0;
  let endPosition = pageLimit;

  for (let i = 1; i <= pages; i++) {
    arrayOfPages.push(i);
  }

  if (pages <= pageLimit) {
    pageLimit = pages;
  }

  // increment startPosition page when current page is greater than 5
  if (currentPage - 5 >= 0) {
    startPosition = currentPage - 4;
  }

  // if reaching endPosition of pagination stop increment
  if (startPosition + pageLimit >= pages) {
    startPosition = pages - pageLimit;
  }

  // increment endPosition page when current + 5 exceedPositions page limit
  if (currentPage + 5 >= pageLimit) {
    endPosition = currentPage + 6;
    pageLimit = endPosition;
    if (pages <= pageLimit) {
      pageLimit = pages;
    }
  }

  if (currentPage !== undefined && currentPageNumber !== currentPage) {
    setCurrentPageNumber(currentPage);
  }

  //при выборе крайних ячеек пагинации стрелки принимают opacity
  const opacityArrow = extreme => {
    if (currentPageNumber === extreme) {
      return { opacity: '0.4' };
    } else return { opacity: '1' };
  };

  return (
    <>
      {arrayOfPages.length !== 1 /*  если страница одна, пагинации на странице нет */ ? (
        <Pagination aria-label="Page navigation">
          <PaginationItem>
            <PaginationLink
              previous
              onClick={() => {
                setCurrentPageNumber(currentPageNumber > 1 ? currentPageNumber - 1 : 1);
                changePage('prev');
              }}
              style={opacityArrow(1)}
            />
          </PaginationItem>
          {arrayOfPages.slice(startPosition, endPosition).map((a) => (
            (a >= startPosition && a < endPosition) ? 
              (<PaginationItem key={`pagination${a}`} active={currentPageNumber === a ? true : false}>
              <PaginationLink
                onClick={() => {
                  setCurrentPageNumber(a);
                  request(a);
                }}
              >
                {a}
              </PaginationLink>
            </PaginationItem>) : null
          ))}
          <PaginationItem>
            <PaginationLink
              next
              onClick={() => {
                setCurrentPageNumber(currentPageNumber < pages ? currentPageNumber + 1 : pages);
                changePage('next');
              }}
              style={opacityArrow(pages)}
            />
          </PaginationItem>
        </Pagination>
      ) : null}
    </>
  );
};

export default PaginationLogic;
