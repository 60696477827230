import { useQuery } from '@tanstack/react-query';
import { getHomeworkReportsByUserId } from '../api/api';

const useUserHomeworkReport = () => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['homework-reports', 'user'],
    queryFn: async () => await getHomeworkReportsByUserId().then((res) => res.data),
  });

  return {
    isLoading,
    data: data ?? [],
    error,
    refetch,
  };
};

export default useUserHomeworkReport;
