import React, { useEffect } from 'react';
import styles from './RecoveryEmail.module.sass';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { recoveryEmail } from '../../../../api/api';
import Cookies from 'universal-cookie';

function RecoveryEmail() {
  const history = useHistory();
  const cookies = new Cookies();
  const { tokenFromUrl } = useParams();

  useEffect(() => {
    recoveryEmail(tokenFromUrl);
  }, [tokenFromUrl]);

  const cleanAndRedirect = () => {
    cookies.set('finaleToken', ``, { path: '/' });
    history.push('/auth/login');
  };

  return (
    <main className={styles.main}>
      <form>
        <h2>Your email address has been changed</h2>
        <p>Click below to sign-in with your updated email address</p>
        <button onClick={cleanAndRedirect}>Ok</button>
      </form>
    </main>
  );
}

export default withRouter(RecoveryEmail);
