import React from 'react';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import styles from './Employment.module.sass';

const Employment = () => {
  useScrollToTop();

  return (
    <div className={styles.container}>
      <div className={styles.container__main}>
        <h2 className={styles.container__main_title}>We Are Hiring!</h2>
        <div className={styles.container__main_list}>
          <p>Open positions</p>
          <div>
            <img src="/img/employment/arrow-right.webp" alt="arrow-right" />
            Social Media Consultant
          </div>
          <div>
            <img src="/img/employment/arrow-right.webp" alt="arrow-right" />
            Software Development Consultant
          </div>
          <div>
            <img src="/img/employment/arrow-right.webp" alt="arrow-right" />
            Software Marketing Consultant
          </div>
          <div>
            <img src="/img/employment/arrow-right.webp" alt="arrow-right" />
            Film Editor
          </div>
        </div>
        <div className={styles.container__main_subtitle}>
          Passionate people who want to join something small and help make it big please send CV to{' '}
          <a href="mailto:neil@anytime-soccer.com">neil@anytime-soccer.com</a>
        </div>
      </div>
    </div>
  );
};

export default Employment;
