import React from 'react';
import MainFoldersSeries from './MainFoldersSeries';

const CreateSeries = ({ array, itemOfDashboard, accessAllVideos, setShowUpgradePopup }) => {
  const { title, subTitle, img } = itemOfDashboard;

  return array.map((item) => (
    <MainFoldersSeries
      key={item.uri}
      item={item}
      uri={item.uri}
      urlImage={img}
      name={item.name}
      title={title}
      subtitle={subTitle}
      accessAllVideos={accessAllVideos}
      setShowUpgradePopup={setShowUpgradePopup}
    />
  ));
};

export default CreateSeries;
