import { message } from "antd"
import React from "react"
import { useHistory } from "react-router";
import Cookies from 'universal-cookie';
import path from '../../config/routes.json';
import config from '../../config.json';
import mainRoute from '../../mainRoute';
import styles from './AdminDashboard.module.sass';

const AdminDashboard = ({ setAdmin }) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const cookies = new Cookies();
    const location = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '' || password === '') {
            return message.error("eror");
        } else {
            const data = {
                email,
                password
            }
            try {
                const res = await fetch(mainRoute.route + config.adminSignIn, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (res.status === 200) {
                    const { token } = await res.json();
                    cookies.set('finaleTokenAdmin', ``, { 'path': '/' });
                    cookies.set('finaleTokenAdmin', `${token}`, { 'path': '/', maxAge: (3600 * 30) });
                    setAdmin(true)
                    location.replace(path.admin.dashboard)
                } else {
                    const data = await res.json();
                    return message.error(data.message);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    return (
        <main className={styles.main}>
            <section className={styles.section}>
                <h2 className={styles.h2}>
                    <strong>Admin</strong> Sign In
                </h2>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.div}>
                        <label htmlFor="email" className={styles.label_user}>
                            Username
                        </label>
                        <input type="text" id="email" name="email" className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off" placeholder="example@gmail.com" />
                    </div>
                    <div className={styles.div}>
                        <label htmlFor="email" className={styles.label_password}>
                            password
                        </label>
                        <input type="password" id="password" name="password" className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)}
                            autoComplete="off" placeholder="your password"
                        />
                    </div>
                    <div className={styles.btnCont}>
                        <button className={styles.btn}>log in</button>
                    </div>
                </form>
            </section>
        </main>
    )
}

export default AdminDashboard
