import { SubfolderPageUrl } from '../constants/const';

/* директория, содержит данные для секций внутри train like a pro section / переименована в all training areas
структура объекта: заголовок (отвечает проверке item.name.includes(title), где item.name - это название папки на vimeo), img, link
часть папок имеет совподающие с другой секцией названия, поэтому при создании папок на Vimeo и здесь добавляем название секции (Pro, free section) */

export const ActionType = {
  SET_SEVEN_DAY_CHALLENGE_DATA: 'set-seven-day-challenge-data',
  SET_WARM_UP_DATA: 'set-warm-up-data',
  SET_BALL_MASTERY_DATA: 'set-ball-mastery-data',
  SET_DRIBBLING_GAMES_DATA: 'set-dribbling-games-data',
  SET_DRIBBLE_MASTER_DATA: 'set-dribble-master-data',
  SET_QUICK_TURNS_DATA: 'set-quick-turns-data',
  SET_DRIBBLING_1V1_SHIELDING_DATA: 'set-dribbling-1v1-shielding-data',
  SET_1V1_FINISHING_DATA: 'set-1v1-finishing-data',
  SET_PASSING_RECEIVING_DATA: 'set-passing-receiving-data',
  SET_FITNESS_AGILITY_DATA: 'set-fitness-agility-data',
  SET_FREESTYLE_CHALLENGE_DATA: 'set-freestyle-challenge-data',
  SET_1V1_GAMES_DATA: 'set-1v1-games-data',
  SET_DRIBBLING_TURNS_DATA: 'set-dribbling-turns-data',
  SET_PASSING_DATA: 'set-passing-data',
  SET_FINISHING_DATA: 'set-finishing-data',
  SET_1V1_MOVES_DATA: 'set-1v1-moves-data',
  SET_CLINICAL_FINISHING_DATA: 'set-clinical-finishing-data',
  SET_BEGINNER_DATA: 'set-beginner-data',
  SET_FIGURE8_DATA: 'set-figure8-data',
  SET_LINE_CONE_DATA: 'set-line-cone-data',
  SET_1000_TOUCH_DATA: 'set-1000-data',
  SET_BOX_DRIBBLING_DATA: 'set-box-dribbling-data',
  SET_1000_TOUCH_PART_1_DATA: 'set-1000-touch-part-1-data',
  SET_1000_TOUCH_PART_2_DATA: 'set-1000-touch-part-2-data',
  SET_LATERAL_DYNAMIC_OTHER_DATA: 'set-lateral-dynamic-other-data',
  SET_CONE_LATERAL_DATA: 'set-cone-lateral-data',
  SET_LONG_DISTANCE_AND_OTHER_DATA: 'set-long-distance-and-other-data',
  SET_REBOUNDER_AND_WALL_DATA: 'set-rebounder-and-wall-data',
  SET_CORE_PASSING_DRILLS_DATA: 'set-core-passing-drills-data',
  SET_BATTLE_FINISHING_DATA: 'set-battle-finishing-data',
  SET_MOVES_1V1_PRO_DATA: 'set-moves-1v1-pro-data',
  SET_FIRS_TOUCH_PRO_DATA: 'set-first-touch-pro-data',
  SET_TWO_CONE_PRO_DATA: 'set-two-cone-pro-data',
  SET_TIGHT_SPACE_DRIBBLING_DATA: 'set-tight-space-dribbling-data',
  SET_LINE_CONE_AND_CONE_SLALOM_DRIBBLING_DATA: 'set-line-cone-and-cone-slalom-dribbling-data',
  SET_SHAPE_PATTERN_DATA: 'set-shape-pattern-data',
  SET_SPEED_DRIBBLING_TURNS_FITNESS_DATA: 'set-speed-dribbling-turns-fitness-data',
  SET_OBSTACLE_LANE_LATERAL_DRIBBLING_DATA: 'set-obstacle-lane-lateral-dribbling-data',
  SET_CORE_JUGGLING_BEGINNER_TO_ADVANCED_DATA: 'set-core-juggling-beginner-to-advanced-data',
  SET_SIZE_ONE_TENNIS_BALL_JUGGLING_DATA: 'set-size-one-tennis-ball-juggling',
  SET_PARTNER_TOSS_AERIAL_WALL_JUGGLING_DATA: 'set-partner-toss-aerial-wall-juggling',
  SET_BODY_AERIAL_DRIBBLING_AERIAL_FINISHING_DATA: 'set-body-aerial-dribbling-aerial-finishing',
  SET_PARTNER_DRILLS_DATA: 'set-partner-drills-data',
  SET_ADVANCED_JUGGLE_MASTER_DATA: 'set-advanced-juggle-master-data',
  SET_MIDDLE_JUGGLE_MASTER_DATA: 'set-middle-juggle-master-data',
  SET_LATERAL_MOVEMENT_DATA: 'set-lateral-movement-data',
  SET_CHANGE_DIRECTION_DATA: 'set-change-direction-data',
  SET_FAST_SPEED_DRIBBLE_MASTER_DATA: 'set-fast-speed-dribble-master-data',
  SET_PARTNER_DRILLS_PRO_DATA: 'set-partner-drills-pro',
  SET_DIRECTIONAL_CONTROL_RAPID_FIRE_DATA: 'set-directional-control-rapid-fire-data',
  SET_DRIBBLING_FINISHING_DATA: 'set-dribbling-finishing-data',
  SET_DRIBBLE_PASS_SHOOT_DATA: 'set-dribble-pass-shoot-data',
  SET_AERIAL_CONTROL_OTHER_DATA: 'set-aerial-control-other-data',
  SET_FREE_KICKS_PENALTIES_DATA: 'set-free-kicks-penalties-data',
  SET_ONE_TWO_FOUR_CONE_DATA: 'set-one-two-four-cone-data',
  SET_OTHER_PROGRAMS_BALL_MASTERY_DATA: 'set-other-programs-ball-mastery-data',
  SET_OTHER_DRIBBLING_PROGRAMS_DATA: 'set-other-dribbling-programs-data',
  SET_JUGGLING_AND_AREAL_PROGRAMS_DATA: 'set-juggling-and-areal-programs-data',
  SET_OTHER_JUGGLING_AND_AREAL_PROGRAMS_DATA: 'set-other-juggling-and-areal-programs-data',
  SET_OTHER_TIGHT_DRIBBLING_PROGRAMS_DATA: 'set-other-tight-dribbling-programs-data',
  SET_OTHER_FIGURE_8_PROGRAMS_DATA: 'set-other-figure-8-subfolder-data',
};

const initialState = {
  data: [
    {
      title: 'Warm-Up and Juggling',
      subTitle: '',
      actionType: ActionType.SET_WARM_UP_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_30_grey_blue.webp',
      link: SubfolderPageUrl.warmUp,
    },
    {
      title: 'Ball Mastery',
      subTitle: '',
      actionType: ActionType.SET_BALL_MASTERY_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_31_grey_blue.webp',
      link: SubfolderPageUrl.ballMastery,
    },
    {
      title: 'Dribbling Games',
      subTitle: '',
      actionType: ActionType.SET_DRIBBLING_GAMES_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_32_grey_blue.webp',
      link: SubfolderPageUrl.dribblingGames,
    },
    {
      title: 'Passing Receiving',
      subTitle: '',
      actionType: ActionType.SET_PASSING_RECEIVING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_33_grey_blue.webp',
      link: SubfolderPageUrl.passingReceivingData,
    },
    {
      title: 'Fitness Agility',
      subTitle: '',
      actionType: ActionType.SET_FITNESS_AGILITY_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_34_grey_blue.webp',
      link: SubfolderPageUrl.allTrainingAreasSubfolder,
    },
    {
      title: '1V1 Finishing',
      subTitle: '',
      actionType: ActionType.SET_1V1_FINISHING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_35_grey_blue.webp',
      link: SubfolderPageUrl.finishing1v1,
    },
  ],
  subfoldersData: [],
};

// data for subfolders
export const subfoldersData = {
  warmUp: [
    {
      title: 'Dynamic Warmup Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_37_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Juggling & Areal Programs',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_304_black_grey.webp',
      link: SubfolderPageUrl.jugglingAndArealPrograms,
    },
  ],
  jugglingAndArealPrograms: [
    {
      title: '(Aerial) Warm-up Juggle',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_128_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Core Juggling Beginner to Advanced',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_234_black_grey.webp',
      link: SubfolderPageUrl.coreJugglingBeginnerToAdvanced,
    },

    {
      title: '(Timed) Juggle Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_97_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Other Juggling & Areal Programs',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_311_black_grey.webp',
      link: SubfolderPageUrl.otherJugglingAndArealPrograms,
    },
  ],
  otherJugglingAndArealPrograms: [
    {
      title: 'Size One | Tennis Ball Juggling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_235_black_grey.webp',
      link: SubfolderPageUrl.sizeOneTennisBallJuggling,
    },
    {
      title: 'Partner Toss | Aerial Wall Juggling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_236_black_grey.webp',
      link: SubfolderPageUrl.partnerTossAerialWallJuggling,
    },
    {
      title: 'Body | Aerial & Dribbling Aerial & Finishing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_237_black_grey.webp',
      link: SubfolderPageUrl.bodyAerialDribblingAerialFinishing,
    },
  ],
  ballMastery: [
    {
      title: '1,000 Touch',
      subTitle: 'Ball Mastery',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_38_grey_black.webp',
      link: SubfolderPageUrl.touch1000,
    },
    {
      title: 'One Two Four Cone Ball Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_268_black_grey.webp',
      link: SubfolderPageUrl.oneTwoFourCone,
    },
    {
      title: 'Other Programs Ball Mastery',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_312_black_grey.webp',
      link: SubfolderPageUrl.otherProgramsBallMasetery,
    },
  ],
  otherProgramsBallMasetery: [
    {
      title: 'Lateral | Dynamic | Other',
      subTitle: '',
      actionType: ActionType.SET_LATERAL_MOVEMENT_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_269_black_grey.webp',
      link: SubfolderPageUrl.lateralDynamicOther,
    },
    {
      title: 'Midfield Ball Mastery',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_309_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Ball Mastery & Dribbling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_272_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Ball Mastery & Tennis Ball',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_288_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribblingGames: [
    {
      title: 'Dribbling Master',
      subTitle: '',
      actionType: ActionType.SET_DRIBBLE_MASTER_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_129_red.webp',
      link: SubfolderPageUrl.dribbleMaster,
    },
    {
      title: 'Quick Turns',
      subTitle: '',
      actionType: ActionType.SET_QUICK_TURNS_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_130_red.webp',
      link: SubfolderPageUrl.quickTurns,
    },
    {
      title: 'Brief Freestyle',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_131_red.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dribbling 1v1 & Shielding',
      subTitle: '',
      actionType: ActionType.SET_DRIBBLING_1V1_SHIELDING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_281_red_grey.webp',
      link: SubfolderPageUrl.dribbling1v1Shielding,
    },
  ],
  quickTurns: [
    {
      title: 'Change Direction',
      subTitle: ' & Quick Turns',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_49_grey_black.webp',
      link: SubfolderPageUrl.changeDirection,
    },
    {
      title: 'Escape Pressure Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_137_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line & Turns',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_188_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(Short Long) Turns',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_187_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Double Turns',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_189_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribbling1v1Shielding: [
    {
      title: 'Circle Dribbling Tag & 1v1',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_282_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dribbling & Shielding',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_283_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribbleMaster: [
    {
      title: 'Tight Space Dribbling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_223_black_grey.webp',
      link: SubfolderPageUrl.tightSpaceDribbling,
    },
    {
      // на сайте папка называется Dribble Master
      title: 'Fast Speed Dribble Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_101_grey_black.webp',
      link: SubfolderPageUrl.fastSpeedDribbleMaster,
    },
    {
      title: 'Line Cone & Cone Slalom Dribbling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_224_black_grey.webp',
      link: SubfolderPageUrl.lineConeAndConeSlalomDribbling,
    },
    {
      title: 'Other Dribling Programs',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_310_black_grey.webp',
      link: SubfolderPageUrl.otherDribblingPrograms,
    },
  ],
  otherDribblingPrograms: [
    {
      title: 'Shape Pattern',
      subTitle: 'Speed Dribbling',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_225_black_grey.webp',
      link: SubfolderPageUrl.shapePattern,
    },
    {
      title: 'Speed Dribbling Turns | Fitness',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_226_black_grey.webp',
      link: SubfolderPageUrl.speedDribblingTurnsFitness,
    },
    {
      title: 'Obstacle | Lane Lateral Dribbling',
      subTitle: '',
      actionType: ActionType.SET_OBSTACLE_LANE_LATERAL_DRIBBLING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_227_black_grey.webp',
      link: SubfolderPageUrl.obstacleLaneLateralDribbling,
    },
  ],
  finishing1V1: [
    {
      title: '1v1 Moves',
      subTitle: '',
      actionType: ActionType.SET_1V1_MOVES_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_153_red_grey.webp',
      link: SubfolderPageUrl.moves,
    },
    {
      title: 'Clinical Finishing',
      subTitle: '',
      actionType: ActionType.SET_CLINICAL_FINISHING_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_154_red_grey.webp',
      link: SubfolderPageUrl.clinicalFinishing,
    },
  ],
  '1V1Moves': [
    {
      title: '1v1 Moves Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_52_grey_black.webp',
      link: SubfolderPageUrl.moves1V1Pro,
    },
    {
      title: 'Figure 8 Moves',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_169_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  clinicalFinishing: [
    {
      title: 'Directional Control & Rapid Fire',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_275_black_grey.webp',
      link: SubfolderPageUrl.directionalControlRapidFire,
    },
    {
      title: '1v1 & Dribbling Finishing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_276_black_grey.webp',
      link: SubfolderPageUrl.dribblingFinishing,
    },
    {
      title: 'Dribble, Pass Shoot',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_290_black_grey.webp',
      link: SubfolderPageUrl.dribblePassShoot,
    },
    {
      title: 'Aerial Control & Other',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_278_black_grey.webp',
      link: SubfolderPageUrl.aerialControlOther,
    },
    {
      title: 'Free Kicks & Penalties',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_277_black_grey.webp',
      link: SubfolderPageUrl.freeKicksPenalties,
    },
    {
      title: '1v1 & Wall Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_53_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  directionalControlRapidFire: [
    {
      title: 'Fire Rapid Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_155_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Directional Control',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_182_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribblePassShoot: [
    {
      title: 'Passing Warmup Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_298_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Slalom Dribbling',
      subTitle: 'Finishing',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_291_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line Dribbling Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_292_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Chase Dribbling Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_294_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Pass & Shoot Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_299_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  aerialControlOther: [
    {
      title: 'Aerial Control Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_200_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Aerial Control, 1v1 Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_280_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  freeKicksPenalties: [
    {
      title: 'Free Kick Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_279_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Free Kick Penalty Shootout',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_156_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Crossing and Passing',
      subTitle: 'Finishing',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_185_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  dribblingFinishing: [
    {
      title: '1vs1 Moves & Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_191_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Battle Finishing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_183_black_grey.webp',
      link: SubfolderPageUrl.battle1v1Finishing,
    },
    {
      title: 'Attacking Winger',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_190_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line Dribbling Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_292_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Bodying Shielding Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_301_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Freestyle Dribbling Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_300_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  passingReceiving: [
    {
      title: 'Rebounder and Wall',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_263_black_grey.webp',
      link: SubfolderPageUrl.rebounderAndWall,
    },
    {
      title: 'Core Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_264_black_grey.webp',
      link: SubfolderPageUrl.corePassing,
    },
    {
      title: 'Cone and Lateral',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_265_black_grey.webp',
      link: SubfolderPageUrl.coneAndLateral,
    },
    {
      title: 'Long Distance & Other',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_266_black_grey.webp',
      link: SubfolderPageUrl.longDistanceAndOther,
    },
  ],
  fitnessAgility: [
    {
      // на сайте папка называется Fitness_and_Soccer - слишком длинное название папки для Vimeo
      title: 'Endurance in Soccer',
      subTitle: 'Sessions',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_54_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Agility Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_55_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cable Jump Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_91_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Agility Rings Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_98_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fitness Hurdles Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_99_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  games1v1: [
    // {
    //   title: 'Games Playing, Practising',
    //   subTitle: '',
    //   isSeries: true,
    //   img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_175_blue_grey.webp',
    //   link: SubfolderPageUrl.videoCategories,
    // },
    {
      title: '1V1 Soccer Chasing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_119_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Chasing Tagging Games Playing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_120_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Soccer Chasing Main',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_122_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Games Playing, Racing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_121_grey_blue.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1V1 Finishing Games',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_186_blue_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Passing Games',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_297_blue_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  beginner: [
    {
      title: '2 Footed Thigh Juggling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_192_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Catch & Hit Juggle',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_193_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Beginner Juggle & Bounce',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_194_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  figure8: [
    {
      title: 'Figure 8 Close Control',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_197_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Figure 8 Essential Drill',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_199_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Figure 8 Long Distance Drills',
      subTitle: '',
      isSeries: 'true',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_313_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Other Figure 8 Programs',
      subtitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_314_black_grey.webp',
      link: SubfolderPageUrl.otherFigure8Programs,
    },
  ],
  otherFigure8Programs: [
    {
      title: 'Figure 8 Lateral Drills',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_198_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Figure 8 1v1 Moves',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_196_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Figure 8 Ball Mastery',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_195_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  lineCone: [
    {
      title: '1 Line Cone',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_213_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '2 Line Cone',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_214_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '3 Line Cone',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_215_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '4 Line Cone',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_216_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  touch1000: [
    {
      title: '1,000 Touch P1',
      subTitle: 'Ball Mastery',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_38_grey_black.webp',
      link: SubfolderPageUrl.touch1000Part1,
    },
    {
      title: '1,000 Touch P2',
      subTitle: 'Ball Mastery',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_118_grey_black.webp',
      link: SubfolderPageUrl.touch1000Part2,
    },
  ],
  touch1000part1: [
    {
      title: '1000 Touch P1 Part 1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_201_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1000 Touch P1 Part 2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_202_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1000 Touch P1 Part 3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_203_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  touch1000part2: [
    {
      title: '1000 Touch P2 Part 1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_204_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1000 Touch P2 Part 2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_205_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1000 Touch P2 Part 3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_206_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  battle1v1Finishing: [
    {
      title: 'Battle Finishing Part 1',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_221_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Battle Finishing Part 2',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_222_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Battle Finishing Part 3',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_232_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  moves1v1Pro: [
    {
      title: '1v1 Moves Pro P1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_210_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1v1 Moves Pro P2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_211_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1v1 Moves Pro P3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_212_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  firstTouchPro: [
    {
      title: 'First Touch Pro P1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_207_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'First Touch Pro P2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_208_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'First Touch Pro P3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_209_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  twoConePro: [
    {
      title: 'Dual Cone Pro P1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_218_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dual Cone Pro P2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_219_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dual Cone Pro P3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_220_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  tightSpaceDribbling: [
    {
      title: 'Figure 8 Pro',
      subTitle: 'Dribbling',
      actionType: ActionType.SET_BEGINNER_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_42_grey_black.webp',
      link: SubfolderPageUrl.figure8,
    },
    {
      title: 'Tight Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_103_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Box Dribbling Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_262_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Other tight dribbling programs',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_315_black_grey.webp',
      link: SubfolderPageUrl.otherTightDribblingPrograms,
    },
  ],
  otherTightDribblingPrograms: [
    {
      title: 'Five Star Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_260_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Body Feint Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_41_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '3 Cone Dribbling',
      subTitle: '',
      starImg: '/img/home/star.webp',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_48_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Change Route & Freestyle',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_261_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  lateralDynamicOther: [
    {
      title: 'Lateral Movement',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_171_black_grey.webp',
      link: SubfolderPageUrl.lateralMovement,
    },
    {
      title: 'Dynamic Ball Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_95_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  lineConeAndConeSlalomDribbling: [
    {
      title: 'Line Cone Pro',
      subTitle: 'Dribble Master',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_44_grey_black.webp',
      link: SubfolderPageUrl.lineCone,
    },
    {
      title: 'Cone Slalom Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_46_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line Slalom',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_45_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line Mixed Distance',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_217_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  rebounderAndWall: [
    {
      title: 'First Touch Pro',
      subTitle: 'Rebounder',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_50_grey_black.webp',
      link: SubfolderPageUrl.firstTouchPro,
    },
  ],
  corePassing: [
    {
      title: 'Foundation Passing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_267_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Partner Drills Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_51_grey_black.webp',
      link: SubfolderPageUrl.partnerDrillsPro,
    },
    {
      title: '1 Cone Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_270_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Area Running',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_176_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  coneAndLateral: [
    {
      title: 'Cone Line Lateral',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_233_black_grey.webp',
      link: SubfolderPageUrl.partnerDrillsPro,
    },
    {
      title: 'Footway Passing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_178_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cone Line Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_177_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  oneTwoFourCone: [
    {
      title: '1 Cone Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_39_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Two Cone Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_40_grey_black.webp',
      link: SubfolderPageUrl.twoConePro,
    },
    {
      title: 'Four Cone Essential',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_170_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Four Cone Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_94_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  longDistanceAndOther: [
    {
      title: 'Distant Passing',
      subTitle: '',
      img: '/img/home/new-design/long-distance-main.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Cardio Passing Drills',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_174_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(T)Formation Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_274_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Agility Ring Passing',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_273_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Circle Passing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_302_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Defender Passing ',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_316_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  shapePattern: [
    {
      title: 'Dribble Box',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_102_grey_black.webp',
      link: SubfolderPageUrl.boxDribbling,
    },
    {
      title: 'Dribble T-Form',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_104_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      // title: 'Dribble Circle',
      title: 'Fast Speed 06 Circle Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_106_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Five Star Pro',
      subTitle: 'Dribbling',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_47_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dribble Pentagon',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_123_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(W)Pattern',
      subTitle: 'Dribbling',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_140_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  boxDribbling: [
    {
      title: 'Foundation Box Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_284_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(X)Box Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_285_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Close Box Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_286_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1v1 Moves & More',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_287_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  speedDribblingTurnsFitness: [
    {
      title: 'Fast Dribbling & Turns',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_228_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Dribbling & Weaves',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_229_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Dribbling & Fitness',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_230_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Dribbling Hop Taps | Other',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_231_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  obstacleLaneLateralDribbling: [
    {
      title: 'Course of Obstacle Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_138_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Lateral Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_107_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Lane Dribbling',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_108_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  coreJugglingBeginnerToAdvanced: [
    {
      // Beginner
      title: 'Two-Footed',
      subTitle: 'Juggle Master',
      actionType: ActionType.SET_BEGINNER_DATA,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_111_grey_black.webp',
      link: SubfolderPageUrl.beginner,
    },
    {
      // Intermediate
      title: 'Middle Juggle Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_112_grey_black.webp',
      link: SubfolderPageUrl.middleJuggleMaster,
    },
    {
      title: 'Advanced Juggle Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_113_grey_black.webp',
      link: SubfolderPageUrl.advancedJuggleMaster,
    },
  ],
  sizeOneTennisBallJuggling: [
    {
      title: 'Ball of Size One',
      subTitle: 'Juggle Master',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_114_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Big Tennis Ball Pro',
      subTitle: 'Juggle Master',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_115_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Balls of Mixed-size',
      subTitle: 'Juggle Master',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_116_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  partnerTossAerialWallJuggling: [
    {
      title: 'Partner Aerial Toss Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_90_grey_black.webp',
      link: SubfolderPageUrl.partnerDrills,
    },
    {
      title: 'Aero Wall Control',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_109_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Aero Juggling with Partner',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_157_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  bodyAerialDribblingAerialFinishing: [
    {
      title: 'Body Control Juggle Master',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_117_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Aerial Control Dribbling Escape',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_133_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Aerial Control Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_238_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  partnerDrills: [
    {
      title: 'Aerial Toss Part 1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_247_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Aerial Toss Part 2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_248_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  advancedJuggleMaster: [
    {
      title: 'Advanced Juggling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_239_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Advanced Moving Juggling',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_240_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Flicks | Juggling',
      subTitle: ' | High Low',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_241_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  middleJuggleMaster: [
    {
      title: '1 Intermed Juggle Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_242_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '2 Intermed Juggle Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_243_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  lateralMovement: [
    {
      title: '1 Lateral Movement',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_244_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '2 Lateral Movement',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_245_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '3 Lateral Movement',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_246_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  changeDirection: [
    {
      title: 'Change Direction P1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_249_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Change Direction P2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_250_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Change Direction P3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_251_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  fastSpeedDribbleMaster: [
    {
      title: 'Fast Speed Dribble Master P1',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_252_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Speed Dribble Master P2',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_253_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Speed Dribble Master P3',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_254_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Fast Speed Dribble Master P4',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_255_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
  partnerDrillsPro: [
    // titles from card subtitles
    {
      title: '2 Touch Passing Partner',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_256_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Directional Ctrl Sole',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_257_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '1 Cone Movement More',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_259_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const dashboardChildReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_WARM_UP_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.warmUp,
      };
    case ActionType.SET_JUGGLING_AND_AREAL_PROGRAMS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.jugglingAndArealPrograms,
      };
    case ActionType.SET_OTHER_JUGGLING_AND_AREAL_PROGRAMS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.otherJugglingAndArealPrograms,
      };
    case ActionType.SET_BALL_MASTERY_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.ballMastery,
      };
    case ActionType.SET_DRIBBLING_GAMES_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribblingGames,
      };
    case ActionType.SET_1V1_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.finishing1V1,
      };
    case ActionType.SET_PASSING_RECEIVING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.passingReceiving,
      };
    case ActionType.SET_FITNESS_AGILITY_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.fitnessAgility,
      };
    case ActionType.SET_FREESTYLE_CHALLENGE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.freestyleChallenge,
      };
    case ActionType.SET_1V1_GAMES_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.games1v1,
      };
    case ActionType.SET_DRIBBLE_MASTER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribbleMaster,
      };
    case ActionType.SET_OTHER_DRIBBLING_PROGRAMS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.otherDribblingPrograms,
      };
    case ActionType.SET_QUICK_TURNS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.quickTurns,
      };
    case ActionType.SET_DRIBBLING_1V1_SHIELDING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribbling1v1Shielding,
      };
    case ActionType.SET_1V1_MOVES_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData['1V1Moves'],
      };
    case ActionType.SET_CLINICAL_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.clinicalFinishing,
      };
    case ActionType.SET_BEGINNER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.beginner,
      };
    case ActionType.SET_FIGURE8_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.figure8,
      };
    case ActionType.SET_OTHER_FIGURE_8_PROGRAMS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.otherFigure8Programs,
      };
    case ActionType.SET_LINE_CONE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.lineCone,
      };
    case ActionType.SET_1000_TOUCH_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.touch1000,
      };
    case ActionType.SET_1000_TOUCH_PART_1_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.touch1000part1,
      };
    case ActionType.SET_BOX_DRIBBLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.boxDribbling,
      };
    case ActionType.SET_1000_TOUCH_PART_2_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.touch1000part2,
      };
    case ActionType.SET_OTHER_PROGRAMS_BALL_MASTERY_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.otherProgramsBallMasetery,
      };
    case ActionType.SET_LATERAL_DYNAMIC_OTHER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.lateralDynamicOther,
      };
    case ActionType.SET_CONE_LATERAL_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.coneAndLateral,
      };
    case ActionType.SET_LONG_DISTANCE_AND_OTHER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.longDistanceAndOther,
      };
    case ActionType.SET_REBOUNDER_AND_WALL_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.rebounderAndWall,
      };

    case ActionType.SET_CORE_PASSING_DRILLS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.corePassing,
      };
    case ActionType.SET_BATTLE_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.battle1v1Finishing,
      };
    case ActionType.SET_MOVES_1V1_PRO_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.moves1v1Pro,
      };
    case ActionType.SET_FIRS_TOUCH_PRO_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.firstTouchPro,
      };
    case ActionType.SET_TWO_CONE_PRO_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.twoConePro,
      };
    case ActionType.SET_TIGHT_SPACE_DRIBBLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.tightSpaceDribbling,
      };
    case ActionType.SET_OTHER_TIGHT_DRIBBLING_PROGRAMS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.otherTightDribblingPrograms,
      };
    case ActionType.SET_LINE_CONE_AND_CONE_SLALOM_DRIBBLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.lineConeAndConeSlalomDribbling,
      };
    case ActionType.SET_SHAPE_PATTERN_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.shapePattern,
      };
    case ActionType.SET_SPEED_DRIBBLING_TURNS_FITNESS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.speedDribblingTurnsFitness,
      };
    case ActionType.SET_OBSTACLE_LANE_LATERAL_DRIBBLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.obstacleLaneLateralDribbling,
      };
    case ActionType.SET_CORE_JUGGLING_BEGINNER_TO_ADVANCED_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.coreJugglingBeginnerToAdvanced,
      };
    case ActionType.SET_SIZE_ONE_TENNIS_BALL_JUGGLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.sizeOneTennisBallJuggling,
      };
    case ActionType.SET_PARTNER_TOSS_AERIAL_WALL_JUGGLING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.partnerTossAerialWallJuggling,
      };
    case ActionType.SET_BODY_AERIAL_DRIBBLING_AERIAL_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.bodyAerialDribblingAerialFinishing,
      };
    case ActionType.SET_PARTNER_DRILLS_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.partnerDrills,
      };
    case ActionType.SET_ADVANCED_JUGGLE_MASTER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.advancedJuggleMaster,
      };
    case ActionType.SET_MIDDLE_JUGGLE_MASTER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.middleJuggleMaster,
      };
    case ActionType.SET_LATERAL_MOVEMENT_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.lateralMovement,
      };
    case ActionType.SET_CHANGE_DIRECTION_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.changeDirection,
      };
    case ActionType.SET_FAST_SPEED_DRIBBLE_MASTER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.fastSpeedDribbleMaster,
      };
    case ActionType.SET_DIRECTIONAL_CONTROL_RAPID_FIRE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.directionalControlRapidFire,
      };
    case ActionType.SET_PARTNER_DRILLS_PRO_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.partnerDrillsPro,
      };
    case ActionType.SET_DRIBBLING_FINISHING_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribblingFinishing,
      };
    case ActionType.SET_DRIBBLE_PASS_SHOOT_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.dribblePassShoot,
      };
    case ActionType.SET_AERIAL_CONTROL_OTHER_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.aerialControlOther,
      };
    case ActionType.SET_FREE_KICKS_PENALTIES_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.freeKicksPenalties,
      };
    case ActionType.SET_ONE_TWO_FOUR_CONE_DATA:
      return {
        ...state,
        subfoldersData: subfoldersData.oneTwoFourCone,
      };
    default:
      return state;
  }
};

export const setWarmUpData = () => ({
  type: ActionType.SET_WARM_UP_DATA,
});

export const setJugglingAndArealProgramsData = () => ({
  type: ActionType.SET_JUGGLING_AND_AREAL_PROGRAMS_DATA,
});

export const setBallMasteryData = () => ({
  type: ActionType.SET_BALL_MASTERY_DATA,
});

export const setDribblingGamesData = () => ({
  type: ActionType.SET_DRIBBLING_GAMES_DATA,
});

export const setDribbleMasterData = () => ({
  type: ActionType.SET_DRIBBLE_MASTER_DATA,
});

export const setOtherDribblingProgramsData = () => ({
  type: ActionType.SET_OTHER_DRIBBLING_PROGRAMS_DATA,
});

export const setOtherJugglingAndArealProgramsData = () => ({
  type: ActionType.SET_OTHER_JUGGLING_AND_AREAL_PROGRAMS_DATA,
});

export const setQuickTurnsData = () => ({
  type: ActionType.SET_QUICK_TURNS_DATA,
});

export const setDribbling1v1ShieldingData = () => ({
  type: ActionType.SET_DRIBBLING_1V1_SHIELDING_DATA,
});

export const setFinishingData = () => ({
  type: ActionType.SET_1V1_FINISHING_DATA,
});

export const setPassingReceivingData = () => ({
  type: ActionType.SET_PASSING_RECEIVING_DATA,
});

export const setFitnessAgilityData = () => ({
  type: ActionType.SET_FITNESS_AGILITY_DATA,
});

export const setFreestyleChallengeData = () => ({
  type: ActionType.SET_FREESTYLE_CHALLENGE_DATA,
});

export const set1v1GamesData = () => ({
  type: ActionType.SET_1V1_GAMES_DATA,
});

export const set1v1MovesData = () => ({
  type: ActionType.SET_1V1_MOVES_DATA,
});

export const setClinicalFinishingData = () => ({
  type: ActionType.SET_CLINICAL_FINISHING_DATA,
});

export const setBeginnerData = () => ({
  type: ActionType.SET_BEGINNER_DATA,
});

export const setFigure8Data = () => ({
  type: ActionType.SET_FIGURE8_DATA,
});

export const setOtherFigure8Programs = () => ({
  type: ActionType.SET_OTHER_FIGURE_8_PROGRAMS_DATA,
});

export const setLineConeData = () => ({
  type: ActionType.SET_LINE_CONE_DATA,
});

export const set1000Touch = () => ({
  type: ActionType.SET_1000_TOUCH_DATA,
});

export const set100TouchPart1 = () => ({
  type: ActionType.SET_1000_TOUCH_PART_1_DATA,
});

export const setBoxDribbling = () => ({
  type: ActionType.SET_BOX_DRIBBLING_DATA,
});

export const set100TouchPart2 = () => ({
  type: ActionType.SET_1000_TOUCH_PART_2_DATA,
});

export const setLateralDynamicOther = () => ({
  type: ActionType.SET_LATERAL_DYNAMIC_OTHER_DATA,
});

export const setOtherProgramsBallMastery = () => ({
  type: ActionType.SET_OTHER_PROGRAMS_BALL_MASTERY_DATA,
});
export const setConeAndLateral = () => ({
  type: ActionType.SET_CONE_LATERAL_DATA,
});

export const setLongDistanceAndOther = () => ({
  type: ActionType.SET_LONG_DISTANCE_AND_OTHER_DATA,
});

export const setRebounderAndWall = () => ({
  type: ActionType.SET_REBOUNDER_AND_WALL_DATA,
});

export const setCorePassing = () => ({
  type: ActionType.SET_CORE_PASSING_DRILLS_DATA,
});

export const setBattle1v1Finishing = () => ({
  type: ActionType.SET_BATTLE_FINISHING_DATA,
});

export const setMoves1V1Pro = () => ({
  type: ActionType.SET_MOVES_1V1_PRO_DATA,
});

export const setFirstTouchPro = () => ({
  type: ActionType.SET_FIRS_TOUCH_PRO_DATA,
});

export const setTwoConePro = () => ({
  type: ActionType.SET_TWO_CONE_PRO_DATA,
});

export const setTightSpaceDribbling = () => ({
  type: ActionType.SET_TIGHT_SPACE_DRIBBLING_DATA,
});

export const setOtherTightDribblingProgramsData = () => ({
  type: ActionType.SET_OTHER_TIGHT_DRIBBLING_PROGRAMS_DATA,
});

export const setLineConeAndConeSlalomDribbling = () => ({
  type: ActionType.SET_LINE_CONE_AND_CONE_SLALOM_DRIBBLING_DATA,
});

export const setShapePattern = () => ({
  type: ActionType.SET_SHAPE_PATTERN_DATA,
});

export const setSpeedDribblingTurnsFitness = () => ({
  type: ActionType.SET_SPEED_DRIBBLING_TURNS_FITNESS_DATA,
});

export const setObstacleLaneLateralDribbling = () => ({
  type: ActionType.SET_OBSTACLE_LANE_LATERAL_DRIBBLING_DATA,
});

export const setCoreJugglingBeginnerToAdvanced = () => ({
  type: ActionType.SET_CORE_JUGGLING_BEGINNER_TO_ADVANCED_DATA,
});

export const setSizeOneTennisBallJuggling = () => ({
  type: ActionType.SET_SIZE_ONE_TENNIS_BALL_JUGGLING_DATA,
});

export const setPartnerTossAerialWallJuggling = () => ({
  type: ActionType.SET_PARTNER_TOSS_AERIAL_WALL_JUGGLING_DATA,
});

export const setBodyAerialDribblingAerialFinishing = () => ({
  type: ActionType.SET_BODY_AERIAL_DRIBBLING_AERIAL_FINISHING_DATA,
});

export const setPartnerDrills = () => ({
  type: ActionType.SET_PARTNER_DRILLS_DATA,
});

export const setAdvancedJuggleMaster = () => ({
  type: ActionType.SET_ADVANCED_JUGGLE_MASTER_DATA,
});

export const setMiddleJuggleMaster = () => ({
  type: ActionType.SET_MIDDLE_JUGGLE_MASTER_DATA,
});

export const setLateralMovement = () => ({
  type: ActionType.SET_LATERAL_MOVEMENT_DATA,
});

export const setChangeDirection = () => ({
  type: ActionType.SET_CHANGE_DIRECTION_DATA,
});

export const setFastSpeedDribbleMaster = () => ({
  type: ActionType.SET_FAST_SPEED_DRIBBLE_MASTER_DATA,
});

export const setDirectionalControlRapidFire = () => ({
  type: ActionType.SET_DIRECTIONAL_CONTROL_RAPID_FIRE_DATA,
});

export const setPartnerDrillsPro = () => ({
  type: ActionType.SET_PARTNER_DRILLS_PRO_DATA,
});

export const setDribblingFinishing = () => ({
  type: ActionType.SET_DRIBBLING_FINISHING_DATA,
});

export const setDribblePassShoot = () => ({
  type: ActionType.SET_DRIBBLE_PASS_SHOOT_DATA,
});

export const setAerialControlOther = () => ({
  type: ActionType.SET_AERIAL_CONTROL_OTHER_DATA,
});

export const setFreeKicksPenalties = () => ({
  type: ActionType.SET_FREE_KICKS_PENALTIES_DATA,
});

export const setOneTwoFourCone = () => ({
  type: ActionType.SET_ONE_TWO_FOUR_CONE_DATA,
});

export default dashboardChildReducer;
