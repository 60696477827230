import React from 'react';
import styles from './PlayerRow.module.sass';
import RedCross from '../../../../../../../asset/videoSeries/RedCross.webp';
import PlayersGoal from '../../../../../../../asset/teams/roster/invitations/Players_goal.webp';
import { timeConvert } from '../../../../../../../utils/utils.js';
import { Avatars } from '../../../../../../../constants/const';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';

export function PlayerRow(props) {
  const { playerData, thisItemOpen, setThisItemOpen, handleActionMenuButtonClick, teamId } = props;

  return (
    <>
      {playerData.map((data, index) => {
        const isMenuOpen = thisItemOpen === String(data.childId);
        const classSelector = data.isManager ? styles.cell_player_manager : styles.cell_player_name;
        const {
          childFirstName,
          childId,
          childLastName,
          countVideo,
          image,
          picture,
          playerStatusGoal,
          playerGoal,
          timeVideo,
        } = data;
        const fullName = childFirstName.concat(childLastName ? `${' ' + childLastName}` : ' ');
        return (
          <tr className={styles.row} key={childId}>
            <th scope="row" className={styles.cell_first}>
              {index + 1}
            </th>
            <td className={classSelector}>
              <img
                src={image || Avatars[parseInt(picture) - 1]}
                className={styles.cell_logo}
                alt=""
              />
              <span>{fullName}</span>
            </td>
            <td className={styles.cell}>{countVideo}</td>
            <td className={styles.cell}>{timeVideo ? timeConvert(timeVideo) : '0m'}</td>
            <td className={styles.cell}>{playerGoal}</td>
            <td className={styles.cell}>
              <img className={styles.icon} src={playerStatusGoal ? PlayersGoal : RedCross} alt="" />
            </td>
            <td className={styles.cell}>
              <ProfileMenu
                managerData={data}
                teamId={teamId}
                isMenuOpen={isMenuOpen}
                thisItemOpen={thisItemOpen}
                setThisItemOpen={setThisItemOpen}
                picture={picture}
                handleActionMenuButtonClick={handleActionMenuButtonClick}
              />
            </td>
          </tr>
        );
      })}
    </>
  );
}
