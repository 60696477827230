import React, { useEffect, useState } from 'react';
import MainFoldersGridCards from './MainFoldersGridCards/mainFoldersGridCards';
import styles from './dashboardChild.module.sass';
import s from '../../app.module.sass';
import { Spinner } from 'reactstrap';
import {
  listOfCategoriesVimeo,
  getTrainingPlan,
  getWeeklyStreak,
  childInfoRequest,
} from '../../api/api';
import { message } from 'antd';
import GettingStartedPopup from '../GettingStartedPopup/GettingStartedPopup';
import { ChildGreeting } from './ChildGreeting/ChildGreeting';
import { setOnlyChildData } from '../../Redux/playerDataReducer';
import { useDispatch } from 'react-redux';
import { devEmail } from '../../utils/consts';

function MainFolders(props) {
  const [upLoadedData, setUpLoadedData] = useState(false);
  const {
    trainingPlan: { videoGoal, videoCompleted },
  } = props;
  const [weeklyStreakNumber, setWeeklyStreakNumber] = useState(0);
  const [isWeeklyStreakLoaded, setIsWeeklyStreakLoaded] = useState(false);
  const [showFirstSignInPopup, setShowFirstSignInPopup] = useState(!props.playerData.last_login);
  const dispatch = useDispatch();

  // useEffect для загрузки данных для папок
  useEffect(() => {
    let isComponentUnmounted = false;
    if (!props.playerData.id) {
      childInfoRequest().then((response) => {
        responseHandler(response);
      });
    }
    getTrainingPlan().then((response) => {
      requestTrainingPlanHandler(response);
    });
    if (!isWeeklyStreakLoaded) {
      getWeeklyStreak().then((response) => {
        requestWeeklyStreakHandler(response);
      });
    }
    if (props.vimeoFolderData.length === 0) {
      try {
        listOfCategoriesVimeo('finaleChildToken', 'vimeoCategoriesChild').then((response) => {
          if (!isComponentUnmounted) requestHandler(response);
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setUpLoadedData(true);
    }
    return () => (isComponentUnmounted = true);
  }, [props.vimeoFolderData.length]);

  const responseHandler = (response) => {
    response.json().then((result) => {
      dispatch(setOnlyChildData(result));
    });
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (result?.data) {
        props.setChildDashVimeoInfo(result.data.map((a) => ({ uri: a.uri, name: a.name })));
      } else {
        console.error(`Can't get Vimeo info.`.toUpperCase());
      }
      setUpLoadedData(true);
    });
  };

  const requestTrainingPlanHandler = React.useCallback((response) => {
    if (!response.ok) return;

    response
      .json()
      .then((result) => {
        props.setTrainingPlan(result);
        props.setTrainingGoal(true);
      })
      .catch((e) => {
        console.error(e?.message || e);
      });
  }, []);

  const requestWeeklyStreakHandler = (response) => {
    setIsWeeklyStreakLoaded(true);
    response
      .json()
      .then((result) => {
        setWeeklyStreakNumber(result.weeklyStreak);
      })
      .catch(() => {
        message.error(
          'We can`t load your week streak information. Please try refreash page in some minutes.',
        );
      });
  };

  return (
    <>
      {upLoadedData ? (
        <>
          {showFirstSignInPopup && (
            <GettingStartedPopup setShowFirstSignInPopup={setShowFirstSignInPopup} />
          )}
          <div className={styles.header}>
            <ChildGreeting userScreenName={props.playerData.screenname} />
            <div className={styles.header__statistics}>
              <p className={styles.header__statistics_video_goal}>
                Video Goal: {videoCompleted}/{videoGoal}
              </p>
              <p className={styles.header__statistics_weak_streak}>
                Weekly Streak: {weeklyStreakNumber}
              </p>
            </div>
          </div>
          <MainFoldersGridCards
            dashboardInfo={props.dashboardInfo}
            vimeoFolderData={props.vimeoFolderData}
            accessAllVideos={
              props.playerData.username === devEmail ? true : props.playerData.accessAllVideos
            }
            playerData={props.playerData}
            signUpData={props.signUpData}
          />
        </>
      ) : (
        <div style={{ background: '#f4fbff', minHeight: '80vh' }}>
          <Spinner color="primary" className={s.spinner} />
        </div>
      )}
    </>
  );
}

export default MainFolders;
