import React, { useState, useEffect } from "react";
import styles from './rewardCenter.module.sass';
import RewardCard from '../RewardCard/RewardCard';
import PaginationLogic from '../../DashboardChild/VideoSeries/Pagination';
import { getChildAwardList } from '../../../api/api.js';
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { Loading } from "../../commonComponents/Loading/Loading";

function RewardCenter(props) {
    const { userAwards, setUserAwards } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [requestPayload, setRequestPayload] = useState(false);
    const pages = userAwards.totalPage;
    const [isLoadedData, setIsLoadedData] = useState(false);
    
    useScrollToTop();
    useEffect(() => {
        if (!userAwards.isUserAwardsLoaded) {
            getChildAwardList()
                .then((response) => {
                    requestUserAwardsHandler(response);
                })
        } else {
            setIsLoadedData(true);
        }
    }, []);

    const changePage = (direction) => {
        if (direction === 'prev' && 1 <= currentPage - 1) {
            setCurrentPage(currentPage - 1);
            lessonsRequest(currentPage - 1);
        } else if (direction === 'next' && pages >= currentPage + 1) {
            setCurrentPage(currentPage + 1);
            lessonsRequest(currentPage + 1);
        }
    };

    const requestUserAwardsHandler = (response) => {
        response.json().then(result => {
            setUserAwards(result);
            setIsLoadedData(true);
        })
            .catch((response) => {
                console.log(response);
            })
    };

    const lessonsRequest = e => {
        if (!requestPayload) {
            setRequestPayload(true);
            setCurrentPage(e);
            onChange(e);
        }
    };

    const onChange = (event) => {
        try {
            getChildAwardList(event).then(response => requestHandler(response));
        } catch (e) {
            console.log(e);
        }
    };

    const requestHandler = (response) => {
        response.json().then(result => {
            setUserAwards(result);
        })
            .catch((response) => {
                console.log(response);
            })
    };

    return (
        <>
            {isLoadedData ?
                (<div className={styles.certificate_center}>
                    <section className={styles.reward_container}>
                        {userAwards.certificate.map((element, index) => {
                            return <RewardCard
                                element={element}
                                key={element.targetDate + index}
                            />
                        })}
                    </section>
                    {pages > 1 && (<PaginationLogic
                        request={lessonsRequest}
                        changePage={changePage}
                        pages={pages}
                    />)}
                </div>) :
                (<Loading height={'20vh'} />)}
        </>
    );
}

export default RewardCenter;