import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './dashboardChild.module.sass';
import GridCards from './GridCards/gridCards';
import { listOfCategoriesVimeo } from '../../api/api';
import { parentRedirect, haveNoLoginRedirect } from '../../hoc/redirectComp';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setChildDashVimeoInfo } from '../../Redux/childDashVimeoInfoReducer';
import { CHILD_TOKEN, VIMEO_CHILD_CATEGORIES } from '../../constants/const';
import { Loading } from '../commonComponents/Loading/Loading';
import { devEmail } from '../../utils/consts';
import ListGridViewButtons from './ListGridViewButtons/ListGridViewButtons';
import ListViewPage from './ListView/ListViewPage';
import { setCategory } from '../../Redux/listViewCategoryReducer';
import { setSubcategory } from '../../Redux/listViewSubcategoryReducer';
import { switchToListView } from '../../Redux/listGridViewReducer';

const DashboardChild = (props) => {
  const listGridViewData = useSelector((state) => state.listGridViewReducer);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const dispatch = useDispatch();

  // for 'free section' there is no list view
  const { pathname } = useLocation();
  const category = pathname.split('/').at(2);

  useEffect(() => {
    if (props.vimeoFolderData.length === 0) {
      try {
        listOfCategoriesVimeo(CHILD_TOKEN, VIMEO_CHILD_CATEGORIES).then((response) =>
          requestHandler(response),
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      setUpLoadedData(true);
    }
    // list view is opened especially in its category list views
    if (category === 'playerJourneys') {
      dispatch(setCategory('SET_MY_FAVORITE_DATA'));
      dispatch(setSubcategory('SET_MY_FAVORITE_CATEGORY_DATA'));
    }
    if (category === 'beginnersKickStart') {
      dispatch(switchToListView());
      dispatch(setCategory('SET_BEGINNERS_ESSENTIALS_DATA'));
      dispatch(setSubcategory('SET_BEGINNERS_ESSENTIALS_CATEGORY_DATA'));
    }

    if (category === '30DayPlan') {
      dispatch(setCategory('SET_THIRTY_DAY_PLANS_DATA'));
      dispatch(setSubcategory('SET_GET_STARTED_AND_NEXT_LEVEL_PLANS_DATA'));
    }

    if (category === 'team-homework') {
      dispatch(setCategory('SET_MY_HOMEWORK_DATA'));
    }
    if (category === 'all-programs') {
      dispatch(setCategory('SET_ALL_PROGRAMS_DATA'));
    }
    
    if (category === 'getStarted') {
      dispatch(setCategory('SET_GET_STARTED_DATA'));
      dispatch(setSubcategory('SET_BALL_MASTERY_GS_DATA'));
    }
  }, []);

  const requestHandler = (response) => {
    response.json().then((result) => {
      dispatch(
        setChildDashVimeoInfo(
          result.data.map((a) => {
            return { uri: a.uri, name: a.name };
          }),
        ),
      );
      setUpLoadedData(true);
    });
  };

  return (
    <>
      {upLoadedData ? (
        <div>
          <div>
            <p className={styles.container_title}>
              Welcome to Your Member Dashboard,
              {' ' + props.playerData.screenname}!
            </p>
          </div>

          {/** for free section there is only grid view */}
          <div className={styles.listGridViewButton}>
            {category === 'freeSection' || <ListGridViewButtons />}
          </div>
          {listGridViewData === 'GRID_VIEW' || category === 'freeSection' ? (
            <GridCards
              dashboardInfo={props.dashboardInfo}
              vimeoFolderData={props.vimeoFolderData}
              accessAllVideos={
                props.playerData.username === devEmail ? true : props.playerData.accessAllVideos
              }
            />
          ) : (
            <ListViewPage />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default compose(parentRedirect, haveNoLoginRedirect)(DashboardChild);
