import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../HomeMenu/HomeMenu.module.sass';
import menuClose from '../../../../asset/home/menuClose.webp';
import { useLocation } from 'react-router-dom';

const HomeMenu = ({ setMobileMenu, mobileMenu, path }) => {
  const location = useLocation();

  const closeMenu = () => {
    if (setMobileMenu) {
      setMobileMenu(!mobileMenu);
    }
  };

  return (
    <div className={styles.mobile_container}>
      <div className={styles.mobile_links}>
        <img src={menuClose} alt="menuClose" onClick={closeMenu} />
        <ul>
          <div>
            <Link to={path.home}>
              <li onClick={closeMenu} className={styles.li}>
                Home
              </li>
            </Link>
          </div>
          <div>
            <li className={styles.li} onClick={closeMenu}>
              <a
                href="https://www.facebook.com/groups/anytimesoccer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Community
              </a>
            </li>
          </div>
          <div>
            <li className={styles.li} onClick={closeMenu}>
              <a href="https://anchor.fm/anytime-soccer" target="_blank" rel="noopener noreferrer">
                Podcast
              </a>
            </li>
          </div>
        </ul>
      </div>

      <div className={styles.home_links}>
        {location.pathname === '/auth/registerFree' ? null : (
          <Link to={path.sign.upFree}>
            <li onClick={closeMenu} className={styles.visit_btn}>
              Get Started
            </li>
          </Link>
        )}
        <Link to={path.sign.in}>
          <li className={styles.signIn} onClick={closeMenu}>
            <i className="fas fa-user"></i>&nbsp;&nbsp;&nbsp;Sign In
          </li>
        </Link>
      </div>
    </div>
  );
};

export default HomeMenu;
