import React from 'react';
import styles from './rewardCertificate.module.sass';
import { getFormattedDate } from '../../../utils/utils.js';

const RewardCertificate = (props) => {
  const {
    trainingPlan: { targetDate, name, reward },
  } = props;

  return (
    <div className={styles.container}>
      <section className={styles.certificate}>
        <img className={styles.certificate__border} src="/img/Border.webp" alt="" />
        <div className={styles.certificate__logo}>
          <img
            src="/img/logo.webp"
            className={styles.certificate__logo__img}
            alt="Anytime Soccer logo"
          />
          <h3 className={styles.certificate__logo__title}>Anytime Soccer Training</h3>
        </div>
        <p className={styles.certificate__date}>{getFormattedDate(targetDate)}</p>
        <p className={styles.certificate__user_name}>{name}</p>
        <p className={styles.certificate__description}>
          has successfully completed an
          <br />
          <span className={styles.certificate__description_red}>Anytime Soccer Training Plan</span>
        </p>
        <p className={styles.certificate__reward}>
          Congratulations! Claim your reward
          <span className={styles.user_reward}>{reward}</span>
        </p>
        <img src="/img/trainingPlan/signature.webp" className={styles.certificate__sign} alt="" />
        <p className={styles.certificate__footer}>Neil M. Crawford</p>
        <p className={styles.certificate__footer_strong}>Founder</p>
        <p className={styles.certificate__footer}>Anytime Soccer Training</p>
      </section>
      <img
        className={styles.label}
        src="/img/trainingPlan/Ribbon.webp"
        alt="Reward certificate ribbon with logo"
      />
    </div>
  );
};

export default RewardCertificate;
