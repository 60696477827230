import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Form.module.sass';
import path from '../../../../config';
import mainRoute from '../../../../mainRoute';
import { message } from 'antd';
import { Spinner } from 'reactstrap';
import routes from '../../../../config/routes.json';

const NOT_UNIQUE_EMAIL_ERROR = 'This email already exist';

function Form({ signUpFieldValues }) {
  const [toggleEmailErr, setToggleEmailErr] = useState(false);
  const [toggPassConfirmCheckState, setToggPassConfirmCheckState] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [loading, setLoading] = useState(false);
  const { step1 } = signUpFieldValues;
  const history = useHistory();

  /** CONFIRM PASSWORD CHECK (method for Yup check if password is equal) */
  function equalTo(ref, msg) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      message: msg || ' must be the same as ',
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value === this.resolve(ref);
      },
    });
  }

  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  const toggPassConfirmCheck = () => {
    // если пароли не совпадают выводит надпись
    if (formik.values.passwordConfirm && formik.errors.passwordConfirm === 'Passwords must match') {
      setToggPassConfirmCheckState(false);
    } else {
      setToggPassConfirmCheckState(true);
    }
  };

  const handleEmailInputClick = () => {
    if (toggleEmailErr) setToggleEmailErr(false);
  };

  const handleConfirmMailClick = () => {
    if (!toggPassConfirmCheckState) setToggPassConfirmCheckState(!toggPassConfirmCheckState);
  };

  const formik = useFormik({
    initialValues: step1
      ? step1
      : {
          parentFirstName: '',
          parentLastName: '',
          email: '',
          password: '',
          passwordConfirm: '',
          players: '1',
          discountCoupon: '',
        },
    validationSchema: Yup.object({
      parentFirstName: Yup.string().trim().required('Required'),
      parentLastName: Yup.string().trim().required('Required'),
      password: Yup.string().trim().required('Required'),
      passwordConfirm: Yup.string()
        .trim()
        .equalTo(Yup.ref('password'), 'Passwords must match')
        .required('Required'),
      players: Yup.string().trim().required('Required'),
      email: Yup.string().trim().email('Required').required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await fetch(`${mainRoute.route + path.confirmEmail}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
            parentFirstName: values.parentFirstName,
            parentLastName: values.parentLastName,
            promoName: coupon,
          }),
        });
        const statusJson = await response;

        //new
        if (statusJson.status === 200) {
          history.replace('/registerFreeThunk');
        } else {
          setToggleEmailErr(!toggleEmailErr);
          message.error(NOT_UNIQUE_EMAIL_ERROR);
          setLoading(false);
        }
      } catch (e) {
        message.error(e.message);
      }
    },
  });

  const submitCoupon = async (e) => {
    e.preventDefault();
    if (coupon === '') {
      return message.error('Enter your Team Code!');
    }
    try {
      const res = await fetch(`${mainRoute.route + path.applyCoupon}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          promoName: coupon,
        }),
      });
      const resStatus = await res;
      const data = await res.json();
      if (resStatus.status === 200) {
        message.success('Team Code entered successfully');
      } else {
        message.error(data.message);
      }
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
      <p className={styles.form__description}>
        Fields marked with <span className={styles.red}>*</span> are mandatory
      </p>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.col}>
            <label htmlFor="parentFirstName" className={styles.label}>
              First Name
            </label>
            <input
              className={`${styles.input} ${
                formik.touched.parentFirstName && formik.errors.parentFirstName
                  ? styles[formik.errors.parentFirstName]
                  : ''
              }`}
              name="parentFirstName"
              id="parentFirstName"
              aria-required="true"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.parentFirstName.replace(/[^A-z\s]/g, '')}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="parentLastName" className={styles.label}>
              Last Name
            </label>
            <input
              className={`${styles.input} ${
                formik.touched.parentLastName && formik.errors.parentLastName
                  ? styles[formik.errors.parentLastName]
                  : ''
              }`}
              name="parentLastName"
              id="parentLastName"
              type="text"
              aria-required="true"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.parentLastName.replace(/[^A-z\s]/g, '')}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="email" className={styles.label}>
              Email address
            </label>
            {toggleEmailErr && (
              <p className={styles.invalidEmailAdressMessage}>{NOT_UNIQUE_EMAIL_ERROR}</p>
            )}
            <input
              className={`${styles.input} ${
                formik.touched.email && formik.errors.email ? styles[formik.errors.email] : ''
              }`}
              name="email"
              id="email"
              type="text"
              onClick={handleEmailInputClick}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email.replace(/[^A-z@._0-9-]/g, '')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <label htmlFor="password" className={styles.label}>
              Password
            </label>
            <input
              className={`${styles.input} ${
                formik.touched.password && formik.errors.password
                  ? styles[formik.errors.password]
                  : ''
              }`}
              name="password"
              id="password"
              type="password"
              aria-required="true"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password.replace(/[\s]/g, '')}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="passwordConfirm" className={styles.label}>
              Confirm Password
            </label>
            <input
              className={`${styles.input} ${
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
                  ? styles.Required
                  : ''
              }`}
              name="passwordConfirm"
              id="passwordConfirm"
              type="password"
              aria-required="true"
              onClick={handleConfirmMailClick}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirm.replace(/[\s]/g, '')}
            />
            {!toggPassConfirmCheckState && (
              <p className={styles.confirmPass}> Passwords must match</p>
            )}
          </div>
        </div>
        <label htmlFor="discountCoupon" className={styles.label}>
          Team Code <span className={styles.form__description}>- optional</span>
        </label>
        <div className={styles.container_coupon}>
          <input
            className={`${styles.input_coupon} ${formik.touched.discountCoupon}`}
            name="discountCoupon"
            id="discountCoupon"
            type="text"
            onChange={(e) => {
              setCoupon(e.target.value);
            }}
            onBlur={formik.handleBlur}
            value={coupon}
          />
          <button className={styles.gray_button} onClick={submitCoupon}>
            Apply
          </button>
        </div>
        <p className={styles.mobSign}>
          Already have an account?{' '}
          <Link to={routes.sign.in} className={styles.link}>
            {' '}
            Sign In{' '}
          </Link>
        </p>
        <div className={styles.btn_step}>
          {loading ? (
            <Spinner color="primary" style={{ width: '5rem', height: '5rem' }} />
          ) : (
            <button
              type="submit"
              id="toggleForBtnNext"
              className={styles.buttonDefault}
              onClick={toggPassConfirmCheck}
            >
              Create Account
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default Form;
