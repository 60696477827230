import { SubfolderPageUrl } from '../constants/const';

const initialState = {
  data: [
    {
      title: '(Start) Quick Tips',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_17_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '30 Day Player',
      subTitle: 'Getting Started Essentials',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_18_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(Start) Juggle Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_19_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(Start) Wall Pass Pro',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_20_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Dribble Challenge',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_21_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const getStartedReducer = (state = initialState) => {
  return state;
};

export default getStartedReducer;
