import React from 'react';
import styles from '../Table.module.sass';
import { Link } from 'react-router-dom';
import lockalPath from '../../../../config/routes';
import ImageLogin from '../../../../../src/asset/dashboard/dashboardChild/avatar.webp';

const ChildrenRowMobile = ({
  resultStatus,
  slider,
  loginAsPlayerRequest,
  screenName,
  picture,
  image,
  status,
  count,
}) => {
  const checkPhotoOrPicture = () => {
    let avatar = null;
    if (image) {
      avatar = 'image';
    } else if (picture) {
      avatar = 'picture';
    }
    switch (avatar) {
      case 'image':
        return <img src={image} className={styles.photo} alt="avatar" />;
      case 'picture':
        return (
          <p className={styles.avatar_item}>
            <img
              src={require(`../../../../asset/dashboard/avatars/${picture}_100.webp`)}
              alt="avatar"
            />
          </p>
        );
      default:
        return (
          <p className={styles.avatar_item}>
            <img alt="avatar" src={ImageLogin} className={styles.avatar_picture} />
          </p>
        );
    }
  };
  return (
    <div className={styles.mobile_container}>
      <div className={styles.user_container}>
        <div className={styles.user_container_screenname}>
          {checkPhotoOrPicture()}
          <p>{screenName}</p>
        </div>
      </div>
      <div className={styles.action_container}>
        <div className={styles.action_slider}>
          {slider ? (
            <div className={styles.action_slider__action}>
              <div className={styles.action_slider__action_btn}>
                <button
                  onClick={loginAsPlayerRequest}
                  className={styles.button}
                  style={
                    status === 'active' || status === 'free' || status === 'nonSubscription'
                      ? { backgroundColor: '#dd373e' }
                      : { backgroundColor: '#b5b9b8' }
                  }
                  disabled={
                    status === 'active' || status === 'free' || status === 'nonSubscription'
                      ? false
                      : true
                  }
                >
                  Login
                </button>
              </div>
              <div className={styles.action_slider__action_edit}>
                <Link
                  to={lockalPath.dashboard.editPlayerInfo + '/' + count}
                  className={`${styles.button} ${styles.bodered}`}
                >
                  Edit
                </Link>
              </div>
            </div>
          ) : (
            <div className={styles.container_status}>
              <p>{resultStatus}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildrenRowMobile;
