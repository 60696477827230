import React, { useState } from 'react';
import { TEAMS, NO_ACTIVE_TEAMS, NO_PENDING_TEAMS } from '../../../../constants/teams';
import styles from './ProxyTeamsComponent.module.sass';
import { ActiveCard } from '../TeamItem/ActiveCard';
import { PendingCard } from '../PendingCard/PendingCard';
import { NoCards } from '../NoCards/NoCards';
import { DefaultRadioButton } from '../../../../constants/teams';
import PaginationLogic from '../../VideoSeries/Pagination';
import { getActiveTeamList, getPendingTeamList } from '../../../../api/teams-api';
import { showErrorMessage } from '../../../../utils/throwError';

export function ProxyTeamsComponent(props) {
  const {
    checkedRadioButton,
    activeTeamCards,
    setActiveTeamCards,
    pendingTeamCards,
    setPendingTeamCards,
    isNeedUpdate,
    setIsNeedUpdate,
  } = props;

  const [activeTeamCurrentPage, setActiveTeamCurrentPage] = useState(1);
  const [pendingTeamCurrentPage, setPendingTeamCurrentPage] = useState(1);
  const [requestActivePayload, setRequestActivePayload] = useState(false);
  const [requestPendingPayload, setRequestPendingPayload] = useState(false);
  const activePages = activeTeamCards?.totalPage;
  const pendingPages = pendingTeamCards?.totalPage;

  const changeActivePage = direction => {
    if (direction === 'prev' && 1 <= activeTeamCurrentPage - 1) {
      setActiveTeamCurrentPage(activeTeamCurrentPage - 1);
      lessonsActiveRequest(activeTeamCurrentPage - 1);
    } else if (direction === 'next' && activePages >= activeTeamCurrentPage + 1) {
      setActiveTeamCurrentPage(activeTeamCurrentPage + 1);
      lessonsActiveRequest(activeTeamCurrentPage + 1);
    }
  };

  const changePendingPage = direction => {
    if (direction === 'prev' && 1 <= pendingTeamCurrentPage - 1) {
      setPendingTeamCurrentPage(pendingTeamCurrentPage - 1);
      lessonsPendingRequest(pendingTeamCurrentPage - 1);
    } else if (direction === 'next' && pendingPages >= pendingTeamCurrentPage + 1) {
      setPendingTeamCurrentPage(pendingTeamCurrentPage + 1);
      lessonsPendingRequest(pendingTeamCurrentPage + 1);
    }
  };

  const lessonsActiveRequest = e => {
    if (!requestActivePayload) {
      setRequestActivePayload(true);
      setActiveTeamCurrentPage(e);
      onActiveTeamChange(e);
    }
  };

  const lessonsPendingRequest = e => {
    if (!requestPendingPayload) {
      setRequestPendingPayload(true);
      setPendingTeamCurrentPage(e);
      onPendingTeamChange(e);
    }
  };

  const onActiveTeamChange = event => {
    try {
      getActiveTeamList(event).then(response => requestActiveHandler(response));
    } catch (e) {
      console.log(e);
    }
  };

  const onPendingTeamChange = event => {
    try {
      getPendingTeamList(event).then(response => requestPendingHandler(response));
    } catch (e) {
      console.log(e);
    }
  };

  const requestActiveHandler = response => {
    setRequestActivePayload(false);
    if (response.ok) {
      response.json().then(result => {
        setActiveTeamCards(result);
      });
    } else {
      showErrorMessage(`status: ${response.status} ${response.statusText}`);
    }
  };

  const requestPendingHandler = response => {
    setRequestPendingPayload(false);
    if (response.status === 200) {
      response.json().then(result => {
        setPendingTeamCards(result);
      });
    } else {
      showErrorMessage(`status: ${response.status} ${response.statusText}`);
    }
  };
  
  return (
    <>
      <h3 className={styles.title}>My {TEAMS}</h3>
      <article className={styles.card_container}>
        {checkedRadioButton === DefaultRadioButton.ACTIVE &&
          (activeTeamCards?.totalPage ? (
            <>
              {activeTeamCards.activeTeams.map(teamCard => (
                <ActiveCard key={teamCard.teamId} teamCard={teamCard} />
              ))}
              {activeTeamCards?.totalPage > 1 && (
                <PaginationLogic
                  currentPage={activeTeamCurrentPage}
                  request={lessonsActiveRequest}
                  changePage={changeActivePage}
                  pages={activePages}
                />
              )}
            </>
          ) : (
            <NoCards>{NO_ACTIVE_TEAMS}</NoCards>
          ))}
        {checkedRadioButton === DefaultRadioButton.PENDING &&
          (pendingTeamCards?.totalPage ? (
            <>
              {pendingTeamCards.inviteTeams.map(teamCard => (
                <PendingCard
                  key={teamCard.teamId}
                  teamCard={teamCard}
                  setIsNeedUpdate={setIsNeedUpdate}
                  isNeedUpdate={isNeedUpdate}
                />
              ))}
              {pendingTeamCards?.totalPage > 1 && (
                <PaginationLogic
                  currentPage={pendingTeamCurrentPage}
                  request={lessonsPendingRequest}
                  changePage={changePendingPage}
                  pages={pendingPages}
                />
              )}
            </>
          ) : (
            <NoCards>{NO_PENDING_TEAMS}</NoCards>
          ))}
        {checkedRadioButton === DefaultRadioButton.PENDING && (
          <p className={styles.info}>
            If you have multiple players associated with this account, <br /> be sure to only accept the
            appropriate teams for each of your players.
          </p>
        )}
      </article>
    </>
  );
}
