import React from 'react';
import styles from './Selector.module.sass';

export function Selector({ selectorList, activeSelector, setActiveSelector }) {
  const handleRadioButtonClick = event => {
    setActiveSelector(event.target.value);
  };
  return (
    <>
      {Object.keys(selectorList).map(selector => {
        return (
          <li className={styles.selector_item} key={selector} onClick={handleRadioButtonClick}>
            <input
              type="radio"
              id={selector}
              name="component"
              value={selectorList[selector]}
              className={styles.radio_button}
              defaultChecked={activeSelector === selectorList[selector]}
            />
            <label
              htmlFor={selector}
              tabIndex="0"
              value={selectorList[selector]}
              className={styles.label}
            >
              {selectorList[selector]}
            </label>
          </li>
        );
      })}
    </>
  );
}
