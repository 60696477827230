import React, { useMemo, useState } from 'react';
import styles from './Contest.module.sass';
import { ChallengeButtons } from './ChallengeButtons';
import ViewCoachChallenge from './View';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { BsArrowClockwise, BsFillStopFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import useCoachChallenge from '../../../../hooks/useCoachChallenge';
import { Loading } from '../../../commonComponents/Loading/Loading';
import ScoreTable from './ScoreTable';

const CoachChallenge = ({ currentTeam, isManager, isPlayerManager }) => {
  const history = useHistory();
  const [isNotifying, setIsNotifying] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [viewTab, setViewTab] = useState('');
  const {
    data: challenge,
    isLoading,
    handleDuplicateChallenge,
    handleNotifyChallenge,
  } = useCoachChallenge(currentTeam.id);

  const handleNotify = (id) => {
    setIsNotifying(true);
    handleNotifyChallenge(id, {
      onSettled: () => setIsNotifying(false),
    });
  };

  const handleDuplicate = (id) => {
    setIsDuplicating(true);
    handleDuplicateChallenge(
      { id, status: 'ongoing' },
      {
        onSettled: () => setIsDuplicating(false),
      },
    );
  };

  const handleStopChallenge = (id) => {
    setIsStopping(true);
    handleDuplicateChallenge(
      { id, status: 'stop' },
      {
        onSettled: () => setIsStopping(false),
      },
    );
  };

  const renderChallenge = useMemo(
    () => (
      <ViewCoachChallenge
        challenge={challenge}
        isManager={isManager}
        isPlayerManager={isPlayerManager}
      />
    ),
    [challenge],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={styles.contest}
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
        }}
      >
        {!challenge && (
          <h2 className={styles.contest__title} style={{ textTransform: 'uppercase' }}>
            {currentTeam.name}
          </h2>
        )}
        {challenge ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '15px',
              gap: '12px',
            }}
          >
            <h2
              className={styles.contest__info}
              style={{
                color: '#164162',
                fontWeight: '600',
              }}
            >
              Coach&apos;s Challenges {viewTab}
            </h2>
            <div className={styles.buttonActions}>
              <span
                style={{
                  color: '#164162',
                }}
              >
                {challenge?.challenge && (
                  <>
                    <span
                      className={styles.statLabel}
                      style={{
                        opacity: '0.7',
                      }}
                    >
                      Target Date:
                    </span>
                    <span
                      className={styles.statLabel}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {' '}
                      {challenge?.challenge?.targetDate
                        ? moment(challenge?.challenge?.targetDate).format('ll')
                        : ''}
                    </span>
                  </>
                )}
              </span>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <button
                  className={`${styles.button} ${styles.actionb}`}
                  style={{
                    background: '#fff',
                    color: '#e1343f',
                    border: '1px solid #e1343f',
                  }}
                  onClick={() => {
                    if (viewTab === 'Results') {
                      setViewTab('');
                    } else {
                      setViewTab('Results');
                    }
                  }}
                >
                  Score
                </button>
                {(isManager || isPlayerManager) && (
                  <>
                    {challenge?.challenge?.status === 'ongoing' ? (
                      <button
                        className={`${styles.button} ${styles.actionb}`}
                        onClick={() => handleStopChallenge(challenge.challenge.id)}
                      >
                        <BsFillStopFill />
                        {isStopping ? 'Stopping' : 'Stop'} Challenge
                      </button>
                    ) : (
                      <button
                        className={`${styles.button} ${styles.actionb}`}
                        onClick={() => handleDuplicate(challenge.challenge.id)}
                      >
                        <BsArrowClockwise />
                        {isDuplicating ? 'Repeating' : 'Repeat'} Challenge
                      </button>
                    )}
                    <button
                      className={`${styles.button} ${styles.actionb}`}
                      onClick={() => {
                        history.push('challenge/create');
                      }}
                    >
                      <PlusOutlined />
                      New Challenge
                    </button>
                    <button
                      className={`${styles.button} ${styles.actionb}`}
                      onClick={() => handleNotify(challenge.challenge.id)}
                    >
                      {isNotifying ? 'Notifying' : 'Notify'} Players
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.header}>
            <h2 className={styles.contest__info}>Coach&apos;s Challenges</h2>
            <ChallengeButtons
              isManager={isManager}
              isPlayerManager={isPlayerManager}
              challenge={challenge?.challenge}
            />
          </div>
        )}
      </div>
      {viewTab === 'Results' ? (
        <ScoreTable
          playerData={challenge.players}
          teamId={currentTeam.id}
          isManager={isManager}
          isPlayerManager={isPlayerManager}
        />
      ) : (
        renderChallenge
      )}
    </div>
  );
};

export default CoachChallenge;
