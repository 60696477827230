import React from 'react';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import styles from './TermsNConditions.module.sass';

function TermsNConditions() {
  useScrollToTop();

  return (
    <main className={styles.main}>
      <section>
        <article>
          <h1 id="terms-and-conditions-of-sale">Terms and Conditions of Sale</h1>
          <p>
            PLEASE CAREFULLY READ THESE TERMS OF USE AND TERMS AND CONDITIONS OF SALE (collectively
            with any amendments hereto, “TERMS AND CONDITIONS”). BY USING THIS WEB SITE OR ANY WEB
            SITE OF Anytime SOCCER TRAINING, AND/OR PLACING AN ORDER FOR Anytime SOCCER TRAINING,
            PRODUCTS OR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ANY
            AMENDMENTS THERETO, AND AGREE THAT YOU ARE AT LEAST 13 YEARS OF AGE AND ELIGIBLE TO USE
            THIS SITE AND PLACE AN ORDER FOR Anytime SOCCER TRAINING’S PRODUCTS AND SERVICES. IF YOU
            ARE 13 TO 17 YEARS OF AGE, YOU MUST USE THIS SITE AND/OR PURCHASE AND USE Anytime SOCCER
            TRAINING’S PRODUCTS OR SERVICES ONLY WITH THE INVOLVEMENT OF YOUR PARENT OR GUARDIAN.
          </p>
          <p>
            IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS, PLEASE DO NOT USE THE SITE OR ORDER OR
            USE Anytime SOCCER TRAINING’S PRODUCTS OR SERVICES.
          </p>
          <p>
            Welcome to the Web site of Anytime Soccer Training, among others (the “Site”)
            (collectively with its wholly owned business units, including Anytime Soccer Training®,
            or “us” or “we”). These Terms and Conditions govern and apply to your (“you” or “your”)
            access to and use of this Site and its related domains on which this document appears,
            any order you place through any of the Anytime Soccer Training Web sites, and—as
            applicable—your use of other products or services of Anytime Soccer Training. You must
            read these Terms and Conditions carefully before placing an order, or using the Site. By
            using the Site, and/or placing an order through the Site, you confirm that you have
            read, understood, and agree to these Terms and Conditions in their entirety. If you do
            not agree to these Terms and Conditions in their entirety, you must not order any
            product or service through or otherwise use the Site. The Terms and Conditions may be
            changed in the future without further notice. Your continued use of the Site and/or
            other products or services of Anytime Soccer Training after any such changes constitutes
            your acceptance of the new Terms and Conditions. These Terms and Conditions apply to
            your access to, and use of, the Site, any order you place through any of the Anytime
            Soccer Training Web sites, and to all products and services we supply, produce,
            distribute, or market. To the extent of any express inconsistency with any other
            agreement you may have with Anytime Soccer Training for products, services, or
            otherwise, that other agreement shall prevail unless explicitly stated therein. If these
            Terms and Conditions refer or relate to a clause that is not part of that other
            agreement, then these terms shall apply therein as well, if you are a user of the Site.
            If you register for our Support Center features, including, but not limited to, the
            Message Boards or Anytime Soccer Training Support Team, additional rules, policies, and
            disclaimers may apply.
          </p>
          <h2 id="user-conduct">User Conduct</h2>
          <p>
            You must only use the Site for lawful purposes, and you must not use it in a way that
            infringes the rights of anyone else or that restricts or inhibits anyone else’s
            enjoyment of the Site. You may not without our prior written consent:
          </p>
          <ul>
            <li>
              <p>copy, reproduce, use, or otherwise deal with any content on the Site;</p>
            </li>
            <li>
              <p>modify, distribute, or re-post any content on the Site for any purpose;</p>
            </li>
            <li>
              <p>
                or use the content of the Site for any commercial exploitation whatsoever. In using
                the Site, you further agree:
              </p>
            </li>
            <li>
              <p>
                not to disrupt or interfere with the security of, or otherwise abuse, the Site, or
                any services, system resources, accounts, servers, or networks connected to or
                accessible through the Site or affiliated or linked sites;
              </p>
            </li>
            <li>
              <p>
                not to disrupt or interfere with any other user’s enjoyment of the Site or
                affiliated or linked Web sites;
              </p>
            </li>
            <li>
              <p>
                not to upload, post, or otherwise transmit through or on the Site any viruses or
                other harmful, disruptive, or destructive files;
              </p>
            </li>
            <li>
              <p>
                not to use, frame, or utilize framing techniques to enclose any Anytime Soccer
                Training trademark, logo, or other proprietary information (including the images
                found at the Site, the content of any text, or the layout/design of any page or form
                contained on a page) without Anytime Soccer Training’s express written consent;
              </p>
            </li>
            <li>
              <p>
                not to use meta tags or any other “hidden text” utilizing a Anytime Soccer Training
                name, trademark, or product name without Anytime Soccer Training’s express written
                consent;
              </p>
            </li>
            <li>
              <p>
                not to deeplink to this Site without Anytime Soccer Training’s express written
                consent;
              </p>
            </li>
            <li>
              <p>
                not to create or use a false identity on this Site, share your account information,
                or allow any person besides yourself to use your account to access the Site;
              </p>
            </li>
            <li>
              <p>not to collect or store personal data about others;</p>
            </li>
            <li>
              <p>
                not to attempt to obtain unauthorized access to the Site or portions of the Site
                that are restricted from general access;
              </p>
            </li>
            <li>
              <p>
                not to post any material that is knowingly false and/or defamatory, inaccurate,
                abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented,
                threatening, invasive of a person’s privacy, or otherwise violative of any law. You
                also agree not to post any copyrighted material unless the copyright is owned by you
                or by Anytime Soccer Training;
              </p>
            </li>
            <li>
              <p>
                and to be bound by the Product Submission Policies of Anytime Soccer Training,
                including that any product submission you may make to Anytime Soccer Training will
                not be held in confidence by Anytime Soccer Training, and is not proprietary, that
                Anytime Soccer Training may use the product submission and any aspect thereof for
                any purposes in Anytime Soccer Training’s sole discretion, and that Anytime Soccer
                Training owes no duties or obligations with respect to you or the product submission
                made. In addition, you agree that you will comply with all applicable local,
                national, and international laws and regulations that relate to your use of or
                activities on this Site.
              </p>
            </li>
          </ul>
          <p>
            We will not be responsible, or liable to any third party, for the content or accuracy of
            any materials posted by you or any other user of the Site. We have the right to remove
            any material or posting you make on the Site if, in our opinion, such material does not
            comply with the content standards set out in these Terms and Conditions.
          </p>
          <h2 id="changes-to-the-site">Changes to the Site</h2>
          <p>
            Anytime Soccer Training may, in its sole discretion, terminate, change, modify, suspend,
            make improvements to, or discontinue any aspect of the Site or any products available
            through or outside of the Site, temporarily or permanently, including the availability
            of any features of the Site or access to any parts of the Site, at any time without
            notice to you, and you agree that Anytime Soccer Training shall not be liable therefore.
          </p>
          <h2 id="linked-sites">Linked Sites</h2>
          <p>
            Anytime Soccer Training makes no claim or representation regarding, and accepts no
            responsibility for, the quality, content, nature, or reliability of sites accessible by
            hyperlink from this Site, or sites linking to this Site. The linked sites are not under
            the control of Anytime Soccer Training, and Anytime Soccer Training is not responsible
            for the content of any linked site or any link contained in a linked site, or any
            review, changes, or updates to such sites. The inclusion of any link does not imply
            affiliation, endorsement, or adoption by Anytime Soccer Training of the site or any
            information contained therein. When leaving the Site, you should be aware that Anytime
            Soccer Training’s Terms and Conditions and policies no longer govern, and therefore you
            should review the applicable terms and policies, including privacy and data-gathering
            practices, of that site.
          </p>
          <h2 id="copyright-and-trademarks">Copyright and Trademarks</h2>
          <p>
            All Site materials, including, without limitation, the Product Partners, Anytime Soccer
            Training and all Anytime Soccer Training product logos, design, text, graphics,
            software, other files, and the selection and arrangement thereof (the “Content”) are
            Copyright © 2009-2013 Anytime SOCCER TRAINING . ALL RIGHTS RESERVED. Anytime Soccer
            Training or its suppliers or licensors own and retain other proprietary rights in all
            products available through the Site. Except as stated herein, none of the Content may be
            copied, modified, reproduced, distributed, republished, downloaded, performed,
            displayed, posted, transmitted, sold, or made into derivative works in any form or by
            any means, including, but not limited to, electronic, mechanical, photocopying,
            recording, or otherwise, without the prior written permission of Anytime Soccer Training
            or the respective copyright owner. You may not, without the express written permission
            of Anytime Soccer Training or the respective copyright owner, (a) copy, publish, or post
            any materials on any computer network or broadcast or publications media; (b) modify the
            materials; or (c) remove or alter any copyright or other proprietary notices contained
            in the materials. You also may not (a) sell, resell, or make commercial use of the Site,
            its content, or services or products obtained through the Site; (b) collect and use of
            any product listings or descriptions; (c) make derivative uses of the Site or its
            Content; or (d) use of any data mining, robots, or similar data gathering and extraction
            methods. You are not conveyed any right or license by implication, estoppel, or
            otherwise in or under any patent, trademark, copyright, or other proprietary right of
            Anytime Soccer Training or any third party.
          </p>
          <p>
            Trademarks: Together with other logos and product names described on this Site, the
            following includes a partial list of trademarks or registered trademarks of Anytime
            Soccer Training, in the United States and other countries: Anytime Soccer Training,
            Anytime Soccer Training.com, Total Control Series, Evolution of Touch. These trademarks
            may not be copied, imitated, or used, in whole or in part, without the prior written
            permission of Anytime Soccer Training or the rights holder. In addition, all page
            headers, custom graphics, button icons, and scripts are service marks, trademarks,
            and/or trade dress of Anytime Soccer Training, and may not be copied, imitated, or used,
            in whole or in part, without the prior written permission of Anytime Soccer Training.
            Other names and brands may be claimed as the property of others.
          </p>
          <h2 id="copyright-policy-and-copyright-agent">Copyright Policy and Copyright Agent</h2>
          <p>
            It is Anytime Soccer Training’s policy to respect the copyright and intellectual
            property rights of others. Anytime Soccer Training may remove content that appears to
            infringe the copyright or other intellectual property rights of others. In addition,
            Anytime Soccer Training may terminate access by users who appear to infringe the
            copyright or other intellectual property rights of others. Further, Anytime Soccer
            Training complies with the Digital Millennium Copyright Act.
          </p>
          <p>
            If you believe that Anytime Soccer Training or any user of our Site has infringed your
            copyright in any material way, please notify Anytime Soccer Training, and provide the
            following:
          </p>
          <ul>
            <li>
              <p>
                an identification of the intellectual property right claimed to have been infringed;
              </p>
            </li>
            <li>
              <p>
                an identification of the material that you claim is infringing so that we may locate
                it on the Site;
              </p>
            </li>
            <li>
              <p>your address, telephone number, and email address;</p>
            </li>
            <li>
              <p>
                a statement by you that you have a good faith belief that the disputed use is not
                authorized by the owner, their licensee, and agent of either of the foregoing, or
                the law;
              </p>
            </li>
            <li>
              <p>
                and a statement by you that the above information in your notice is accurate, made
                under penalty of perjury, and that you are authorized to act on behalf of the owner
                of the intellectual property interest involved. Please direct inquiries regarding
                intellectual property infringement issues by email to:
                terms@Anytimesoccertraining.com, or by mail to:
              </p>
            </li>
          </ul>
          <p>Anytime Soccer Training</p>
          <p>1111 Brookhill Way Cary, NC 27519</p>
          <p>Attn.: Chief Legal Officer.</p>
          <h2 id="indemnification">Indemnification</h2>
          <p>
            You agree to indemnify and hold harmless Anytime Soccer Training, its parents,
            subsidiaries, shareholders, officers, directors, employees, agents, and suppliers from
            any claim, action, demand, loss, or damages (including attorneys’ fees) made or incurred
            by any third party arising out of or relating to your use of the Site, your violation of
            these Terms and Conditions, or your violation of any rights of a third party
          </p>
          <h2 id="product-information">Product Information</h2>
          <p>
            For questions about the products or services on this Site, please use the Customer
            Service email links found on each product page or section.
          </p>
          <h2 id="product-and-service-descriptions">Product and Service Descriptions</h2>
          <p>
            We have taken reasonable precautions to try to ensure that prices quoted on the Site are
            correct and that all products have been fairly described. However, when ordering
            products or services through the Site, please note that:
          </p>
          <ul>
            <li>
              <p>
                orders will only be accepted if there are no material errors in the description of
                the goods or services or their prices as advertised on this Site, including, but not
                limited to, the event that a product is listed at an incorrect price due to
                typographical or other error;
              </p>
            </li>
            <li>
              <p>
                all prices are displayed in United States Dollars unless expressly indicated
                otherwise;
              </p>
            </li>
            <li>
              <p>packaging may vary from that shown on the Site;</p>
            </li>
            <li>
              <p>any weights, dimensions, and capacities shown on the Site are approximate only;</p>
            </li>
            <li>
              <p>
                and all items are subject to availability and we will inform you as soon as possible
                if the product(s) or service(s) you have ordered are not available and we may offer
                alternative product(s) or service(s) of equal or higher quality and value.
              </p>
              <h2 id="order-and-payment-information">Order and Payment Information</h2>
              <p>
                If you use the Site or other means to purchase a product, payment must be received
                by Anytime Soccer Training prior to Anytime Soccer Training’s acceptance of an
                order, unless otherwise agreed by Anytime Soccer Training. All Anytime Soccer
                Training products are subject to sales tax which will be applied to your order
                total. Anytime Soccer Training may need to verify information you provide before
                Anytime Soccer Training accepts an order, and may cancel or limit an order any time
                after it has been placed. If payment has already been made and your order is
                cancelled or limited, Anytime Soccer Training will refund any payment you made for
                the product that will not be shipped due to cancellation or limitation of an order
                in the same tender as the original purchase. Anytime Soccer Training expressly
                conditions its acceptance of your order on your agreement to these Terms and
                Conditions.
              </p>
            </li>
          </ul>
          <p>
            In ordering products through the Site or otherwise, you agree to provide only true,
            accurate, current, and complete information. You hereby certify that any email account
            you provide to Anytime Soccer Training is registered to you. Anytime Soccer Training
            shall have the right to bar your access to and use of the Site or its other products or
            services if it has reasonable grounds to believe that you have provided untrue,
            inaccurate, not current, or incomplete information to Anytime Soccer Training, or for
            any other reason it, in its sole discretion, deems appropriate. You agree that if you
            are ordering or purchasing products on behalf of a company, that you have sufficient
            authority to bind that company to the Terms and Conditions. You agree that your
            placement of an electronic order on the Site is sufficient to satisfy any applicable
            Statute of Frauds, and no further writing is required.
          </p>
          <p>
            Anytime Soccer Training may reject orders where the stated delivery address is outside
            the United States and Canada. Anytime Soccer Training will add applicable shipping and
            handling fees.
          </p>
          <p>
            Anytime Soccer Training reserves the right without prior notice to discontinue or change
            specifications and prices on products offered on and outside of the Site without
            incurring any obligation to you.
          </p>
          <p>
            Anytime Soccer Training’s descriptions of, or references to, products not owned by
            Anytime Soccer Training on and outside of the Site do not imply endorsement of that
            product, or constitute a warranty, by Anytime Soccer Training.
          </p>
          <h2 id="international-orders">International Orders</h2>
          <p>
            Anytime Soccer Training does not directly sell certain Anytime Soccer Training products
            in any jurisdiction other than the United States of America as these products may not be
            approved for sale in other jurisdictions. While Anytime Soccer Training may choose to
            accept orders for the purchase of its products from non-residents of the U.S., the
            acceptance of such orders and the sale of such products will only be based on the
            following conditions precedent:
          </p>
          <ul>
            <li>
              <p>
                you agree that the purchase of any Anytime Soccer Training products by you, as a
                non-resident of the U.S., shall be (a) ex works Anytime Soccer Training’s facilities
                in the U.S. per Incoterms 2010, with all title risk and loss in the products passing
                to you in the U.S. and (b) for your own personal use only and not for further resale
                or distribution in any manner;
              </p>
            </li>
            <li>
              <p>
                you agree not to order more than a 90 day supply of any consumable products within
                any ninety (90) day period;
              </p>
            </li>
            <li>
              <p>
                you hereby expressly authorize and direct Anytime Soccer Training to load and ship
                the purchased products to you to your designated ship to destination, and to
                contract on your behalf with a common carrier or courier company for that purpose;
              </p>
            </li>
            <li>
              <p>
                and you are the principal importer of record and will undertake responsibility for
                all applicable taxes, shipping, customs clearance, duties and import requirements
                from Anytime Soccer Training’s facilities in the U.S. to your foreign ship to
                destination.
              </p>
              <h2 id="shipping">Shipping</h2>
              <p>
                Unless otherwise noted, Anytime Soccer Training will use its best efforts to ship
                products within a reasonable time after receipt of your properly completed order.
                Although Anytime Soccer Training may provide delivery or shipment timeframes or
                dates, you understand that those are Anytime Soccer Training’s good-faith estimates
                and may subject to change. You further understand that product availability may be
                limited and particular products may not be available for immediate delivery, in
                which case the products will be delivered when they become available. Anytime Soccer
                Training shall not be liable for any loss, damage, cost, or expense related to any
                delay in shipment or delivery.
              </p>
            </li>
          </ul>
          <p>
            The delivery time for shipments to P.O. Boxes within the United States or for shipments
            outside the United States, including to U.S. territories, Alaska and Hawaii, may take up
            to 7-21 days.
          </p>
          <h2 id="automatic-renewal">Automatic Renewal</h2>
          <p>
            With regards to any product made available to you on a monthly (or other period)
            subscription as sold through the Site or otherwise (including any free trial product)
            (the “Product”), the default term of our agreement shall be for so long as we make the
            Product available to you. IF YOU HAVE PROVIDED US WITH A VALID CREDIT CARD NUMBER OR AN
            ALTERNATE PAYMENT METHOD, YOUR SUBSCRIPTION WILL BE AUTOMATICALLY RENEWED (AND CHARGED
            TO THE ACCOUNT YOU HAVE PROVIDED) FOR ANOTHER TERM AT THE EXPIRATION OF YOUR CURRENT
            TERM, WHENEVER LOCAL REGULATIONS ALLOW, FOR A FEE NO GREATER THAN OUR THEN-CURRENT
            PRICE, EXCLUDING PROMOTIONAL AND DISCOUNT PRICING.
          </p>
          <p>
            The new term will be for the same duration as the expired term unless otherwise
            specified at time of renewal. This renewal will be processed (and your card charged)
            within 30 days prior to the expiration of the term and each period thereafter. IF YOU DO
            NOT DESIRE TO HAVE YOUR SUBSCRIPTION AUTOMATICALLY RENEWED, YOU MUST, PRIOR TO THE
            EXPIRATION OF YOUR SUBSCRIPTION TERM, INFORM US OF YOUR INTENTION NOT TO RENEW YOUR
            SUBSCRIPTION TO THE PRODUCT.
          </p>
          <p>
            You must provide current, complete, and accurate information for your billing account.
            You are responsible for ensuring this information is correct and must promptly update
            all information to keep your billing account current, complete, and accurate (such as a
            change in billing address, credit card number, or credit card expiration date). You must
            promptly notify us if your credit card is cancelled (for example, for loss or theft).
            Changes to such information can be made by contacting Customer Service.
          </p>
          <p>
            We reserve the right to refuse or discontinue the supply of the Product to any user at
            any time at our sole discretion.
          </p>
          <h2 id="digital-products">Digital Products</h2>
          <p>
            Digital products are non-returnable and non-refundable. We regret, therefore, that once
            the product has been puchased by you, your order may not be cancelled or refunded unless
            otherwise stated by a guarantee on the specific products page. If, however, you
            experience difficulty accessing or downloading your purchased product, then help is
            available – please see your purchase invoice/receipt for our support contact details.
          </p>
          <h2 id="warranties">Warranties</h2>
          <p>
            CERTAIN WARRANTIES WITH RESPECT TO PARTICULAR PRODUCTS FOR SALE ON OR OUTSIDE THE SITE
            MAY BE APPLICABLE THROUGH MANUFACTURERS’ WARRANTIES, THOUGH NOT THROUGH Anytime SOCCER
            TRAINING. SEE THE WARRANTIES INCLUDED IN THE DOCUMENTATION ALONG WITH THE PRODUCTS FOR
            FURTHER DETAILS REGARDING WARRANTIES PROVIDED BY MANUFACTURERS OF PRODUCTS AVAILABLE
            THROUGH OR OUTSIDE THE SITE.
          </p>
          <h2 id="disclaimer-of-warranties">Disclaimer of Warranties</h2>
          <p>
            YOU EXPRESSLY AGREE THAT USE OF THIS SITE AND OTHER PRODUCTS AND SERVICES OF Anytime
            SOCCER TRAINING IS AT YOUR SOLE RISK. YOU UNDERSTAND AND AGREE THAT THIS SITE AND THE
            INFORMATION, SERVICES, PRODUCTS, AND MATERIALS AVAILABLE THROUGH IT AND OTHERWISE ARE
            PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS.
          </p>
          <p>
            YOU ACKNOWLEDGE THAT Anytime SOCCER TRAINING DOES NOT CONTROL IN ANY RESPECT ANY
            ADVERTISEMENTS, PRODUCT DESCRIPTIONS, PRODUCTS, OR CONTENT OFFERED BY THIRD PARTIES ON
            OR THROUGH THIS SITE, INCLUDING, BUT NOT LIMITED TO, INFORMATION OR PRODUCTS PROVIDED BY
            LICENSE TO Anytime SOCCER TRAINING FROM THIRD PARTIES. EXCEPT AS OTHERWISE AGREED IN
            WRITING, Anytime SOCCER TRAINING AND ITS AFFILIATES ASSUME NO RESPONSIBILITY FOR AND
            MAKE NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY, CURRENCY, COMPLETENESS,
            RELIABILITY, OR USEFULNESS OF CONTENT OR PRODUCTS (INCLUDING PRODUCT DESCRIPTIONS)
            DISTRIBUTED OR MADE AVAILABLE BY THIRD PARTIES THROUGH OR OUTSIDE OF THIS SITE. NOR DOES
            Anytime SOCCER TRAINING MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM
            USE OF THE SITE, ANY PRODUCTS OR SERVICES OFFERED OR SOLD THROUGH THE SITE, OR ITS
            CONTENT.
          </p>
          <p>
            ALTHOUGH Anytime SOCCER TRAINING STRIVES TO PREVENT THE INTRODUCTION OF VIRUSES OR OTHER
            DESTRUCTIVE MATERIALS TO THE SITE, Anytime SOCCER TRAINING DOES NOT WARRANT, GUARANTEE,
            OR MAKE ANY REPRESENTATIONS THAT THIS SITE IS FREE OF DESTRUCTIVE MATERIALS. IN
            ADDITION, Anytime SOCCER TRAINING DOES NOT WARRANT THAT ACCESS TO THIS SITE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE
            CORRECTED.
          </p>
          <p>
            Anytime SOCCER TRAINING DISCLAIMS ANY WARRANTY OR REPRESENTATION THAT CONFIDENTIALITY OF
            INFORMATION TRANSMITTED THROUGH THIS SITE WILL BE MAINTAINED. TO THE EXTENT ALLOWED BY
            LAW, Anytime SOCCER TRAINING DISCLAIMS ALL WARRANTIES NOT EXPLICITLY STATED HEREIN. SOME
            JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF CERTAIN WARRANTIES OR
            CONDITIONS, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <p>The terms of this section survive any termination of the Terms and Conditions.</p>
          <h2 id="limitations-on-liability">Limitations on Liability</h2>
          <p>
            WE EXCLUDE ALL REPRESENTATIONS, WARRANTIES, CONDITIONS, AND TERMS (WHETHER EXPRESS OR
            IMPLIED BY STATUTE, COMMON LAW OR OTHERWISE) TO THE FULLEST EXTENT PERMITTED BY LAW.
          </p>
          <p>
            IN NO EVENT, INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, SHALL Anytime SOCCER TRAINING OR
            ITS AFFILIATES BE LIABLE FOR ANY DAMAGES, CLAIMS, OR LOSSES INCURRED (INCLUDING WITHOUT
            LIMITATION COMPENSATORY, INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
            DAMAGES), HOWEVER CAUSED, AND UNDER ANY THEORY OF LIABILITY ARISING IN CONNECTION WITH:
            (I) THE SITE; (II) THE USE OR INABILITY TO USE THIS SITE; (III) THE USE OF OR RELIANCE
            ON ANY CONTENT OR INFORMATION DISPLAYED IN OR ON THE SITE; (IV) THE PURCHASE OR USE OF
            ANY PRODUCTS THROUGH THE SITE OR OTHERWISE; (V) UNAUTHORIZED ACCESS TO OR ALTERATION OR
            LOSS OF YOUR TRANSMISSIONS OR DATA OR OTHER INFORMATION THAT IS SENT OR RECEIVED; (VI)
            ERRORS, SYSTEM DOWN TIME, NETWORK OR SYSTEM OUTAGES, OR FILE CORRUPTION OR SERVICE
            INTERRUPTIONS; OR (VII) OTHERWISE UNDER THIS AGREEMENT/TERMS AND CONDITIONS, WHETHER OR
            NOT REASONABLY FORESEEABLE, EVEN IF Anytime SOCCER TRAINING OR ITS REPRESENTATIVES ARE
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, CLAIMS, OR LOSSES AND NOTWITHSTANDING ANY
            FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
          </p>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, Anytime SOCCER TRAINING’S
            LIABILITY TO YOU FOR ANY CAUSE OF ACTION OR CLAIM WHATSOEVER, AND REGARDLESS OF THE FORM
            OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU IN THE
            LAST 90 DAYS TO Anytime SOCCER TRAINING FOR ANY PRODUCTS SUPPLIED BY Anytime SOCCER
            TRAINING THROUGH YOUR USE OF THE SITE OR OTHERWISE.
          </p>
          <p>
            Anytime SOCCER TRAINING WILL NOT BE LIABLE IN ANY AMOUNT FOR FAILURE TO PERFORM ANY
            OBLIGATION UNDER THESE TERMS AND CONDITIONS IF SUCH FAILURE IS CAUSED BY THE OCCURRENCE
            OF ANY UNFORESEEN CONTINGENCY BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT
            LIMITATION, INTERNET OUTAGES, COMMUNICATIONS OUTAGES, FIRE, FLOOD, OR WAR.
          </p>
          <p>
            THESE EXCLUSIONS SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAW OF THE
            UNITED STATES OF AMERICA. IF ANY PROVISION OF THESE DISCLAIMERS AND EXCLUSIONS SHALL BE
            UNLAWFUL, VOID, OR FOR ANY REASON UNENFORCEABLE THEN THAT PROVISION SHALL BE DEEMED
            SEVERABLE AND SHALL NOT AFFECT THE VALIDITY AND ENFORCEABILITY OF THE REMAINING
            PROVISIONS.
          </p>
          <p>The terms of this section survive any termination of the Terms and Conditions.</p>
          <h2 id="sec-disclosure">SEC Disclosure</h2>
          <p>
            The information contained within press releases issued by Anytime Soccer Training should
            not be deemed accurate or current except as of the date the release was posted. Anytime
            Soccer Training has no intention of updating, and specifically disclaims any duty to
            update, the information in the press releases. To the extent any information therein is
            forward-looking, it is intended to fit within the safe harbor for forward-looking
            statements, and is subject to material risk.
          </p>
          <h2 id="international-users">International Users</h2>
          <p>
            This Site is controlled, operated, and administered by Anytime Soccer Training from its
            offices within the United States of America. Anytime Soccer Training makes no
            representation that materials on the Site are appropriate or available for use at other
            locations outside of the United States, and access to them from territories where the
            contents or products available through the Site are illegal is prohibited. You may not
            use the Site or export the content or products in violation of U.S. export laws and
            regulations. If you access this Site from a location outside of the United States, you
            are responsible for compliance with all local laws.
          </p>
          <h2 id="termination">Termination</h2>
          <p>
            Notwithstanding any of these Terms and Conditions, Anytime Soccer Training reserves the
            right, without notice and in its sole discretion, for any reason or no reason, to
            terminate your ability to use the Site and to block or prevent future access to and use
            of the Site. You agree that Anytime Soccer Training shall not be liable for any
            termination of your use of or access to the Site.
          </p>
          <h2 id="third-party-rights">Third-party rights</h2>
          <p>
            Only you and Anytime Soccer Training shall be entitled to enforce these Terms and
            Conditions. No third party shall be entitled to enforce any of these Terms and
            Conditions.
          </p>
          <h2 id="severability">Severability</h2>
          <p>
            If any provision of these Terms and Conditions shall be deemed unlawful, void, or for
            any reason unenforceable by a court of competent jurisdiction, then that provision shall
            be deemed severable from these Terms and Conditions and shall not affect the validity
            and enforceability of any remaining provisions.
          </p>
          <p>The terms of this section survive any termination of the Terms and Conditions.</p>
          <h2 id="language">Language</h2>
          <p>
            It is the express intent of the parties that the Terms and Conditions and all related
            documents have been drawn up in English.
          </p>
          <h2 id="applicable-law-and-venue">Applicable Law and Venue</h2>
          <p>
            These Terms and Conditions shall be governed by and construed in accordance with the
            laws of the State of California, without resort to its conflict of law provisions. You
            agree that any action at law or in equity arising out of or relating to the Terms and
            Conditions or your use of the Site shall be filed only in the Superior Court of Los
            Angeles County, California, or the United States District Court for the Central District
            of California, and except as stated herein you hereby irrevocably and unconditionally
            consent and submit to the exclusive jurisdiction of such courts over any suit, action,
            or proceeding arising out of the Terms and Conditions. Notwithstanding the above, any
            controversy or claim related to this Agreement shall be resolved exclusively by
            arbitration in accordance with the Federal Arbitration Act (Title 9, U. S. Code) (the
            “Act”). The Act will apply even though this Agreement provides that it is governed by
            the laws of California. Arbitration proceedings will be determined in accordance with
            the Act, the rules and procedures for the arbitration of financial services disputes of
            JAMS/Endispute, LLC, a Delaware limited liability company or any successor thereof
            (“JAMS”), except that discovery in said arbitration shall be limited in scope to the
            specifics of liability on the Claim, and any discovery related to damages calculations
            or any financials shall be withheld until after liability has been decided by the
            arbitrator(s). In the event of any inconsistency between the JAMS rules and this
            paragraph, the terms of this paragraph shall control. The arbitration shall be
            administered by JAMS and conducted in Los Angeles, California. All Claims shall be
            determined by one arbitrator; however, if Claims exceed Five Million Dollars, upon the
            request of either Anytime Soccer Training or You, the Claims shall be decided by three
            arbitrators. All arbitration hearings shall commence within ninety (90) days of the
            demand for arbitration and close within ninety (90) days of commencement and the award
            of the arbitrator(s) shall be issued within thirty (30) days of the close of the
            hearing. However, the arbitrator(s), upon a showing of good cause, may extend the
            commencement of the hearing for up to an additional sixty (60) days. The arbitrator(s)
            shall provide a concise written statement of reasons for the award. The arbitration
            award may be submitted to any court having jurisdiction to be confirmed and enforced.
            The arbitrator(s) will have the authority to decide whether any Claim is barred by the
            statute of limitations and, if so, to dismiss the arbitration on that basis. For
            purposes of the application of the statute of limitations, the service on JAMS under
            applicable JAMS rules of a notice of Claim is the equivalent of the filing of a lawsuit.
            Any dispute concerning this arbitration provision or whether a Claim is arbitrable shall
            be determined by the arbitrator(s). The arbitrator(s) shall have the power to award
            legal fees pursuant to the terms of this Agreement. This paragraph does not limit the
            right of Anytime Soccer Training to: (i) exercise self-help remedies, such as, but not
            limited to, setoff; (ii) initiate judicial or non-judicial foreclosure against any real
            or personal property collateral; (iii) exercise any judicial or power of sale rights, or
            (iv) act in a court of law to obtain an interim remedy, such as, but not limited to,
            injunctive relief, writ of possession or appointment of a receiver, or additional or
            supplementary remedies. The prevailing party in any action or arbitration related to
            these Terms and Conditions shall be entitled to its reasonable attorney fees and costs.
          </p>
          <p>The terms of this section survive any termination of the Terms and Conditions.</p>
          <h2 id="general">General</h2>
          <p>
            The Terms and Conditions constitute the entire agreement between Anytime Soccer Training
            and you with respect to your use of the Site, your purchase of products and services
            through the site, and as applicable any products or services of Anytime Soccer Training.
            Any cause of action you may have with respect to the Site or Anytime Soccer Training’s
            products or services must be commenced within one (1) year after the claim or cause of
            action arises, notwithstanding any statutes of limitation to the contrary. Any failure
            by Anytime Soccer Training to enforce or exercise any provision of the Terms and
            Conditions or related right shall not constitute a waiver of that right or provision.
            The section titles used in the Terms and Conditions are purely for convenience and carry
            with them no legal or contractual effect. Nothing in these Terms and Conditions will be
            construed as creating a joint venture, partnership, employment, or agency relationship
            between you and Anytime Soccer Training, and you do not have any authority to create any
            obligation or make any representation on Anytime Soccer Training’s behalf. You may not
            assign or transfer any rights or obligations of these Terms and Conditions, by operation
            of law or otherwise, without Anytime Soccer Training’s written consent. Subject to the
            foregoing, these Terms and Conditions will be binding on, inure to the benefit of, and
            be enforceable against you and Anytime Soccer Training and their respective successors
            and assigns.
          </p>
          <p>The terms of this section survive any termination of the Terms and Conditions.</p>
          <h2 id="other-important-terms">Other important terms</h2>
          <p>
            We may update or amend these Terms and Conditions from time to time to comply with law
            or to meet our changing business requirements without notice to you. Any updates or
            amendments will be posted on the Site. The Terms and Conditions displayed on the Web
            site at the time the order is accepted will apply to the order.
          </p>
          <p>
            These Terms and Conditions supersede any other terms and conditions previously published
            by us and any other representations or statements made by us to you, whether oral,
            written, or otherwise. We may assign, transfer, or sub-contract any of our rights or
            obligations under these Terms and Conditions to any third party at our discretion. No
            delay by us in exercising any right or remedy under these Terms and Conditions shall
            operate as waiver of that right or remedy or shall affect our ability to subsequently
            exercise that right or remedy. Any waiver must be agreed by us in writing.
          </p>
        </article>
      </section>
    </main>
  );
}

export default TermsNConditions;
