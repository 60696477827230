import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../config/routes.json';

/** TODO: for React Router v6 - Redirect Component must be replaced by Navigate one */ 
function PrivateRouteToUpgradePage({ render, path, exact, isLogin }) {

    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps) => (
                isLogin
                    ? render(routeProps)
                    : <Redirect to={routes.home} />
            )}
        />
    );
}

const mapStateToProps = (state) => ({
    isLogin: state.authReducer.isLogin,
});

export default connect(mapStateToProps, null)(PrivateRouteToUpgradePage);