import React from 'react';
import styles from './gridCards.module.sass';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { SubfolderPageUrl } from '../../../constants/const';
import { useDispatch } from 'react-redux';

const Card = (props) => {
  const { item, array, accessAllVideos, setShowUpgradePopup, currentPage, setCurrentPage } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const isSkillAreas = history.location.pathname.split('/').at(2) === 'skillAreas';

  const style = {};

  style.backgroundImage = `url(${item.img})`;

  if (isSkillAreas) {
    style.backgroundSize = 'contain';
    style.backgroundColor = 'white';
    style.borderRadius = '5px';
  }

  const updateDashboardChildInfo = () => {
    if (item.actionType !== undefined) {
      // из-за пагинации страница остается пустой для папок со второй страницы, поэтому из GridCards передаем setCurrentPage и принудительно переключаемся на 1 стр
      if (currentPage === 2) {
        setCurrentPage(currentPage - 1);
      }
      dispatch({ type: item.actionType });
    }
  };

  const checkUrl = (url) => {
    switch (url) {
      case SubfolderPageUrl.videoCategories:
        return `${SubfolderPageUrl.videoCategories}/${item.title}subtitle=${item.subTitle}`;
      default:
        return url;
    }
  };

  const replaceToVideoCategory = () => {
    if (item.title === 'Freestyle Challenge') {
      history.push(SubfolderPageUrl.freestyleChallenge);
      return;
    }

    if (item.title === 'Fundraising') {
      return message.error('This category will appear soon');
    }
    if (accessAllVideos !== true) {
      return setShowUpgradePopup(true);
    }
    // если такие разделы  и они пустые (arrray=[]), тогда выпадающее окно с текстом
    if (array !== 'undefined') {
      updateDashboardChildInfo();
      history.push(checkUrl(item.link));
    } else {
      message.error('This category will appear soon');
    }
  };

  return (
    <article tabIndex="0" onClick={replaceToVideoCategory} className={styles.card} style={style}>
      <p className={isSkillAreas ? styles.skillAreas : styles.p}>
        {!item.desc && item.desc}
        {isSkillAreas && item.title}
      </p>
    </article>
  );
};

export default Card;
