import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Recovery.module.sass';
import { useHistory, withRouter } from 'react-router-dom';
import { sendNewPass } from '../../../api/api';

function Recovery(props) {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required'),
      confirmPassword: Yup.string().required('Required'),
    }),
    onSubmit: async values => {
      if (values.password !== values.confirmPassword) {
        formik.setStatus({ password: 'Passwords do not match' });
      } else {
        formik.setStatus(false);
        try {
          const response = await sendNewPass(values.password, props.match.params.recoveryToken);
          if (response.status === 200) {
            history.push('/dashboard');
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  });
  return (
    <main className={styles.main}>
      <h1>Recovery Password</h1>
      <form onSubmit={formik.handleSubmit}>
        <label htmlFor="password">Password</label>
        <input
          className={
            formik.touched.password && formik.errors.password ? styles['errorsMessages'] : null
          }
          type="password"
          name="password"
          id="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        <span>Minimum 6 characters, case sensitive</span>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          className={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? styles['errorsMessages']
              : null
          }
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
        />
        <button type="submit">Submit</button>
      </form>
      {formik.status?.password && <div className={styles.error}>{formik.status.password}</div>}
    </main>
  );
}

export default withRouter(Recovery);
