const SET_PROFILE_DATA = './profileData/SET-PROFILE-DATA';
const SET_UPDATE_PROFILE_NAME_DATA = './profileData/SET-UPDATE-PROFILE-NAME-DATA';
const SET_UPDATE_PROFILE_ROLE_DATA = './profileData/SET-UPDATE-PROFILE-ROLE-DATA';

const initialState = {
  profileData: {
    firstName: null,
    lastName: null,
    role: null,
    currentChildRole: null,
    annualVideo: 0,
    annualTime: '0',
    alltimeVideo: null,
    alltimeTime: null,
    image: null,
    picture: null,
    email: null
  },
};

const profileDataReducer = (state = initialState, action) => {
  if (action.type === SET_PROFILE_DATA) {
    return {
      ...state,
      profileData: {
        ...action.profileData,
      },
    };
  } else if (action.type === SET_UPDATE_PROFILE_NAME_DATA) {
    return {
      ...state,
      profileData: {
        ...state.profileData,
        ...action.profileData,
      },
    };
  } else if (action.type === SET_UPDATE_PROFILE_ROLE_DATA) {
    return {
      ...state,
      profileData: {
        ...state.profileData,
        ...action.profileData,
      },
    };
  } else {
    return state;
  }
};

export const getProfileDataAction = (profileData) => {
  return ({
    type: SET_PROFILE_DATA,
    profileData,
  })
};

export const updateProfileNameDataAction = (profileData) => ({
  type: SET_UPDATE_PROFILE_NAME_DATA,
  profileData,
});

export const updateProfileRoleDataAction = (profileData) => ({
  type: SET_UPDATE_PROFILE_ROLE_DATA,
  profileData,
});

export default profileDataReducer;
