import React, { useEffect, useState } from 'react';
import styles from './CancelSubscription.module.sass';
import { Spinner } from 'reactstrap';
import { setUserDataStep1 } from '../../../Redux/signUpDataReducer.js';
import { useDispatch } from 'react-redux';
import { getPrices, getDataForPayment } from '../../../api/payment-api';
import { capitalizeWord } from '../../../utils/utils';
import { ChildStatus } from '../../../constants/const';
import { message } from 'antd';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

const StepOneBody = ({ userData, setStep, setTotal, setSubscriptionPlayers }) => {
  const childStatus = userData?.childInfo || userData;
  const { userInfo } = userData;
  useScrollToTop();

  /** We use two different requests for getting users payment data
   * These requests return different response object
   */
  const getUserName = () => {
    const resultArr = [];
    const numOfAllChild =
      userData.childInfo?.childrenCount || Object.keys(userData.childInfo).length;
    for (let i = 1; i <= numOfAllChild; i++) {
      resultArr.push(userData.childInfo[`child${i}`].username);
    }
    return resultArr;
  };

  const [users, setUsers] = useState(
    getUserName().map((name) => ({ name, selected: false, tariff: 'year', id: '' })),
  );
  const [v, setV] = useState(users.length ? true : false);
  const [player, setPlayer] = useState([]);
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  let isDiscountForFirstChild = false;
  for (const key in childStatus) {
    if (childStatus[key].status === 'active') {
      isDiscountForFirstChild = true;
    }
  }

  useEffect(() => {
    try {
      getPrices().then((response) => {
        getPricesResponseHandler(response);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getPricesResponseHandler = (response) => {
    response.json().then((prices) => {
      setPrices(prices);
      setLoading(true);
    });
  };

  const paymentDataRequestHandler = (response) => {
    response.json().then((paymentData) => {
      setTotal(paymentData.totalTarifs.total);
      dispatch(setUserDataStep1({ ...userInfo, stripeCus: paymentData.stripeCus }));
    });
  };

  const handleSubmitRadio = (e) => {
    const user = users.find((user) => user.name === e.target.value);
    user.selected = !user.selected;
    setUsers(users);
    const checked = e.target.checked;

    if (checked) {
      setPlayer([...player, user.name]);
      setV(false);
    } else {
      if (player.length === 1) {
        setV(true);
      }
      setPlayer(player.filter((i) => i !== user.name));
    }
  };

  const handleSubmitSelect = (e, name) => {
    const user = users.find((user) => user.name === name.keyvalue);
    user.tariff = e;
  };

  const handleSubmit = () => {
    if (!player.length) {
      message.error('You should choose a player or players.');
      return;
    }
    setStep(1);
    const data = { email: userInfo.email };
    const childrenForBE = {};

    player.forEach((playerName, idx) => {
      const user = users.find((user) => user.name === playerName);

      let email = '';
      let childID = '';
      for (const key in childStatus) {
        if (childStatus[key].username === user.name) {
          email = childStatus[key].childEmail;
          childID = childStatus[key].id;
        }
      }

      const index = isDiscountForFirstChild ? idx + 2 : idx + 1;

      childrenForBE[`child${index}`] = {};
      childrenForBE[`child${index}`].email = email;
      childrenForBE[`child${index}`].tariff = user.tariff;
      childrenForBE[`child${index}`].id = childID;

      data[`child${index}`] = user.name;
      data[`child${index}Period`] = user.tariff;
    });

    setSubscriptionPlayers(childrenForBE);

    try {
      getDataForPayment(data).then((response) => {
        paymentDataRequestHandler(response);
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    return <Spinner color="primary" />;
  }

  return (
    <>
      <ul className={styles.container}>
        {!!users.length &&
          users.map(({ name }, index) => {
            const currentChildStatus = childStatus[`child${index + 1}`].status;
            const checkOption =
              v && !isDiscountForFirstChild
                ? `Year $${prices.firstChild.year / 100}`
                : player[0] === name && !isDiscountForFirstChild
                ? `Year $${prices.firstChild.year / 100}`
                : `Year $${prices.addChild.year / 100}`;
            if (
              currentChildStatus === ChildStatus.FREE ||
              currentChildStatus === ChildStatus.NON_SUBSCRIPTION
            )
              return (
                <li className={styles.child_payment_row} key={index}>
                  <p className={styles.child__name}>
                    {name} ({capitalizeWord(currentChildStatus)})
                  </p>
                  <select
                    id="select"
                    defaultValue={checkOption}
                    onChange={handleSubmitSelect}
                    className={styles.select}
                    aria-label="Select"
                  >
                    <option value="year">
                      {v && !isDiscountForFirstChild
                        ? `Year $${prices.firstChild.year / 100}`
                        : player[0] === name && !isDiscountForFirstChild
                        ? `Year $${prices.firstChild.year / 100}`
                        : `Year $${prices.addChild.year / 100}`}
                    </option>
                  </select>
                  <label className={styles.radioBtnContainer}>
                    <input type="checkbox" name="radio" onClick={handleSubmitRadio} value={name} />
                    <span className={styles.checkmark}></span>
                  </label>
                </li>
              );
          })}
      </ul>
      <button type="button" className={styles.button} onClick={handleSubmit}>
        Next
        <img className={styles.arrow} src="/img/right-arrow.webp" alt="right arrow" />
      </button>
    </>
  );
};

export default StepOneBody;
