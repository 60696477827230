import React from 'react';
import styles from './ListViewPage.module.sass';
import LeftSideBar from './LeftSideBar';
import ListView from './ListView';

const ListViewPage = () => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.row}>
          <div className={styles.column30}>
            <LeftSideBar />
          </div>
          <div className={styles.column70}>
            <ListView />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListViewPage;
