import { SubfolderPageUrl } from '../constants/const';

const initialState = {
  data: [
    {
      title: 'Anytime Skills',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_87_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Brief Freestyle',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_86_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const anytimeReducer = (state = initialState) => state;

export default anytimeReducer;
