const SET_ALL_MEMBERS_DATA = './allMembers/SET-ALL-Members';

const initialState = {
  allMembers: [],
};

const allMembersDataReducer = (state = initialState, action) => {
  if (action.type === SET_ALL_MEMBERS_DATA) {
    return {
      ...state,
      allMembers: [...action.allMembers],
    };
  } else {
    return state;
  }
};

export const getAllMembersDataAction = allMembers => ({
  type: SET_ALL_MEMBERS_DATA,
  allMembers,
});

export default allMembersDataReducer;
