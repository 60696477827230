import React from "react"
import './AdminDashboardTable.css'
import TodoList from './components/TodoList.jsx'

const AdminDashboardTable = () => {
    return (
        <div className="todo-app">
            <TodoList />
        </div>
    )
}

export default AdminDashboardTable