import React from 'react'
import styles from '../Contest.module.sass'
import { Avatars } from '../../../../../constants/const';

const TopPerformers = ({ players }) => {
  const topPlayers = players
    .sort((a, b) => Number(b.timeVideo) - Number(a.timeVideo))
    .slice(0, 3);

  return (
    <div className={styles.topPerformers} style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '1.25rem',
      gap: '1rem',
      marginTop: '2rem'
    }}>
      <h2 className={styles.topPerformer}>TOP PERFORMERS</h2>
      <div style={{
        display: 'flex', alignItems: 'center', gap: '4rem'
      }}>
        {topPlayers.map((player, i) => (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem'
          }} key={i}>
            <div className={styles.topPlayer}>
              <img
                src={player?.image || Avatars[parseInt(player.picture) - 1]}
                alt='Avatar'
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',

                }} />
            </div>
            <span className={styles.topPlayer_name}>{`${player.childFirstName} ${player?.childLastName ?? ''}`}</span>
            <span className={styles.topPlayer_value} style={{
              color: player.playerStatusGoal ? '#2cdebe' : '#e1343f',
            }}>{`${((player.timeVideo / 60) / 60).toFixed(2)} hours`}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TopPerformers