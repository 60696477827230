import React from 'react';
import styles from './PlayerStatsRow.module.sass';
import { capitalizeWord, timeConvert } from '../../../../../utils/utils';

export function PlayerStatsRow(props) {
  const { playerStats, numberWithCommas } = props;

  /** We expect object with stats for players { performance, playerName, videoCompletedCount, trainingTime} */
  return (
    <>
      {Object.keys(playerStats).map(playerData => {
        return (
          <tr className={styles.row} key={playerData}>
            <th scope="row" className={styles.cell_first}>
              {capitalizeWord(playerData)}
            </th>
            <td className={styles.cell_player_name}>{playerStats?.[playerData]?.name}</td>
            <td className={styles.cell}>{numberWithCommas(playerStats?.[playerData]?.countVideo ?? '0')}</td>
            <td className={styles.cell}>
              {playerStats?.[playerData]?.timeVideo ? timeConvert(playerStats?.[playerData]?.timeVideo) : '0'}
            </td>
          </tr>
        );
      })}
    </>
  );
}
