import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Steps.module.sass';
import path from '../../../../config';
import mainRoute from '../../../../mainRoute';
import { message } from 'antd';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';

function Step1({ changeStep, step, signUpFieldValues, changeSignUpFieldValues, setUserDataStep1 }) {
  useScrollToTop();

  const [toggleEmailErr, setToggleEmailErr] = useState(false);
  const [toggPassConfirmCheckState, setToggPassConfirmCheckState] = useState(true);
  const { step1 } = signUpFieldValues;

  // =============   CONFIRM PASSWORD CHECK  ============= (метод для Yup проверяющий совпадают ли пароли)
  function equalTo(ref, msg) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      message: msg || ' must be the same as ',
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value === this.resolve(ref);
      },
    });
  }

  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  const toggPassConfirmCheck = () => {
    // если пароли не совпадают выводит надпись
    if (formik.values.passwordConfirm && formik.errors.passwordConfirm === 'Passwords must match') {
      setToggPassConfirmCheckState(false);
    } else {
      setToggPassConfirmCheckState(true);
    }
  };
  // ===================================================

  const formik = useFormik({
    initialValues: step1
      ? step1
      : {
          parentFirstName: '',
          parentLastName: '',
          email: '',
          password: '',
          passwordConfirm: '',
          players: '1',
          hear: '',
        },
    validationSchema: Yup.object({
      parentFirstName: Yup.string().trim().required('Required'),
      parentLastName: Yup.string().trim().required('Required'),
      password: Yup.string().trim().required('Required'),
      passwordConfirm: Yup.string()
        .trim()
        .equalTo(Yup.ref('password'), 'Passwords must match')
        .required('Required'),
      players: Yup.string().trim().required('Required'),
      email: Yup.string().trim().email('Required').required('Required'),
      hear: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      changeSignUpFieldValues({ ...signUpFieldValues, step1: { ...values } });
      try {
        const response = await fetch(`${mainRoute.route + path.usersearch + `/` + values.email}`, {
          method: 'GET',
          headers: {},
        });
        const statusJson = await response;
        if (statusJson.status === 200) {
          setUserDataStep1({
            email: values.email,
            firstname: values.parentFirstName,
            lastname: values.parentLastName,
            password: values.password,
            hear: values.hear,
          });
          changeStep(step + 1);
        } else {
          setToggleEmailErr(!toggleEmailErr);
        }
      } catch (e) {
        message.error(e.message);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
      <h5>Step 1 of 3</h5>
      <h3>Account Information</h3>
      <h6>Fields marked with * are mandatory</h6>
      <div className={styles.section}>
        <h4>Personal Information</h4>
        <div className={styles.row}>
          <div className={styles.col}>
            <label htmlFor="parentFirstName">Parent First Name *</label>
            <input
              className={
                formik.touched.parentFirstName && formik.errors.parentFirstName
                  ? styles[formik.errors.parentFirstName]
                  : ''
              }
              name="parentFirstName"
              id="parentFirstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.parentFirstName.replace(/[^A-z\s]/g, '')}
            />
          </div>
          <div className={styles.col}>
            <label htmlFor="parentLastName">Parent Last Name *</label>
            <input
              className={
                formik.touched.parentLastName && formik.errors.parentLastName
                  ? styles[formik.errors.parentLastName]
                  : ''
              }
              name="parentLastName"
              id="parentLastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.parentLastName.replace(/[^A-z\s]/g, '')}
            />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <h4>Account Information</h4>
        <div className={styles.col}>
          <div className={styles.emailFlexBlock}>
            <label htmlFor="email">Email address *</label>
            {toggleEmailErr ? (
              <div className={styles.invalidEmailAdressMessage}>*Email already exist</div>
            ) : null}
          </div>
          <input
            className={
              formik.touched.email && formik.errors.email ? styles[formik.errors.email] : ''
            }
            name="email"
            id="email"
            placeholder="email"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email.replace(/[^A-z@._0-9-]/g, '')}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <label htmlFor="password">Password *</label>
            <input
              className={
                formik.touched.password && formik.errors.password
                  ? styles[formik.errors.password]
                  : ''
              }
              name="password"
              id="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password.replace(/[\s]/g, '')}
            />
          </div>
          <div className={styles.col}>
            {toggPassConfirmCheckState ? (
              <label htmlFor="passwordConfirm">Confirm Password *</label>
            ) : (
              <label htmlFor="passwordConfirm">
                Confirm Password *<p className={styles.confirmPass}>Password must match</p>
              </label>
            )}
            <input
              className={
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
                  ? styles.Required
                  : ''
              }
              name="passwordConfirm"
              id="passwordConfirm"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirm.replace(/[\s]/g, '')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <label htmlFor="players">Number of Players</label>
            <input
              className={
                formik.touched.players && formik.errors.players ? styles[formik.errors.players] : ''
              }
              name="players"
              id="players"
              type="number"
              min="1"
              max="4" // later change to 5
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.players}
            />
          </div>
          <p className={styles.row_notification}>
            Choose the number of players. Each player tracks their own progress, receives awards and
            competes on the leaderboard. Max players is 4.
          </p>
        </div>
      </div>

      <div className={styles.section}>
        <h4>Additional Account Information</h4>

        <div className={styles.col}>
          <label htmlFor="hear">How did you hear about us? &nbsp; *</label>
          <select
            className={formik.touched.hear && formik.errors.hear ? styles[formik.errors.hear] : ''}
            name="hear"
            id="hear"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hear}
          >
            <option className={styles.grayOptions} value="" label="Please select a source">
              Please a select a source
            </option>
            <option value="Facebook" label="Facebook">
              Facebook
            </option>
            <option value="Youtube" label="Youtube">
              Youtube
            </option>
            <option value="Other Social Media" label="Other Social Media">
              Other Social Media
            </option>
            <option value="Friend" label="Friend">
              Friend
            </option>
            <option value="Anytime Soccer Employee" label="Anytime Soccer Employee">
              Anytime Soccer Employee
            </option>
            <option value="Other" label="Other">
              Other
            </option>
          </select>
        </div>
      </div>
      <div className={styles.btn_step}>
        <button
          type="submit"
          id="toggleForBtnNext"
          className={styles.buttonDefault}
          onClick={toggPassConfirmCheck}
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default Step1;
