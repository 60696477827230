import React from 'react';
import styles from '../Contest.module.sass';
import ContestImg from '../../../../../asset/teams/contest/contest-image.webp';
import RArrow from '../../../../../asset/right-arrow.webp';
import { useHistory } from 'react-router-dom';
// import ContestMetadata from '../Metadata';
import ChallengeTable from '../Table';
// import TopPerformers from '../TopPerformers';

const ViewCoachChallenge = ({ challenge, isManager, isPlayerManager }) => {
  const history = useHistory();

  if (challenge && Object.entries(challenge).length > 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <ContestMetadata
          contest={contest.contest}
          totalTime={contest.totalTime}
          totalVideo={contest.totalVideo}
        /> */}
        {/* <TopPerformers players={challenge.players} /> */}
        <ChallengeTable playerData={challenge.players} teamId={challenge?.challenge?.teamId} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '1rem',
        gap: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2
          className={styles.contest__title}
          style={{
            lineHeight: '1.7rem',
            margin: 0,
          }}
        >
          Anytime Soccer Training
        </h2>
        <h2 className={styles.contest__subtext}>Coach&apos;s Challenges</h2>
      </div>

      <img src={ContestImg} alt="" />
      {isManager || isPlayerManager ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <h2
            className={styles.contest__title}
            style={{
              lineHeight: '1.7rem',
              margin: 0,
              fontWeight: 500,
            }}
          >
            Start a New Team Competition
          </h2>

          <button
            className={styles.button}
            onClick={() => {
              history.push('challenge/create');
            }}
          >
            Start Now <img src={RArrow} alt="" className={styles.button__image} />
          </button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <h2
            className={styles.contest__title}
            style={{
              lineHeight: '1.7rem',
              margin: 0,
              fontWeight: 500,
            }}
          >
            No Challenges Available!
          </h2>
        </div>
      )}
    </div>
  );
};

export default ViewCoachChallenge;
