import React, { useState } from 'react';
import styles from './videoAdjustmentPopup.module.sass';
import { adjustWatchedVideoCount } from '../../api/api.js';
import { message } from 'antd';

const SelectorType = {
    SUBTRACT: 'Subtract',
    ADD: 'Add',
}
let selectorValue = 0;

/** изменение одного селектора должно возвращать второй селектор к нулю и отправляться значение последнего */
const VideoAdjustmentPopup = (props) => {
    const {
        setShowVideoAdjustmentPopup,
        setVideoCountUpdateSuccessfully,
        setVideoCountUpdateToday,
        videoCompleted
    } = props;

    const [addValueSelect, setAddValueSelect] = useState(0);
    const [subtractValueSelect, setSubtractValueSelect] = useState(0);

    const handleSelectorChange = (event) => {
        if (event.currentTarget.name === SelectorType.SUBTRACT) {
            if ((videoCompleted + Number(event.currentTarget.value)) < 0) {
                message.error('You cannot make the video count negative. Sorry :)');
            } else {
                selectorValue = event.currentTarget.value;
                setSubtractValueSelect(event.currentTarget.value);
                if (addValueSelect) setAddValueSelect(0);
            }
        } else if (event.currentTarget.name === SelectorType.ADD) {
            selectorValue = event.currentTarget.value;
            setAddValueSelect(event.currentTarget.value);
            if (subtractValueSelect) setSubtractValueSelect(0);
        }
        return selectorValue;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (Number(selectorValue)) {
            adjustWatchedVideoCount({ countChangeViewedVideos: selectorValue }).then((response) => {
                responseHandler(response);
            });
        } else {
            message.error('You did not choose value for changing watched video count.');
        }
    }

    const responseHandler = (response) => {
        response.json().then(() => {
            setVideoCountUpdateSuccessfully(true);
            setShowVideoAdjustmentPopup(false);
        })
            .catch(() => {
                if (response.status === 400) {
                    message.error('You cannot update video count more than one time per day');
                }
                setShowVideoAdjustmentPopup(false);
                setVideoCountUpdateToday(true);
            })
    };

    return (
        <>
            <div className={styles.new_plan_popup__wrapper}></div>
            <div className={styles.new_plan_popup}>
                <div className={styles.closeImg} onClick={() => setShowVideoAdjustmentPopup(false)}>
                    &times;
                </div>
                <div className={styles.container}>
                    <h3 className={styles.header}>Manual Video Adjustment</h3>
                    <hr className={styles.hr} />
                    <p className={styles.popup_description}>Complete the form to adjust the
                        videos completed.<br />
                        <span className={styles.important}> One entry per day (4 video limit)</span>
                    </p>

                </div>
                <form className={styles.form_video_adjustment}>
                    <label className={styles.select_name}>
                        Add Videos
                        <select value={addValueSelect} onChange={handleSelectorChange} name="Add" className={styles.select}>
                            <option defaultValue="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </label>
                    <label className={styles.select_name}>
                        Subtract Videos
                        <select value={subtractValueSelect} onChange={handleSelectorChange} name="Subtract" className={styles.select}>
                            <option value="0">0</option>
                            <option value="-1">1</option>
                            <option value="-2">2</option>
                            <option value="-3">3</option>
                            <option value="-4">4</option>
                        </select>
                    </label>
                </form>
                <div className={styles.buttons}>
                    <button
                        type="button"
                        className={styles.buttons__cancel_button}
                        onClick={() => setShowVideoAdjustmentPopup(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={styles.buttons__create_button}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </>
    );
};

export default VideoAdjustmentPopup;