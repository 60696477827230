import React from 'react';
import styles from './DeletePopup.module.sass';
import { BorderedButton } from '../../../../commonComponents/BorderedButton/BorderedButton';
import { RedButton } from '../../../../commonComponents/RedButton/RedButton';
import { ClosePopupButton } from '../../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { PopupWrapper } from '../../../../commonComponents/PopupWrapper/PopupWrapper';

export function DeletePopup({ setPopup, popupTitle, handleDelete }) {
  const handleClosePopup = () => {
    setPopup(false);
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton handleClosePopupButtonClick={handleClosePopup} />
        <h3 className={styles.invite_popup__title}>{popupTitle}</h3>
        <div className={styles.container}>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopup}
              width={'20rem'}
            />
            <RedButton
              handleButtonClick={handleDelete}
              title={'OK'}
              width={'20rem'}
            />
          </div>
        </div>
      </section>
    </>
  );
}
