import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Steps.module.sass';
import path from '../../../../config';
import mainRoute from '../../../../mainRoute';
import PlayerForm from './Step2/Step2forms/PlayerForm';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';

function Step2(props) {
  const {
    changeStep,
    step,
    signUpFieldValues,
    changeSignUpFieldValues,
    setfinalPrice,
    email,
    signUpData,
    setUserDataStep2,
    setEmailAndUsername,
    setUserDataStep3,
  } = props;

  useScrollToTop();

  const [errorArray, setErrorArray] = useState([]);
  const { players } = signUpFieldValues.step1;
  const { initialValues, validationSchema } = createInitValues(players);

  //------------ FOR REQUEST TO REDUX ---------- (собирает данные с Formik и забрасывает их в redux в Store)
  const requestData = (obj, length) => {
    const obj2 = { child1: { childEmail: email, picture: '1' } };
    let i = 1; // текуший child
    let j = 0; // итерация
    for (const key in obj) {
      ++j;
      if (j < 5) {
        if (!key.includes('Confirm')) {
          obj2['child' + i][key.slice(0, key.length - 1)] = obj[key].replace(/[^A-z@._0-9-]/g, '');
        }
      } else {
        if (i < length) {
          j = 0;
          ++i;
          obj2['child' + i] = { childEmail: email, picture: '1' };
        }
      }
    }
    return obj2;
  };

  //----------- FOR REQUEST TO BACK ------------ (c Formik берет Email и имя детей и возвращает)
  const serverRequestData = (obj) => {
    let i = 1;
    const usernameObj = {};
    //changed from user to email
    usernameObj['email'] = signUpData.userData.email;
    for (const key in obj) {
      if (key.includes('username')) {
        usernameObj['child' + i] = obj[key];
        i++;
      }
    }
    return usernameObj;
  };

  // =============   CONFIRM PASSWORD CHECK  ============= (метод для Yup проверяющий совпадают ли пароли)
  function equalTo(ref, msg) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      message: msg || 'must be the same as',
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value === this.resolve(ref);
      },
    });
  }
  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  // заменить на метод который будет обрабатывать что присылает лёня
  const userNameCheck = (obj) => {
    const arr = [];
    let i = 0;
    for (const key in obj) {
      if (key.includes('child')) {
        ++i;
        if (!obj[key]) {
          arr.push(i - 1);
        }
      }
    }
    setErrorArray(arr);
  };

  const checkUniqueEmail = (values) => {
    const userCount = Object.keys(values).length / 5;
    const result = [];
    const arr = [];
    //push all userName in arr
    for (let i = 0; i < userCount; i++) {
      arr.push(values[`username` + i].replace(/[^A-z@._0-9-]/g, ''));
    }
    // check on equal
    // код рабочий но нужно тестить или подключить _lodash
    const isEqual = (el, ind, arr) => {
      for (let i = 1; i <= userCount; i++) {
        if (Object.is(el, arr[i + ind])) {
          result.push(ind, i);
        }
      }
    };
    arr.forEach(isEqual);
    setErrorArray(result);
    return result;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values) => {
      setErrorArray([]);
      if (!checkUniqueEmail(values).length) {
        const response = await fetch(`${mainRoute.route + path.unitsearch}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(serverRequestData(values, players)),
        });
        //Step2 Data with stripeCus
        const responseJson = await response.json();
        setfinalPrice(responseJson.totalTarifs.total);
        if (responseJson.result === true) {
          // --------------- Put dot into price, summ and push it into state ---------------------
          const response = await fetch(`${mainRoute.route + path.pricesAndHear}`, {
            method: 'GET',
            headers: {},
          });
          const body = await response.json();
          // let statusJson = await response;
          const tarifsBlock = body.tarifs;
          let finalPrice = 0;
          for (let i = 1; i <= players; i++) {
            //вставляет точку между ценой на одного ребенка
            finalPrice =
              finalPrice +
              +(tarifsBlock['ch' + i].slice(0, 2) + '.' + tarifsBlock['ch' + i].slice(2));
          }
          // -------------------------------------------------------------
          //     setfinalPrice(finalPrice);
          setUserDataStep2(requestData(values, players));
          setUserDataStep3(responseJson);
          setEmailAndUsername(serverRequestData(values, players));
          changeStep(step + 1);
        } else {
          userNameCheck(responseJson);
        }
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={`${styles.form} ${styles.form_mobile}`}>
        <h5>Step 2 of 3</h5>
        <h3>Add Player Information</h3>
        <h6>Fields marked with * are mandatory</h6>
        {createForm(players)}
        <div className={styles.btnGroup}>
          <button className={styles.buttonDefault} type="submit">
            Next
          </button>
          <button
            className={styles.buttonDefault}
            onClick={() => {
              changeSignUpFieldValues({
                ...signUpFieldValues,
                step2: requestData(formik.values, players),
              });
              changeStep(step - 1);
            }}
          >
            Back
          </button>
        </div>
      </form>
    </>
  );

  function createForm(playerCount) {
    const count = Number(playerCount);
    const resultArr = [];
    for (let i = 0; i < count; i++) {
      resultArr.push(
        <PlayerForm
          errorArray={errorArray}
          playerNumber={i}
          key={count + `${i}`}
          formik={formik}
          confirmPass={formik.errors[`passwordConfirm` + i]}
          touched={formik.touched[`passwordConfirm` + i]}
        />,
      );
    }
    return resultArr;
  }

  function createInitValues(playerCount) {
    const count = Number(playerCount);
    const result = {
      initialValues: {},
      validationSchema: {},
    };
    for (let i = 0; i < count; i++) {
      Object.assign(result.initialValues, {
        [`firstname${i}`]: '',
        [`screenname${i}`]: '',
        [`username${i}`]: '',
        [`password${i}`]: '',
        [`passwordConfirm${i}`]: '',
      });
      Object.assign(result.validationSchema, {
        [`firstname${i}`]: Yup.string().trim().required('Required'),
        [`screenname${i}`]: Yup.string().trim().required('Required'),
        [`username${i}`]: Yup.string().trim().required('Required'),
        [`password${i}`]: Yup.string().trim().required('Required'),
        [`passwordConfirm${i}`]: Yup.string()
          .trim()
          .equalTo(Yup.ref(`password${i}`), 'Passwords not equal')
          .required('Required'),
      });
    }
    return result;
  }
}

export default Step2;
