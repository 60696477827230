import React, { useEffect, useState } from 'react';
import styles from './Roster.module.sass';
import { Selector } from '../../../commonComponents/Selector/Selector';
import { InviteStatus, SelectorForTeam } from '../../../../constants/teams.js';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { TeamSummary } from './RosterTeamSummary/TeamSummary/TeamSummary';
import { Invitations } from './Invitations/Invitations';
import { InvitationButtons } from './InvitationButtons/InvitationButtons';
import { SendInvitePopup } from '../SendInvitePopup/SendInvitePopup';
import { getPendingMembers, getSentRequests } from '../../../../api/teams-api';
import { useSelector } from 'react-redux';
import { showErrorMessage } from '../../../../utils/throwError';
import { CongratulationPopup } from '../CongratulationPopup/CongratulationPopup';
import { Congratulation } from '../../../../constants/teams.js';

export function Roster({
  activeSelector,
  setActiveSelector,
  currentTeamId,
  currentTeamStats,
  isManager,
  currentTeam,
  isPlayerManager,
}) {
  useScrollToTop();
  const [pendingInvitations, setPendingInvitations] = useState(false);
  const [inviteStatus, setInviteStatus] = useState(InviteStatus.HIDE_INVITE);
  const [invitations, setInvitations] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [videoGoal, setVideoGoal] = useState(0);
  const goalHours = useSelector(
    (state) => state.teamReducer.currentTeamStats.teamSummary.totalTime,
  );


  useEffect(() => {
    getPendingMembers(currentTeamId).then((response) => {
      responsePendingMembersHandler(response);
    });
    getSentRequests(currentTeamId).then((response) => {
      responseSentRequestsHandler(response);
    });
  }, [isUpdated]);

  useEffect(() => {
    switch (activeSelector) {
      case SelectorForTeam.WEEKLY:
        setVideoGoal(currentTeam.weeklyVideoGoal);
        break;
      case SelectorForTeam.MONTHLY:
        setVideoGoal(currentTeam.monthlyVideoGoal);
        break;
      case SelectorForTeam.ANNUAL:
        setVideoGoal(currentTeam.annualVideoGoal);
        break;
    }
  }, [currentTeam.weeklyVideoGoal, activeSelector]);

  const responsePendingMembersHandler = (response) => {
    if (response.ok) {
      response
        .json()
        .then((invitations) => {
          setInvitations(invitations);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      response.text().then((data) => {
        console.log(data);
      });
    }
  };

  const responseSentRequestsHandler = (response) => {
    if (response.ok) {
      response
        .json()
        .then((requests) => {
          setRequests(requests);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      response.text().then((data) => {
        console.log(data);
      });
    }
  };

  const countVideosSum = currentTeamStats.players.reduce(
    (total, playerCount) => total + playerCount.countVideo,
    0,
  );

  const timeVideosSum = currentTeamStats.players.reduce(
    (total, playerTime) => total + playerTime.timeVideo,
    0,
  );

  const handlePendingInvitationButtonClick = () => {
    if (isManager || isPlayerManager) {
      setPendingInvitations(!pendingInvitations);
    } else {
      showErrorMessage('This is only for managers.');
    }
  };

  return (
    <>
      {inviteStatus === InviteStatus.SEND_INVITE && (
        <SendInvitePopup
          setInviteStatus={setInviteStatus}
          teamId={currentTeamId}
          popupTitle={'Send Player Invite'}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      )}
      {inviteStatus === InviteStatus.INVITE_SUCCESS && (
        <CongratulationPopup
          changeChosenScreen={setInviteStatus}
          defaultScreen={InviteStatus.HIDE_INVITE}
          popupText={Congratulation.INVITE}
          setIsNeedUpdate={setIsUpdated}
        >
          <img src="/img/teams/letters.webp" style={{ width: '250px', height: 'auto' }} alt="" />
        </CongratulationPopup>
      )}
      <section className={styles.roster}>
        <ul className={styles.selector_container}>
          <Selector
            selectorList={SelectorForTeam}
            activeSelector={activeSelector}
            setActiveSelector={setActiveSelector}
          />
        </ul>
        <div className={styles.title_buttons_container}>
          <h2 className={styles.roster__title}>
            {pendingInvitations ? 'Pending Invitations' : `${activeSelector} Team Summary`}
          </h2>
          <InvitationButtons
            pendingInvitations={pendingInvitations}
            handlePendingInvitationButtonClick={handlePendingInvitationButtonClick}
            setInviteStatus={setInviteStatus}
          />
        </div>
        {pendingInvitations ? (
          <Invitations
            pendingInvitations={pendingInvitations}
            setPendingInvitations={setPendingInvitations}
            teamId={currentTeamId}
            invitations={invitations}
            setInvitations={setInvitations}
            requests={requests}
            setRequests={setRequests}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
            isAutoAccept={currentTeam.autoAcceptPending}
          />
        ) : (
          <TeamSummary
            activeSelector={activeSelector}
            countVideosSum={countVideosSum}
            timeVideosSum={timeVideosSum}
            goalHours={goalHours}
            team={currentTeamStats.players}
            managers={currentTeamStats.managers}
            homeworkManagers={currentTeamStats.homeworkManagers}
            videoGoal={videoGoal}
            teamSummary={currentTeamStats.teamSummary}
            teamId={currentTeamId}
          />
        )}
      </section>
    </>
  );
}
