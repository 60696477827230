import { message } from 'antd';
import { ERROR_MESSAGE } from '../constants/const';

export const showErrorMessage = errorMessage => {
  if (typeof errorMessage === 'string') {
    message.error(errorMessage);
  } else if (typeof errorMessage === 'object') {
    message.error({
      content: errorMessage?.content,
      duration: errorMessage?.duration,
    });
  }
};

export const showDefaultErrorMessage = () => {
  message.error(ERROR_MESSAGE);
};
