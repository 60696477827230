import React from 'react';
import styles from './MobileMenuItem.module.sass';

export function MobileMenuItem(props) {
    const { radioButton, handleMobileRadioButtonChange, isChecked } = props;

    return (
        <>
            <li key={radioButton}
                className={styles.mobile_menu__item}>
                <input
                    id={radioButton}
                    type="radio"
                    name="navigation"
                    value={radioButton}
                    onChange={handleMobileRadioButtonChange}
                    className={styles.mobile_menu__input}
                    checked={isChecked}
                />
                <label
                    htmlFor={radioButton}
                    tabIndex="0"
                    className={styles.mobile_menu__label}>
                    {radioButton}
                </label>
            </li>
        </>
    )
}
