import React from 'react';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import styles from './PrivacyPolicy.module.sass';

function PrivacyPolicy(props) {
  useScrollToTop();

  return (
    <main className={styles.main}>
      <section>
        <article>
          <h1 id="privacy-policy">PRIVACY POLICY</h1>
          <h6 id="last-updated-january-23-2020">Last updated January 23, 2020</h6>
          <p>
            Thank you for choosing to be part of our community at Anytime Online Training, doing
            business as Anytime Soccer Training (&ldquo;Anytime Soccer Training&rdquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;). We are committed to
            protecting your personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with regards to your personal
            information, please contact us at policies@anytimesoccertraining.com.
          </p>
          <p>
            When you visit our website www.anytimesoccertraining.com, and use our services, you
            trust us with your personal information.
          </p>
          <p>
            We take your privacy very seriously. In this privacy policy, we seek to explain to you
            in the clearest way possible what information we collect, how we use it and what rights
            you have in relation to it. We hope you take some time to read through it carefully, as
            it is important. If there are any terms in this privacy policy that you do not agree
            with, please discontinue use of our Sites and our services.
          </p>
          <p>
            This privacy policy applies to all information collected through our website (such as
            www.anytimesoccertraining.com), and/or any related services, sales, marketing or events
            (we refer to them collectively in this privacy policy as the &quot;Services&quot;).
          </p>
          <p>
            Please read this privacy policy carefully as it will help you make informed decisions
            about sharing your personal information with us.
          </p>
          <h2 id="1-what-information-do-we-collect-">1. WHAT INFORMATION DO WE COLLECT?</h2>
          <h5 id="personal-information-you-disclose-to-us">
            Personal information you disclose to us
          </h5>
          <p>
            <em>
              In Short: We collect personal information that you provide to us such as name,
              address, contact information, passwords and security data, payment information, and
              social media login data.
            </em>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us when registering at
            the Services expressing an interest in obtaining information about us or our products
            and services, when participating in activities on the Services (such as posting messages
            in our online forums or entering competitions, contests or giveaways) or otherwise
            contacting us.
          </p>
          <p>
            The personal information that we collect depends on the context of your interactions
            with us and the Services, the choices you make and the products and features you use.
            The personal information we collect can include the following:
          </p>
          <p>
            Publicly Available Personal Information. We collect first name, maiden name, last name,
            and nickname; current and former address; email addresses; social media; and other
            similar data.
          </p>
          <p>
            Personal Information Provided by You. We collect financial information (credit card
            number, purchase history, invoices); and other similar data.
          </p>
          <p>
            Credentials. We collect passwords, password hints, and similar security information used
            for authentication and account access.
          </p>
          <p>
            Payment Data. We collect data necessary to process your payment if you make purchases,
            such as your payment instrument number (such as a credit card number), and the security
            code associated with your payment instrument. All payment data is stored by{' '}
            <a href="https://stripe.com/">https://stripe.com/</a>. You may find their privacy policy
            link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
          </p>
          <p>
            Social Media Login Data. We provide you with the option to register using social media
            account details, like your Facebook, Twitter or other social media account. If you
            choose to register in this way, we will collect the Information described in the section
            called &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS&quot; below.
          </p>
          <p>
            All personal information that you provide to us must be true, complete and accurate, and
            you must notify us of any changes to such personal information.
          </p>
          <h5 id="information-automatically-collected">Information automatically collected</h5>
          <p>
            <em>
              In Short: Some information — such as IP address and/or browser and device
              characteristics — is collected automatically when you visit our Services.
            </em>
          </p>
          <p>
            We automatically collect certain information when you visit, use or navigate the
            Services. This information does not reveal your specific identity (like your name or
            contact information) but may include device and usage information, such as your IP
            address, browser and device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about how and when you use
            our Services and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and
            reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies and similar
            technologies.
          </p>
          <p>
            Online Identifiers. We collect devices; tools and protocols, such as IP (Internet
            Protocol) addresses; cookie identifiers, or others such as the ones used for analytics
            and marketing; and other similar data.
          </p>
          <h2 id="2-how-do-we-use-your-information-">2. HOW DO WE USE YOUR INFORMATION?</h2>
          <p>
            <em>
              In Short: We process your information for purposes based on legitimate business
              interests, the fulfillment of our contract with you, compliance with our legal
              obligations, and/or your consent.
            </em>
          </p>
          <p>
            We use personal information collected via our Services for a variety of business
            purposes described below. We process your personal information for these purposes in
            reliance on our legitimate business interests, in order to enter into or perform a
            contract with you, with your consent, and/or for compliance with our legal obligations.
            We indicate the specific processing grounds we rely on next to each purpose listed
            below.
          </p>
          <p>
            Below are some definitions that will help you understand the roles and responsibilities
            of Anytime Online Training:
          </p>
          <ul>
            <li>
              <p>
                &ldquo;data controller&rdquo; means a person who (either alone or jointly or in
                common with other persons) determines the purposes for which and the manner in which
                any personal information are, or are to be used.
              </p>
            </li>
            <li>
              <p>
                &ldquo;data processor&rdquo;, in relation to personal information, means any person
                (other than an employee of the data controller) who processes the data on behalf of
                the data controller.
              </p>
            </li>
          </ul>
          <p>
            If you provide the data and the instructions, then you are the data controller and
            Anytime Online Training is the data processor.
          </p>
          <p>
            If we determine the purposes for which we collect and use your personal information,
            then we are the Controller.
          </p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>
              <p>
                To facilitate account creation and logon process. If you choose to link your account
                with us to a third party account (such as your Google or Facebook account), we use
                the information you allowed us to collect from those third parties to facilitate
                account creation and logon process for the performance of the contract. See the
                section below headed &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS&quot; for further
                information.
              </p>
            </li>
            <li>
              <p>
                To send you marketing and promotional communications. We and/or our third party
                marketing partners may use the personal information you send to us for our marketing
                purposes, if this is in accordance with your marketing preferences. You can opt-out
                of our marketing emails at any time (see the &quot;WHAT ARE YOUR PRIVACY
                RIGHTS&quot; below).
              </p>
            </li>
            <li>
              <p>
                To send administrative information to you. We may use your personal information to
                send you product, service and new feature information and/or information about
                changes to our terms, conditions, and policies.
              </p>
            </li>
            <li>
              <p>
                Fulfill and manage your orders. We may use your information to fulfill and manage
                your orders, payments, returns, and exchanges made through the Services.
              </p>
            </li>
            <li>
              <p>
                Deliver targeted advertising to you. We may use your information to develop and
                display content and advertising (and work with third parties who do so) tailored to
                your interests and/or location and to measure its effectiveness.
              </p>
            </li>
            <li>
              <p>
                Administer prize draws and competitions. We may use your information to administer
                prize draws and competitions when you elect to participate in competitions.
              </p>
            </li>
            <li>
              <p>
                Request Feedback. We may use your information to request feedback and to contact you
                about your use of our Services.
              </p>
            </li>
            <li>
              <p>
                To protect our Services. We may use your information as part of our efforts to keep
                our Services safe and secure (for example, for fraud monitoring and prevention).
              </p>
            </li>
            <li>
              <p>
                To enable user-to-user communications. We may use your information in order to
                enable user-to-user communications with each user&#39;s consent.
              </p>
            </li>
            <li>
              <p>
                To enforce our terms, conditions and policies for Business Purposes, Legal Reasons
                and Contractual.
              </p>
            </li>
            <li>
              <p>
                To respond to legal requests and prevent harm. If we receive a subpoena or other
                legal request, we may need to inspect the data we hold to determine how to respond.
              </p>
            </li>
            <li>
              <p>
                To manage user accounts. We may use your information for the purposes of managing
                our account and keeping it in working order.
              </p>
            </li>
            <li>
              <p>
                To deliver services to the user. We may use your information to provide you with the
                requested service.
              </p>
            </li>
            <li>
              <p>
                To respond to user inquiries/offer support to users. We may use your information to
                respond to your inquiries and solve any potential issues you might have with the use
                of our Services.
              </p>
            </li>
            <li>
              <p>
                For other Business Purposes. We may use your information for other Business
                Purposes, such as data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and improve our Services,
                products, marketing and your experience. We may use and store this information in
                aggregated and anonymized form so that it is not associated with individual end
                users and does not include personal information. We will not use identifiable
                personal information without your consent.
              </p>
            </li>
          </ul>
          <h2 id="3-will-your-information-be-shared-with-anyone-">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </h2>
          <p>
            <em>
              In Short: We only share information with your consent, to comply with laws, to provide
              you with services, to protect your rights, or to fulfill business obligations.
            </em>
          </p>
          <p>We may process or share data based on the following legal basis:</p>
          <ul>
            <li>
              <p>
                Consent: We may process your data if you have given us specific consent to use your
                personal information in a specific purpose.
              </p>
            </li>
            <li>
              <p>
                Legitimate Interests: We may process your data when it is reasonably necessary to
                achieve our legitimate business interests.
              </p>
            </li>
            <li>
              <p>
                Performance of a Contract: Where we have entered into a contract with you, we may
                process your personal information to fulfill the terms of our contract.
              </p>
            </li>
            <li>
              <p>
                Legal Obligations: We may disclose your information where we are legally required to
                do so in order to comply with applicable law, governmental requests, a judicial
                proceeding, court order, or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet national security or
                law enforcement requirements).
              </p>
            </li>
            <li>
              <p>
                Vital Interests: We may disclose your information where we believe it is necessary
                to investigate, prevent, or take action regarding potential violations of our
                policies, suspected fraud, situations involving potential threats to the safety of
                any person and illegal activities, or as evidence in litigation in which we are
                involved.
              </p>
            </li>
          </ul>
          <p>
            More specifically, we may need to process your data or share your personal information
            in the following situations:
          </p>
          <p>
            Business Transfers. We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </p>
          <p>
            Third-Party Advertisers. We may use third-party advertising companies to serve ads when
            you visit the Services. These companies may use information about your visits to our
            Website(s) and other websites that are contained in web cookies and other tracking
            technologies in order to provide advertisements about goods and services of interest to
            you.
          </p>
          <h2 id="4-do-we-use-cookies-and-other-tracking-technologies-">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h2>
          <p>
            <em>
              In Short: We may use cookies and other tracking technologies to collect and store your
              information.
            </em>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to
            access or store information. Specific information about how we use such technologies and
            how you can refuse certain cookies is set out in our Cookie Policy.
          </p>
          <h2 id="5-do-we-use-google-maps-">5. DO WE USE GOOGLE MAPS?</h2>
          <p>
            <em>In Short: Yes, we use Google Maps for the purpose of providing better service.</em>
          </p>
          <p>
            This website, mobile application, or Facebook application uses Google Maps APIs. You may
            find the Google Maps APIs Terms of Service <a href="/#">here</a>. To better understand
            Google’s Privacy Policy, please refer to this <a href="/#">link</a>.
          </p>
          <p>
            By using our Maps API Implementation, you agree to be bound by Google’s Terms of
            Service. You agree to allow us to obtain or cache your location. You may revoke your
            consent at anytime.
          </p>
          <h2 id="6-how-do-we-handle-your-social-logins-">
            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </h2>
          <p>
            <em>
              In Short: If you choose to register or log in to our services using a social media
              account, we may have access to certain information about you.
            </em>
          </p>
          <p>
            Our Services offer you the ability to register and login using your third party social
            media account details (like your Facebook or Twitter logins). Where you choose to do
            this, we will receive certain profile information about you from your social media
            provider. The profile Information we receive may vary depending on the social media
            provider concerned, but will often include your name, e-mail address, friends list,
            profile picture as well as other information you choose to make public.
          </p>
          <p>
            We will use the information we receive only for the purposes that are described in this
            privacy policy or that are otherwise made clear to you on the Services. Please note that
            we do not control, and are not responsible for, other uses of your personal information
            by your third party social media provider. We recommend that you review their privacy
            policy to understand how they collect, use and share your personal information, and how
            you can set your privacy preferences on their sites and apps.
          </p>
          <h2 id="7-how-long-do-we-keep-your-information-">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p>
            <em>
              In Short: We keep your information for as long as necessary to fulfill the purposes
              outlined in this privacy policy unless otherwise required by law.
            </em>
          </p>
          <p>
            We will only keep your personal information for as long as it is necessary for the
            purposes set out in this privacy policy, unless a longer retention period is required or
            permitted by law (such as tax, accounting or other legal requirements). No purpose in
            this policy will require us keeping your personal information for longer than 1 year
            past the termination of the user&#39;s account.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your personal information,
            we will either delete or anonymize it, or, if this is not possible (for example, because
            your personal information has been stored in backup archives), then we will securely
            store your personal information and isolate it from any further processing until
            deletion is possible.
          </p>
          <h2 id="8-how-do-we-keep-your-information-safe-">
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h2>
          <p>
            <em>
              In Short: We aim to protect your personal information through a system of
              organizational and technical security measures.
            </em>
          </p>
          <p>
            We have implemented appropriate technical and organizational security measures designed
            to protect the security of any personal information we process. However, please also
            remember that we cannot guarantee that the internet itself is 100% secure. Although we
            will do our best to protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should only access the
            services within a secure environment.
          </p>
          <h2 id="9-what-are-your-privacy-rights-">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
          <p>
            <em>
              In Short: In some regions, such as the European Economic Area, you have rights that
              allow you greater access to and control over your personal information. You may
              review, change, or terminate your account at any time.
            </em>
          </p>
          <p>
            In some regions (like the European Economic Area), you have certain rights under
            applicable data protection laws. These may include the right (i) to request access and
            obtain a copy of your personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; and (iv) if applicable,
            to data portability. In certain circumstances, you may also have the right to object to
            the processing of your personal information. To make such a request, please use the{' '}
            <a href="/#">contact details</a> provided below. We will consider and act upon any
            request in accordance with applicable data protection laws.
          </p>
          <p>
            If we are relying on your consent to process your personal information, you have the
            right to withdraw your consent at any time. Please note however that this will not
            affect the lawfulness of the processing before its withdrawal.
          </p>
          <p>
            If you are resident in the European Economic Area and you believe we are unlawfully
            processing your personal information, you also have the right to complain to your local
            data protection supervisory authority. You can find their contact details here:
          </p>
          <p>
            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may email us at{' '}
            <a href="mailto:policies@anytimesoccertraining.com">
              policies@anytimesoccertraining.com
            </a>
            .
          </p>
          <h5 id="account-information">Account Information</h5>
          <p>
            If you would at any time like to review or change the information in your account or
            terminate your account, you can:
          </p>
          <ul>
            <li>Log into your account settings and update your user account.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or delete your account
            and information from our active databases. However, some information may be retained in
            our files to prevent fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with legal requirements.
          </p>
          <p>
            <em>Cookies and similar technologies</em>: Most Web browsers are set to accept cookies
            by default. If you prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject cookies, this could
            affect certain features or services of our Services. To opt-out of interest-based
            advertising by advertisers on our Services visit
          </p>
          <p>
            <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>
          </p>
          <p>
            <em>Opting out of email marketing</em>: You can unsubscribe from our marketing email
            list at any time by clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be removed from the
            marketing email list – however, we will still need to send you service-related emails
            that are necessary for the administration and use of your account. To otherwise opt-out,
            you may:
          </p>
          <ul>
            <li>
              <p>Note your preferences when you register an account with the site.</p>
            </li>
            <li>
              <p>Access your account settings and update preferences</p>
            </li>
          </ul>
          <h2 id="10-data-breach">10. DATA BREACH</h2>
          <p>
            A privacy breach occurs when there is unauthorized access to or collection, use,
            disclosure or disposal of personal information. You will be notified about data breaches
            when Anytime Online Training believes you are likely to be at risk or serious harm. For
            example, a data breach may be likely to result in serious financial harm or harm to your
            mental or physical well-being. In the event that Anytime Online Training becomes aware
            of a security breach which has resulted or may result in unauthorized access, use or
            disclosure of personal information Anytime Online Training will promptly investigate the
            matter and notify the applicable Supervisory Authority not later than 72 hours after
            having become aware of it, unless the personal data breach is unlikely to result in a
            risk to the rights and freedoms of natural persons.
          </p>
          <h2 id="11-controls-for-do-not-track-features">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile applications include a
            Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your
            privacy preference not to have data about your online browsing activities monitored and
            collected. No uniform technology standard for recognizing and implementing DNT signals
            has been finalized. As such, we do not currently respond to DNT browser signals or any
            other mechanism that automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the future, we will
            inform you about that practice in a revised version of this privacy policy.
          </p>
          <h2 id="12-do-california-residents-have-specific-privacy-rights-">
            12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </h2>
          <p>
            <em>
              In Short: Yes, if you are a resident of California, you are granted specific rights
              regarding access to your personal information.
            </em>
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo;
            law, permits our users who are California residents to request and obtain from us, once
            a year and free of charge, information about categories of personal information (if any)
            we disclosed to third parties for direct marketing purposes and the names and addresses
            of all third parties with which we shared personal information in the immediately
            preceding calendar year. If you are a California resident and would like to make such a
            request, please submit your request in writing to us using the contact information
            provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a registered account
            with the Services, you have the right to request removal of unwanted data that you
            publicly post on the Services. To request removal of such data, please contact us using
            the contact information provided below, and include the email address associated with
            your account and a statement that you reside in California. We will make sure the data
            is not publicly displayed on the Services, but please be aware that the data may not be
            completely or comprehensively removed from our systems.
          </p>
          <h2 id="13-do-we-make-updates-to-this-policy-">13. DO WE MAKE UPDATES TO THIS POLICY?</h2>
          <p>
            <em>
              In Short: Yes, we will update this policy as necessary to stay compliant with relevant
              laws.
            </em>
          </p>
          <p>
            We may update this privacy policy from time to time. The updated version will be
            indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be
            effective as soon as it is accessible. If we make material changes to this privacy
            policy, we may notify you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this privacy policy
            frequently to be informed of how we are protecting your information.
          </p>
          <h2 id="14-how-can-you-contact-us-about-this-policy-">
            14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
          </h2>
          <p>
            If you have questions or comments about this policy, you may contact our Data Protection
            Officer (DPO), Michael Crawford, by email at data@anytimesoccertraining.com, or by post
            to:
          </p>
          <p>
            Anytime Online Training Michael Crawford 1111 Brookhill Way Cary, NC 27519 United States
          </p>
          <p>
            If you are a resident in the European Economic Area, the &quot;data controller&quot; of
            your personal information is Anytime Online Training. Anytime Online Training has
            appointed Samson A Crowder to be its representative in the EEA. You can contact them
            directly regarding the processing of your information by Anytime Online Training, by
            email at policies@anytimesoccertraining.com, or by post to:
          </p>
          <p>21 Kent Close Mitcham, Surrey CR4 1XN United Kingdom</p>
          <h2 id="how-can-you-review-update-or-delete-the-data-we-collect-from-you-">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </h2>
          <p>
            Based on the laws of some countries, you may have the right to request access to the
            personal information we collect from you, change that information, or delete it in some
            circumstances. To request to review, update, or delete your personal information, please
            submit a request form by clicking <a href="/#">here</a>. We will respond to your request
            within 30 days.
          </p>
        </article>
      </section>
      <aside>
        <div>
          <h4>TABLE OF CONTENTS</h4>
          <ol>
            <li>
              <a href={`${props.path.privacyPolicy}#1-what-information-do-we-collect-`}>
                1. WHAT INFORMATION DO WE COLLECT?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#2-how-do-we-use-your-information-`}>
                2. HOW DO WE USE YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a
                href={`${props.path.privacyPolicy}#3-will-your-information-be-shared-with-anyone-`}
              >
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </a>
            </li>
            <li>
              <a
                href={`${props.path.privacyPolicy}#4-do-we-use-cookies-and-other-tracking-technologies-`}
              >
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#5-do-we-use-google-maps-`}>
                5. DO WE USE GOOGLE MAPS?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#6-how-do-we-handle-your-social-logins-`}>
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#7-how-long-do-we-keep-your-information-`}>
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#8-how-do-we-keep-your-information-safe-`}>
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#9-what-are-your-privacy-rights-`}>
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#10-data-breach`}>10. DATA BREACH</a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#11-controls-for-do-not-track-features`}>
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
            </li>
            <li>
              <a
                href={`${props.path.privacyPolicy}#12-do-california-residents-have-specific-privacy-rights-`}
              >
                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#13-do-we-make-updates-to-this-policy-`}>
                13. DO WE MAKE UPDATES TO THIS POLICY?
              </a>
            </li>
            <li>
              <a href={`${props.path.privacyPolicy}#14-how-can-you-contact-us-about-this-policy-`}>
                14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
              </a>
            </li>
          </ol>
        </div>
      </aside>
    </main>
  );
}

export default PrivacyPolicy;
