import React, { useState } from 'react';
import styles from './Table.module.sass';
import ChildrenRow from './ChildrenRow';
import FirstPlayerAddBlock from './FirstPlayerAddBlock';

function Table(props) {
  const count = props.playerData.childInfo.childrenCount;
  const [slider, setSlider] = useState(true);

  const changeSlide = () => {
    setSlider(!slider);
  };

  return (
    <>
      <section className={styles.section}>
        {Boolean(count) && (
          <>
            <div className={styles.thead}>
              <div className={styles.thead__row}>Screen Name</div>
              <div className={styles.thead__row}>Username</div>
              <div className={styles.thead__row}>Contact Email</div>
              <div className={styles.thead__row_small}>Status</div>
              <div className={styles.thead__row}>Action</div>
            </div>
            <div className={styles.tbody}>
              <div className={styles.mobile_header}>
                <div className={styles.mobile_header__screenname}>
                  <p>Screen Name</p>
                </div>
                <div className={styles.mobile_header__action}>
                  <p onClick={changeSlide}>
                    <span>{'<'}</span>
                  </p>
                  <div className={styles.action_slider__status}>
                    <p>{slider ? 'Action' : 'Status'}</p>
                  </div>
                  <p onClick={changeSlide}>
                    <span>{'>'}</span>
                  </p>
                </div>
              </div>
              {createChildrenTable(
                count,
                props.playerData,
                props.setRole,
                props.setOnlyChildData,
                slider,
                setSlider,
              )}
            </div>
          </>
        )}
        {!count && <FirstPlayerAddBlock />}
      </section>
    </>
  );
}

function createChildrenTable(count, playerData, setRole, setOnlyChildData, slider, setSlider) {
  const resultArr = [];
  for (let i = 1; i <= count; i++) {
    resultArr.push(
      <ChildrenRow
        screenName={playerData.childInfo[`child` + i].screenname}
        userName={playerData.childInfo[`child` + i].username}
        email={playerData.childInfo[`child` + i].childEmail}
        status={playerData.childInfo[`child` + i].status}
        state={playerData.childInfo[`child` + i].state}
        subPeriodEnd={playerData.childInfo[`child` + i].subPeriodEnd}
        key={playerData.childInfo[`child` + i].id}
        count={i}
        picture={playerData.childInfo[`child` + i].picture}
        image={playerData.childInfo[`child` + i].image}
        slider={slider}
        setSlider={setSlider}
        setRole={setRole}
        setOnlyChildData={setOnlyChildData}
      />,
    );
  }
  return resultArr;
}

export default Table;
