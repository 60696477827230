// статические картинки из папки /img/home/
const URl_Pictores_Folder = '/img/home/';
export const videoDataImg = {
  'Dynamic Warmup Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dynamic_Warmup_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dynamic_Warmup_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dynamic_Warmup_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Dynamic_Warm_Up_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Passing_Warmup_Red_Bottom.webp`,
  ],
  '1000 Touch P1 Part 1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_13_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_13_Red_Bottom.webp`,
  ],
  '1000 Touch P1 Part 2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_16_Red_Bottom.webp`,
  ],
  '1000 Touch P1 Part 3': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_6_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_14_Red_Bottom.webp`,
  ],
  '1000 Touch P2 Part 1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_8_Red_Bottom.webp`,
  ],
  '1000 Touch P2 Part 2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_9_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_10_Red_Bottom.webp`,
  ],
  '1000 Touch P2 Part 3': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_11_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_12_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_1000_Touch_15_Red_Bottom.webp`,
  ],
  'Beginner Juggle & Bounce': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_6_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_Red_15_Bottom.webp`,
  ],
  '1V1 Finishing Games': [
    `${URl_Pictores_Folder}new-design/AST_Free_Section_Dashboard_Simple_Cards_295_blue_grey.webp`,
    `${URl_Pictores_Folder}new-design/AST_Free_Section_Dashboard_Simple_Cards_296_blue_grey.webp`,
  ],
  '2 Footed Thigh Juggling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_Red_16_Red_Bottom.webp`,
  ],
  'Catch & Hit Juggle': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_Red_17_Red_Bottom.webp`,
  ],
  '1 Intermed Juggle Master': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_7_Red_Bottom.webp`,
  ],
  '2 Intermed Juggle Master': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_8_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_9_Red_Bottom.webp`,
  ],
  'Advanced Juggle Master': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_10_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_11_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_12_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_10_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_11_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_12_Red_Bottom.webp`,
  ],
  'Advanced Juggling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_10_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_11_Red_Bottom.webp`,
  ],
  'Advanced Moving Juggling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_12_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_10_Red_Bottom.webp`,
  ],
  'Flicks | Juggling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_11_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_12_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Footed_14_Red_Bottom.webp`,
  ],
  'Figure 8 Close Control': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_2_Red_Bottom.webp`,
  ],
  'Figure 8 Essential Drill': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_4_Red_Bottom.webp`,
  ],
  'Figure 8 Lateral Drills': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_3_Red_Bottom.webp`,
  ],
  'Figure 8 1v1 Moves': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_6_Red_Bottom.webp`,
  ],
  'Figure 8 Ball Mastery': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Figure_8_Dribbling_5_Red_Bottom.webp`,
  ],
  '1 Cone Passing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_One_Cone_Passing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_One_Cone_Passing_2_Red_Bottom.webp`,
  ],
  'Five Star Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Five_Star_Dribbling_6_Red_Bottom.webp`,
  ],
  '1 Line Cone': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_2_Red_Bottom.webp`,
  ],
  '2 Line Cone': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_4_Red_Bottom.webp`,
  ],
  '3 Line Cone': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_6_Red_Bottom.webp`,
  ],
  '4 Line Cone': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_8_Red_Bottom.webp`,
  ],
  // на сайте папка называется Fitness_and_Soccer - слишком длинное название папки для Vimeo
  'Endurance in Soccer': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Fitness_And_Soccer_6_Red_Bottom.webp`,
  ],
  'Partner Drills Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_6_Red_Bottom.webp`,
  ],
  '2 Touch Passing Partner': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_2_Red_Bottom.webp`,
  ],
  'Directional Ctrl Sole': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_4_Red_Bottom.webp`,
  ],
  '1 Cone Movement More': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Passing_Drills_6_Red_Bottom.webp`,
  ],
  'Two Cone Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_6_Red_Bottom.webp`,
  ],
  'Dual Cone Pro P1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_2_Red_Bottom.webp`,
  ],
  'Dual Cone Pro P2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_4_Red_Bottom.webp`,
  ],
  'Dual Cone Pro P3': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_6_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Two_Cone_8_Red_Bottom.webp`,
  ],
  'Change Direction P1': [
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_2_Red_Bottom.webp`,
  ],
  'Change Direction P2': [
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_4_Red_Bottom.webp`,
  ],
  'Change Direction P3': [
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}change-direction/Challenge_Cards_Design_Quick_Turns_6_Red_Bottom.webp`,
  ],
  'Fast Speed Dribble Master P1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_1_Red_Bottom.webp`,
  ],
  'Fast Speed Dribble Master P2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_2_Red_Bottom.webp`,
  ],
  'Fast Speed Dribble Master P3': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_4_Red_Bottom.webp`,
  ],
  'Fast Speed Dribble Master P4': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Dribble_Master_6_Red_Bottom.webp`,
  ],
  '1v1 Moves Pro P1': [
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_2_Red_Bottom.webp`,
  ],
  '1v1 Moves Pro P2': [
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_4_Red_Bottom.webp`,
  ],
  '1v1 Moves Pro P3': [
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}1V1/Challenge_Cards_Design_1v1_Moves_6_Red_Bottom.webp`,
  ],
  '5 Minute': [
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_8_Red_Bottom.webp`,
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}5-minute/Challenge_Cards_Design_Five_Minute_Workouts_6_Red_Bottom.webp`,
  ],
  '1v1 & Wall Passing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Clinical_Fishing_6_Red_Bottom.webp`,
  ],
  '30 Day Player': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_One_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Two_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Three_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Four_Red_Bottom.webp`,
  ],
  '30 Day Ball Mastery': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_One_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Two_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Three_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Four_Red_Bottom.webp`,
  ],
  'Anytime Skills': [
    `${URl_Pictores_Folder}anytime/New_Cards_Design_Ball_Mastery_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/New_Cards_Design_Juggle_Master_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/New_Cards_Design_Figure_8_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Two_Cone_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Rebounder_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Line_Cone_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Fitness_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Dribbling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Five_Star_Red_Bottom.webp`,
    `${URl_Pictores_Folder}anytime/Challenge_Cards_Design_Finishing_Red_Bottom.webp`,
  ],
  'Cone Slalom Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_6_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Cone_Slalom_7_Red_Bottom.webp`,
  ],
  'First Touch Pro P1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_2_Red_Bottom.webp`,
  ],
  'First Touch Pro P2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_4_Red_Bottom.webp`,
  ],
  'First Touch Pro P3': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_5_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_First_Touch_6_Red_Bottom.webp`,
  ],
  'Games Playing, Racing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Soccer_Racing_Games_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Soccer_Racing_Games_2_Red_Bottom.webp`,
  ],
  '(T)Formation Passing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_T_Formation_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_T_Formation_2_Red_Bottom.webp`,
  ],
  'Agility Ring Passing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Agility_Ring_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Agility_Ring_2_Red_Bottom.webp`,
  ],
  'Defender Passing ': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Defender_Passing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Defender_Passing_2_Red_Bottom.webp`,
  ],
  'Distant Passing': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Long_Distance_Passing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Long_Distance_Passing_2_Red_Bottom.webp`,
  ],
  'Free Section 01 Quick Tips': [`${URl_Pictores_Folder}Free_Section_Quick_Tips.webp`],
  'Free Section 02 Anytime Soccer': [`${URl_Pictores_Folder}Free_Section_Anytime_Soccer.webp`],
  'Free Section 03 Ball Mastery': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_Touch_Ball_Mastery.webp`,
  ],
  'Free Section 04 Juggle Master': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_Juggle_Master.webp`,
  ],
  'Box Dribbling Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Tight_Box_Dribbling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Tight_Box_Dribbling_2_Red_Bottom.webp`,
  ],
  'Change Route & Freestyle': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Freestyle_Dribbling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Freestyle_Dribbling_2_Red_Bottom.webp`,
  ],
  'Body Feint Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Body_Feint_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Body_Feint_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Body_Feint_3_Red_Bottom.webp`,
  ],
  'Free Section Games': [
    `${URl_Pictores_Folder}new-design/Free_Area_Video_Cards_blue_Bottom_9.webp`,
    `${URl_Pictores_Folder}new-design/Free_Area_Video_Cards_blue_Bottom_10.webp`,
    `${URl_Pictores_Folder}new-design/Free_Area_Video_Cards_blue_Bottom_11.webp`,
  ],
  'Free Section 1V1 Moves': [
    `${URl_Pictores_Folder}new-design/Free_Area_Video_Cards_blue_Bottom_8.webp`,
  ],
  'Free Section Two Cone': [`${URl_Pictores_Folder}Free_Section_Dashboard_Two_Cone.webp`],
  'Partner Aerial Toss Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Partner_Aerial_Toss_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Partner_Aerial_Toss_5_Red_Bottom.webp`,
  ],
  'Aerial Toss Part 1': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_2_Red_Bottom.webp`,
  ],
  'Aerial Toss Part 2': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Partner_Aerial_Toss_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Partner_Aerial_Toss_4_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Partner_Aerial_Toss_5_Red_Bottom.webp`,
  ],
  'Free Section First Touch': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_First_Touch_Rebounder.webp`,
  ],
  'Free Section Figure 8 Dribbling': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_Figure_Dribbling.webp`,
  ],
  'Free Section Body Feints': [`${URl_Pictores_Folder}Free_Section_Dashboard_Red_Bottom_28.webp`],
  'Free Section Dribble Master': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_Dribble_Master.webp`,
  ],
  'Free Section Partner Aerial Toss': [
    `${URl_Pictores_Folder}Free_Section_Dashboard_Partner_Aerial_Toss.webp`,
  ],
  'Free Section Line Cone': [`${URl_Pictores_Folder}Free_Section_Dashboard_Line_Cone.webp`],
  'Agility Pro': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Agility_Ladders_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Agility_Ladders_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Agility_Ladders_3_Red_Bottom.webp`,
  ],
  'Cone Line Slalom': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_and_Slalom_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_and_Slalom_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Line_Cone_and_Slalom_3_Red_Bottom.webp`,
  ],
  // TODO: не хватает одной картинки
  '3 Cone Dribbling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Three_Cone_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Three_Cone_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Three_Cone_2_Red_Bottom.webp`,
  ],
  '(Start) Juggle Challenge': [
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Beginner_Juggling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Intermediate_Juggling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Advanced_Juggling_Red_Bottom.webp`,
  ],
  'Dribble Challenge': [
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_One_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Two_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Three_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Four_2_Red_Bottom.webp`,
  ],
  '30 Day No Weak Foot ': [
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_30_Day_Right_Foot_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_30_Day_Left_Foot_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_30_Day_Both_Feet_Red_Bottom.webp`,
  ],
  '30 Day Tight Space': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_One_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Two_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Three_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Week_Four_Red_Bottom.webp`,
  ],
  'Brief Freestyle': [
    `${URl_Pictores_Folder}summary-freestyle/Challenge_Cards_Design_Anytime_Freestyle_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}summary-freestyle/Challenge_Cards_Design_Anytime_Freestyle_4_Red_Bottom.webp`,
  ],
  '30 Day Fitness': [
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_One_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Two_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Three_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}player-journey/Challenge_Cards_Design_Week_Four_2_Red_Bottom.webp`,
  ],
  '(Start) Wall Pass Pro': [
    `${URl_Pictores_Folder}wall-pass/Challenge_Cards_Design_Wall_Pass_Week_One_Red_Bottom.webp`,
    `${URl_Pictores_Folder}wall-pass/Challenge_Cards_Design_Wall_Pass_Week_Two_Red_Bottom.webp`,
    `${URl_Pictores_Folder}wall-pass/Challenge_Cards_Design_Wall_Pass_Week_Three_Red_Bottom.webp`,
    `${URl_Pictores_Folder}wall-pass/Challenge_Cards_Design_Wall_Pass_Week_Four_Red_Bottom.webp`,
  ],
  '1 Cone Pro': [
    `${URl_Pictores_Folder}one-cone/Challenge_Cards_Design_One_Cone_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}one-cone/Challenge_Cards_Design_One_Cone_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}one-cone/Challenge_Cards_Design_One_Cone_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}one-cone/Challenge_Cards_Design_One_Cone_Red_Bottom.webp`,
  ],
  'Cable Jump Master': [
    `${URl_Pictores_Folder}jump-rope/Challenge_Cards_Design_Jump_Rope_Master_Red_Bottom.webp`,
    `${URl_Pictores_Folder}jump-rope/Free_Area_Video_Cards_Design_28_blue_Bottom.webp`,
  ],
  'Selected Ball Mastery': [
    `${URl_Pictores_Folder}new-design/AST_Free_Section_Dashboard_Simple_Cards_92_grey_black.webp`,
  ],
  'Intermed to Adv Dribbling Drills': [
    `${URl_Pictores_Folder}new-design/AST_Free_Section_Dashboard_Simple_Cards_93_grey_black.webp`,
  ],
  'Four Cone Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Three_Cone_Ball_Mastery_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Four_Cone_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Six_Cone_Red_Bottom.webp`,
  ],
  'Dynamic Ball Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Dynamic_Ball_Mastery_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Dynamic_Ball_Mastery_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Dynamic_Ball_Mastery_3_Red_Bottom.webp`,
  ],
  'Choice of Juggle Master': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Beginner_Juggling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Intermediate_Juggling_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Advanced_Juggling_Red_Bottom.webp`,
  ],
  '(Timed) Juggle Challenge': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Juggle_Master_Timed_Challenges_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Three_Juggling_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Three_Juggling_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Three_Juggling_3_Red_Bottom.webp`,
  ],
  'Agility Rings Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Agility_Rings_Ball_Mastery_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Agility_Rings_Ball_Mastery_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Agility_Rings_Ball_Mastery_3_Red_Bottom.webp`,
  ],
  'Fitness Hurdles Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Fitness_Hurdless_Ball_Mastery_Red_Bottom.webp`,
  ],
  'Escape Pressure Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Dribbling_Turns_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Escape_Pressure_Red_Bottom.webp`,
  ],
  'Figure 8 Moves': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Figure_8_1v1_Moves_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Figure_8_1v1_Moves_2_Red_Bottom.webp`,
  ],
  'Four Cone Essential': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Design_Four_Cone_Essential_2_Red_Bottom_(1).webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Four_Cone_Essential_1_Red_Bottom_(1).webp`,
  ],
  '1 Lateral Movement': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_2_Red_Bottom.webp`,
  ],
  '2 Lateral Movement': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_4_Red_Bottom.webp`,
  ],
  '3 Lateral Movement': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_7_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Lateral_Movement_8_Red_Bottom.webp`,
  ],
  'Ball Mastery & Dribbling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Ball_Mastery_And_Dribbling_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Ball_Mastery_And_Dribbling_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Ball_Mastery_And_Dribbling_3_Red_Bottom.webp`,
  ],
  'Fire Rapid Pro': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Rapid_Fire_Finishing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Rapid_Fire_Finishing_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Rapid_Fire_Finishing_3_Red_Bottom.webp`,
  ],
  'Directional Control': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Directional_Control_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Directional_Control_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Directional_Control_3_Red_Bottom.webp`,
  ],
  'Crossing and Passing': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Passing_and_Crossing_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Passing_and_Crossing_2_Red_Bottom.webp`,
  ],
  'Cone Line Passing': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Line_Cone_10_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Line_Cone_9_Red_Bottom.webp`,
  ],
  'Cone Slalom Dribbling': [
    `${URl_Pictores_Folder}Challenge_Cards_Design_Slalom_Dribbling_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}Challenge_Cards_Design_Slalom_Dribbling_2_Red_Bottom.webp`,
  ],
  'Double Turns': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Double_Turns_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Double_Turns_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Double_Turns_3_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Double_Turns_4_Red_Bottom.webp`,
  ],
  'Midfield Ball Mastery': [
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Midfielder_1_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Midfielder_2_Red_Bottom.webp`,
    `${URl_Pictores_Folder}new-design/Challenge_Cards_Design_Midfielder_3_Red_Bottom.webp`,
  ],
};
