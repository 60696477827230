import React, { useEffect, useState } from 'react';
import styles from './ManagementTeam.module.sass';
import { BorderedButton } from '../../../commonComponents/BorderedButton/BorderedButton';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { SubscriptionDetailsButton } from '../SubscriptionDetails/SubscriptionDetailsButton';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { ImageCropper } from '../../../Dashbord/ImageCropper/ImageCropper';
import {
  checkIsTeamNameUnique,
  deleteTeam,
  getTeamGoals,
  updateTeamGoals,
} from '../../../../api/teams-api';
import { UPLOAD_LOGO_TITLE } from '../../../../constants/teams';
import { showErrorMessage } from '../../../../utils/throwError';
import { getFormData } from '../../../../utils/utils';
import { DeletePopup } from './DeletePopup/DeletePopup';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';
import { Loading } from '../../../commonComponents/Loading/Loading';

/** Save Changes = request to server for saving */
export function ManagementTeam({ setDefaultScreen, currentTeamId, setIsGoalUpdated }) {
  const [formData, setFormData] = useState({});
  const [defaultTeamName, setDefaultTeamName] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isReadyToCheckName, setIsReadyToCheckName] = useState(false);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [hasNameChecked, setHasNameChecked] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [isClearImage, setIsClearImage] = useState(false);
  const [popup, setPopup] = useState(false);
  const { width } = useWindowDimensions();
  const [isDataUploaded, setIsDataUploaded] = useState(false);

  const { annualVideoGoal, monthlyVideoGoal, name, weeklyVideoGoal, weeklyTrainingTime } = formData;

  const history = useHistory();

  useScrollToTop();

  useEffect(() => {
    getTeamGoals(currentTeamId).then((response) => {
      handleResponse(response);
    });
  }, []);

  useEffect(() => {
    if (isSaving) {
      /** Request success redirect user to dashboard */
      const formData = getFormData({
        ...requestData,
        teamId: currentTeamId,
        name,
        weeklyVideoGoal: Number(weeklyVideoGoal),
        monthlyVideoGoal: Number(monthlyVideoGoal),
        annualVideoGoal: Number(annualVideoGoal),
      });

      updateTeamGoals(formData).then((response) => {
        updateGoalResponseHandler(response);
      });
    }
  }, [isSaving]);

  useEffect(() => {
    checkUniqueness(name);
  }, [isReadyToCheckName]);

  useEffect(() => {
    setFormData({
      ...formData,
      weeklyTrainingTime: weeklyVideoGoal * 3,
    });
  }, [weeklyVideoGoal]); // not sure why other disabled inputs reacting accordingly to their enabled ones

  async function checkUniqueness(teamName) {
    if (defaultTeamName !== teamName?.toLowerCase()) {
      const response = await checkIsTeamNameUnique(teamName);
      setIsNameUnique(response.ok);
      setHasNameChecked(true);
    }
  }
  const updateGoalResponseHandler = (response) => {
    if (response.ok) {
      setIsGoalUpdated(true);
      setDefaultScreen();
    }
  };
  const handleResponse = (response) => {
    response.json().then((data) => {
      setFormData(data);
      const teamName = data.name.toLowerCase();
      setDefaultTeamName(teamName);
      setIsDataUploaded(true);
    });
  };

  const handleSubmit = () => {
    if (!hasNameChecked) {
      checkUniqueness(name);
    }
    if (isNameUnique) {
      setIsSaving(true);
    } else {
      showErrorMessage('This team name has already existed.');
    }
  };

  const handleDelete = () => {
    deleteTeam(currentTeamId);
    history.push('/teams');
  };

  const handlePopup = () => {
    setPopup(true);
  };

  const handleTeamNameChange = (event) => {
    setFormData({
      ...formData,
      name: event.target.value,
    });
    if (!isNameUnique) {
      setIsNameUnique(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlurTeamName = () => {
    setIsReadyToCheckName(!isReadyToCheckName);
  };
  const checkMobileTrainingTime = () => {
    return width >= MOBILE_DIMENSION ? 'Minimum Training Time' : 'Min. Training Time';
  };

  return (
    <>
      {popup && (
        <DeletePopup setPopup={setPopup} popupTitle="Are you sure?" handleDelete={handleDelete} />
      )}
      {isDataUploaded ? (
        <section className={styles.management}>
          <h2 className={styles.management__title}>Edit Team & Set Goals</h2>
          <form className={styles.form}>
            <label className={styles.label}>
              Team Name
              <input
                className={styles.input}
                type="text"
                value={name}
                onChange={handleTeamNameChange}
                onBlur={handleBlurTeamName}
              />
              {!isNameUnique && (
                <p className={styles.input__error}>This name is already being used.</p>
              )}
            </label>
            <div className={styles.button_upload}>
              <ImageCropper
                requestData={requestData}
                setRequestData={setRequestData}
                isClearImage={isClearImage}
                setIsClearImage={setIsClearImage}
                title={UPLOAD_LOGO_TITLE}
              />
            </div>
            <label className={styles.label}>
              {width >= MOBILE_DIMENSION ? 'Weekly Player Video Training Goal' : 'Weekly Goal'}
              <input
                className={styles.input}
                type="number"
                name="weeklyVideoGoal"
                value={weeklyVideoGoal}
                onChange={handleInputChange}
              />
            </label>
            <label className={styles.label}>
              <p className={styles.label_important}>{checkMobileTrainingTime()}</p>
              <input className={styles.input} type="number" value={weeklyTrainingTime} disabled />
            </label>
            <label className={styles.label}>
              {width >= MOBILE_DIMENSION ? 'Monthly Player Video Training Goal' : 'Monthly Goal'}
              <input
                className={styles.input}
                type="number"
                name="monthlyVideoGoal"
                value={monthlyVideoGoal}
                onChange={handleInputChange}
              />
            </label>
            <label className={styles.label}>
              {checkMobileTrainingTime()}
              <input className={styles.input} type="number" value={monthlyVideoGoal * 3} disabled />
            </label>
            <label className={styles.label}>
              {width >= MOBILE_DIMENSION ? 'Annual Player Video Training Goal' : 'Annual Goal'}
              <input
                className={styles.input}
                type="number"
                name="annualVideoGoal"
                value={annualVideoGoal}
                onChange={handleInputChange}
              />
            </label>
            <label className={styles.label}>
              {checkMobileTrainingTime()}
              <input className={styles.input} type="number" value={annualVideoGoal * 3} disabled />
            </label>
          </form>
          <p className={styles.management__info}>
            {width >= MOBILE_DIMENSION
              ? 'Teams must complete the total amount of videos and the minimum training time to reach their training goal.'
              : 'Minimum training time must be met to achieve goal.'}
          </p>
          <div className={styles.buttons}>
            <button className={styles.button_delete} onClick={handlePopup}>
              Delete Team
            </button>
            <BorderedButton handleButtonClick={setDefaultScreen} width={'15rem'} />
            <RedButton handleButtonClick={handleSubmit} title={'Save Changes'} width={'18rem'} />
            <SubscriptionDetailsButton />
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
}
