export const PromoForCreateAccount = [
  'Over 5,000 Training Videos',
  'Over 101 Fun Soccer Games',
  'Free Lifetime Membership of Select Videos',
  'Goal setting, tracking and much more',
];

export const PromoForUpgradePage = [
  'Over 5,000 Training Videos',
  'Over 101 Fun Soccer Games',
  'Free Lifetime Membership of Select Videos',
  'Goal setting, tracking and much more',
];

export const PromoForUpgradeTeam = [
  'Free Team Manager Account',
  'Unlimited access to +1,000 training videos',
  '+100 soccer games and skills challenges',
  'Performance reports & rewards',
  'And more ...',
];
