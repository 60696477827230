import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './ChildGreeting.module.sass';

export function ChildGreeting(props) {
  const { firstName } = useParams();
  let { userScreenName } = props;
  if (firstName) {
    userScreenName = firstName;
  }
  return <p className={styles.header__main_dashboard_title}>Player Dashboard: {userScreenName}!</p>;
}
