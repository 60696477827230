import React, { useEffect } from 'react';
import styles from './RecoveryEmail.module.sass';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { recoveryEmail } from '../../../../api/api';
import Cookies from 'universal-cookie';

function RecoveryEmailError() {
  const history = useHistory();
  const cookies = new Cookies();
  const { tokenFromUrl } = useParams();

  useEffect(() => {
    recoveryEmail(tokenFromUrl);
  }, [tokenFromUrl]);

  const cleanAndRedirect = () => {
    cookies.set('finaleToken', ``, { path: '/' });
    history.push('/auth/login');
  };

  return (
    <main className={styles.main}>
      <form>
        <h2>Error of your Email changing</h2>
        <p>Something went wrong with Email changing.</p>
        <button onClick={cleanAndRedirect}>Ok</button>
      </form>
    </main>
  );
}

export default withRouter(RecoveryEmailError);
