import React, { useEffect, useState } from 'react';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './SendInvitePopup.module.sass';
import { ClosePopupButton } from '../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../../commonComponents/BorderedButton/BorderedButton';
import { InviteStatus } from '../../../../constants/teams';
import { inviteMembers } from '../../../../api/teams-api';
import { showDefaultErrorMessage, showErrorMessage } from '../../../../utils/throwError.js';
import { mailRegExp } from '../../../../constants/regExp.js';
import { useSelector } from 'react-redux';

/** This component needs function handleClosePopupButtonClick for closing popup,
 * props.children (it can be img tag)
 * and props.popupText */

export function SendInvitePopup({ setInviteStatus, popupTitle, isUpdated, setIsUpdated }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const currentTeamId = useSelector(state => state.teamReducer.currentTeamId);

  useEffect(() => {
    if (isSubmitting) {
      const onlyUniqueEmails = Array.from(new Set(emailAddresses));
      inviteMembers({ teamId: currentTeamId, emails: onlyUniqueEmails }).then((response) => {
        if (response.ok) {
          setInviteStatus(InviteStatus.INVITE_SUCCESS);
          setIsUpdated(!isUpdated);
        } else {
          showDefaultErrorMessage();
        }
      })
    }
  }, [isSubmitting]);

  const handleClosePopupButtonClick = () => {
    setInviteStatus(InviteStatus.HIDE_INVITE);
  };

  const handleTextAreaChange = (event) => {
    setEmailAddresses(event.target.value.replace(/\s/g, '').split(','));
  };

  const handlePopupSubmit = () => {
    if (emailAddresses.every(adr => adr.match(mailRegExp))) {
      setIsSubmitting(true);
    } else {
      showErrorMessage(
        {
          content:
            'You can only send invitations to valid email addresses separated by comma! Please check the correctness of the entered data',
          duration: 8,
        });
    }
  };

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 className={styles.invite_popup__title}>{popupTitle}</h3>
        <div className={styles.container}>
          <label className={styles.invite_popup__label}>
            Email Addresses
            <textarea
              className={styles.invite_popup__textarea}
              onChange={handleTextAreaChange}
            ></textarea>
          </label>
          <p className={styles.invite_popup__description}>Separate multiple emails with a comma</p>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopupButtonClick}
              width={'20rem'}
            />
            <RedButton
              handleButtonClick={handlePopupSubmit}
              width={'20rem'}
              title={'Send'} />
          </div>
        </div>
      </section>
    </>
  );
}
