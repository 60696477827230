/** This is constant values of the project */
import FirstAvatarMale from '../asset/dashboard/avatars/1_100.webp';
import SecondAvatarMale from '../asset/dashboard/avatars/2_100.webp';
import ThirdAvatarMale from '../asset/dashboard/avatars/3_100.webp';
import FourthAvatarMale from '../asset/dashboard/avatars/4_100.webp';
import FifthAvatarMale from '../asset/dashboard/avatars/5_100.webp';
import SixthAvatarMale from '../asset/dashboard/avatars/6_100.webp';
import SeventhAvatarMale from '../asset/dashboard/avatars/7_100.webp';
import EighthAvatarMale from '../asset/dashboard/avatars/8_100.webp';
import NinthAvatarMale from '../asset/dashboard/avatars/9_100.webp';

import FirstAvatarFemale from '../asset/dashboard/avatars/10_100.webp';
import SecondAvatarFemale from '../asset/dashboard/avatars/11_100.webp';
import ThirdAvatarFemale from '../asset/dashboard/avatars/12_100.webp';
import FourthAvatarFemale from '../asset/dashboard/avatars/13_100.webp';
import FifthAvatarFemale from '../asset/dashboard/avatars/14_100.webp';
import SixthAvatarFemale from '../asset/dashboard/avatars/15_100.webp';
import SeventhAvatarFemale from '../asset/dashboard/avatars/16_100.webp';
import EighthAvatarFemale from '../asset/dashboard/avatars/17_100.webp';

export const CHILD_TOKEN = 'finaleChildToken';
export const PARENT_TOKEN = 'finaleToken';
export const VIMEO_CHILD_CATEGORIES = 'vimeoCategoriesChild';

export const SubfolderPageUrl = {
  parentDashboard: '/dashboard/',
  dashboard: '/dashboardChild/',
  allTrainingAreas: '/dashboardChild/allTrainingAreas',
  allTrainingAreasSubfolder: '/dashboardChild/allTrainingAreas/subfolder_',
  playerJourneysSubfolder: '/dashboardChild/playerJourneys/subfolder_',
  playerJourneys: '/dashboardChild/playerJourneys',
  freeSection: '/dashboardChild/freeSection',
  freeSectionSubfolders: '/dashboardChild/freeSection/subfolder_',
  freestyleChallenge: '/dashboardChild/freestyle&challenge',
  getStarted: '/dashboardChild/getStarted',
  quickTurns: '/dashboardChild/quickTurns',
  dribbling1v1Shielding: '/dashboardChild/dribbling1v1Shielding',
  dribbleMaster: '/dashboardChild/dribbleMaster',
  dribblingGames: '/dashboardChild/allTrainingAreas/dribblingGames',
  videoSeries: '/dashboardChild/videoSeries',
  videoCategories: '/dashboardChild/videoCategories',
  finishing1v1: '/dashboardChild/finishingAnd1v1',
  moves: '/dashboardChild/1v1Moves',
  clinicalFinishing: '/dashboardChild/clinicalFinishing',
  warmUp: '/dashboardChild/warmUpAndJuggling',
  ballMastery: '/dashboardChild/ballMastery',
  beginner: '/dashboardChild/beginnerJugglingMaster',
  figure8: '/dashboardChild/figure8Dribbling',
  lineCone: '/dashboardChild/LineConeDribbling',
  touch1000: '/dashboardChild/1000Touch',
  touch1000Part1: '/dashboardChild/1000TouchPart1BallMastery',
  touch1000Part2: '/dashboardChild/1000TouchPart2BallMastery',
  battle1v1Finishing: '/dashboardChild/battle1v1Finishing',
  moves1V1Pro: '/dashboardChild/moves1V1Pro',
  firstTouchPro: '/dashboardChild/firstTouchPro',
  twoConePro: '/dashboardChild/twoConePro',
  tightSpaceDribbling: '/dashboardChild/tightSpaceDribbling',
  lineConeAndConeSlalomDribbling: '/dashboardChild/lineConeAndConeSlalomDribbling',
  shapePattern: '/dashboardChild/shapePattern',
  boxDribbling: '/dashboardChild/boxDribbling',
  speedDribblingTurnsFitness: '/dashboardChild/speedDribblingTurnsFitness',
  obstacleLaneLateralDribbling: '/dashboardChild/obstacleLaneLateralDribbling',
  coreJugglingBeginnerToAdvanced: '/dashboardChild/coreJugglingBeginnerToAdvanced',
  sizeOneTennisBallJuggling: '/dashboardChild/sizeOneTennisBallJuggling',
  partnerTossAerialWallJuggling: '/dashboardChild/partnerTossAerialWallJuggling',
  bodyAerialDribblingAerialFinishing: '/dashboardChild/bodyAerialDribblingAerialFinishing',
  partnerDrills: '/dashboardChild/partnerDrills',
  advancedJuggleMaster: '/dashboardChild/advancedJuggleMaster',
  middleJuggleMaster: '/dashboardChild/middleJuggleMaster',
  lateralMovement: '/dashboardChild/lateralMovement',
  lateralDynamicOther: '/dashboardChild/lateralDynamicOther',
  coneAndLateral: '/dashboardChild/coneAndLateral',
  longDistanceAndOther: '/dashboardChild/longDistanceAndOther',
  rebounderAndWall: '/dashboardChild/rebounderAndWall',
  corePassing: '/dashboardChild/corePassing',
  changeDirection: '/dashboardChild/changeDirection',
  fastSpeedDribbleMaster: '/dashboardChild/fastSpeedDribbleMaster',
  partnerDrillsPro: '/dashboardChild/partnerDrillsPro',
  passingReceivingData: '/dashboardChild/passingReceivingData',
  directionalControlRapidFire: '/dashboardChild/directionalControlRapidFire',
  dribblingFinishing: '/dashboardChild/dribblingFinishing',
  dribblePassShoot: '/dashboardChild/dribblePassShoot',
  aerialControlOther: '/dashboardChild/aerialControlOther',
  freeKicksPenalties: '/dashboardChild/freeKicksPenalties',
  oneTwoFourCone: '/dashboardChild/oneTwoFourCone',
  quickSessions: '/dashboardChild/quickSessions',
  getStartedAnd30DaysPlan: '/dashboardChild/getStartedAnd30DaysPlan',
  otherProgramsBallMasetery: '/dashboardChild/otherProgramsBallMasetery',
  otherDribblingPrograms: '/dashboardChild/otherDribblingPrograms',
  jugglingAndArealPrograms: '/dashboardChild/jugglingAndArealPrograms',
  otherJugglingAndArealPrograms: '/dashboardChild/otherJugglingAndArealPrograms',
  otherTightDribblingPrograms: '/dashboard/otherTightDribblingPrograms',
  otherFigure8Programs: '/dashboard/otherFigure8Programs',
  beginnersKickStart: '/dashboardChild/beginnersKickStart',
  '30DayPlan': '/dashboardChild/30DayPlan',
  teamHomework: '/dashboardChild/team-homework',
  allPrograms: '/dashboardChild/all-programs'
};

export const SectionName = {
  FREE_SECTION: 'Free Section',
  PRO: 'Pro',
  SUMMARY_ANYTIME_SKILLS: 'Summary Anytime Skills',
  ANYTIME_SKILLS: 'Anytime Skills',
  ANYTIME_CHALLENGES: 'Anytime Challenges',
  ANYTIME: 'Anytime',
  DAY_PLAYER: '30 Day Player',
  SKILLS: 'Skills',
  FITNESS_AND_SOCCER: 'Fitness & Soccer',
  SOCCER_GAMES: 'Soccer games',
  SOCCER_CHASING: 'Soccer Chasing',
  TRAIN_LIKE_PRO: 'Train Like a',
  GAMES: 'games',
  FAST_SPEED: 'Fast Speed',
  TWO_FOOTED: 'Two-Footed',
  INTERMEDIATE_JUGGLE_MASTER: 'Intermediate Juggle Master',
  MIDDLE_JUGGLE_MASTER: 'Middle Juggle Master',
  BEGINNER: 'Beginner',
  TEAMS: 'My Teams',
};

export const TrainingGoalStatus = {
  TRUE: true,
  FALSE: false,
  CREATING: 'creating',
};

export const HELP_CENTER_URL = '/dashboardChild/videoSeries/Get Started 01 Quick Tips';

export const Avatars = [
  FirstAvatarMale,
  SecondAvatarMale,
  ThirdAvatarMale,
  FourthAvatarMale,
  FifthAvatarMale,
  SixthAvatarMale,
  SeventhAvatarMale,
  EighthAvatarMale,
  NinthAvatarMale,

  FirstAvatarFemale,
  SecondAvatarFemale,
  ThirdAvatarFemale,
  FourthAvatarFemale,
  FifthAvatarFemale,
  SixthAvatarFemale,
  SeventhAvatarFemale,
  EighthAvatarFemale,
];

export const Role = {
  CHILD: 'child',
  PARENT: 'parent',
};

export const Price = {
  REQUEST_DEMO: '5',
  FULL_PRICE: '49.98',
  ADDITIONAL_PLAYER_PRICE: '14.98',
};

export const GOAL_MAX_VIDEO_COUNT = 1000;
export const MAX_DURATION_GOAL = 12;

export const birthArray = [
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
  '1999',
  '1998',
  '1997',
  '1996',
  '1995',
  '1994',
  '1993',
  '1992',
  '1991',
  '1990',
];

export const ChildStatus = {
  FREE: 'free',
  NON_SUBSCRIPTION: 'nonSubscription',
  ACTIVE: 'active',
};

export const MOBILE_DIMENSION = 576;
export const MIN_SEARCH_REQUEST_LENGTH = 3;

export const ERROR_MESSAGE = 'Something went wrong. Please try again later';
