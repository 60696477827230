import React from 'react';
import styles from './trainingGoalMobileHeader.module.sass';
import { ComponentType } from '../TrainingGoal';

function TrainingGoalMobileHeader(props) {

    return (
        <div className={styles.mobile_container}>
            <div className={styles.buttons}>
                <button
                    type="button"
                    onClick={props.handleNewPlanButtonClick}
                    className={styles.buttons__item}
                >
                    <span className={styles.button__title}>New Plan</span>
                </button>
                <button
                    type="button"
                    onClick={props.handleVideoAdjustButtonClick}
                    className={styles.buttons__item}
                >
                    <span className={styles.button__title}>Video Adjustment</span>
                </button>
                <button
                    type="button"
                    onClick={props.handleAwardButtonClick}
                    className={styles.buttons__item}>
                    <span className={styles.button__title}>{props.componentType === ComponentType.TRAINING_PLAN ? 'Awards' : 'Training Plan'}</span>
                </button>
            </div>
        </div>);
}

export default TrainingGoalMobileHeader;