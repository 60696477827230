import React, { useState, useEffect } from 'react';
import styles from './VideoCategories.module.sass';
import stylesPagination from '../GridCards/gridCards.module.sass';
import VideoCart from './VideoCart';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PaginationLogic from '../VideoSeries/Pagination';
import { SectionName, SubfolderPageUrl, CHILD_TOKEN } from '../../../constants/const';
import { videoDataImg } from '../../../constants/videoDataImage';
import { listOfCategoriesVimeo } from '../../../api/api';
import { useHistory } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Loading } from '../../commonComponents/Loading/Loading';
import { isInternetExplorer } from '../../../utils/utils';

const checkTitle = (title) => {
  switch (title) {
    case SectionName.SKILLS:
      return SectionName.FITNESS_AND_SOCCER;
    case SectionName.ANYTIME_SKILLS:
      return SectionName.ANYTIME;
    case SectionName.DAY_PLAYER:
      return '';
    case 'Soccer Chasing':
      return '1v1 & Games';
    case SectionName.FAST_SPEED:
      return 'Dribble';
    case SectionName.TWO_FOOTED:
      return SectionName.BEGINNER;
    case SectionName.MIDDLE_JUGGLE_MASTER:
      return SectionName.INTERMEDIATE_JUGGLE_MASTER;
    case '(Start) Juggle Challenge':
      return '30 Day Juggling Challenge';
    case '(Start) Wall Pass Pro':
      return '30 Day Pass Challenge';
    default:
      return title;
  }
};

const VideoCategories = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [upLoadedData, setUpLoadedData] = useState(false);
  const history = useHistory();
  // console.log(props.vimeoFolderData);
  const nameOfCategory = props.match.params.videoCategory;
  const position = nameOfCategory.indexOf('subtitle=');
  let title = nameOfCategory.slice(0, position);
  let newTitle = '';
  let newSubtitle = '';
  const subtitle = nameOfCategory.slice(position + 9);
  useScrollToTop();
  useEffect(() => {
    let isComponentUnmounted = false;
    if (props.vimeoFolderData.length) {
      try {
        listOfCategoriesVimeo(CHILD_TOKEN, 'vimeoCategoriesChild').then((response) => {
          if (!isComponentUnmounted) {
            requestVideoCategoriesHandler(response);
          }
        });
      } catch (e) {
        history.push(`${SubfolderPageUrl.dashboard}`);
      }
    } else {
      setUpLoadedData(true);
    }
    return () => (isComponentUnmounted = true);
  }, [nameOfCategory]);

  const requestVideoCategoriesHandler = (response) => {
    response.json().then((result) => {
      props.setChildDashVimeoInfo(
        result.data.map((a) => {
          return { uri: a.uri, name: a.name };
        }),
      );
      setUpLoadedData(true);
    });
  };
  // фильтруем все полученные данные и сохраняем в  videoFolders и к этим данным привязываем пагинацию
  const videoFolders = props.vimeoFolderData.filter((item) => {
    // так как часть заголовков Anytime Skills содержит другие тайтлы добавляем проверку для уточнения результатов фильтрации
    if (item.name.startsWith(SectionName.ANYTIME_SKILLS) && title !== SectionName.ANYTIME_SKILLS) {
      return false;
    }

    // workaround for Juggling Challenge & Wall Pass Challenge categories
    if (title === 'Juggling Challenge') {
      title = '(Start) Juggle Challenge';
    } else if (title === 'Wall Pass Challenge') {
      title = '(Start) Wall Pass Pro';
    }
    return item.name.includes(title);
  });
  const allVideos = videoFolders.length;
  const pages = Math.ceil(allVideos / 6);

  const changePage = (direction) => {
    if (direction === 'prev' && 1 <= currentPage - 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && pages >= currentPage + 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      {upLoadedData ? (
        <section className={styles.grid_cards}>
          <div className={styles.container}>
            <p className={styles.container_title}>
              Welcome to Your Member Dashboard,
              {' ' + props.playerData.screenname}!
            </p>
            <div className={styles.container_names}>
              <div className={styles.first_line}></div>
              <div>
                <p className={styles.container_subtitle}>{`${checkTitle(
                  newTitle ? newTitle : title,
                )} ${subtitle}`}</p>
              </div>
              <div className={styles.second_line}></div>
            </div>
          </div>

          <div
            className={isInternetExplorer() ? styles.cardContainerIExplorer : styles.cardContainer}
          >
            {videoFolders.map((item, idx) => {
              const checkCategories = Boolean(idx >= currentPage * 6 - 6 && idx < currentPage * 6);
              if (item.name.includes('Fast Speed')) {
                newTitle = 'Dribble';
                newSubtitle = '';
              }

              if (checkCategories) {
                return (
                  <VideoCart
                    item={item}
                    uri={item.uri}
                    key={item.uri}
                    urlImage={videoDataImg[title][idx]}
                    name={item.name}
                    title={newTitle ? newTitle : title}
                    subtitle={newSubtitle ? newSubtitle : subtitle}
                  />
                );
              }
            })}
          </div>
          {(pages > 0 || videoFolders?.length !== 0) && (
            <div className={stylesPagination.grid_cards__pagination}>
              <PaginationLogic
                numOfPages={videoFolders}
                request={(e) => setCurrentPage(e)}
                changePage={changePage}
                pages={pages}
              />
            </div>
          )}
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default compose(withRouter)(VideoCategories);
