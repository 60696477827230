import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// ===================Child redirect=============================
const mapStateToPropsForRedirect = (state) => ({
  role: state.authReducer.role,
});

export const withRoleRedirectComp = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (this.props.role === 'child') {
        return <Redirect to="/dashboardChild" />;
      }
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToPropsForRedirect)(RedirectComponent);
};

export const parentRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (this.props.role === 'parent') {
        return <Redirect to="/dashboard" />;
      }
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToPropsForRedirect)(RedirectComponent);
};

// ===================4 Child redirect=============================
const mapStateToPropsForChildRedirect = (state) => ({
  childCount: state.userDataReducer.userData.childInfo.childrenCount,
});

export const fourChildRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      //if (this.props.childCount >= 4) return <Redirect to='/dashboard'/>;
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToPropsForChildRedirect)(RedirectComponent);
};

// ===================have no data redirect=============================
const mapStateToPropsHaveNoDataRedirect = (state) => ({
  userName: state.appReducer.playerData.username,
});

export const haveNoDataRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!this.props.userName) return <Redirect to="/dashboard/createPlayer" />;
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToPropsHaveNoDataRedirect)(RedirectComponent);
};

// ===================have no data redirect=============================
const mapStateToPropsNoVideo = (state) => ({
  vimeoVideoData: state.childDashVimeoInfoReducer.vimeoVideoData,
});

export const haveNoVideoRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!this.props.vimeoVideoData.total) return <Redirect to="/dashboardChild" />;
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToPropsNoVideo)(RedirectComponent);
};

const mapStateHaveNoFoldersDataRedirect = (state) => ({
  vimeoFolderData: state.childDashVimeoInfoReducer.vimeoFolderData,
});

export const haveNoFoldersDataRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!this.props.vimeoFolderData.length) return <Redirect to="/dashboardChild" />;
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateHaveNoFoldersDataRedirect)(RedirectComponent);
};

// ===================for logOut redirect=============================
const mapStateNoLogin = (state) => ({
  login: state.authReducer.isLogin,
});

export const haveNoLoginRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!this.props.login) return <Redirect to="/" />;
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateNoLogin)(RedirectComponent);
};
