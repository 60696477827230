import React from 'react';
import styles from './ContactUs.module.sass';
import ContactForm from './ContactForm/ContactForm';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

const ContactUs = () => {
  useScrollToTop();

  return (
    <section className={styles.header}>
      <div className={styles.main_container}>
        <img className={styles.img_back} src="/img/contactUs/contactusback.webp" alt="contact Us" />
        <div className={styles.main_title}>
          <div className={styles.main_container__block}>
            <h2>We are headquartered in Cary, NC</h2>
            <div className={styles.header_block}>
              Anytime Soccer Training
              <br />
              1111 Brookhill Way, Suite 100 <br />
              Cary, NC 27519
            </div>
            <div className={styles.info_block}>
              <p>
                <span className={styles.span_block_phone}>Phone:</span>
                <a href="tel:803-431-1082">803-431-1082</a>
              </p>
              <p>
                <span className={styles.span_block_email}>Email:</span>
                <a href="mailto:info@anytime-soccer.com" className={styles.span_block_email__a}>
                  info@anytime-soccer.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </section>
  );
};

export default ContactUs;
