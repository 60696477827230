import React, { useEffect, useState } from 'react';
import styles from './Reports.module.sass';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ChildGreeting } from '../ChildGreeting/ChildGreeting';
import { ReportRow } from './ReportRow/ReportRow';
import path from '../../../config/routes.json';
import { getAllTimeViewedVideo, getReportsData, getChildAwardList } from '../../../api/api.js';
import { message } from 'antd';
import { Loading } from '../../commonComponents/Loading/Loading';

const defaultYear = String(new Date().getFullYear());
const userReportData = {
  annualCount: 0,
  allTimeCount: 0,
  currentYear: defaultYear,
  selectorYears: [],
  months: [],
};

export function Reports(props) {
  const [isLoadedData, setIsLoadedData] = useState(false);
  const history = useHistory();
  const params = useParams();

  const onError = (errorMessage) => {
    history.push(path.dashboardChild.main);
    message.error(errorMessage);
  };

  useEffect(() => {
    if (!isLoadedData) {
      getAllTimeViewedVideo(params.userId).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            userReportData.allTimeCount = data;
          });
        } else {
          message.error({
            content:
              'Sorry, we can`t get count of all time viewed videos for you. Please reload page in some time.',
            duration: 6,
          });
        }
      });
      getReportsData(userReportData.currentYear, params.userId).then((response) => {
        responseHandler(response);
      });
    }
  }, [userReportData.currentYear, params.userId]);

  useEffect(() => {
    if (!props.userAwards.isUserAwardsLoaded) {
      getChildAwardList(undefined, params.userId).then((response) => {
        requestUserAwardsHandler(response);
      });
    }
  }, []);

  const responseHandler = (response) => {
    if (response.ok) {
      response.json().then((data) => {
        if (Object.keys(data).length) {
          userReportData.annualCount = data.annual;
          userReportData.selectorYears = data.allYear;
          userReportData.months = Object.values(data.months);
          setIsLoadedData(true);
        } else {
          onError("You haven't watched any video.");
        }
      });
    } else {
      onError('Sorry. Something went wrong. Please try again later.');
    }
  };

  const requestUserAwardsHandler = (response) => {
    if (response.ok) {
      response.json().then((result) => {
        props.setUserAwards(result);
      });
    } else {
      message.error('Sorry. Something went wrong. Please reload the page later.');
    }
  };

  const handleCertificatesClick = (event) => {
    event.preventDefault();
    if (!props.userAwards.certificate) {
      message.error('Unfortunately you don`t have any awards.');
    } else {
      history.push(path.rewardCenter);
    }
  };

  const handleSelectorChange = (event) => {
    /** check what year have loaded if user would like to have different reload it */
    if (event.currentTarget.value !== userReportData.currentYear) {
      userReportData.currentYear = event.currentTarget.value;
      setIsLoadedData(false);
    }
  };

  return isLoadedData ? (
    <section className={styles.reports}>
      <div className={styles.header_wrapper}>
        <ChildGreeting userScreenName={props.playerData.screenname} />
        <ul className={styles.header_description}>
          <li className={styles.header_description__item}>
            <p className={styles.text}>Videos Completed</p>
            {!!userReportData.selectorYears.length && (
              <select
                onChange={handleSelectorChange}
                value={userReportData.currentYear}
                className={styles.select}
              >
                {userReportData.selectorYears.map((element) => {
                  return (
                    <option className={styles.select__item} value={element} key={element}>
                      {element}
                    </option>
                  );
                })}
              </select>
            )}
          </li>
          <li className={styles.header_description__item}>
            Annual: <span className={styles.important}> {userReportData.annualCount}</span>
          </li>
          <li className={styles.header_description__item}>
            All Time: <span className={styles.important}> {userReportData.allTimeCount}</span>
          </li>
          <li className={styles.header_description__item}>
            <Link
              className={styles.header_description__item_link}
              onClick={handleCertificatesClick}
              to={path.rewardCenter}
            >
              Certificates
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.wrapper}>
        <article className={styles.reports_statistics}>
          <ul className={styles.reports_statistics__header}>
            <li className={styles.reports_statistics__header__item}>Month</li>
            <li className={styles.reports_statistics__header__item}>Videos Completed</li>
            <li className={styles.reports_statistics__header__item}>Time</li>
          </ul>
          <ReportRow months={userReportData.months} />
        </article>
        <img
          src="/img/Ribbon.webp"
          className={styles.decorative_img}
          aria-hidden="true"
          alt="ribbon"
        />
      </div>
    </section>
  ) : (
    <Loading />
  );
}
