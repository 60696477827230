import { getProfileData } from '../../../api/teams-api';
import { getProfileDataAction } from '../../../Redux/profileDataReducer';

export const fetchProfileData = data => {
  return dispatch => {
    try {
      getProfileData(data).then(response => {
        response.json().then(stats => {
          dispatch(getProfileDataAction(stats));
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};
