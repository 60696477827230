import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './CreatePlayerFree.module.sass';
import { useHistory } from 'react-router-dom';
import { checkUniqueValues, profileInfoRequest, submitPlayerData } from '../../../api/api';
import {
  fourChildRedirect,
  withRoleRedirectComp,
  haveNoLoginRedirect,
} from '../../../hoc/redirectComp';
import { compose } from 'redux';
import { Input } from 'reactstrap';
import showPwdImg from './show-password.svg';
import hidePwdImg from './hide-password.svg';
import { message } from 'antd';
import { AvatarComponent } from '../AvatarComponent/AvatarComponent';
import { getFormData } from '../../../utils/utils.js';

const CreatePlayer = (props) => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [userNameErr, setUserNameErr] = useState(false);
  /** Next state manages and contains picture or image data both we hang it over to avatar component
   * It is named this way for compatability of avatar component with this component and
   *  editPlayerInfo component */
  const [requestData, setRequestData] = useState({ picture: '1' });
  /** This state is responsible for only built-in app avatar pictures
   * picture one is default value */
  const [avatarNumber, setAvatarNumber] = useState(1);

  const history = useHistory();
  const currentChild = `child` + (props.childCount + 1);

  const getYears = (min, max) => {
    const years = [];
    for (let i = max; i >= min; i--) years.push(i);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  const formik = useFormik({
    initialValues: {
      screenName: props.playerData.screenName,
      birthyear: props.playerData.birthyear || new Date().getFullYear(),
      childEmail: props.playerData.childEmail,
      username: props.playerData.username,
      password: props.playerData.password,
    },
    validationSchema: Yup.object({
      screenName: Yup.string().trim().required('Required'),
      birthyear: Yup.string().required('Required'),
      childEmail: Yup.string().trim().email('Required').required('Required'),
      username: Yup.string().trim().required('Required'),
      password: Yup.string().trim().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        checkUniqueValues({ [currentChild]: values.username, addPlayer: true }).then((response) =>
          requestHandler(response),
        );
        props.setAmountPlayer(props.childCount + 1);
      } catch (e) {
        console.log(e);
      }
    },
  });

  // 4 step
  const serverPostRequest = () => {
    const formData = getFormData({ ...formik.values, ...requestData });
    try {
      submitPlayerData(formData).then((response) => {
        addPlayerRequestHandler(response);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // 5 step
  const addPlayerRequestHandler = (response) => {
    response.json().then(() => {
      if (response.ok) {
        try {
          profileInfoRequest().then((response) => requestHandler2(response));
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  // 6 step
  const requestHandler2 = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        props.setUserData(result);
        props.setDeletePlayer();
        history.replace('/dashboard');
      }
    });
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (result.result) {
        props.setPlayerData({ ...formik.values, ...requestData });
        serverPostRequest();
        setUserNameErr(false);
      } else {
        setUserNameErr(true);
      }
    });
  };

  useEffect(() => {
    if (userNameErr) {
      message.error('This username is already taken.');
    }
  }, [userNameErr]);

  return (
    <div className={styles.container}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <h3>Add Player/Profile</h3>
        <p>Fields marked with * are mandatory</p>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_rightP}>
                <label htmlFor="screenName">Screen Name *</label>
                <input
                  className={
                    formik.touched.screenName && formik.errors.screenName
                      ? styles[formik.errors.screenName]
                      : ''
                  }
                  name="screenName"
                  id="screenName"
                  type="text"
                  placeholder="John"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.screenName.replace(/[^A-z0-9\s]/g, '')} //
                />
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_leftP}>
                <label htmlFor="birthYear">Birth Year *</label>
                <Input
                  name="birthyear"
                  className={styles.birthForm}
                  type="select"
                  bsSize="lg"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birthyear}
                >
                  {getYears(1950, new Date().getFullYear())}
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <label htmlFor="childEmail">Contact Email *</label>
          <input
            className={
              formik.touched.childEmail && formik.errors.childEmail
                ? styles[formik.errors.childEmail]
                : ''
            }
            name="childEmail"
            id="childEmail"
            type="email"
            placeholder="example@gmail.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.childEmail.replace(/[^A-z@._0-9-]/g, '')}
          />
        </div>
        <div className={styles.wrap}>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_rightP}>
                <div className={styles.flex}>
                  <label htmlFor="username">Username *</label>
                </div>
                <input
                  className={
                    formik.touched.username && formik.errors.username
                      ? styles[formik.errors.username]
                      : ''
                  }
                  name="username"
                  id="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username.replace(/[\s]/g, '')}
                />
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.col__half}>
              <div className={styles.col__half_leftP}>
                <div className={styles.flex}>
                  <label htmlFor="password">Password *</label>
                </div>
                <div className={styles.customInput}>
                  <input
                    className={
                      formik.touched.password && formik.errors.password
                        ? styles[formik.errors.password]
                        : ''
                    }
                    name="password"
                    id="password"
                    onBlur={formik.handleBlur}
                    type={isRevealPwd ? 'text' : 'password'}
                    value={formik.values.password.replace(/[\s]/g, '')}
                    onChange={formik.handleChange}
                  />
                  <img
                    title={isRevealPwd ? 'Hide password' : 'Show password'}
                    src={isRevealPwd ? showPwdImg : hidePwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    aria-hidden
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AvatarComponent
          requestData={requestData}
          setRequestData={setRequestData}
          avatarNumber={avatarNumber}
          setAvatarNumber={setAvatarNumber}
        />
        <button type="submit" className={styles.submitButton}>
          Add Player/Profile
        </button>
      </form>
    </div>
  );
};

export default compose(fourChildRedirect, withRoleRedirectComp, haveNoLoginRedirect)(CreatePlayer);
