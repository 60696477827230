import React, { useEffect, useState } from 'react';
import styles from './AccountManage.module.sass';
import { Link, useHistory } from 'react-router-dom';
import CancelSubscription from '../CancelSubscription/CancelSubscription';
import { billingRequest } from '../../../api/api';
import { connect } from 'react-redux';
import { setUserData } from '../../../Redux/userDataReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { editUser } from '../../../api/api';
import { haveNoLoginRedirect } from '../../../hoc/redirectComp';
import { compose } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import SevenDaysMoneyBack from '../CancelSubscription/SevenDaysMoneyBack';
import { ModalWindowChangePass } from './modalWindow/ModalWindow';
import { message } from 'antd';
import path from '../../../config.json';
import mainRoute from '../../../mainRoute';

function AccountManage(props) {
  const [passToggle, setPassToggle] = useState(false);
  const [changeEmailToggle, setChangeEmailToggle] = useState(false);
  const [toggChangeEmailPopUpWindow, setToggChangeEmailPopUpWindow] = useState(0);
  const [toggDateEndsOrRenew, setToggDateEndsOrRenew] = useState(false);
  const [coupon, setCoupon] = useState('');

  const history = useHistory();
  const redirect = () => {
    setToggChangeEmailPopUpWindow(0);
    history.replace('/dashboard');
  };

  let finaleDate = '';

  if (props.playerData.status === 'Active' && props.userData.userInfo.nextChargeDate) {
    const date = props.userData.userInfo.nextChargeDate.slice(0, 19).replace('T', ' ');
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    finaleDate = `${month + '.' + day + '.' + year}`;
  }

  useEffect(() => {
    try {
      billingRequest().then((response) => {
        response.json().then((result) => {
          props.setBillingDetails(result.billing_details, result.card, result.stripeCus);
        });
      });
    } catch (e) {
      console.log(e);
    }
    // Check if all child status non equal 'active' change text at the right to "ends on mm/dd/yyyy"
    const childrenCount = props.userData.childInfo.childrenCount;
    for (let i = 1; i <= childrenCount; i++) {
      if (props.userData.childInfo[`child${i}`].status === 'active') {
        setToggDateEndsOrRenew(true);
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: props.playerData.email,
      firstname: props.playerData.firstname,
      lastname: props.playerData.lastname,
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().email('Invalid email address').required('Required'),
      firstname: Yup.string().trim().required('required'),
      lastname: Yup.string().trim().required('required'),
    }),
    onSubmit: (values) => {
      const result = {};
      for (const key in values) {
        if (key === 'password') {
          if (values[key].trim().length !== 0) {
            result[key] = values[key];
          }
        } else if (!(props.playerData[key] === values[key])) {
          result[key] = values[key];
        }
      }
      if (!(Object.keys(result).length === 0)) {
        try {
          editUser(result).then((response) => requestHandler(response));
        } catch (e) {
          console.log(e);
        }
      }
    },
  });

  const submitCoupon = async (e) => {
    e.preventDefault();
    if (coupon === '') {
      return message.error('Enter your Team Code!');
    }
    try {
      const res = await fetch(`${mainRoute.route + path.appendpromocode}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          promoName: coupon,
          userId: props.userData.userInfo.id,
        }),
      });
      const resStatus = await res;
      const data = await res.json();
      if (resStatus.status === 200) {
        message.success('Team Code entered successfully');
        setCoupon('');
      } else {
        message.error(data.message);
      }
    } catch (e) {
      message.error(e);
    }
  };

  const requestHandler = (response) => {
    response.json().then(() => {
      props.update();
      history.replace('../dashboard');
    });
  };

  const SubscriptionCard = () => (
    <div>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <h4>Subscription</h4>
          <p>
            Your subscription {toggDateEndsOrRenew ? 'will renew' : 'ends'} on {finaleDate}.
          </p>
        </div>
        <Link to={props.path.dashboard.changeBilling} onClick={billingRequest}>
          Update credit card information
        </Link>
        {props.playerData.status !== 'freeAccount' ? (
          <SevenDaysMoneyBack
            createdAt={props.createdAt}
            cancelType={'7day'}
            title={'7-day money-back guarantee'}
          />
        ) : null}
        <CancelSubscription
          createdAt={props.createdAt}
          cancelType={'cancelSubscription'}
          title={'Cancel your subscription'}
          childData={props.userData.childInfo}
          update={props.update}
        />
        <div className={styles.cardFoot}>
          <h5>Need help with your account?</h5>
          <p>
            Reach out to us through our&nbsp;
            <Link to={props.path.dashboard.support}>support form</Link>.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <main className={styles.account_manage}>
        <div className={styles.header}>
          <h2>Account Management</h2>
        </div>
        <section className={styles.account_manage__section}>
          <form onSubmit={formik.handleSubmit}>
            <h4>Edit Parent Information</h4>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="email" className={styles.emailText}>
                  Email *
                </label>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <button
                      type="submit"
                      className={styles.changeEmail}
                      onClick={() => setToggChangeEmailPopUpWindow(1)}
                    >
                      Change Email
                    </button>
                    <ModalWindowChangePass
                      formik={formik}
                      changeEmailToggle={changeEmailToggle}
                      setChangeEmailToggle={setChangeEmailToggle}
                      toggChangeEmailPopUpWindow={toggChangeEmailPopUpWindow}
                      setToggChangeEmailPopUpWindow={setToggChangeEmailPopUpWindow}
                      update={props.update}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="firstname">First Name *</label>
                <input
                  className={
                    formik.touched.firstname && formik.errors.firstname
                      ? styles[formik.errors.firstname]
                      : ''
                  }
                  type="text"
                  name="firstname"
                  id="firstname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname.replace(/[^A-z]/g, '')}
                />
              </div>
              <div className={styles.col}>
                <label htmlFor="lastname">Last Name *</label>
                <input
                  className={
                    formik.touched.lastname && formik.errors.lastname
                      ? styles[formik.errors.lastname]
                      : ''
                  }
                  type="text"
                  name="lastname"
                  id="lastname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname.replace(/[^A-z]/g, '')}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <label>Password</label>
                {!passToggle ? (
                  <button
                    type="submit"
                    className={styles.changePass}
                    onClick={() => setPassToggle(true)}
                  >
                    Change Password
                  </button>
                ) : (
                  <input
                    className={styles.changePass}
                    type="password"
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password.replace(/[[А-я\s]/g, '')}
                  />
                )}
              </div>
            </div>
            <label htmlFor="discountCoupon" className={styles.cardNumTextDiscCoupon}>
              Team Code
            </label>
            <div className={styles.container_coupon}>
              <input
                className={formik.touched.discountCoupon}
                name="discountCoupon"
                id="discountCoupon"
                type="text"
                onChange={(e) => {
                  setCoupon(e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={coupon}
                style={{ width: '16.35rem' }}
              />
              <button
                className={styles.buttonDefault}
                id={styles.buttonApply}
                style={{ width: '17.4rem', margin: '0 1rem' }}
                onClick={submitCoupon}
              >
                Apply
              </button>
            </div>
            <div className={styles.row}>
              <button type="submit" className={styles.submit}>
                Submit
              </button>
              <button type="button" className={styles.bordered} onClick={redirect}>
                Cancel
              </button>
            </div>
          </form>
          <SubscriptionCard playerData={props.playerData.status} />
        </section>
      </main>
    </>
  );
}

const mapStateToProp = (state) => ({
  createdAt: state.userDataReducer.userData.userInfo.createdAt,
});

export default compose(
  connect(mapStateToProp, { setUserData }),
  haveNoLoginRedirect,
)(AccountManage);
