import React, { useState } from 'react';
import styles from './rewardCard.module.sass';
import RewardPreview from '../RewardPreview/RewardPreview';
import { getFormattedDate } from '.././../../utils/utils.js';

function RewardCard(props) {
  const {
    element: { targetDate, videoGoal },
  } = props;
  const [isRewardShown, setRewardShown] = useState(false);

  const handleArticleClick = () => {
    setRewardShown(true);
  };

  return (
    <>
      {isRewardShown && (
        <RewardPreview trainingPlan={props.element} setRewardShown={setRewardShown} />
      )}
      <article onClick={handleArticleClick} className={styles.reward_card}>
        <img
          className={styles.reward_card__image}
          src="/img/trainingPlan/award.webp"
          alt="Gold cup"
        />
        <div className={styles.reward_card__details}>
          <p className={styles.description}>
            Target
            <br />
            Date:
            <br />
            <span className={styles.main_text}>{getFormattedDate(targetDate)}</span>
          </p>
          <p className={styles.description}>
            Videos Completed:
            <br />
            <span className={styles.main_text}>{videoGoal}</span>
          </p>
        </div>
      </article>
    </>
  );
}

export default RewardCard;
