import { getAllMembers } from '../../../api/teams-api';
import { getAllMembersDataAction } from '../../../Redux/allMembersDataReducer';

export const fetchAllMembersData = data => {
  return dispatch => {
    try {
      getAllMembers(data).then(response => {
        response.json().then(stats => {
          dispatch(getAllMembersDataAction(stats));
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};
