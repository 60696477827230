import React, { useEffect, useState } from 'react';
import { useParams, Redirect, Link } from "react-router-dom";
import { getContestByReference } from '../../../api/api';
import styles from './Contest.module.sass'
import { Avatars } from '../../../constants/const';
import playlist from './playlist.png'
import timer from './timer.png'
import arrow from './arrow.png'
import teamAvatar from './team.png'
import { truncateText } from '../../DashboardChild/Teams/Contest/StatCard';
import ContestTable from '../../DashboardChild/Teams/Contest/Leaderboard'

const PublicContest = () => {
  const [contest, setContest] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    let isActive = true;

    if (isActive && id) {
      getContestByReference(id)
        .then(res => {
          setContest(res);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      isActive = false;
    };
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading contest: {error.message}</div>;
  }

  if (!contest?.contest) {
    return <Redirect to="/" />
  }

  const { team, players, totalTime, totalVideo } = contest

  const leaderBoard = players?.length > 0 ? players
    .sort((a, b) => Number(b.timeVideo) - Number(a.timeVideo))
    .slice(0, 10) : []

  const topPlayers = players?.length > 0 ? players
    .sort((a, b) => Number(b.timeVideo) - Number(a.timeVideo))
    .slice(0, 3) : []

  const teamReward = contest?.contest?.reward ?? 'Coach Surprise'
  const topReward = contest?.contest?.topReward ?? 'Coach Surprise'

  const goalCompleted = contest?.contest?.completed

  return (
    <div className={styles.contest}>
      <div className={styles.contest__header}>
        <div className={styles.contest__team}>
          <div className={styles.contest__team_logo}>
            <img style={{ width: '100%', height: '100%', objectFit: 'contain'}} src={team.imgUrl} />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <span className={styles.contest__team_name}>{team.name}</span>
            <span className={styles.contest__team_sub}>Anytime Soccer Training Homework Contest</span>
          </div>

          <div className={`${styles.contest__card} ${styles.contest__card_top}`}>
            <div className={styles.topPerformers}>
              <span className={styles.topPlayer}> Our Top Performers</span>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center' , gap: '4rem',
                flexWrap: 'wrap'
              }}>
                {topPlayers.map((player, i) => (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '.25rem'
                  }} key={i}>
                    <div className={styles.topPlayer_avatar}>
                      <img
                        src={player?.image || Avatars[parseInt(player.picture) - 1]}
                        alt='Avatar'
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',

                        }} />
                    </div>
                    <span className={styles.topPlayer_name}>{`${player.childFirstName} ${player?.childLastName ?? ''}`}</span>
                    <span className={styles.topPlayer_value} style={{
                      color: player.playerStatusGoal ? '#2cdebe' : '#e1343f',
                    }}>{`${((player.timeVideo / 60) / 60).toFixed(2)} hours`}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contest__body}>
        <div className={`${styles.contest__card} ${styles.contest__card_content}`}>
          <div className={styles.content}>
            {goalCompleted ? (
              <div className={styles.content_one}>
                <span className={styles.content_one_sub1}>Cheer On!!!</span>
                <span className={styles.content_one_sub2}>Our Team!</span>
                <span className={styles.content_one_image} />
              </div>
            ) : (
              <div className={styles.content_one}>
                <span className={styles.content_one_sub1}>Congratulations!!!!</span>
                <span className={styles.content_one_sub2}>We Did It!</span>
                <span className={styles.content_one_image} />
              </div>
            )}
            <div className={styles.content_two}>
              <div style={{
                display: 'flex',
                gap: '2rem'
              }}>
                 <span className={styles.content_two_image} />
                 <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                 }}>
                  <span className={styles.content_two_sub1}>Homework Practice Time</span>
                  <span className={styles.content_two_sub2}><img src={playlist} alt='Videos' /> {totalVideo} Videos</span>
                  <span className={styles.content_two_sub3}><img src={timer} alt='Hours' /> {((totalTime / 60) / 60).toFixed(2)} Hours</span>
                 </div>
                 
              </div>
              <div style={{
                display: 'flex',
                gap: '2rem',
                flexDirection: 'column',
                marginTop: '35px'
              }}>
                <span className={styles.content_two_sub4}>Top Prizes</span>
                <div className={styles.content_earned}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '.25rem'
                  }} >
                    <img
                      src={teamAvatar}
                      alt='Avatar'
                      style={{
                        height: '108px',
                        width: '139px',
                        objectFit: 'contain',
                        }}
                    />
                    <span className={styles.content_earned_name}>Team Reward</span>
                    <span className={styles.content_earned_value}>{truncateText(teamReward, 15)}</span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '.25rem'
                  }} >
                    <img
                      src={Avatars[0]}
                      alt='Avatar'
                      style={{
                        height: '108px',
                        width: '55px',
                        objectFit: 'contain',
                        }}
                    />
                    <span className={styles.content_earned_name}>Top Player Reward</span>
                    <span className={styles.content_earned_value}>{truncateText(topReward, 15)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.contest__card} ${styles.contest__card_content}`} style={{
          marginTop: '20px'
        }}>
          <div className={styles.content} style={{ flexDirection: 'column'}}>
            <div className={styles.content_header}> 
              <img src="/img/logo.webp" alt="logo" style={{
                width: '50px', height: '60px', flexShrink: 0
              }} />
              <span className={styles.content_header_text}>Top Ten Leaderboard</span>
            </div>
            <ContestTable playerData={leaderBoard} teamId={team.id} />
          </div>
        </div>
      </div>
      <div className={styles.contest__footer}>
        <img src="/img/logo.webp" alt="logo" className={styles.img} />
        <div style={{
          display: 'flex',
          alignItems: 'center', gap: '10px'
        }}>
          <div className={styles.stick} />
          <span className={styles.contest__footer_text}>Anytime Soccer Training</span>
          <div className={styles.stick} />
        </div>
        <Link to="/auth/registerFree" className={styles.btn}>Join for Free <img src={arrow} alt='Sign Up' /> </Link>
      </div>
    </div>
  );
};

export default PublicContest;
