const GET_DETAILS = './details/GET_DETAILS';

const initialState = {
  billing_details: {
    address: {
      city: '',
      country: '',
      line1: '',
      line2: null,
      postal_code: '',
      state: '',
    },
    email: '',
    name: '',
    phone: null,
  },
  card: {
    brand: '',
    checks: {
      address_line1_check: '',
      address_postal_code_check: '',
      cvc_check: '',
    },
    country: '',
    exp_month: null,
    exp_year: null,
    fingerprint: '',
    funding: '',
    generated_from: null,
    last4: '',
    three_d_secure_usage: {
      supported: null,
    },
    wallet: null,
  },
  stripeCus: '',
};

const billingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAILS:
      return {
        ...state,
        billing_details: { ...action.billing_details },
        card: { ...action.card },
        stripeCus: action.stripeCus,
      };
    default:
      return state;
  }
};

export const setBillingDetails = (billing_details, card, stripeCus) => ({
  type: GET_DETAILS,
  billing_details,
  card,
  stripeCus,
});

export default billingDetailsReducer;
