import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchToGridView, switchToListView } from '../../../Redux/listGridViewReducer';
import styles from './ListGridViewButtons.module.sass';
import list from './list.png';
import listGrey from './list_grey.png';
import grid from './grid.png';
import gridGrey from './grid_grey.png';

const ListGridViewButtons = () => {
  const listGridViewData = useSelector((state) => state.listGridViewReducer);
  const dispatch = useDispatch();

  return (
    <>
      <img
        onClick={() => {
          dispatch(switchToListView());
        }}
        className={styles.icon}
        src={listGridViewData === 'LIST_VIEW' ? listGrey : list}
        alt="list"
      />
      <img
        onClick={() => {
          dispatch(switchToGridView());
        }}
        className={styles.icon}
        src={listGridViewData === 'GRID_VIEW' ? gridGrey : grid}
        alt="grid"
      />
    </>
  );
};

export default ListGridViewButtons;
