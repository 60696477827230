import React, { useState, useEffect } from 'react';
import styles from './rewardPage.module.sass';
import RewardCertificate from '../RewardCertificate/RewardCertificate';
import { Link } from 'react-router-dom';
import path from '../../../config/routes.json';
import TrainingGoalPopup from '../../TrainingPlanPopup/TrainingPlanPopup';
import { getTrainingPlan } from '../../../api/api';

const RewardPage = ({ trainingPlan, setTrainingGoal, setTrainingPlan }) => {
  const [isShowNewPlanPopup, setShowNewPlanPopup] = useState(false);

  useEffect(() => {
    if (!trainingPlan.isTrainingPlanLoaded) {
      getTrainingPlan().then((response) => {
        requestTrainingPlanHandler(response);
      });
    }
  });

  const requestTrainingPlanHandler = (response) => {
    response
      .json()
      .then((result) => {
        setTrainingPlan(result);
        setTrainingGoal(true);
      })
      .catch((e) => {
        console.log(e?.message || e);
      });
  };

  const handleNewPlanButtonClick = () => {
    setShowNewPlanPopup(true);
  };
  return (
    <>
      {isShowNewPlanPopup ? (
        <TrainingGoalPopup
          setShowNewPlanPopup={setShowNewPlanPopup}
          setTrainingGoal={setTrainingGoal}
        />
      ) : null}
      <div className={styles.container}>
        <header className={styles.section}>
          <div className={styles.section__flexContainer}>
            <p className={styles.title}>Certificate of Completion</p>
          </div>
        </header>
        <main>
          <RewardCertificate trainingPlan={trainingPlan} />
          <div className={styles.buttons}>
            <Link to={path.trainingGoal} type="button" className={styles.buttons__cancel}>
              <span className={styles.button__title}>Cancel</span>
            </Link>
            <button
              type="button"
              onClick={handleNewPlanButtonClick}
              className={styles.buttons__new_plan}
            >
              <span className={styles.button__title}>Create New Plan</span>
            </button>
          </div>
        </main>
      </div>
    </>
  );
};

export default RewardPage;
