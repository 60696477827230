import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  useLocation,
  Switch,
  Redirect,
} from 'react-router-dom';
import path from './config/routes.json';
import './App.sass';
import store from './Redux/redux-store';
import Footer from './Components/Footer/Footer.jsx';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy/PrivacyPolicy';
import TermsNConditions from './Components/Pages/TermsNConditions/TermsNConditions';
import SignIn from './Components/Auth/SignIn/SignIn';
import SignUp from './Components/Auth/SignUp/SignUp';
import Forgot from './Components/Auth/Forgot/Forgot';
import Recovery from './Components/Auth/Recovery/Recovery';
import RecoveryEmail from './Components/Dashbord/AccountManage/RecoveryEmail/RecoveryEmail';
import RecoveryEmailError from './Components/Dashbord/AccountManage/RecoveryEmail/RecoveryEmailError';
import ThankYou from './Components/Auth/ThankYou/ThankYou';
import ChangeBillingDetails from './Components/Dashbord/ChangeBillingDetails/ChangeBillingDetails';
import DashboardChild from './Components/DashboardChild/dashboardChild';
import VideoSeries from './Components/DashboardChild/VideoSeries/VideoSeries';
import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import AdminDashboardTable from './Components/AdminDashboard/AdminDashboardTable';
import { setUserData } from './Redux/userDataReducer';
import { setAuth, setLogin, setNeedUpdate, setRole } from './Redux/authReducer';
import { Spinner } from 'reactstrap';
import s from './app.module.sass';
import { setDeletePlayer, setPlayerData } from './Redux/appReducer';
import { childInfoRequest, getAllVideoCategoryBookmarks, profileInfoRequest } from './api/api';
import HeaderEighth from './Components/DashboardChild/Header-eighth/Header-eighth';
import Header from './Components/Header/Header';
import ContactUs from './Components/Pages/ContactUs/ContactUs';
import {
  setUserDataStep1,
  setUserDataStep2,
  setUserDataStep3,
  delUserDataSteps,
} from './Redux/signUpDataReducer';
import { setOnlyChildData } from './Redux/playerDataReducer';
import { setBillingDetails } from './Redux/billingDetailsReducer';
import Cookies from 'universal-cookie';
import Employment from './Components/Pages/Employment/Employment';
import TeamDiscounts from './Components/Pages/TeamDiscounts/TeamDiscounts';
import JoinMailingList from './Components/Pages/JoinMailingList/JoinMailingList';
import NotFound from './Components/NotFound/NotFound';
import ParentDash from './Components/Dashbord/Parent/Parent';
import ConfirmPlayer from './Components/Dashbord/AddPlayers/ConfirmPlayer';
import ReactivationPlayer from './Components/Dashbord/Reactivation/ReactivationPlayer';
import CreatePlayer from './Components/Dashbord/AddPlayers/CreatePlayer';
import CreatePlayerFree from './Components/Dashbord/AddPlayersFree/CreatePlayerFree';
import AccountManage from './Components/Dashbord/AccountManage/AccountManage';
import SupportForm from './Components/Dashbord/SupportForm/SupportForm';
import EditPlayerInfo from './Components/Dashbord/EditPlayerInfo/editPlayerInfo';
import EditPlayerThank from './Components/Dashbord/EditPlayerInfo/editPlayerThank';
import ResetPasswordSuccess from './Components/Auth/Forgot/resetPasswordSuccess';
import { connect, Provider, useDispatch } from 'react-redux';
import { setPlayerTotalPrice } from './Redux/addPlayerReducer';
import {
  setChildDashVimeoInfo,
  setUri,
  setVideo,
  setViewed,
  setTrainingGoal,
  setTrainingPlan,
  setUserAwards,
} from './Redux/childDashVimeoInfoReducer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import VideoCategories from './Components/DashboardChild/VideoCategories/VideoCategories';
import DownloadPage from './Components/Home/DownloadPage/DownloadPage';
import MainFolders from './Components/DashboardChild/MainFolders';
import { SignUpFree } from './Components/Auth/SignUpFree/SignUpFree';
import SearchPageComponent from './Components/DashboardChild/SearchPage/SearchPageComponent';
import PrivateRouteToSearch from './Components/PrivateRouteToSearch/PrivateRouteToSearch';
import TrainingGoalComponent from './Components/TrainingGoal/TrainingGoal';
import { SectionName, TrainingGoalStatus } from './constants/const';
import CreateTrainingGoalFormComponent from './Components/CreateTrainingGoalForm/CreateTrainingGoalForm';
import RewardPage from './Components/Reward/RewardPage/RewardPage';
import { Reports } from './Components/DashboardChild/Reports/Reports';
import { CertificatePage } from './Components/Reward/CertificatePage/CertificatePage';
import { UpgradePage } from './Components/UpgradePage/UpgradePage';
import PrivateRouteToTeamDashboard from './Components/PrivateRoutes/PrivateRouteToTeamDashboard';
import PrivateRouteToUpgradePage from './Components/PrivateRouteToUpgradePage/PrivateRouteToUpgradePage';
import { TeamsMainPage } from './Components/DashboardChild/Teams/TeamsMainScreen/TeamsMainScreen';
import { Dashboard } from './Components/DashboardChild/Teams/Dashboard/Dashboard';
import ProfilePage from './Components/ProfilePage/ProfilePage';
import GetStartedAndThirtyDays from './Components/DashboardChild/MainDashboardCard/GetStartedAndThirtyDays';
import CreateContestFormComponent from './Components/CreateContestForm/index.jsx';
import PublicContest from './Components/Pages/Contest/index.jsx';
import CreateChallengeFormComponent from './Components/CreateChallengeForm/index.jsx';

function App(props) {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const [welcomePage, setWelcomePage] = useState(false);
  const [updateDashboard, setUpdateDashboard] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [amountPlayer, setAmountPlayer] = useState(0);

  useEffect(() => {
    if (cookies.get('finaleToken')) {
      try {
        profileInfoRequest().then((response) =>
          requestHandler(response, 'parent', props.setUserData),
        );
      } catch (e) {
        console.log(e);
      }
    } else if (cookies.get('finaleChildToken')) {
      try {
        childInfoRequest().then((response) =>
          requestHandler(response, 'child', props.setOnlyChildData),
        );
      } catch (e) {
        console.log(e);
      }
    } else if (cookies.get('finaleTokenAdmin')) {
      setAdmin(true);
      props.setAuth(true);
    } else {
      props.setLogin(false);
      props.setAuth(true);
      setAdmin(false);
    }
  }, [props.update, updateDashboard]);

  const requestHandler = (serverResponse, role, setData) => {
    serverResponse.json().then((result) => {
      if (result.Error !== 'TOKEN!!!!!') {
        props.setRole(role);
        setData(result);
        props.setLogin(true);
        props.setAuth(true);
        if (
          location.pathname.includes(path.admin.sign) ||
          location.pathname.includes(path.admin.dashboard)
        ) {
          // If user which logged as Parent account try to go admin page
          props.setLogin(false);
          cookies.remove('finaleToken');
        } else {
          // Из-за него ссылки не работают, но на всякий пока оставлю
          // history.replace('/dashboard');
        }
      } else {
        props.setLogin(false);
        props.setAuth(true);
        history.replace('/');
      }
    });
  };

  return (
    <div>
      {props.isAuth ? (
        <div>
          {!props.isLogin ? (
            <Header path={path} welcomePage={welcomePage} setWelcomePage={setWelcomePage} />
          ) : props.role === 'parent' ? (
            <HeaderEighth
              role={props.role}
              playerData={props.playerData}
              path={path}
              name={props.userData.userInfo.firstname + ' ' + props.userData.userInfo.lastname}
              setAuth={props.setAuth}
              setNeedUpdate={props.setNeedUpdate}
            />
          ) : (
            <HeaderEighth
              setLogin={props.setLogin}
              role={props.role}
              playerData={props.playerData}
              name={props.playerData.screenname}
              setNeedUpdate={props.setNeedUpdate}
              setAuth={props.setAuth}
            />
          )}
          <div style={{ background: '#f4fbff', minHeight: '0vh' }}>
            <Switch>
              <Route
                path={path.sign.in}
                exact
                render={() => (
                  <SignIn
                    path={path}
                    setRole={props.setRole}
                    setLogin={props.setLogin}
                    setOnlyChildData={props.setOnlyChildData}
                    setUserData={props.setUserData}
                  />
                )}
              />
              <Route path={path.sign.upFree} exact render={() => <SignUpFree />} />
              <Route
                path={path.sign.up}
                exact
                render={() => (
                  <SignUp
                    path={path}
                    setRole={props.setRole}
                    setLogin={props.setLogin}
                    setUserDataStep1={props.setUserDataStep1}
                    setUserDataStep2={props.setUserDataStep2}
                    setUserDataStep3={props.setUserDataStep3}
                    delUserDataSteps={props.delUserDataSteps}
                    signUpData={props.signUpData}
                  />
                )}
              />
              <Route path={path.sign.forgot} exact render={() => <Forgot path={path} />} />
              <Route
                path={path.sign.resetPassword}
                exact
                render={() => <ResetPasswordSuccess path={path} />}
              />
              <Route
                path={`${path.sign.recovery}/:recoveryToken?`}
                render={() => <Recovery path={path} />}
              />
              <Route
                path={`${path.recoveryemail}/:recoveryToken?`}
                render={() => <RecoveryEmail path={path} />}
              />
              <Route
                path={`${path.recoveryemailerror}/:recoveryToken?`}
                render={() => <RecoveryEmailError path={path} />}
              />
              <Route
                path={path.sign.thank}
                exact
                render={() => (
                  <ThankYou
                    path={path}
                    setUserData={props.setUserData}
                    setLogin={props.setLogin}
                    setRole={props.setRole}
                  />
                )}
              />
              <Route
                path={path.home}
                exact
                render={() => (
                  <SignIn
                    path={path}
                    setRole={props.setRole}
                    setLogin={props.setLogin}
                    setOnlyChildData={props.setOnlyChildData}
                    setUserData={props.setUserData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.changeBilling}
                render={() => (
                  <ChangeBillingDetails
                    billingDetails={props.billingDetails}
                    setBillingDetails={props.setBillingDetails}
                  />
                )}
              />
              <Route exact path={path.privacyPolicy} render={() => <PrivacyPolicy path={path} />} />
              <Route exact path={path.termsNconditions} render={() => <TermsNConditions />} />
              <Route exact path={path.contactUs} render={() => <ContactUs />} />
              <Route exact path={path.employment} render={() => <Employment />} />
              <Route exact path={path.teamDiscounts} render={() => <TeamDiscounts />} />
              <Route exact path={path.joinMailingList} render={() => <JoinMailingList />} />
              <Route path={path.downloadPage} render={() => <DownloadPage path={path} />} />
              <Route exact path={path.contest} render={() => <PublicContest />} />
              <Route
                path={path.dashboard.main}
                exact
                render={() => (
                  <ParentDash
                    playerData={props.userData}
                    setRole={props.setRole}
                    setOnlyChildData={props.setOnlyChildData}
                    setUserDataStep1={props.setUserDataStep1}
                    setUserDataStep3={props.setUserDataStep3}
                    signUpData={props.signUpData}
                    amountPlayer={amountPlayer}
                  />
                )}
              />
              <PrivateRouteToUpgradePage
                path={path.upgradePage}
                render={() => (
                  <UpgradePage
                    playerData={props.playerData}
                    signUpData={props.signUpData}
                    userData={props.userData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.confirmPlayer}
                render={() => (
                  <ConfirmPlayer
                    path={path}
                    setUserData={props.setUserData}
                    setDeletePlayer={props.setDeletePlayer}
                    userData={props.userData}
                    playerData={props.addChildData}
                    addPlayerReducer={props.addPlayerReducer}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.createPlayerFree}
                render={() => (
                  <CreatePlayerFree
                    path={path}
                    childCount={props.userData.childInfo.childrenCount}
                    playerData={props.addChildData}
                    setPlayerData={props.setPlayerData}
                    setPlayerTotalPrice={props.setPlayerTotalPrice}
                    setUserData={props.setUserData}
                    setDeletePlayer={props.setDeletePlayer}
                    setAmountPlayer={setAmountPlayer}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.createPlayer}
                render={() => (
                  <CreatePlayer
                    path={path}
                    childCount={props.userData.childInfo.childrenCount}
                    playerData={props.addChildData}
                    setPlayerData={props.setPlayerData}
                    setPlayerTotalPrice={props.setPlayerTotalPrice}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.reactivationPlayer + `/:childNumber?`}
                render={() => (
                  <ReactivationPlayer
                    childCount={props.userData.childInfo.childrenCount}
                    playerData={props.addChildData}
                    setPlayerData={props.setPlayerData}
                    setUpdateDashboard={setUpdateDashboard}
                    updateDashboard={updateDashboard}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.editPlayerInfo + `/:childCount?`}
                render={() => (
                  <EditPlayerInfo
                    childInfo={props.userData.childInfo}
                    setUserData={props.setUserData}
                    userData={props.userData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.manage.account}
                render={() => (
                  <AccountManage
                    path={path}
                    playerData={props.userData.userInfo}
                    childInfo={props.userData.childInfo}
                    update={props.setNeedUpdate}
                    setBillingDetails={props.setBillingDetails}
                    userData={props.userData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.profilePage + `/:userId?` + `/:teamId?`}
                render={() => (
                  <ProfilePage
                    playerDataId={props.playerData.id}
                    playerStatus={props.playerData.status}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.support}
                render={() => (
                  <SupportForm
                    name={props.userData.userInfo.firstname}
                    email={props.userData.userInfo.email}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboard.editPlayerThank}
                render={() => (
                  <EditPlayerThank setRole={props.setRole} setUserData={props.setUserData} />
                )}
              />
              <Route exact path={path.registerFreeThunk} render={() => <EditPlayerThank />} />
              <Route
                exact
                path={path.dashboardChild.main}
                render={() => (
                  <MainFolders
                    trainingPlan={props.trainingPlan}
                    playerData={props.playerData}
                    dashboardInfo={props.mainFoldersDashboardChildReducer}
                    setChildDashVimeoInfo={props.setChildDashVimeoInfo}
                    setTrainingGoal={props.setTrainingGoal}
                    setTrainingPlan={props.setTrainingPlan}
                    vimeoFolderData={props.vimeoFolderData}
                    signUpData={props.signUpData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.reports}
                render={() => (
                  <Reports
                    playerData={props.playerData}
                    userAwards={props.userAwards}
                    setUserAwards={props.setUserAwards}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.teammateReports + '/:userId/:firstName'}
                render={() => (
                  <Reports
                    playerData={props.playerData}
                    userAwards={props.userAwards}
                    setUserAwards={props.setUserAwards}
                  />
                )}
              />
              <Route
                exact
                path={path.admin.sign}
                render={() => <AdminDashboard setAdmin={setAdmin} />}
              />
              <Route
                exact
                path={path.admin.dashboard}
                render={() =>
                  admin || cookies.get('finaleTokenAdmin') ? (
                    <AdminDashboardTable />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path={path.dashboardChild.allPrograms}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.quickSessions}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.quickSessionsData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />

              <Route
                exact
                path={path.dashboardChild.dribblingGames}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.allTrainingAreas}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.playerJourneys}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.playerJorneyData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.playerJourneysSubfolder}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.playerJorneySubfolderData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild['30DayPlan']}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.playerJorneySubfolderData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.teamHomework}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.playerJorneySubfolderData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.beginnersKickStart}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.getStartedData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.getStarted}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.getStartedData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.quickTurns}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.getStartedAnd30DaysPlan}
                render={() => (
                  <GetStartedAndThirtyDays
                    playerData={props.playerData}
                    dashboardInfo={props.mainFoldersDashboardChildReducer}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.dribbling1v1Shielding}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.boxDribbling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.finishing1v1}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.tightSpaceDribbling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.lineConeAndConeSlalomDribbling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.otherTightDribblingPrograms}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.shapePattern}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.speedDribblingTurnsFitness}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.obstacleLaneLateralDribbling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.partnerDrills}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.advancedJuggleMaster}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.fastSpeedDribbleMaster}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.partnerDrillsPro}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.passingReceivingData}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.middleJuggleMaster}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.moves}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.clinicalFinishing}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.directionalControlRapidFire}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.dribblingFinishing}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.dribblePassShoot}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.aerialControlOther}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.freekicksPenalties}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.dribbleMaster}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.otherDribblingPrograms}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.warmUp}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.otherJugglingAndArealPrograms}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />

              <Route
                exact
                path={path.dashboardChild.jugglingAndArealPrograms}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.beginner}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.lineCone}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.touch1000}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.touch1000Part1}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.twoConePro}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.firstTouchPro}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.moves1V1Pro}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.touch1000Part2}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.battle1v1Finishing}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.lateralMovement}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.lateralDynamicOther}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.otherProgramsBallMasetery}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.coneAndLateral}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.longDistanceAndOther}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.rebounderAndWall}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.corePassing}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.ballMastery}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.changeDirection}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.figure8}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.otherFigure8Programs}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.coreJugglingBeginnerToAdvanced}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.sizeOneTennisBallJuggling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.partnerTossAerialWallJuggling}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.bodyAerialDribblingAerialFinishing}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.oneTwoFourCone}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildSubfoldersData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.freeSection}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.freeSectionData}
                    vimeoFolderData={props.vimeoFolderData.filter((element) =>
                      element.name.includes(SectionName.FREE_SECTION),
                    )} // часть секций не содержит вложенных папок, для этих случаев мы фильтруем данные, которые потом используем в gridCards -> CreateSeries
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.freeSectionSubfolders}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.freeSectionSubfolderData}
                    vimeoFolderData={props.vimeoFolderData.filter((element) =>
                      element.name.includes(SectionName.FREE_SECTION),
                    )} // часть секций не содержит вложенных папок, для этих случаев мы фильтруем данные, которые потом используем в gridCards -> CreateSeries
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.freestyleChallenge}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.anytimeData}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.skillAreas}
                render={() => (
                  <DashboardChild
                    playerData={props.playerData}
                    dashboardInfo={props.mainFoldersDashboardChildReducer.quickLinks}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <PrivateRouteToSearch
                exact
                path={path.search}
                render={() => (
                  <SearchPageComponent
                    dashboardInfo={props.dashboardChildData}
                    vimeoFolderData={props.vimeoFolderData}
                    setChildDashVimeoInfo={props.setChildDashVimeoInfo}
                    vimeoVideoData={props.vimeoVideoData}
                    setVideo={props.setVideo}
                    setViewed={props.setViewed}
                  />
                )}
              />
              <Route
                exact
                path={path.trainingGoal}
                render={() =>
                  !props.hasTrainingGoal ||
                  props.hasTrainingGoal === TrainingGoalStatus.CREATING ? (
                    <CreateTrainingGoalFormComponent
                      hasTrainingGoal={props.hasTrainingGoal}
                      setTrainingGoal={props.setTrainingGoal}
                      setTrainingPlan={props.setTrainingPlan}
                    />
                  ) : (
                    <TrainingGoalComponent
                      trainingPlan={props.trainingPlan}
                      setTrainingGoal={props.setTrainingGoal}
                      setTrainingPlan={props.setTrainingPlan}
                      userAwards={props.userAwards}
                      setUserAwards={props.setUserAwards}
                      playerData={props.playerData}
                    />
                  )
                }
              />
              <Route
                exact
                path={path.teams.createContest}
                render={() => <CreateContestFormComponent />}
              />
              <Route
                exact
                path={path.teams.createChallenge}
                render={() => <CreateChallengeFormComponent />}
              />
              <Route
                exact
                path={path.teammateTrainingGoal + '/:userId'}
                render={() => (
                  <TrainingGoalComponent
                    trainingPlan={props.trainingPlan}
                    setTrainingGoal={props.setTrainingGoal}
                    setTrainingPlan={props.setTrainingPlan}
                    userAwards={props.userAwards}
                    setUserAwards={props.setUserAwards}
                    playerData={props.playerData}
                  />
                )}
              />
              <Route
                exact
                path={path.rewardCertificate}
                render={() =>
                  props.trainingPlan.videoGoal !== 0 &&
                  props.trainingPlan.videoGoal <= props.trainingPlan.videoCompleted ? (
                    <RewardPage
                      trainingPlan={props.trainingPlan}
                      setTrainingGoal={props.setTrainingGoal}
                      setTrainingPlan={props.setTrainingPlan}
                      setUserAwards={props.setUserAwards}
                    />
                  ) : (
                    <TrainingGoalComponent
                      trainingPlan={props.trainingPlan}
                      setTrainingGoal={props.setTrainingGoal}
                      setTrainingPlan={props.setTrainingPlan}
                      userAwards={props.userAwards}
                      setUserAwards={props.setUserAwards}
                    />
                  )
                }
              />
              <Route
                exact
                path={path.rewardCenter}
                render={() => (
                  // props.trainingPlan.videoGoal !== 0 && props.trainingPlan.videoGoal <= props.trainingPlan.videoCompleted ?
                  <CertificatePage
                    trainingPlan={props.trainingPlan}
                    setTrainingGoal={props.setTrainingGoal}
                    setTrainingPlan={props.setTrainingPlan}
                    userAwards={props.userAwards}
                    setUserAwards={props.setUserAwards}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.anytimeSkills}
                render={() => (
                  <VideoSeries
                    dashboardInfo={props.dashboardChildData}
                    vimeoFolderData={props.vimeoFolderData}
                    vimeoVideoData={props.vimeoVideoData}
                    currentUri={'/users/110211968/projects/7219259'}
                    setChildDashVimeoInfo={props.setChildDashVimeoInfo}
                    setUri={props.setUri}
                    setVideo={props.setVideo}
                    setViewed={props.setViewed}
                  />
                )}
              />
              <Route
                path={path.dashboardChild.videoCategories + `/:videoCategory?`}
                render={() => (
                  <VideoCategories
                    playerData={props.playerData}
                    dashboardInfo={props.dashboardChildData}
                    setChildDashVimeoInfo={props.setChildDashVimeoInfo}
                    vimeoFolderData={props.vimeoFolderData}
                  />
                )}
              />
              <Route
                exact
                path={path.dashboardChild.videoSeries + `/:videoId?/:fromListViewPage?`}
                render={() => (
                  <VideoSeries
                    dashboardInfo={props.dashboardChildData}
                    vimeoFolderData={props.vimeoFolderData}
                    vimeoVideoData={props.vimeoVideoData}
                    currentUri={props.currentUri}
                    setChildDashVimeoInfo={props.setChildDashVimeoInfo}
                    setUri={props.setUri}
                    setVideo={props.setVideo}
                    setViewed={props.setViewed}
                  />
                )}
              />
              <PrivateRouteToTeamDashboard
                exact
                path={path.teams.dashboard}
                render={() => <Dashboard />}
              />
              <PrivateRouteToTeamDashboard
                exact
                path={path.teams.roster}
                render={() => <Dashboard />}
              />
              <PrivateRouteToTeamDashboard
                exact
                path={path.teams.homework}
                render={() => <Dashboard />}
              />
              <PrivateRouteToTeamDashboard
                exact
                path={path.teams.contest}
                render={() => <Dashboard />}
              />
              <PrivateRouteToTeamDashboard
                exact
                path={path.teams.challenge}
                render={() => <Dashboard />}
              />
              <Route exact path={path.dashboardChild.teams} render={() => <TeamsMainPage />} />
              <Route>
                <NotFound />
              </Route>
            </Switch>
            <Footer path={path} welcomePage={welcomePage} isLogin={props.isLogin} />
          </div>
        </div>
      ) : (
        <Spinner color="primary" className={s.spinner} />
      )}
    </div>
  );
}

const mapStateToProp = (state) => ({
  userData: state.userDataReducer.userData,
  isAuth: state.authReducer.isAuth,
  isLogin: state.authReducer.isLogin,
  update: state.authReducer.needUpdate,
  role: state.authReducer.role,
  signUpData: state.signUpDataReducer,
  playerData: state.playerDataReducer.playerData,
  dashboardChildData: state.dashboardChildReducer.data,
  dashboardChildSubfoldersData: state.dashboardChildReducer.subfoldersData,
  mainFoldersDashboardChildReducer: state.mainFoldersDashboardChildReducer.data,
  addChildData: state.appReducer.playerData,
  vimeoFolderData: state.childDashVimeoInfoReducer.vimeoFolderData,
  vimeoVideoData: state.childDashVimeoInfoReducer.vimeoVideoData,
  currentUri: state.childDashVimeoInfoReducer.currentUri,
  hasTrainingGoal: state.childDashVimeoInfoReducer.hasTrainingGoal,
  trainingPlan: state.childDashVimeoInfoReducer.trainingPlan,
  userAwards: state.childDashVimeoInfoReducer.userAwards,
  addPlayerReducer: state.addPlayerReducer.playerTotalPrice,
  billingDetails: state.billingDetailsReducer,
  playerJorneyData: state.playerJourneyReducer.data,
  playerJorneySubfolderData: state.playerJourneyReducer.subfoldersData,
  getStartedData: state.getStartedReducer.data,
  freeSectionData: state.freeSectionReducer.data,
  freeSectionSubfolderData: state.freeSectionReducer.subfoldersData,
  anytimeData: state.anytimeReducer.data,
  quickSessionsData: state.quickSessionsReducer.data,
  quickSessionsReducer: state.quickSessionsReducer.data,
});

const AppContainer = connect(mapStateToProp, {
  setUserData,
  setAuth,
  setDeletePlayer,
  setLogin,
  setNeedUpdate,
  setRole,
  setUserDataStep1,
  setPlayerData,
  setUserDataStep2,
  setUserDataStep3,
  delUserDataSteps,
  setOnlyChildData,
  setChildDashVimeoInfo,
  setVideo,
  setUri,
  setViewed,
  setTrainingGoal,
  setTrainingPlan,
  setUserAwards,
  setPlayerTotalPrice,
  setBillingDetails,
})(App);

const stripePromise = loadStripe(
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'pk_test_51H6fiUCbbae3QC4JpIcGfSP6Chc6y0bIsBtTsVJaIZ66W835BxVf7uGrzNWmnZJNEb7WqFpi5RbDralLsXbXt7FV005qWg4Q7n'
    : 'pk_live_4d5aEXWKehlnw2RjJ51ouVyS00TF2GDqJb',
);

const MainApp = () => {
  return (
    <Router>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <AppContainer />
        </Elements>
      </Provider>
    </Router>
  );
};

export default MainApp;
