import React, { useState } from 'react';
import styles from './DownloadPage.module.sass';
import { Link } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import { Modal } from 'reactstrap';

const DownloadPage = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <section className={styles.DownloadPage}>
      <div className={styles.container}>
        <img
          className={styles.container_img}
          src="/img/home/boyplaysfootball.png" alt="Boy plays football"/>
        <div className={styles.container_wrapper}>
          <img className={styles.container_wrapper__image} src="/img/home/main_central_block.webp"
               alt="centralblockimg"/>
          <div className={styles.container_wrapper__title}>
            <h2 className={styles.h2}>Join Today and get<br/> 50% OFF!</h2>
            <p className={styles.p}>(Offer Ends Today!)</p>
            <p className={styles.p_promo}>PROMO CODE: <br/><span>7DAYS</span></p>
          </div>
        </div>
        <div className={styles.container_desc}>
          <div className={styles.wrapper_desc}>
            <h2 className={styles.wrapper_desc__h2}>Five Secrets of ball mastery!</h2>
            <p className={styles.wrapper_desc__p}>Your competition will <span>never</span> do this!</p>
          </div>
          <div className={styles.container_items}>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h2>Do an Anytime Soccer Training Video.<span>Before Practice</span></h2>
                <p>15 minutes equals 40 extra hours per year!</p>
              </div>
              <p className={styles.count}>1</p>
            </div>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h2>Become <span>Efficient</span> with Interval-Based Timer.</h2>
                <p>All Anytime Soccer Training videos are interval-based.</p>
              </div>
              <p className={styles.count}>2</p>
            </div>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h2>Do an Anytime Soccer Training Video <span>Before Each Game.</span></h2>
                <p>Get more touches than the rest of the team combined!</p>
              </div>
              <p className={styles.count}>3</p>
            </div>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h2>Do an Anytime Soccer Training Video <span>Before School.</span></h2>
                <p>10 minutes equals 60 extra hours per year!</p>
              </div>
              <p className={styles.count}>4</p>
            </div>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h2>Master <span>One Skill </span>at a Time.</h2>
                <p>Hundreds of new Skill of the Week videos!</p>
              </div>
              <p className={styles.count}>5</p>
            </div>
            <div className={styles.container_items__block}>
              <div className={styles.container_items__description}>
                <h3>You Can Do It - No Matter What!</h3>
                <p>Anytime Soccer Training is 100% follow-along. <span className={styles.desc}>No guesswork!</span></p>
              </div>
              <p className={styles.star}>&#10026;</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line_block}>
        <div className={styles.first_line}/>
        <div className={styles.text_line}>FEEL FREE TO</div>
        <div className={styles.second_line}/>
      </div>
      <div className={styles.main_menu}>
        <Link to={props.path.sign.upFree}
              className={styles.button}>
          Get Started&nbsp;
          <i className="fas fa-arrow-right"></i>
        </Link>
        <button onClick={() => toggle()}
                className={styles.watch_btn}>
          <i className="fas fa-play"></i>
          &nbsp;&nbsp;&nbsp;Watch More
          <Modal isOpen={modal} toggle={toggle}>
            <Vimeo
              className={styles.modal_vimeo}
              autopause={true}
              responsive={true}
              video={'https://vimeo.com/400024449'}
            >
            </Vimeo>
          </Modal>
        </button>
      </div>
    </section>
  );
};

export default DownloadPage;