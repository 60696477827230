import React from 'react';
import styles from './../GridCards/gridCards.module.sass';
import { useHistory } from 'react-router-dom';
import { listOfVideosVimeo } from '../../../api/api';
import { message } from 'antd';
import { setUri, setVideo } from '../../../Redux/childDashVimeoInfoReducer';
import { useDispatch } from 'react-redux';
import { SubfolderPageUrl } from '../../../constants/const';

const MainFoldersSeries = (props) => {
  const { name, uri, setShowUpgradePopup, urlImage, accessAllVideos } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const serverVideoRequest = () => {
    dispatch(setUri(uri));
    try {
      listOfVideosVimeo(uri, 1).then((response) => requestHandler(response));
    } catch (e) {
      console.log(e);
      return message.error('This category will appear soon');
    }
  };

  const requestHandler = (response) => {
    if (response.status === 200) {
      response.json().then((result) => {
        const videoArray = result.data.map((a) => ({
          name: a.name,
          link: a.link,
          viewed: a.viewed,
        }));
        dispatch(setVideo({ total: result.total, videoArray }));

        if (!accessAllVideos) {
          return setShowUpgradePopup(true);
        }

        if (result.total) {
          history.push(`${SubfolderPageUrl.videoSeries}/${name}`);
        } else {
          message.error('This category will appear soon');
        }
      });
    } else {
      console.error('Please, reload the page');
    }
  };

  return (
    <article
      tabIndex="0"
      onClick={serverVideoRequest}
      className={styles.card}
      style={{ backgroundImage: `url(${urlImage})` }}
    ></article>
  );
};

export default MainFoldersSeries;
