const DELETE_PLAYER = 'DELETE-PLAYER';
const CREATE_PLAYER = 'CREATE-PLAYER';
//birthdate changed to birthyear
const initialState = {
  playerData: {
    screenName: '',
    birthyear: '',
    childEmail: '',
    username: '',
    password: '',
    picture: 1,
  },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PLAYER:
      return {
        ...state,
        playerData: { ...state.playerData, ...action.playerData },
      };
    case DELETE_PLAYER:
      return {
        ...state,
        playerData: {
          screenName: '',
          birthdate: '',
          childEmail: '',
          username: '',
          password: '',
          picture: 1,
        },
      };
    default:
      return state;
  }
};

//сокращение player: player

export const setDeletePlayer = () => ({ type: DELETE_PLAYER });
export const setPlayerData = (playerData) => ({ type: CREATE_PLAYER, playerData });

export default appReducer;
