import React, { useEffect, useState } from 'react';
import styles from './ProfilePage.module.sass';
import HeaderProfilePage from './HeaderProfilePage/HeaderProfilePage';
import ProfileInformation from './ProfileInformation/ProfileInformation';
import { fetchProfileData } from './asyncActions/profile';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import s from '../../app.module.sass';
import { ModalWindowPopupSendMessage } from './ModalWindowPopap/ModalWindowPopupSendMessage/ModalWindowPopupSendMessage';
import { ModalWindowPopupUpdateRole } from './ModalWindowPopap/ModalWindowPopapUpdateRole/ModalWindowPopapUpdateRole';
import { ModalWindowPopupEditMember } from './ModalWindowPopap/ModalWindowPopapEditMember';
import { ModalWindowPopupLeaveTeam } from './ModalWindowPopap/ModalWindowPopapLeaveTeam';
import { PopupStatusProfilePage } from './constants';
import { WarningPopup } from './ModalWindowPopap/WarningPopup';

const ProfilePage = ({ playerDataId, playerStatus }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [popupStatusProfilePage, setPopupStatusProfilePage] = useState(
    PopupStatusProfilePage.INACTIVE,
  );

  useEffect(() => {
    dispatch(fetchProfileData({ childId: params.userId, teamId: params.teamId }));
  }, [params]);

  const profileData = useSelector((state) => state?.profileDataReducer?.profileData);

  const checkYourProfile = playerDataId == params.userId;

  if (!profileData.role) {
    return (
      <div style={{ background: '#f4fbff', minHeight: '80vh' }}>
        <Spinner color="primary" className={s.spinner} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderProfilePage />
      <ProfileInformation
        profileData={profileData}
        checkYourProfile={checkYourProfile}
        playerStatus={playerStatus}
        setPopupStatusProfilePage={setPopupStatusProfilePage}
        teamId={params.teamId}
        userId={params.userId}
      />
      {popupStatusProfilePage === PopupStatusProfilePage.SEND_MESSAGE ? (
        <ModalWindowPopupSendMessage
          teamId={params.teamId}
          profileData={profileData}
          childId={params.userId}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
      {popupStatusProfilePage === PopupStatusProfilePage.UPDATE_ROLE ? (
        <ModalWindowPopupUpdateRole
          profileData={profileData}
          teamId={params.teamId}
          childId={params.userId}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
      {popupStatusProfilePage === PopupStatusProfilePage.EDIT_PROFILE ? (
        <ModalWindowPopupEditMember
          profileData={profileData}
          teamId={params.teamId}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
      {popupStatusProfilePage === PopupStatusProfilePage.LEAVE_TEAM ? (
        <ModalWindowPopupLeaveTeam
          childId={params.userId}
          teamId={params.teamId}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
      {popupStatusProfilePage === PopupStatusProfilePage.WARNING_ONE_MANAGER ? (
        <WarningPopup
          titleWarning={'Warning'}
          descriptionWarning={
            'Groups must have at least one manager.\n' +
            'You must make someone else a manager before leaving the group'
          }
          titleButton={'OK. Thanks!'}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
      {popupStatusProfilePage === PopupStatusProfilePage.WARNING_LEAVE_MANAGER ? (
        <WarningPopup
          titleWarning={'Oops! Something went wrong.'}
          descriptionWarning={'Managers cannot leave their team.'}
          titleButton={'OK. Thanks!'}
          setPopupStatusProfilePage={setPopupStatusProfilePage}
        />
      ) : null}
    </div>
  );
};

export default ProfilePage;
