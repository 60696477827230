import React from 'react';
import styles from './InvitationRow.module.sass';
import { TableButtons } from './TableButtons/TableButtons';

export function InvitationRow({ invitations, requests, isUpdated, setIsUpdated, teamId }) {
  return (
    <>
      {invitations
        ? invitations.map(data => {
            return (
              <tr className={styles.row} key={data.childId}>
                <th scope="row" className={styles.cell_first}>
                  <div className={styles.row_container}>
                    {data.childFirstName} {data.childLastName}
                    <TableButtons
                      table="Pending Invitations"
                      childId={data.childId}
                      teamId={teamId}
                      isUpdated={isUpdated}
                      setIsUpdated={setIsUpdated}
                    />
                  </div>
                </th>
              </tr>
            );
          })
        : requests.map(data => {
            return (
              <tr className={styles.row} key={data.childId}>
                <th scope="row" className={styles.cell_first}>
                  <div className={styles.row_container}>
                    {data.childFirstName} {data.childLastName}
                    <TableButtons
                      table="Requests Sent"
                      childId={data.childId}
                      teamId={teamId}
                      isUpdated={isUpdated}
                      setIsUpdated={setIsUpdated}
                    />
                  </div>
                </th>
              </tr>
            );
          })}
    </>
  );
}
