import React, { useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import styles from './AvatarComponent.module.sass';
import { ImageCropper } from '../ImageCropper/ImageCropper';
import { Avatars } from '../../../constants/const';

export function AvatarComponent(props) {
  const { avatarNumber, setAvatarNumber, requestData, setRequestData } = props;
  const [isClearImage, setIsClearImage] = useState(false);
  const { width } = useWindowDimensions();
  const diff = avatarNumber - 4;

  const handleAvatarClick = (event) => {
    setAvatarNumber(parseInt(event.target.dataset.title));
    if (requestData.image !== undefined) {
      setRequestData({ ...requestData, image: '', picture: event.target.dataset.title });
      setIsClearImage(true);
    } else {
      setRequestData({ ...requestData, image: '', picture: event.target.dataset.title });
    }
  };

  return (
    <>
      <div className={styles.image_crop_button}>
        <ImageCropper
          isClearImage={isClearImage}
          setIsClearImage={setIsClearImage}
          requestData={requestData}
          setRequestData={setRequestData}
        />
      </div>
      <p className={styles.iconsHeading}>Choose Avatar</p>
      <div
        style={diff > 0 && width <= 576 ? { transform: `translateX(calc(-21% * ${diff}))` } : null}
        className={styles.iconContainer}
      >
        <ul className={styles.avatar_list}>
          {Avatars.map((element, index) => {
            return (
              <li
                onClick={handleAvatarClick}
                data-title={index + 1}
                key={element}
                className={
                  Number(avatarNumber) === index + 1
                    ? `${styles.avatar_list__item} ${styles.iconChooseEffect}`
                    : `${styles.avatar_list__item} ${styles.iconEffects}`
                }
              >
                <img
                  data-title={index + 1}
                  key={element}
                  className={styles.icon}
                  src={element}
                  alt={element}
                />
              </li>
            );
          })}
        </ul>
      </div>
      {width <= 576 && (
        <div className={`input-group inline-group ${styles.img_selector}`}>
          <div className="input-group-prepend">
            <div
              className={`btn btn-outline-secondary ${styles.button_selector} ${
                styles.left_selector
              } ${Number(avatarNumber) === 1 && styles.lock_selector}`}
              onClick={() => avatarNumber > 1 && setAvatarNumber((avatarNumber) => avatarNumber - 1)}
            >
              <i className={`fa fa-angle-left ${styles.arrow_selector}`}></i>
            </div>
          </div>
          <input
            className={`form-control quantity ${styles.text_selector}`}
            min="0"
            name="quantity"
            readOnly
            value={avatarNumber}
          />
          <div className="input-group-append">
            <div
              className={`btn btn-outline-secondary ${styles.button_selector} ${
                styles.right_selector
              } ${Number(avatarNumber) === 17 && styles.lock_selector}`}
              onClick={() => avatarNumber < 17 && setAvatarNumber((avatarNumber) => avatarNumber + 1)}
            >
              <i className={`fa fa-angle-right ${styles.arrow_selector}`}></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
