import moment from 'moment';

// check if the user use Internet Explorer and return true or false
export const isInternetExplorer = () =>
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const getFormattedDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const compareDates = (endDate, startedDate) => {
  return moment(endDate).diff(startedDate, 'days');
};

export const capitalizeWord = (word) => {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};

export const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (key === 'image' && object[key]) {
      formData.append(key, object[key], object[key].name || 'imageFilename.png');
    } else if (typeof object[key] !== 'object') {
      formData.append(key, object[key]);
    } else formData.append(key, JSON.stringify(object[key]));
  });
  return formData;
};

export const timeConvert = (num) => {
  const hours = Math.floor(num / 3600);
  let minutes = 0;
  if (hours >= 1) {
    minutes = Math.round((num % 3600) / 60);
    return `${hours}h ${minutes}m`;
  } else if (num >= 60) {
    minutes = Math.round(num / 60);
    return `${minutes}m`;
  } else {
    return '0m';
  }
};

export const changeFormatData = (num) => {
  let data = num;
  if (num >= 10000) {
    data = num.toString();
    data = `${data.slice(0, data.length - 3)},${data.slice(data.length - 3)}`;
  }
  return data;
};

export const convertMinutesInHours = (num) => {
  if (!num || typeof num !== 'number') return 0;
  let hours = 0;
  let minutes = 0;
  if (num >= 60) {
    hours = Math.floor(num / 60);
    minutes = Math.floor(num % 60);
    if (minutes) {
      return `${hours}h ${minutes}m`;
    }
    return `${hours}h`;
  }
  return `${num}m`;
};
