import { getCountManager } from '../../../api/teams-api';
import { getCountManagerAction } from '../../../Redux/countManagerReducer';

export const fetchCountManagerData = data => {
  return dispatch => {
    try {
      getCountManager(data).then(response => {
        response.json().then(stats => {
          dispatch(getCountManagerAction(stats));
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};
