import React, { useState } from 'react';
import styles from './SupportForm.module.sass';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { supportRequest } from '../../../api/api';
import { haveNoLoginRedirect, withRoleRedirectComp } from '../../../hoc/redirectComp';
import { compose } from 'redux';

function SupportForm(props) {
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      role: 'Parent',
      name: props.name,
      email: props.email,
      phone: '',
      what: '',
      desc: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('required'),
      email: Yup.string().required('required').email('invalidEmail'),
      phone: Yup.string().required('required'),
      what: Yup.string().required('required'),
      desc: Yup.string().required('required'),
    }),
    onSubmit: (values) => {
      const requestData = {
        category: 'SupportForm',
        task: values.desc,
        fromuser: values.email,
        phone: values.phone,
        name: values.name,
        role: values.role,
        what: values.what,
      };
      try {
        supportRequest(requestData).then((response) => requestHandler(response));
      } catch (e) {
        console.log(e);
      }
    },
  });
  const requestHandler = (response) => {
    if (response.status === 200) {
      toggle();
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <main className={styles.main}>
        <section>
          <div className={styles.header}>
            <h2>Technical Support</h2>
            <p>
              Welcome! We look forward to helping you. Submit your request and a support specialist
              will contact you within 24 hours
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <h4>Your Contact Information</h4>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="role">Your Role *</label>
                <input
                  className={
                    formik.touched.role && formik.errors.role ? styles[formik.errors.role] : ''
                  }
                  type="text"
                  name="role"
                  id="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role.replace(/[^A-z]/g, '')}
                />
              </div>
              <div className={styles.col_name}>
                <label htmlFor="name">Name *</label>
                <input
                  className={
                    formik.touched.name && formik.errors.name ? styles[formik.errors.name] : ''
                  }
                  type="text"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name.replace(/[^A-z]/g, '')}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="email">Email *</label>
                <input
                  className={
                    formik.touched.email && formik.errors.email ? styles[formik.errors.email] : ''
                  }
                  type="email"
                  name="email2"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email.replace(/[А-я,\s]/g, '')}
                />
              </div>
              <div className={styles.col}>
                <label htmlFor="phone">Phone Number *</label>
                <input
                  className={
                    formik.touched.phone && formik.errors.phone ? styles[formik.errors.phone] : ''
                  }
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone.replace(/[^0-9,^()--+]/g, '')}
                />
              </div>
            </div>
            <h4>How Can We Help?</h4>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="what">What is this about? *</label>
                <input
                  className={
                    formik.touched.what && formik.errors.what ? styles[formik.errors.what] : ''
                  }
                  type="text"
                  name="what"
                  id="what"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.what}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <label htmlFor="desc">Describe the issue you are experiencing *</label>
                <textarea
                  className={
                    formik.touched.desc && formik.errors.desc ? styles[formik.errors.desc] : ''
                  }
                  type="text"
                  name="desc"
                  id="desc"
                  rows="10"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.desc}
                />
              </div>
            </div>
            <div className={styles.row}>
              <button type="submit" className={styles.submit}>
                Submit
              </button>
            </div>
          </form>

          <Modal isOpen={modal} toggle={toggle} className={styles.modal}>
            <ModalHeader toggle={toggle}>
              <h4>Thank you</h4>
            </ModalHeader>

            <ModalBody>Your request has been received!</ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={toggle} className={styles.buttonStrap}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      </main>
    </>
  );
}

export default compose(withRoleRedirectComp, haveNoLoginRedirect)(SupportForm);
