import React, { useCallback, useMemo, useState } from 'react';
import { setCategory } from '../../../Redux/listViewCategoryReducer';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SelectVideos.module.sass';
import PinRed from './pin_red.png';
import PinGrey from './pin_grey.png';
import ChevronRed from './down_arrow_red.png';
import ChevronGrey from './up_arrow_grey.png';
import { Link } from 'react-router-dom';
import { createFavoriteJourney, deleteFavoriteBySubTitle, 
  createHomework,
  deleteHomework } from '../../../api/api';
import { useVideoCategoriesByAction } from '../../../hooks/useVideoCategoriesByAction';
import { deleteFavorite, addFavorite, addHomework, removeHomework } from '../../../Redux/listViewReducer';
import useUserTeams from '../../../hooks/useUserTeam';
import { ModalAssignment } from '../../ProfilePage/ModalWindowPopap/ModalAssignment';

const SelectVideos = (props) => {
  const { accessAllVideos, setShowUpgradePopup } = props;
  const { data } = useSelector((state) => state.selectVideosReducer);
  const selectedCategory = useSelector((state) => state.listViewCategoryReducer);
  const selectedSubCategory = useSelector((state) => state.listViewSubcategoryReducer);
  
  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find((item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA').children
  const dispatch = useDispatch();
  const activeStyle = { color: '#86adc5' };
  const fromListViewPage = true;
  const [selectedItem, setSelectedItem] = useState(null)
  const { manageTeams } = useUserTeams()
  const { data: list } = useSelector(state => state.listViewReducer)

  const homeworkData = useMemo(() => {
    const allHomework = list?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.map((item) => item.children[0].children).flat();
    
    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(item => item?.title === current?.title && item?.subTitle === current?.subTitle);
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);
  
    return uniqueHomework;
  }, [list]);

  const checkFavorite = useCallback(
    (_item) => {
  
      return favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })
    },
    [favoriteData],
  )

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {

      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })
  
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return manageTeams.length > 0 ? (homeworkCheck || favoriteCheck) : favoriteCheck
    },
    [favoriteData, homeworkData, manageTeams],
  )

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item)
        return
      } 
  
      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg)
            dispatch(deleteFavorite(item))
          })
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite))
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  )

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = list?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find(item => item.actionType === `cat-${team.id}`)
        ?.children[0].children;
  
      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });
  
      return homeworkCheck;
    },
    [list],
  );

  const onSubmit = useCallback(async (teams, addToFavorite) => {
    // Remove homework from teams that have been unchecked
    const teamsToRemoveHomework = manageTeams.filter(team => 
      checkHomeworkWithTeam(team, selectedItem) && !teams.some(selectedTeam => selectedTeam.id === team.id)
    );

    for (const team of teamsToRemoveHomework) {
      await deleteHomework({...selectedItem, team }).then((res) => {
        console.info(res.msg);
        dispatch(removeHomework(selectedItem, team));
      });
    }

    // Add homework to newly selected teams
    for (const team of teams) {
      if (!checkHomeworkWithTeam(team, selectedItem)) {
        await createHomework({ ...selectedItem, teamId: team.id }).then((res) => {
          console.info(res.success);
          dispatch(addHomework(selectedItem, team));
        });
      }
    }
  
    // Handle favorites
    if (checkFavorite(selectedItem) === false && addToFavorite === true) {
      await createFavoriteJourney(selectedItem).then((res) => {
        dispatch(addFavorite(res.favorite));
      });
    }
  
    if (checkFavorite(selectedItem) === true && addToFavorite === false) {
      await deleteFavoriteBySubTitle(selectedItem).then((res) => {
        console.log(res.msg);
        dispatch(deleteFavorite(selectedItem));
      });
    }
  
    setSelectedItem(null);
  }, [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams]);


  return (
    <>
      <ul>
        {data.map((category) => (
          <div key={category.title} className={styles.menuRow}>
            <div
              onClick={() => {
                dispatch(setCategory(category?.actionType));
              }}
            >
              <div className={styles.menuItem}>
                <img
                  className={styles.icon}
                  src={category?.actionType === selectedCategory ? PinGrey : PinRed}
                />
                <span style={category?.actionType === selectedCategory ? activeStyle : {}}>
                  {category.title}
                </span>
              </div>
              <div className={styles.chevronColumn}>
                <img
                  className={styles.chevron}
                  src={category?.actionType === selectedCategory ? ChevronGrey : ChevronRed}
                />
              </div>
            </div>
            {category?.actionType === selectedCategory ? (
              <ul className={styles.subCategory}>
                {category.children.map((child) => {
                  return (
                    <li
                      key={child.title}
                      style={child?.actionType === selectedSubCategory ? activeStyle : {}}
                    >
                      <img
                        className={styles.subIcon}
                        src={(checkHomeworkWithFavorite(child) || checkFavorite(child)) ? PinRed : PinGrey}
                        onClick={() => {
                          handleFavoriteClick(child)
                        }}
                      />
                      <Link
                        to={child?.subFolder + '/' + child.title + '/' + fromListViewPage}
                        onClick={(e) => {
                          console.log(accessAllVideos);
                          if (accessAllVideos !== true) {
                            e.preventDefault();
                            setShowUpgradePopup(true);
                          }
                        }}
                      >
                        {child.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            ) : (
              ''
            )}
          </div>
        ))}
        
      </ul>
      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default SelectVideos;
