import React from 'react';
import styles from './ButtonList.module.sass';

export function ButtonList(props) {
  const { chosenScreen, changeChosenScreen } = props;

  const handleButtonChange = (event) => {
    changeChosenScreen(event.target.value);
  };

  return (
    <>
      {Object.keys(props.children).map((button) => {
        const isActive = button === chosenScreen;
        return (
          <li key={button}>
            <label className={styles.radio_button_label}>
              <input
                type="radio"
                onChange={handleButtonChange}
                className={styles.radio_button}
                name="managing"
                value={button}
                checked={isActive}
              />
              <img
                src={isActive ? props.children[button][1] : props.children[button][0]}
                className={styles.button_picture}
                alt={button}
              />
              <p className={`${isActive ? styles.active_button : styles.button_text}`}>{button}</p>
            </label>
          </li>
        );
      })}
    </>
  );
}
