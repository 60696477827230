import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import styles from './SignIn.module.sass';
import Cookies from 'universal-cookie';
import { profileInfoRequest, signInRequest } from '../../../api/api';
import { message, Modal } from 'antd';
import SignInMobile from './SignInMobile/SignInMobile';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

function SignIn(props) {
  const [toggActiveInactSubParentBtn, setToggActiveInactSubParentBtn] = useState(false);
  const [toggActiveInactSubPlayerBtn, setToggActiveInactSubPlayerBtn] = useState(false);

  const cookies = new Cookies();
  const history = useHistory();

  useScrollToTop();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setToggActiveInactSubParentBtn(true);
      try {
        signInRequest(values.email, values.password, 'signIn', 'email').then((response) =>
          parentRequestHandler(response),
        );
      } catch (e) {
        console.log(e);
      }
    },
  });

  const parentRequestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        cookies.set('finaleToken', ``, { path: '/' });
        cookies.set('finaleToken', `${result.token}`, { path: '/', maxAge: 3600 * 30 });
        try {
          profileInfoRequest().then((response) => requestHandler(response));
        } catch (e) {
          console.log(e);
        }
      } else {
        setToggActiveInactSubParentBtn(false);
        message.error('Invalid email or password!');
      }
    });
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        props.setUserData(result);
        props.setRole('parent');
        props.setLogin(true);
        history.replace('/dashboard');
      }
    });
  };

  const formik2 = useFormik({
    initialValues: {
      email2: '',
      password2: '',
    },
    validationSchema: Yup.object({
      email2: Yup.string().required('Required'),
      password2: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setToggActiveInactSubPlayerBtn(true);
      try {
        signInRequest(values.email2, values.password2, 'signInChild', 'username').then((response) =>
          childRequestHandler(response),
        );
      } catch (e) {
        console.log(e);
      }
    },
  });
  const childRequestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        cookies.set('finaleToken', ``, { path: '/' });
        cookies.set('finaleChildToken', `${result.token}`, { path: '/', maxAge: 3600 * 30 });
        props.setOnlyChildData(result);
        props.setRole('child');
        props.setLogin(true);
        history.replace('/dashboardChild');
      } else if (response.status === 500) {
        Modal.error({
          title: 'An error has occurred',
          content: 'Your subscription has expired',
        });
        setToggActiveInactSubPlayerBtn(false);
      } else {
        setToggActiveInactSubPlayerBtn(false);
        message.error('Invalid username or password');
      }
    });
  };

  return (
    <main className={styles.main}>
      <section style={styles.mobile_signin}>
        <SignInMobile
          formik={formik}
          formik2={formik2}
          path={props.path}
          toggActiveInactSubPlayerBtn={toggActiveInactSubPlayerBtn}
          toggActiveInactSubParentBtn={toggActiveInactSubParentBtn}
        />
      </section>
      <section className={styles.section}>
        <h2 className={styles.h2}>
          <strong>Single</strong> Sign In
        </h2>
        <form className={styles.form} name={'Parent'} onSubmit={formik.handleSubmit}>
          <div className={styles.div}>
            <label htmlFor="email" className={styles.label}>
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@gmail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email.replace(/[^A-z@.0-9-]/g, '')}
              className={
                formik.touched.email && formik.errors.email
                  ? `${styles.input} ${styles.error}`
                  : styles.input
              }
            />
          </div>
          <div className={styles.div}>
            <label htmlFor="password" className={styles.label}>
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formik.values.password.replace(/[\s]/g, '')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.password && formik.errors.password
                  ? `${styles.input} ${styles.error}`
                  : styles.input
              }
            />
          </div>
          <div className={styles.linkCont}>
            <Link className={styles.fpLink} to={props.path.sign.forgot}>
              Forgot password?
            </Link>
            <button className={styles.btn} type="submit" disabled={toggActiveInactSubParentBtn}>
              Sign In
            </button>
          </div>
        </form>

        <p className={styles.p}>
          Don&apos;t have an account? <Link to={props.path.sign.upFree}>Sign Up</Link>
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.h2}>
          <strong>Player</strong> Sign In
        </h2>
        <form className={styles.form} onSubmit={formik2.handleSubmit}>
          <div className={styles.div}>
            <label htmlFor="email2" className={styles.label_user}>
              Username
            </label>
            <input
              type="text"
              name="email2"
              id="email2"
              placeholder="exampleUserName"
              onChange={formik2.handleChange}
              onBlur={formik2.handleBlur}
              value={formik2.values.email2.replace(/[^A-z@._0-9-]/g, '')}
              className={
                formik2.touched.email2 && formik2.errors.email2
                  ? `${styles.input} ${styles.error}`
                  : styles.input
              }
            />
          </div>
          <div className={styles.div}>
            <label htmlFor="pass2" className={styles.label_password}>
              Password
            </label>
            <input
              type="password"
              name="password2"
              id="password2"
              value={formik2.values.password2.replace(/[\s]/g, '')}
              onChange={formik2.handleChange}
              onBlur={formik2.handleBlur}
              className={
                formik2.touched.password2 && formik2.errors.password2
                  ? `${styles.input} ${styles.error}`
                  : styles.input
              }
            />
          </div>
          <div className={styles.btnCont}>
            <button className={styles.btn} type="submit" disabled={toggActiveInactSubPlayerBtn}>
              Sign In
            </button>
          </div>
        </form>
      </section>
    </main>
  );
}

export default SignIn;
