import React from 'react';
import styles from './ClosePopupButton.module.sass';

export function ClosePopupButton(props) {
    const handleClosePopupButtonClick = () => {
        if (typeof props.handleClosePopupButtonClick === 'function') {
            props.handleClosePopupButtonClick();
        }
    };

    return (
        <div className={styles.closeImg} onClick={handleClosePopupButtonClick}>
            &times;
        </div>
    );
};