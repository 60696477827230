import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../MobileHeader/mobileHeader.module.sass';
import menuClose from '../../../../asset/home/menuClose.webp';

const WelcomeMenu = (props) => {
  return (
    <div className={styles.mobile_container}>
      <div className={styles.mobile_links}>
        <img
          src={menuClose}
          alt="menuClose"
          onClick={() => props.setMobileMenu(!props.mobileMenu)}
        />
        <ul>
          <div>
            <Link to={props.path.home}>
              <li className={styles.li} onClick={() => props.setWelcomePage(false)}>
                Home
              </li>
            </Link>
          </div>

          <div>
            <li className={styles.li}>
              <a
                href="https://www.facebook.com/groups/anytimesoccer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Community
              </a>
            </li>
          </div>
          <div>
            <li className={styles.li}>
              <a href="https://anchor.fm/anytime-soccer" target="_blank" rel="noopener noreferrer">
                Podcast
              </a>
            </li>
          </div>
        </ul>
      </div>

      <div className={styles.home_links}>
        <Link to={props.path.home}>
          <li className={styles.visit_btn} onClick={() => props.setWelcomePage(false)}>
            Visit Us
          </li>
        </Link>

        <Link to={props.path.sign.in}>
          <li className={styles.signIn} onClick={() => props.setWelcomePage(false)}>
            <i className="fas fa-user"></i>&nbsp;&nbsp;&nbsp;Sign In
          </li>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeMenu;
