import React, { useEffect, useRef, useState } from 'react';
import { message } from "antd"
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopap.module.sass';
import { ClosePopupButton } from '../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { Tag, Input, Tooltip } from 'antd';
import { addHomeworkTag, getHomeworkById, getHomework } from '../../../api/api';
import { setHomework } from '../../../Redux/listViewReducer';
import { useDispatch } from 'react-redux';

export function ModalHomeworkTag({onClose, homeworkId }) {
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch()
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    let isActive = true;
  
    if (isActive) {
      getHomeworkById(homeworkId).then((data) => {
        const dataTags = Array.isArray(data.data?.tags) && data.data.tags.length > 0 
          ? data.data.tags 
          : [];
        setTags(dataTags);
      });
    }
  
    return () => {
      isActive = false;
    };
  }, [homeworkId]);
  
  

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const onSubmit = async () => {
    await addHomeworkTag(homeworkId, {tags}).then((res) => message.success(res.message))
    await getHomework().then((res) => {
      dispatch(setHomework(res))
      onClose()
    })
  }

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton handleClosePopupButtonClick={onClose} />
        <h3 style={{ padding: '0 7rem' }} className={styles.invite_popup__title}>Assignment</h3>
        <div className={styles.container}>
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            border: '1px solid #164162',
            padding: '0px 10px 10px 10px',
            borderRadius: '4px'
          }}>
            {tags.map((tag, index) => {
              const colors = ['#dd373e', '#164162', '#bfcdd9'];
              const tagColor = colors[(index % colors.length)];
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={editInputRef}
                    key={tag}
                    size="small"
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                    style={{
                      marginTop: '10px'
                    }}
                  />
                );
              }

              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable
                  style={{ userSelect: 'none', display: 'flex', alignItems: 'center', marginTop: '10px', fontSize: '15px', gap: '5px', padding: '3px' }}
                  onClose={() => handleClose(tag)}
                  color={tagColor}
                  closeIcon={<div style={{ background: "#fff",
                     padding: '2px',
                     borderRadius: '50%',
                     flexShrink: 0,
                     width: '15px',
                     height: '15px',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     fontSize: '13px',
                     fontWeight: 600,
                     color: '#dd373e'
                  }}>
                    &times;
                  </div>}
                >
                  <span
                    onDoubleClick={(e) => {
                      if (index !== 0) {
                        setEditInputIndex(index);
                        setEditInputValue(tag);
                        e.preventDefault();
                      }
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
            {inputVisible ? (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
                style={{
                  marginTop: '10px'
                }}
              />
            ) : (
              <Tag style={{
                borderStyle: 'dashed',
                marginTop: '10px',
                fontSize: '14px',
                padding: '3px'
              }} onClick={showInput}>+ Tag</Tag>
            )}
          </div>
          <button type='button' onClick={() => setTags([])} style={{
            padding: '2px',
            border: 'none',
            background: 'none',
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '13px',
            fontWeight: 600,
            color: '#dd373e',
            marginTop: '10px'
          }}>Clear All</button>
        </div>
        <div className={styles.container} style={{ paddingTop: '0px' }}>
          <div style={{ borderBottom: '0.1rem solid #bfcdd9', marginBottom: '2rem' }} />
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={onClose}
              width={'35rem'}
            />
            <RedButton
              width={'35rem'}
              handleButtonClick={() => onSubmit()}
              title={'Save'}
            />
          </div>
        </div>
      </section>
    </>
  );
}
