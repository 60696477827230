import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './Table.module.sass';
import { Link, useHistory } from 'react-router-dom';
import lockalPath from '../../../config/routes';
import { loginAsPlayer } from '../../../api/api';
import Cookies from 'universal-cookie';
import ChildrenRowMobile from './ChildrenRowMobile/ChildrenRowMobile';
import ImageLogin from '../../../../src/asset/dashboard/dashboardChild/avatar.webp';

function ChildrenRow(props) {
  const [resultStatus, setResultStatus] = useState();
  const cookies = new Cookies();
  const history = useHistory();

  const getStatusTag = () => {
    if (props.state === 'deactive') return setResultStatus('Inactive');
    switch (props.status) {
      case 'active':
        return setResultStatus('Active');
      case 'free':
        return moment().isBefore(moment(props.subPeriodEnd))
          ? setResultStatus('Renew')
          : setResultStatus('Free');
      default:
        return setResultStatus('Renew');
    }
  };

  useEffect(() => {
    getStatusTag();
  }, [props.status]);

  const loginAsPlayerRequest = () => {
    loginAsPlayer(props.userName).then((response) => requestHandler(response));
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        props.setOnlyChildData({
          username: result.username,
          childEmail: result.childEmail,
          screenname: result.screenname,
          firstname: result.firstname,
          birthyear: result.birthyear,
          image: result.image,
          picture: result.picture,
          status: result.status,
          state: result.state,
          accessAllVideos: result.accessAllVideos,
          last_login: result.last_login,
        });
        cookies.set('finaleToken', ``, { path: '/' });
        cookies.set('finaleChildToken', `${result.token}`, { path: '/', maxAge: 3600 * 30 });
        props.setRole('child');
        history.replace(lockalPath.dashboardChild.main);
      }
    });
  };
  /** Child status: active - paid, free - free period of usage, nonSubscription - probably never use,
   * Child state describes if the child account is active or inactive. There is only two variants.
   * Inactive one says that user can not use his / her account.
   */

  const checkPermissionLogin =
    !(props.state === 'deactive') &&
    (props.status === 'active' || props.status === 'nonSubscription' || props.status === 'free');
  const checkPhotoOrPicture = () => {
    let avatar = null;
    if (props.image) {
      avatar = 'image';
    } else if (props.picture) {
      avatar = 'picture';
    }
    switch (avatar) {
      case 'image':
        return (
          <p className={styles.photo_item}>
            <img alt="avatar" src={props.image} className={styles.photo} />
          </p>
        );
      case 'picture':
        return (
          <p className={styles.avatar_item}>
            <img
              alt="avatar"
              src={require(`../../../asset/dashboard/avatars/${props.picture}_100.webp`)}
              className={styles.avatar_picture}
            />
          </p>
        );
      default:
        return (
          <p className={styles.avatar_item}>
            <img alt="avatar" src={ImageLogin} className={styles.avatar_picture} />
          </p>
        );
    }
  };

  return (
    <>
      {/*MOBILE COMPONENT DASHBOARD*/}
      <ChildrenRowMobile
        resultStatus={resultStatus}
        slider={props.slider}
        setSlider={props.setSlider}
        changeSlide={props.changeSlide}
        screenName={props.screenName}
        status={props.status}
        userName={props.userName}
        email={props.email}
        picture={props.picture}
        image={props.image}
        count={props.count}
        loginAsPlayerRequest={loginAsPlayerRequest}
      />

      {/*MOBILE COMPONENT DASHBOARD END*/}
      <div
        className={styles.tbody__row}
        style={checkPermissionLogin ? { color: '#000000' } : { color: '#b5b9b8' }}
      >
        {checkPhotoOrPicture()}
        <div className={styles.tbody__row_bigEl}>
          {props.screenName.length >= 22 ? props.screenName.slice(0, 17) + '...' : props.screenName}
        </div>
        <div className={styles.tbody__row_bigEl}>
          {props.userName.length >= 25 ? props.userName.slice(0, 17) + '...' : props.userName}
        </div>
        <div className={styles.tbody__row_bigEmail}>
          {props.email.length >= 22 ? props.email.slice(0, 17) + '...' : props.email}
        </div>
        <div className={styles.tbody__row_smallEl}>
          {resultStatus === 'Renew' ? (
            <Link to={lockalPath.dashboard.reactivationPlayer + '/' + props.count}>
              <p>{resultStatus}</p>
            </Link>
          ) : (
            <p>{resultStatus}</p>
          )}
        </div>
        <div>
          <button
            onClick={loginAsPlayerRequest}
            className={styles.button}
            style={
              checkPermissionLogin ? { backgroundColor: '#dd373e' } : { backgroundColor: '#b5b9b8' }
            }
            disabled={checkPermissionLogin ? false : true}
          >
            Login
          </button>
          {/*пробрасываю id ребёнка в путь*/}
          <Link
            to={lockalPath.dashboard.editPlayerInfo + '/' + props.count}
            className={`${styles.button} ${styles.bodered}`}
          >
            Edit player information or password
          </Link>
        </div>
      </div>
    </>
  );
}

export default ChildrenRow;
