import React, { useEffect, useState } from 'react';
import { RedButton } from '../../../commonComponents/RedButton/RedButton';
import { PopupWrapper } from '../../../commonComponents/PopupWrapper/PopupWrapper';
import styles from './ModalWindowPopupSendMessage.module.sass';
import { ClosePopupButton } from '../../../commonComponents/ClosePopupButton/ClosePopupButton';
import { BorderedButton } from '../../../commonComponents/BorderedButton/BorderedButton';
import Dropdown from '../../Dropdown/Dropdown'
import { PopupStatusProfilePage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllMembersData } from '../../asyncActions/allMembers';
import { sendMessageProfile } from '../../../../api/teams-api';
import { message } from 'antd';

export function ModalWindowPopupSendMessage({setPopupStatusProfilePage,teamId, profileData, childId } ) {
  const dispatch = useDispatch();
  const [checkedCheckbox, setCheckedCheckbox] = useState(false);
  const [emailMessage, setEmailMessage] = useState('')
  const [selectedValue, setSelectedValue] = useState( []);

  useEffect(() => {
    dispatch(fetchAllMembersData({ teamId }))
  },[teamId])

  const allMembers = useSelector(state => state?.allMembersDataReducer?.allMembers);

  const defaultValue = allMembers.filter((value) => value.childId === parseInt(childId));

  useEffect(() => {
    setSelectedValue(defaultValue)
  },[allMembers])

  const handleClosePopupButtonClick = () => {
    setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
  };


  const handleActivationPopupButtonClick = () => {
    sendMessageProfile({ emails: selectedValue.map((element) => element.email) ,
      message: emailMessage,
      childName: `${profileData.firstName} ${profileData.lastName}` } ).then((response) => {
      if (response.ok) {
        setPopupStatusProfilePage(PopupStatusProfilePage.INACTIVE)
      } else {
        message.error('Something went wrong. Please try again later.')
      }
    })
  };

  const handleCheckbox = () => {
    if(checkedCheckbox === false){
      setSelectedValue(allMembers)
      setCheckedCheckbox(true)
    }
    if(checkedCheckbox === true){
      setSelectedValue([])
      setCheckedCheckbox(false)
    }
  }



  return (
    <>
      <PopupWrapper />
      <section className={styles.invite_popup}>
        <ClosePopupButton
          handleClosePopupButtonClick={handleClosePopupButtonClick}
        />
        <h3 className={styles.invite_popup__title}> Send Message</h3>
        <div className={styles.container}>
          <form className={styles.form}>
            <span className={styles.invite_popup__label}>
              To:
            </span>
            <Dropdown
              isMulti
              placeHolder="Select..."
              allMembers={allMembers}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            <div className={styles.form__label__checkbox} >
              <input
                type="checkbox"
                id="cb1"
                onClick={handleCheckbox}
              />
              <label htmlFor="cb1">Entire team</label>
            </div>
            <label className={styles.invite_popup__label}>
              Message
              <textarea
                className={styles.invite_popup__textarea}
                onChange={(value) => setEmailMessage(value.target.value)}
              ></textarea>
            </label>
          </form>
          <div className={styles.buttons}>
            <BorderedButton
              handleButtonClick={handleClosePopupButtonClick}
              width={'35rem'}
            />
            <RedButton
              width={'35rem'}
              handleButtonClick={handleActivationPopupButtonClick}
              title={'Send'} />
          </div>
        </div>
      </section>
    </>
  );
}