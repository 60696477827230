import React, { useEffect, useState } from 'react';
import styles from './editPlayerInfo.module.sass';
import { Input } from 'reactstrap';
import EditToolImg from '../../../asset/dashboard/editPlayerInfo/editTool.webp';
import { useHistory, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';
import { profileInfoRequest, editChildUser } from '../../../api/api';
import { withRoleRedirectComp, haveNoLoginRedirect } from '../../../hoc/redirectComp';
import { compose } from 'redux';
import { AvatarComponent } from '../AvatarComponent/AvatarComponent';
import { capitalizeWord, getFormData } from '../../../utils/utils.js';

const EditPlayerInfo = (props) => {
  const currentChild = props.childInfo[`child` + props.match.params.childCount];
  const [contactEmail, setContactEmail] = useState(true);
  const [toggPassBtn, setToggPassBtn] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [avatarNumber, setAvatarNumber] = useState(1);
  const [renewOrEnds, setRenewOrEnds] = useState('');
  const [childState, setChildState] = useState(currentChild.state || '');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const childStatus = currentChild.status;
  const history = useHistory();
  let finaleDate = '';

  // TODO: next block doesn't work?
  if (props.userData.status === 'active') {
    const date = props.userData.childInfo[`child${props.match.params.childCount}`].subPeriodEnd
      .slice(0, 19)
      .replace('T', ' ');
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    finaleDate = `${month + '.' + day + '.' + year}`;
  }
  const statusTag = [];

  useEffect(() => {
    if (childStatus === 'active') {
      statusTag.push('Active');
    } else {
      statusTag.push('Non Subscription');
    }
  }, []);

  useEffect(() => {
    if (childStatus === 'active') {
      setRenewOrEnds('will renew');
    } else if (childStatus === 'nonSubscription') {
      setRenewOrEnds('ends');
    }
  }, []);

  useEffect(() => {
    if (isFormSubmitted) {
      const formData = getFormData({ ...requestData, username });
      editChildUser(formData).then((response) => {
        if (response.ok) {
          try {
            profileInfoRequest().then((response) => requestHandler(response));
          } catch (e) {
            console.log(e);
          }
        } else if (response.status === 403) {
          message.error('Player with this user name has already exist.');
        }
      });
    }
  }, [isFormSubmitted]);

  const childBirthYear = currentChild.birthyear;
  const username = currentChild.username;
  // =======================================================================

  const addToRequestData = (e) => {
    // На нажатия onBlur добавляет value где вызвался этот onBlur в state
    if (e.currentTarget.value.trim() !== '') {
      const name = e.currentTarget.name;
      setRequestData({ ...requestData, [name]: e.currentTarget.value.trim() });
    }
  };

  const changeContactEmailBtn = () => {
    setContactEmail(!contactEmail);
  };

  const changeToggPassBtn = () => {
    setToggPassBtn(!toggPassBtn);
  };

  const requestHandler = (response) => {
    response.json().then((result) => {
      if (response.status === 200) {
        props.setUserData(result);
        history.replace('/dashboard/editPlayerThank');
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      childEmail: currentChild.childEmail,
      status: currentChild.status,
      password: '',
      newUsername: username,
      screenname: currentChild.screenname,
      birthyear: '',
    },
    validationSchema: Yup.object({
      childEmail: Yup.string().trim().email('Invalid email address').required('Required'),
      status: Yup.string().trim().required('Required'),
      screenname: Yup.string().trim().required('Required'),
      birthyear: '',
    }),
    onSubmit: () => {
      if (Object.keys(requestData).length) {
        setIsFormSubmitted(true);
      } else {
        message.error(
          'You have not changed any fields. To return to the previous page press cancel.',
        );
      }
    },
  });

  const handleActivationButtonClick = () => {
    if (childState === 'active') {
      setChildState('deactive');
      setRequestData({ ...requestData, state: 'deactive' });
      message.warning({
        content: 'If you save these changes, this user account will be deactivated.',
        duration: 5,
      });
    } else {
      setChildState('active');
      message.success({
        content: 'If you save these changes, this user account will be activated.',
        duration: 5,
      });
      setRequestData({ ...requestData, state: 'active' });
    }
  };

  const getYears = (min, max) => {
    const years = [];
    for (let i = max; i >= min; i--) years.push(i);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  return (
    <div className={styles.container}>
      <form encType="multipart/form-data" onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={styles.infoPageContainer}>
          <div>
            <h3 className={styles.main_title}>Player Account Information</h3>
            <p className={styles.subTitle1}>Username</p>
            <p className={styles.text}>{currentChild.username}</p>
            <p className={styles.subTitle2}>Contact Email</p>
            <div className={styles.contactEmailDiv}>
              {contactEmail ? (
                <p className={styles.greyText}>{formik.values.childEmail}</p>
              ) : (
                <div className={styles.contactEmail}>
                  <div className={styles.col__half_rightP}>
                    <input
                      name="childEmail"
                      id="childEmail"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={addToRequestData}
                      className={styles.childEmailInput}
                      value={formik.values.childEmail.replace(/[^A-z@._0-9 -]/g, '')}
                    />
                  </div>
                </div>
              )}
              <button type="button" className={styles.button} onClick={changeContactEmailBtn}>
                Update Contact Email
              </button>
            </div>
            <p className={styles.subTitle2}>Status</p>
            <div className={styles.contactEmailDiv}>
              <p className={styles.greyText}>
                {childState === 'deactive' ? 'Inactive' : capitalizeWord(childState)}
              </p>
              <button type="button" className={styles.button} onClick={handleActivationButtonClick}>
                {childState === 'deactive' ? 'Make Active' : 'Make Inactive'}
              </button>
            </div>
            {/* ====================  PASSWORD  ====================== */}
            <p className={styles.subTitle2}>Password</p>
            {toggPassBtn ? (
              <div className={styles.contactEmail}>
                <input
                  name="password"
                  id="password"
                  type="password"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={addToRequestData}
                  value={formik.values.password.replace(/[\s]/g, '')}
                  className={styles.contactEmailInput}
                />
              </div>
            ) : (
              <div onClick={changeToggPassBtn}>
                <div className={styles.editTool}>
                  <p>
                    <img src={EditToolImg} alt="Change Player Password" />
                  </p>
                  <p className={styles.editToolText}>Change Player Password</p>
                </div>
              </div>
            )}
            <div className={styles.online_profiles}>
              <h4 className={styles.online_profiles_title}>Online Profiles</h4>
              <div className={styles.screenBirthDiv}>
                <div>
                  <h5>User name</h5>
                  <input
                    name="newUsername"
                    id="newUsername"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={addToRequestData}
                    value={formik.values.newUsername}
                    className={styles.screenName}
                  />
                </div>
                <div>
                  <h5>
                    Screen name<span>*</span>
                  </h5>
                  <input
                    name="screenname"
                    id="screenname"
                    type="text"
                    placeholder="Eduardo"
                    onChange={formik.handleChange}
                    onBlur={addToRequestData}
                    value={formik.values.screenname}
                    className={styles.screenName}
                  />
                </div>
                <div className={styles.dirthFormDiv}>
                  <h5>
                    Birth Year<span>*</span>
                  </h5>
                  <Input
                    name="birthyear"
                    className={styles.birthForm}
                    type="select"
                    bsSize="lg"
                    onChange={formik.handleChange}
                    onBlur={addToRequestData}
                    value={formik.birthyear}
                  >
                    {childBirthYear ? (
                      <option value={childBirthYear}>{childBirthYear}</option>
                    ) : null}
                    {getYears(1950, new Date().getFullYear())}
                  </Input>
                </div>
              </div>
            </div>
            <AvatarComponent
              requestData={requestData}
              setRequestData={setRequestData}
              avatarNumber={avatarNumber}
              setAvatarNumber={setAvatarNumber}
            />
            <div className={styles.subAndCancelBtn}>
              <button className={styles.submitButton} style={{ outline: 'none' }} type="submit">
                Submit
              </button>
              <button
                className={styles.cancelButton}
                style={{ outline: 'none' }}
                onClick={() => history.replace('/dashboard/')}
              >
                Cancel
              </button>
            </div>
          </div>
          <div>
            {props.playerData === 'Active' && (
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <h4>Subscription</h4>
                  <p className={styles.cardTitleGreyStr}>
                    Your subscription {renewOrEnds} on {finaleDate}.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default compose(withRouter, withRoleRedirectComp, haveNoLoginRedirect)(EditPlayerInfo);
