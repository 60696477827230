import { SubfolderPageUrl } from '../constants/const';

const initialState = {
  data: [
    {
      title: 'Choice of Juggle Master',
      subTitle: '',
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_96_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(Intermed to Adv) Ball Mastery',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_92_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: '(Interm to Adv) Dribbling Drills',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_93_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },
    {
      title: 'Choose Passing Wall & Partner',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_110_grey_black.webp',
      link: SubfolderPageUrl.videoCategories,
    },

    {
      title: 'Select 1v1 Moves',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_307_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },

    {
      title: 'Select Finishing',
      subTitle: '',
      isSeries: true,
      img: '/img/home/new-design/AST_Free_Section_Dashboard_Simple_Cards_308_black_grey.webp',
      link: SubfolderPageUrl.videoCategories,
    },
  ],
};

const quickSessionsReducer = (state = initialState) => {
  return state;
};

export default quickSessionsReducer;
